import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  EEPProgramHomeStatusState,
  EEPProgramHomeStatusStateLabelMapping,
  HIRLEEPProgramHomeStatusStateLabelMapping,
} from '@/data/home/models/eep_program_home_status';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { User } from '@/data/core/models/user';
import {
  EEPProgramHomeStatusProjectReportDownload,
  EEPProgramHomeStatusProjectReportExportField,
  ESGReportList,
  PSRReportList,
} from '@/data/home/models';
import { forkJoin, Subject, Subscription } from 'rxjs';
import {
  CustomerHirlProjectRegistrationFinanceTypeLabelMapping,
  EEPProgramHomeStatusRequestParams,
  EEPProgramHomeStatusService,
} from '@/data/home/services/eep_program_home_status.service';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  CompanyAccessRequestParams,
  CompanyAccessService,
} from '@/data/company/services/company-access.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { first, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import {
  ObservationType,
  QAHIRLCertificationLevelAwardedLabelMapping,
  QARequirementType,
  QARequirementTypeLabelMapping,
  QAStatusStateLabelMapping,
} from '@/data/qa/models';
import {
  Company,
  CompanyAccess,
  CompanyInfo,
  CompanyType,
} from '@/data/company/models';
import {
  PsrExportFieldsDialogComponent,
  PsrExportFieldsDialogData,
} from '@/modules/home/components/psr-export-fields-dialog/psr-export-fields-dialog.component';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { toggleLoading } from '@/state/actions/app.actions';
import { EEPProgramInfo } from '@/data/eep-program/models';
import { SubdivisionInfo } from '@/data/subdivision/models';
import {
  City,
  Country,
  County,
  Metro,
  USState,
} from '@/data/geographic/models';
import {
  HIRLGreenEnergyBadge,
  HIRLProjectAppealsProjectTypeMapping,
  HIRLProjectCommercialSpaceTypeLabelMapping,
  HIRLProjectSamplingMapping,
} from '@/data/customer-hirl/models';
import { ETORegionLabelMapping } from '@/data/customer-eto/models';
import { CustomerETOSettings } from '@/modules/customer-eto/constants';
import { OkDialogComponent } from '@/shared/components/ok-dialog/ok-dialog.component';
import { SchedulingTaskType } from '@/data/scheduling/models';
import { HttpErrorResponse } from '@angular/common/http';
import { ServerResponse } from '@/core/schemes/server-response';
import { AsynchronousProcessedDocumentStatusDialogComponent } from '@/modules/filehandling/components/asynchronous-processed-document-status-dialog/asynchronous-processed-document-status-dialog.component';
import { YesNoPipe } from '@/shared/pipes/yes-no.pipe';

@Component({
  selector: 'app-psr-list',
  templateUrl: './psr-list.component.html',
  styleUrls: ['./psr-list.component.scss'],
  providers: [YesNoPipe],
})
export class PsrListComponent implements OnInit, OnDestroy {
  protected readonly QARequirementTypeLabelMapping =
    QARequirementTypeLabelMapping;
  protected readonly QAHIRLCertificationLevelAwardedLabelMapping =
    QAHIRLCertificationLevelAwardedLabelMapping;
  protected readonly HIRLProjectCommercialSpaceTypeLabelMapping =
    HIRLProjectCommercialSpaceTypeLabelMapping;
  protected readonly HIRLProjectSamplingMapping = HIRLProjectSamplingMapping;
  protected readonly CustomerHirlProjectRegistrationFinanceTypeLabelMapping =
    CustomerHirlProjectRegistrationFinanceTypeLabelMapping;
  protected readonly HIRLProjectAppealsProjectTypeMapping =
    HIRLProjectAppealsProjectTypeMapping;
  protected readonly ETORegionLabelMapping = ETORegionLabelMapping;
  protected readonly CompanyType = CompanyType;
  public MAX_EXPORT_RESULTS = 2000;
  public initialized = false;

  public qaRequirementTypeChoicesLabelMapping = {};

  public tableStateLabelMapping = {
    ...EEPProgramHomeStatusStateLabelMapping,
    ...HIRLEEPProgramHomeStatusStateLabelMapping,
  };

  public EEPProgramHomeStatusStateLabelMapping = {
    not_certified: 'Not Certified',
    not_certified_and_not_abandoned: 'Not Certified (exclude Abandoned)',
    ...EEPProgramHomeStatusStateLabelMapping,
    ...HIRLEEPProgramHomeStatusStateLabelMapping,
  };

  public QAStatusStateLabelMapping = {
    not_in_progress: 'Not In Progress',
    addable: 'QA Addable',
    does_not_exist: 'Does Not Exist',
    not_complete: 'Not Complete',
    ...QAStatusStateLabelMapping,
  };

  public displayedColumns = [
    'home__street_line1',
    'home__subdivision__name',
    'floorplan__name',
    'eep_program__name',
    'state',
    'qa_status',
  ];

  public filterFromGroup: FormGroup;

  public currentUser: User;
  public showCustomerETOFilters = false;
  public showCustomerHIRLFilters = false;
  public showBuilderCompanyField = false;
  public showRaterCompanyField = false;
  public showProviderCompanyField = false;
  public showProgramSponsorCompanyField = false;
  public showUtilityCompanyField = false;
  public showHVACCompanyField = false;
  public showQACompanyField = false;
  public showRaterOfRaterField = false;

  public qaStatusQADesigneeFilterParams = null;
  public qaStatusQADesigneeOptionDisplay: (value: User) => string =
    this.userFilterOptionDisplayWithCompany;

  public homeStatusRelated = true;

  public entities: PSRReportList[];
  public entitiesCount: number;
  public entitiesIsLoading = true;

  public aggregateSubscription$: Subscription;

  public defaultParams: EEPProgramHomeStatusRequestParams =
    new EEPProgramHomeStatusRequestParams(1, '', '-created_date', 25);
  public storedParams: EEPProgramHomeStatusRequestParams =
    new EEPProgramHomeStatusRequestParams(1, '', '-created_date', 25);

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private eepProgramHomeStatusService: EEPProgramHomeStatusService,
    private companyAccessService: CompanyAccessService,
    private router: Router,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private yesNoPipe: YesNoPipe,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit() {
    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    })
      .pipe(
        takeUntil(this.componentDestroyed$),
        switchMap(({ queryParams, currentUser }) => {
          const params = new CompanyAccessRequestParams();
          params.company = currentUser.company;
          return this.companyAccessService.list(params).pipe(
            first(),
            map(companyAccessesResponse => {
              const companyAccesses = companyAccessesResponse.results;
              let companyAccess = null;
              if (companyAccesses.length) {
                companyAccess = companyAccesses[0];
              }
              return {
                queryParams: queryParams,
                currentUser: currentUser,
                companyAccess: companyAccess,
              };
            })
          );
        })
      )
      .subscribe(({ queryParams, currentUser, companyAccess }) => {
        this.currentUser = currentUser;
        this.storedParams.assignQueryParams(queryParams, [
          'state',
          'eep_program',
          'eep_program__slug',
          'qa_status__state',
          'customer_hirl_project__certification_level',
          'customer_hirl_project__sampling',
          'qastatus__requirement__type',
          'qastatus__observation__observation_type',
          'qa_status__qa_designee',
          'qastatus__hirl_badges_awarded',
          'customer_eto_region',
          'customer_hirl_project__registration__financing_type',
          'customer_hirl_project__billing_state',
          'customer_hirl_project__appeals_project',
          'customer_hirl_annotations__energy_path',
          'customer_hirl_rough_verifier',
          'customer_hirl_final_verifier',
          'customer_hirl_project__green_energy_badges',
          'rater_of_record',
          'energy_modeler',
          'field_inspector',
          'builder_organization',
          'rater_organization',
          'utility_organization',
          'eep_organization',
          'hvac_organization',
          'qa_organization',
          'provider_organization',
          'home__subdivision',
          'home__city',
          'home__state',
          'home__city__country',
          'home__city__county',
          'home__metro',
          'home__task__task_type',
          'home__task__assignees',
        ]);

        if (currentUser.is_superuser) {
          this.storedParams.company_access = null;
        } else {
          if (!this.storedParams.company_access) {
            this.storedParams.company_access = [companyAccess.id];
          }
        }

        if (!this.storedParams.state) {
          if (currentUser.company_info?.company_type === CompanyType.rater) {
            this.storedParams.state = ['not_certified_and_not_abandoned'];
          } else if (
            currentUser.company_info?.company_type === CompanyType.provider ||
            currentUser.company_info?.company_type === CompanyType.eep
          ) {
            this.storedParams.state = [EEPProgramHomeStatusState.complete];
          }
        }

        if (currentUser.is_superuser) {
          this.homeStatusRelated = false;
        }

        this.showCustomerHIRLFilters =
          this.currentUser?.company_info?.sponsor_slugs?.includes(
            CustomerHIRLSettings.companySlug
          ) ||
          this.currentUser?.company_info?.slug ===
            CustomerHIRLSettings.companySlug ||
          this.currentUser.is_superuser;

        this.showCustomerETOFilters =
          this.currentUser?.company_info?.sponsor_slugs?.includes(
            CustomerETOSettings.companySlug
          ) ||
          this.currentUser?.company_info?.slug ===
            CustomerETOSettings.companySlug ||
          this.currentUser.is_superuser;

        this.showRaterOfRaterField =
          this.currentUser?.company_info?.slug !==
            CustomerHIRLSettings.companySlug || this.currentUser.is_superuser;

        if (this.showCustomerETOFilters) {
          Object.assign(this.qaRequirementTypeChoicesLabelMapping, {
            [QARequirementType.fileQA]: 'File QA',
            [QARequirementType.fieldQA]: 'Field QA',
            [QARequirementType.programReview]: 'Program Review',
          });
        }
        if (this.showCustomerHIRLFilters) {
          Object.assign(this.qaRequirementTypeChoicesLabelMapping, {
            [QARequirementType.roughInspection]: 'Rough Inspection',
            [QARequirementType.roughInspectionVirtualAudit]:
              'Rough Inspection Virtual Audit',
            [QARequirementType.finalInspection]: 'Final Inspection',
            [QARequirementType.desktopAudit]: 'Desktop Audit',
            [QARequirementType.finalInspectionVirtualAudit]: 'Virtual Audit',
          });
        }

        if (
          currentUser?.company_info?.company_type !== CompanyType.builder ||
          currentUser.is_superuser
        ) {
          this.showBuilderCompanyField = true;
        }
        if (
          currentUser?.company_info?.company_type !== CompanyType.rater ||
          currentUser.is_superuser
        ) {
          this.showRaterCompanyField = true;
        }
        if (
          currentUser?.company_info?.company_type !== CompanyType.provider ||
          currentUser.is_superuser
        ) {
          this.showProviderCompanyField = true;
        }
        if (
          currentUser?.company_info?.company_type !== CompanyType.eep ||
          currentUser.is_superuser
        ) {
          this.showProgramSponsorCompanyField = true;
        }
        if (
          currentUser?.company_info?.company_type !== CompanyType.utility ||
          currentUser.is_superuser
        ) {
          this.showUtilityCompanyField = true;
        }
        if (
          currentUser?.company_info?.company_type !== CompanyType.hvac ||
          currentUser.is_superuser
        ) {
          this.showHVACCompanyField = true;
        }
        if (
          currentUser?.company_info?.company_type !== CompanyType.qa ||
          currentUser.is_superuser
        ) {
          this.showQACompanyField = true;
        }

        if (currentUser.is_superuser) {
          this.qaStatusQADesigneeFilterParams = {};
        } else if (
          this.currentUser?.company_info?.slug ===
          CustomerHIRLSettings.companySlug
        ) {
          this.qaStatusQADesigneeFilterParams = {
            hirluserprofile__is_qa_designee: true,
          };
          this.qaStatusQADesigneeOptionDisplay =
            this.userFilterOptionDisplayWithoutCompany;
        } else {
          this.qaStatusQADesigneeFilterParams = {
            company: currentUser.company,
          };
          this.qaStatusQADesigneeOptionDisplay =
            this.userFilterOptionDisplayWithoutCompany;
        }
        this.initialized = true;

        this.setupFilterForm();
        this.hydrateForm();
        this.list();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    if (this.aggregateSubscription$) {
      this.aggregateSubscription$.unsubscribe();
    }
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      company_access_info: [null],
      state: [null],
      subdivision_info: [null],
      builder_organization_info: [null],
      rater_organization_info: [null],
      utility_organization_info: [null],
      eep_organization_info: [null],
      hvac_organization_info: [null],
      qa_organization_info: [null],
      provider_organization_info: [null],
      home__city_info: [null],
      home__city__country_info: [null],
      home__metro_info: [null],
      home__city__county_info: [null],
      home__task__assignees_info: [null],
      home__task__task_type_info: [null],
      eep_program_info: [null],
      created_date__gte: [null],
      created_date__lte: [null],
      certification_date__gte: [null],
      certification_date__lte: [null],
      home__created_date__gte: [null],
      home__created_date__lte: [null],
      home__state_info: [null],
      qa_status__state: [null],
      qa_status__qa_designee_info: [null],
      qastatus__hirl_badges_awarded_info: [null],
      qastatus__requirement__type: [null],
      qastatus__observation__observation_type_info: [null],

      qastatus__hirl_water_sense_confirmed: [null],
      qastatus__hirl_commercial_space_confirmed: [null],

      rater_of_record_info: [null],
      energy_modeler_info: [null],
      field_inspector_info: [null],

      customer_hirl_project__certification_level: [null],
      customer_hirl_project__is_hud_project: [null],
      customer_hirl_project__registration__is_build_to_rent: [null],
      customer_hirl_project__green_energy_badges_info: [null],
      customer_hirl_project__appeals_project: [null],
      customer_hirl_project__is_require_wri_certification: [null],
      customer_hirl_project__is_require_water_sense_certification: [null],
      customer_hirl_project__commercial_space_type: [null],
      customer_hirl_project__sampling: [null],
      customer_hirl_project__registration__financing_type: [null],
      customer_hirl_rough_verifier_info: [null],
      customer_hirl_final_verifier_info: [null],

      customer_hirl_annotations__energy_path: [null],

      customer_eto_region: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        let params = Object.assign(this.defaultParams, values);

        params = Object.assign(params, {
          eep_program: params.eep_program_info?.map(
            (eep_program: EEPProgramInfo) => eep_program.id
          ),
          home__subdivision: params.subdivision_info?.map(
            (subdivision: SubdivisionInfo) => subdivision.id
          ),
          builder_organization: params.builder_organization_info?.map(
            (builder_organization: CompanyInfo) => builder_organization.id
          ),
          rater_organization: params.rater_organization_info?.map(
            (rater_organization: CompanyInfo) => rater_organization.id
          ),
          utility_organization: params.utility_organization_info?.map(
            (utility_organization: CompanyInfo) => utility_organization.id
          ),
          eep_organization: params.eep_organization_info?.map(
            (eep_organization: CompanyInfo) => eep_organization.id
          ),
          hvac_organization: params.hvac_organization_info?.map(
            (hvac_organization: CompanyInfo) => hvac_organization.id
          ),
          qa_organization: params.qa_organization_info?.map(
            (qa_organization: CompanyInfo) => qa_organization.id
          ),
          provider_organization: params.provider_organization_info?.map(
            (provider_organization: CompanyInfo) => provider_organization.id
          ),
          home__city: params.home__city_info?.map((city: City) => city.id),
          home__city__country: params.home__city__country_info?.map(
            (country: Country) => country.id
          ),
          home__city__county: params.home__city__county_info?.map(
            (county: County) => county.id
          ),
          home__state: params.home__state_info?.map(
            (state: USState) => state.abbr
          ),
          company_access: params.company_access_info?.map(
            (companyAccess: CompanyAccess) => companyAccess.id
          ),
          home__metro: params.home__metro_info?.map((metro: Metro) => metro.id),
          qa_status__qa_designee: params.qa_status__qa_designee_info?.map(
            (user: User) => user.id
          ),
          qastatus__observation__observation_type:
            params.qastatus__observation__observation_type_info?.map(
              (observationType: ObservationType) => observationType.id
            ),
          qastatus__hirl_badges_awarded:
            params.qastatus__hirl_badges_awarded_info?.map(
              (greenEnergyBadge: HIRLGreenEnergyBadge) => greenEnergyBadge.id
            ),
          rater_of_record: params.rater_of_record_info?.map(
            (user: User) => user.id
          ),
          energy_modeler: params.energy_modeler_info?.map(
            (user: User) => user.id
          ),
          field_inspector: params.field_inspector_info?.map(
            (user: User) => user.id
          ),
          customer_hirl_project__green_energy_badges:
            params.customer_hirl_project__green_energy_badges_info?.map(
              (greenEnergyBadge: HIRLGreenEnergyBadge) => greenEnergyBadge.id
            ),
          customer_hirl_rough_verifier:
            params.customer_hirl_rough_verifier_info?.map(
              (user: User) => user.id
            ),
          customer_hirl_final_verifier:
            params.customer_hirl_final_verifier_info?.map(
              (user: User) => user.id
            ),
          home__task__task_type: params.home__task__task_type_info?.map(
            (taskType: SchedulingTaskType) => taskType.id
          ),
          home__task__assignees: params.home__task__assignees_info?.map(
            (user: User) => user.id
          ),
        });
        this.storedParams.assignFilterFormValues({
          formValues: params,
          excludedProperties: [
            'ordering',
            'eep_program_info',
            'subdivision_info',
            'builder_organization_info',
            'rater_organization_info',
            'utility_organization_info',
            'eep_organization_info',
            'hvac_organization_info',
            'qa_organization_info',
            'provider_organization_info',
            'home__city_info',
            'home__state_info',
            'home__city__country_info',
            'home__metro_info',
            'company_access_info',
            'home__city__county_info',
            'qa_status__qa_designee_info',
            'qastatus__hirl_badges_awarded_info',
            'qastatus__observation__observation_type_info',
            'customer_hirl_project__green_energy_badges_info',
            'rater_of_record_info',
            'energy_modeler_info',
            'field_inspector_info',
            'customer_hirl_rough_verifier_info',
            'customer_hirl_final_verifier_info',
            'home__task__task_type_info',
            'home__task__assignees_info',
          ],
        });
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  list() {
    this.entitiesIsLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.eepProgramHomeStatusService
      .psr_report_list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), take(1))
      .subscribe({
        next: (data: ServerResponse<ESGReportList>) => {
          this.entities = data.results;
          this.entitiesCount = data.count;
          this.entitiesIsLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 400) {
            // to avoid cycle loop, reload list only if invalid param in query detected
            let invalidQueryParam = false;
            for (const key of Object.keys(error.error)) {
              if (this.storedParams.hasOwnProperty(key)) {
                invalidQueryParam = true;
                delete this.storedParams[key];
              }
            }

            if (invalidQueryParam) {
              this.list();
            }
          }
        },
      });
  }

  onSort($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  downloadReport($event: MouseEvent) {
    $event.preventDefault();

    const dialogData = new PsrExportFieldsDialogData();

    dialogData.export_fields =
      this.uiHelperService.getUISettings().psrExportFields;

    const dialogRef = this.dialog.open(PsrExportFieldsDialogComponent, {
      width: '50%',
      maxWidth: '750px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.store.dispatch(toggleLoading({ payload: true }));
      const projectReportDownload =
        new EEPProgramHomeStatusProjectReportDownload();
      projectReportDownload.export_fields =
        result as EEPProgramHomeStatusProjectReportExportField[];
      projectReportDownload.export_filters = this.getAppliedFilters();

      this.eepProgramHomeStatusService
        .psr_project_report_download(projectReportDownload, this.storedParams)
        .pipe(first())
        .subscribe(
          asynchronousProcessedDocument => {
            this.store.dispatch(toggleLoading({ payload: false }));

            const asynchronousProcessedDocumentDialogRef = this.dialog.open(
              AsynchronousProcessedDocumentStatusDialogComponent,
              {
                width: '50%',
                data: {
                  entity: asynchronousProcessedDocument,
                  retrieve: true,
                },
              }
            );
            asynchronousProcessedDocumentDialogRef.afterClosed().subscribe();
          },
          error => this.uiHelperService.handleUserRequestError(error)
        );
    });
  }

  get canExport(): ObjectPermissionResponse {
    if (this.entitiesIsLoading) {
      return new ObjectPermissionResponse(false, 'Loading... Please wait');
    }
    if (this.currentUser.is_superuser) {
      return new ObjectPermissionResponse(true);
    }
    if (this.entitiesCount > this.MAX_EXPORT_RESULTS) {
      return new ObjectPermissionResponse(
        false,
        `Cannot export more than ${this.MAX_EXPORT_RESULTS} at once. Filter results to reduce count`
      );
    }
    return new ObjectPermissionResponse(true);
  }

  showAppliedFiltersDialog($event: MouseEvent) {
    $event.preventDefault();

    let content = '';
    for (const [key, value] of Object.entries(this.getAppliedFilters())) {
      content += `<b>${key}</b>: ${value}<br>`;
    }

    this.dialog.open(OkDialogComponent, {
      data: {
        title: 'Current Applied Filters',
        content: content,
      },
    });
  }

  getAppliedFilters(): {
    [key: string]: string;
  } {
    const isBuildToRent =
      this.filterFromGroup.value
        .customer_hirl_project__registration__is_build_to_rent;
    const isHudProject =
      this.filterFromGroup.value.customer_hirl_project__is_hud_project;

    const isAppealsProject =
      this.filterFromGroup.value.customer_hirl_project__appeals_project;

    const isWRICertification =
      this.filterFromGroup.value
        .customer_hirl_project__is_require_wri_certification;

    const isWaterSenseCertification =
      this.filterFromGroup.value
        .customer_hirl_project__is_require_water_sense_certification;

    const isQAWaterSenseConfirmed =
      this.filterFromGroup.value.qastatus__hirl_water_sense_confirmed;

    const isQACommercialSpaceConfirmed =
      this.filterFromGroup.value.qastatus__hirl_commercial_space_confirmed;

    return this.storedParams.toAppliedFilters({
      overwritePropertyValues: {
        state:
          EEPProgramHomeStatusStateLabelMapping[
            this.filterFromGroup.value.state
          ],
        eep_program: this.filterFromGroup.value.eep_program_info?.map(
          (eep_program: EEPProgramInfo) => eep_program.name
        ),
        home__subdivision: this.filterFromGroup.value.subdivision_info?.map(
          (subdivision: SubdivisionInfo) => subdivision.name
        ),
        builder_organization:
          this.filterFromGroup.value.builder_organization_info?.map(
            (builder_organization: CompanyInfo) => builder_organization.name
          ),
        rater_organization:
          this.filterFromGroup.value.rater_organization_info?.map(
            (rater_organization: CompanyInfo) => rater_organization.name
          ),
        utility_organization:
          this.filterFromGroup.value.utility_organization_info?.map(
            (utility_organization: CompanyInfo) => utility_organization.name
          ),
        eep_organization: this.filterFromGroup.value.eep_organization_info?.map(
          (eep_organization: CompanyInfo) => eep_organization.name
        ),
        hvac_organization:
          this.filterFromGroup.value.hvac_organization_info?.map(
            (hvac_organization: CompanyInfo) => hvac_organization.name
          ),
        qa_organization: this.filterFromGroup.value.qa_organization_info?.map(
          (qa_organization: CompanyInfo) => qa_organization.name
        ),
        provider_organization:
          this.filterFromGroup.value.provider_organization_info?.map(
            (provider_organization: CompanyInfo) => provider_organization.name
          ),
        home__city: this.filterFromGroup.value.home__city_info?.map(
          (city: City) => city.name
        ),
        home__city__country:
          this.filterFromGroup.value.home__city__country_info?.map(
            (country: Country) => country.name
          ),
        home__city__county:
          this.filterFromGroup.value.home__city__county_info?.map(
            (county: County) => county.name
          ),
        home__state: this.filterFromGroup.value.home__state_info?.map(
          (state: USState) => state.abbr
        ),
        company_access: this.filterFromGroup.value.company_access_info?.map(
          (companyAccess: CompanyAccess) => companyAccess.company_info?.name
        ),
        home__metro: this.filterFromGroup.value.home__metro_info?.map(
          (metro: Metro) => metro.name
        ),
        qa_status__state: this.filterFromGroup.value.qa_status__state?.map(
          (value: string) => {
            return this.QAStatusStateLabelMapping[value];
          }
        ),
        qa_status__qa_designee:
          this.filterFromGroup.value.qa_status__qa_designee_info?.map(
            (user: User) => `${user.first_name} ${user.last_name}`
          ),
        qastatus__hirl_badges_awarded:
          this.filterFromGroup.value.qastatus__hirl_badges_awarded_info?.map(
            (hirl_green_energy_badge: HIRLGreenEnergyBadge) =>
              `${hirl_green_energy_badge.name}`
          ),
        qastatus__requirement__type:
          this.filterFromGroup.value.qastatus__requirement__type?.map(
            (value: string) => QARequirementTypeLabelMapping[value]
          ),
        qastatus__observation__observation_type:
          this.filterFromGroup.value.qastatus__observation__observation_type_info?.map(
            (observationType: ObservationType) => observationType.name
          ),
        qastatus__hirl_water_sense_confirmed:
          isQAWaterSenseConfirmed !== null
            ? this.yesNoPipe.transform(isQAWaterSenseConfirmed)
            : null,
        qastatus__hirl_commercial_space_confirmed:
          isQACommercialSpaceConfirmed !== null
            ? this.yesNoPipe.transform(isQACommercialSpaceConfirmed)
            : null,
        rater_of_record: this.filterFromGroup.value.rater_of_record_info?.map(
          (user: User) => `${user.first_name} ${user.last_name}`
        ),
        energy_modeler: this.filterFromGroup.value.energy_modeler_info?.map(
          (user: User) => `${user.first_name} ${user.last_name}`
        ),
        field_inspector: this.filterFromGroup.value.field_inspector_info?.map(
          (user: User) => `${user.first_name} ${user.last_name}`
        ),
        customer_hirl_project__certification_level:
          this.filterFromGroup.value.customer_hirl_project__certification_level?.map(
            (value: string) =>
              QAHIRLCertificationLevelAwardedLabelMapping[value]
          ),
        customer_hirl_project__is_hud_project:
          isHudProject !== null ? this.yesNoPipe.transform(isHudProject) : null,
        customer_hirl_project__registration__is_build_to_rent:
          isBuildToRent !== null
            ? this.yesNoPipe.transform(isBuildToRent)
            : null,
        customer_hirl_project__green_energy_badges:
          this.filterFromGroup.value.customer_hirl_project__green_energy_badges_info?.map(
            (greenEnergyBadge: HIRLGreenEnergyBadge) => greenEnergyBadge.name
          ),
        customer_hirl_project__appeals_project:
          isAppealsProject !== null
            ? this.yesNoPipe.transform(isAppealsProject)
            : null,
        customer_hirl_project__is_require_wri_certification:
          isWRICertification !== null
            ? this.yesNoPipe.transform(isWRICertification)
            : null,
        customer_hirl_project__is_require_water_sense_certification:
          isWaterSenseCertification !== null
            ? this.yesNoPipe.transform(isWaterSenseCertification)
            : null,
        customer_hirl_project__commercial_space_type:
          HIRLProjectCommercialSpaceTypeLabelMapping[
            this.filterFromGroup.value
              .customer_hirl_project__commercial_space_type
          ],
        customer_hirl_project__sampling:
          this.filterFromGroup.value.customer_hirl_project__sampling?.map(
            (value: string) => this.HIRLProjectSamplingMapping[value]
          ),
        customer_hirl_project__registration__financing_type:
          this.filterFromGroup.value.customer_hirl_project__registration__financing_type?.map(
            (value: string) =>
              this.CustomerHirlProjectRegistrationFinanceTypeLabelMapping[value]
          ),
        customer_hirl_rough_verifier:
          this.filterFromGroup.value.customer_hirl_rough_verifier_info?.map(
            (user: User) => `${user.first_name} ${user.last_name}`
          ),
        customer_hirl_final_verifier:
          this.filterFromGroup.value.customer_hirl_final_verifier_info?.map(
            (user: User) => `${user.first_name} ${user.last_name}`
          ),
        home__task__task_type:
          this.filterFromGroup.value.home__task__task_type_info?.map(
            (taskType: SchedulingTaskType) => taskType.name
          ),
        home__task__assignees:
          this.filterFromGroup.value.home__task__assignees_info?.map(
            (user: User) => `${user.first_name} ${user.last_name}`
          ),
        customer_eto_region:
          this.filterFromGroup.value.customer_eto_region?.map(
            (value: string) => ETORegionLabelMapping[value]
          ),
      },
      overwritePropertyLabels: {
        eep_program: 'EEP Program',
        company_access: 'Included Companies',
        qa_status__state: 'QA Status State',
        qastatus__requirement__type: 'QA Status Requirement Type',
        qastatus__observation__observation_type: 'QA Observation Type',
        qastatus__hirl_water_sense_confirmed: 'QA WaterSense Confirmed',
        qastatus__hirl_badges_awarded: 'QA Green+ Badges Awarded',
        qastatus__hirl_commercial_space_confirmed:
          'QA Status Commercial Space Confirmed',
        customer_hirl_project__certification_level:
          'Project Certification Level',
        customer_hirl_project__is_hud_project: 'Is HUD Project',
        customer_hirl_project__registration__is_build_to_rent:
          'Is Build To Rent',
        customer_hirl_project__green_energy_badges:
          'Registration Green Energy Badges',
        customer_hirl_project__appeals_project: 'Is Appeals Project',
        customer_hirl_project__is_require_wri_certification:
          'Is Require WRI Certification',
        customer_hirl_project__is_require_water_sense_certification:
          'Is the project seeking WaterSense Certification',
        customer_hirl_project__commercial_space_type: 'Commercial Space Type',
        customer_hirl_project__sampling: 'Project Sampling',
        customer_hirl_project__registration__financing_type:
          'Registration Finance Type',
        customer_hirl_annotations__energy_path: 'Energy Path',
        customer_eto_region: 'ETO Region',
        customer_hirl_rough_verifier: 'Rough Verifier',
        customer_hirl_final_verifier: 'Final Verifier',
        created_date__gte: 'Created Date From',
        created_date__lte: 'Created Date To',
        certification_date__gte: 'Certification Date From',
        certification_date__lte: 'Certification Date To',
        home__created_date__gte: 'Home Created Date From',
        home__created_date__lte: 'Home Created Date To',
      },
    });
  }

  companyFilterOptionDisplay(company: Company): string {
    return `${company.name}`;
  }

  userFilterOptionDisplayWithCompany(user: User): string {
    let str = `${user.first_name} ${user.last_name} [${user.company_info?.name}]`;

    if (!user.is_active) {
      str += ' (Inactive)';
    }
    return str;
  }

  userFilterOptionDisplayWithoutCompany(user: User): string {
    let str = `${user.first_name} ${user.last_name}`;

    if (!user.is_active) {
      str += ' (Inactive)';
    }
    return str;
  }
}
