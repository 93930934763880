import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import {
  aboveWarningRangeValidator,
  belowWarningRangeValidator,
} from '@/modules/simulation/validtaors/helper';
import { FieldLabels } from '../base';

// Labels
export const FIELD_LABELS: FieldLabels<DoorTypeBackendDict> = {
  name: 'Name',
  u_value: 'U-Value',
  includes_storm_door: 'Includes Storm Door',
  note: 'Note',
};

export interface DoorTypeBackendDict {
  id: number | null;
  name: string;
  u_value: number;
  includes_storm_door: boolean;
  note: string;
}

export const DEFAULT_DOOR: DoorTypeBackendDict = {
  id: null,
  name: '',
  u_value: 0,
  includes_storm_door: false,
  note: '',
};

export function createDoorTypeForm(
  doorType: DoorTypeBackendDict
): ModelFormGroup {
  return new ModelFormGroup({
    id: new ModelFormControl(doorType.id, Validators.required),
    name: new ModelFormControl(doorType.name, Validators.required),
    u_value: new ModelFormControl(doorType.u_value, [
      Validators.required,
      Validators.min(0),
      aboveWarningRangeValidator(1.0),
      belowWarningRangeValidator(0.015),
    ]),
    includes_storm_door: new ModelFormControl(doorType.includes_storm_door),
    note: new ModelFormControl(doorType.note),
  });
}
