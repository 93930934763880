import { ActionReducerMap } from '@ngrx/store';
import * as fromaboveGradeWall from './above-grade-wall/reducers';
import * as fromAboveGradeWallType from './above-grade-wall-type/reducers';
import * as fromSimulation from './simulation/reducers';
import * as fromFoundationWall from './foundation-wall/reducers';
import * as fromFoundationWallType from './foundation-wall-type/reducers';
import * as fromRoof from './roof/reducers';
import * as fromRoofType from './roof-type/reducers';
import * as fromForm from './form/reducers';
import * as fromFrameFloor from './frame-floor/reducers';
import * as fromFrameFloorType from './frame-floor-type/reducers';
import * as fromRimJoist from './rim-joist/reducers';
import * as fromRimJoistType from './rim-joist-type/reducers';
import * as fromInfiltration from './infiltration/reducers';
import * as fromSlab from './slab/reducers';
import * as fromSlabType from './slab-type/reducers';
import * as fromSkylight from './skylight/reducers';
import * as fromSkylightType from './skylight-type/reducers';
import * as fromDoor from './door/reducers';
import * as fromDoorType from './door-type/reducers';
import * as fromWindow from './window/reducers';
import * as fromWindowType from './window-type/reducers';
import * as fromAirConditioner from './air-conditioner/reducers';
import * as fromHeater from './heater/reducers';
import * as fromWaterHeater from './water-heater/reducers';
import * as fromMechanicalEquipment from './mechanical-equipment/reducers';
import * as fromAirSourceHeatPump from './air-source-heat-pump/reducers';
import * as fromDehumidifier from './dehumidifier/reducers';
import * as fromGroundSourceHeatPump from './ground-source-heat-pump/reducers';
import * as fromAppliances from './appliances/reducers';
import * as fromLights from './lights/reducers';
import * as fromDistributionSystem from './distribution-system/reducers';
import * as fromDuct from './duct/reducers';
import * as fromThermostat from './thermostat/reducers';
import * as fromPhotovoltaic from './photovoltaic/reducers';
import * as fromWaterSystem from './water-system/reducers';
import * as fromLocation from './location/reducers';
import * as fromProject from './project/reducers';
import * as fromUtilityRate from './utility-rate/reducers';
import * as fromSeasonalRate from './seasonal-rate/reducers';
import * as fromBlockRate from './block-rate/reducers';
import * as fromNote from './note/reducers';
import * as fromNaturalVentilation from './natural-ventilation/reducers';
import * as fromMechanicalVentilation from './mechanical-ventilation/reducers';
import * as fromSimulationConfig from './simulation-config/reducers';

export interface UsableMechnicalEquipmentState {
  airConditioner: fromAirConditioner.AirConditionerState;
  airSourceHeatPump: fromAirSourceHeatPump.AirSourceHeatPumpState;
  dehumidifier: fromDehumidifier.DehumidifierState;
  groundSourceHeatPump: fromGroundSourceHeatPump.GroundSourceHeatPumpState;
  heater: fromHeater.HeaterState;
  waterHeater: fromWaterHeater.WaterHeaterState;
}

export interface SimulationModelsState extends UsableMechnicalEquipmentState {
  simulation: fromSimulation.SimulationState;
  aboveGradeWall: fromaboveGradeWall.AboveGradeWallState;
  aboveGradeWallType: fromAboveGradeWallType.AboveGradeWallTypeState;
  foundationWall: fromFoundationWall.FoundationWallState;
  foundationWallType: fromFoundationWallType.FoundationWallTypeState;
  frameFloor: fromFrameFloor.FrameFloorState;
  frameFloorType: fromFrameFloorType.FrameFloorTypeState;
  roof: fromRoof.RoofState;
  roofType: fromRoofType.RoofTypeState;
  rimJoist: fromRimJoist.RimJoistState;
  rimJoistType: fromRimJoistType.RimJoistTypeState;

  infiltration: fromInfiltration.InfiltrationState;
  slab: fromSlab.SlabState;
  slabType: fromSlabType.SlabTypeState;
  skylight: fromSkylight.SkylightState;
  skylightType: fromSkylightType.SkylightTypeState;
  door: fromDoor.DoorState;
  doorType: fromDoorType.DoorTypeState;
  window: fromWindow.WindowState;
  windowType: fromWindowType.WindowTypeState;
  mechanicalEquipment: fromMechanicalEquipment.MechanicalEquipmentState;
  appliances: fromAppliances.AppliancesState;
  lights: fromLights.LightsState;
  distributionSystem: fromDistributionSystem.DistributionSystemState;
  duct: fromDuct.DuctState;
  thermostat: fromThermostat.ThermostatState;
  photovoltaic: fromPhotovoltaic.PhotovoltaicState;
  waterSystem: fromWaterSystem.WaterSystemState;
  location: fromLocation.LocationState;
  project: fromProject.ProjectState;
  naturalVentilation: fromNaturalVentilation.NaturalVentilationState;
  mechanicalVentilation: fromMechanicalVentilation.MechanicalVentilationState;
  simulationConfig: fromSimulationConfig.SimulationConfigState;

  utilityRate: fromUtilityRate.UtilityRateState;
  seasonalRate: fromSeasonalRate.SeasonalRateState;
  blockRate: fromBlockRate.BlockRateState;
}

export interface SimulationState extends SimulationModelsState {
  form: fromForm.FormState;
  // Note is a special case, it is not a model, but an extracted data from
  // different models.
  note: fromNote.NoteState;
}

export const reducers: ActionReducerMap<SimulationState> = {
  simulation: fromSimulation.reducer,
  form: fromForm.reducer,
  aboveGradeWall: fromaboveGradeWall.reducer,
  aboveGradeWallType: fromAboveGradeWallType.reducer,
  foundationWall: fromFoundationWall.reducer,
  foundationWallType: fromFoundationWallType.reducer,
  frameFloor: fromFrameFloor.reducer,
  frameFloorType: fromFrameFloorType.reducer,
  roof: fromRoof.reducer,
  roofType: fromRoofType.reducer,
  rimJoist: fromRimJoist.reducer,
  rimJoistType: fromRimJoistType.reducer,
  infiltration: fromInfiltration.reducer,
  slab: fromSlab.reducer,
  slabType: fromSlabType.reducer,
  skylight: fromSkylight.reducer,
  skylightType: fromSkylightType.reducer,
  door: fromDoor.reducer,
  doorType: fromDoorType.reducer,
  window: fromWindow.reducer,
  windowType: fromWindowType.reducer,
  mechanicalEquipment: fromMechanicalEquipment.reducer,
  airConditioner: fromAirConditioner.reducer,
  airSourceHeatPump: fromAirSourceHeatPump.reducer,
  dehumidifier: fromDehumidifier.reducer,
  groundSourceHeatPump: fromGroundSourceHeatPump.reducer,
  heater: fromHeater.reducer,
  waterHeater: fromWaterHeater.reducer,
  appliances: fromAppliances.reducer,
  lights: fromLights.reducer,
  distributionSystem: fromDistributionSystem.reducer,
  duct: fromDuct.reducer,
  thermostat: fromThermostat.reducer,
  photovoltaic: fromPhotovoltaic.reducer,
  waterSystem: fromWaterSystem.reducer,
  location: fromLocation.reducer,
  project: fromProject.reducer,
  utilityRate: fromUtilityRate.reducer,
  seasonalRate: fromSeasonalRate.reducer,
  blockRate: fromBlockRate.reducer,
  naturalVentilation: fromNaturalVentilation.reducer,
  mechanicalVentilation: fromMechanicalVentilation.reducer,
  simulationConfig: fromSimulationConfig.reducer,

  // Note is a special case, it is not a model, but an extracted data from
  // different models.
  note: fromNote.reducer,
};
