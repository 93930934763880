<div class="container-fluid" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <div class="row mb-2">
        <div class="col-6">
          <h3>
            <span *ngIf="!entitiesIsLoading">{{ entitiesCount | number }}</span>
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="entitiesIsLoading"></fa-icon>
            Manufacturer Agreements
          </h3>
        </div>
        <div class="col-6 text-right">
          <app-permission-mat-button [permissionResponse]="createPermissionResponse" (click)="create($event);">
            Create New
          </app-permission-mat-button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="filterFromGroup" class="card">
                <h5 class="card-header">
                  Filters
                  <a href="#" matTooltip="Preview applied filters" (click)="showAppliedFiltersDialog($event);">
                    <fa-icon [icon]="['fas', 'filter']"></fa-icon>
                  </a>
                  <a href="#" class="small float-right" (click)="resetFilters($event);">Reset filters</a>
                </h5>
                <div class="card-body filter-card-body">
                  <mat-accordion class="filter-accordion" multi>
                    <mat-expansion-panel [expanded]="true">
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Search</mat-label>
                            <app-search-input formControlName="search"
                                              placeholder="Type to search something"></app-search-input>
                          </mat-form-field>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>

                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row" *ngIf="entitiesIsLoading">
            <div class="col-12">
              <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
            </div>
          </div>
          <table mat-table
                 [dataSource]="entities"
                 matSort
                 [matSortActive]="storedParams.toTableSort().active"
                 [matSortDirection]="storedParams.toTableSort().direction"
                 (matSortChange)="onSort($event)"
                 class="w-100"
          >
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Request Number</th>
              <td mat-cell *matCellDef="let row">
                <a [routerLink]="['/', 'hi', 'product', 'agreements', 'detail', row.id]">{{ row.id }}</a>
              </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
              <td mat-cell *matCellDef="let row">
                <span
                  class="badge ml-2 {{ HIRLProductAgreementStateLabelMapping[row.state]?.badgeClass }}">{{ HIRLProductAgreementStateLabelMapping[row.state]?.label }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="company__name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
              <td mat-cell *matCellDef="let row">
                <app-company-display [company]="row.company_info" displayType="onlyNameAsLink"></app-company-display>
              </td>
            </ng-container>

            <ng-container matColumnDef="created_by__first_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
              <td mat-cell *matCellDef="let row">
                <app-user-display [user]="row.created_by_info" displayType="onlyNameAsLink"></app-user-display>
              </td>
            </ng-container>

            <ng-container matColumnDef="start_date">
              <th mat-header-cell *matHeaderCellDef>Start Date</th>
              <td mat-cell *matCellDef="let row">
                {{ row.start_date | date: 'MM/dd/yyyy' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="expiration_date">
              <th mat-header-cell *matHeaderCellDef>Expiration Date</th>
              <td mat-cell *matCellDef="let row">
                {{ row.expiration_date | date: 'MM/dd/yyyy' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="created_at">
              <th mat-header-cell *matHeaderCellDef>Created At</th>
              <td mat-cell *matCellDef="let row">
                {{ row.created_at | date: 'MM/dd/yyyy' }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div class="d-flex flex-row">
            <div class="align-self-center">
            </div>
            <div class="d-flex flex-fill justify-content-end">
              <mat-paginator [length]="entitiesCount"
                             [pageIndex]="$any(storedParams.page) - 1"
                             [pageSize]="storedParams.page_size"
                             (page)="onPaginateChange($event)"
                             aria-label="Select page">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
