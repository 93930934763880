import { ActionReducerMap } from '@ngrx/store';
import * as fromMenu from './menu.reducer';
import * as fromInfo from './info.reducer';
import * as fromApplication from './app.reducer';
import * as fromMessage from './messaging/message.reducer';
import * as fromCompany from './company/company.reducer';
import * as fromUser from './user/user.reducer';
import * as fromSimulation from './floorplan/simulation.reducer';
import * as fromHIRLProjectRegistration from './customer-hirl/hirl-project-registration.reducer';
import * as fromFloorplan from './floorplan/floorplan.reducers';
import * as fromSchedulingTasks from './scheduling/task.reducer';
import * as fromHIRLClientAgreement from './customer-hirl/client-agreement.reducer';
import * as fromApexRequestStateKey from '@/state/reducers/home/apex-request.reducer';
import * as fromProductStateKey from '@/state/reducers/product/product.reducer';
import * as fromHIRLPaymentRequestStateKey from '@/state/reducers/customer-hirl/payment-request';
import * as fromHIRLProductAgreementStateKey from '@/state/reducers/customer-hirl/hirl-product-agreement.reducer';
import * as fromInvoiceStateKey from '@/state/reducers/invoicing/invoice.reducer';

export interface AppState {
  [fromMenu.menuFeatureKey]: fromMenu.MenuState;
  [fromInfo.infoFeatureKey]: fromInfo.InfoState;
  [fromApplication.appFeatureKey]: fromApplication.ApplicationState;
  [fromMessage.messageStateKey]: fromMessage.MessageState;
  [fromCompany.companyStateKey]: fromCompany.CompanyState;
  [fromUser.userStateKey]: fromUser.UserState;
  [fromSimulation.simulationStateKey]: fromSimulation.SimulationState;
  [fromHIRLProjectRegistration.hirlProjectRegistrationStateKey]: fromHIRLProjectRegistration.HIRLProjectRegistrationState;
  [fromFloorplan.floorplanStateKey]: fromFloorplan.FloorplanState;
  [fromSchedulingTasks.schedulingTaskStateKey]: fromSchedulingTasks.SchedulingTaskState;
  [fromHIRLClientAgreement.hirlClientAgreementStateKey]: fromHIRLClientAgreement.HIRLClientAgreementState;
  [fromApexRequestStateKey.apexRequestStateKey]: fromApexRequestStateKey.ApexRequestState;
  [fromProductStateKey.productStateKey]: fromProductStateKey.ProductState;
  [fromHIRLPaymentRequestStateKey.hirlPaymentRequestStateKey]: fromHIRLPaymentRequestStateKey.HIRLPaymentRequestState;
  [fromHIRLProductAgreementStateKey.hirlProductAgreementStateKey]: fromHIRLProductAgreementStateKey.HIRLProductAgreementState;
  [fromInvoiceStateKey.invoiceStateKey]: fromInvoiceStateKey.InvoiceState;
}

export const reducers: ActionReducerMap<AppState> = {
  [fromMenu.menuFeatureKey]: fromMenu.reducer,
  [fromInfo.infoFeatureKey]: fromInfo.reducer,
  [fromApplication.appFeatureKey]: fromApplication.reducer,
  [fromMessage.messageStateKey]: fromMessage.reducer,
  [fromCompany.companyStateKey]: fromCompany.reducer,
  [fromUser.userStateKey]: fromUser.reducer,
  [fromSimulation.simulationStateKey]: fromSimulation.reducer,
  [fromHIRLProjectRegistration.hirlProjectRegistrationStateKey]:
    fromHIRLProjectRegistration.reducer,
  [fromFloorplan.floorplanStateKey]: fromFloorplan.reducer,
  [fromSchedulingTasks.schedulingTaskStateKey]: fromSchedulingTasks.reducer,
  [fromHIRLClientAgreement.hirlClientAgreementStateKey]:
    fromHIRLClientAgreement.reducer,
  [fromApexRequestStateKey.apexRequestStateKey]:
    fromApexRequestStateKey.reducer,
  [fromProductStateKey.productStateKey]: fromProductStateKey.reducer,
  [fromHIRLPaymentRequestStateKey.hirlPaymentRequestStateKey]:
    fromHIRLPaymentRequestStateKey.reducer,
  [fromHIRLProductAgreementStateKey.hirlProductAgreementStateKey]:
    fromHIRLProductAgreementStateKey.reducer,
  [fromInvoiceStateKey.invoiceStateKey]: fromInvoiceStateKey.reducer,
};
