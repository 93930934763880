<div class="app-wrapper">
    <app-nav></app-nav>
    <div class="container-fluid h-100">
        <div class="row h-100 app-content">
            <div class="col-12">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <footer class="app-footer">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-3">
                        </div>
                        <div class="col-9">
                            <p class="text-right">
                                <small class="text-muted">
                                    Copyright © 2011-2021 Pivotal Energy Solutions. All Rights Reserved.
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>

<div class="zendesk-feedback">
    <app-zendesk-feedback></app-zendesk-feedback>
</div>
