import {
  aboveWarningRangeValidator,
  belowWarningRangeValidator,
} from '@/modules/simulation/validtaors/helper';
import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { FieldLabels } from '../base';

// Labels
export const FIELD_LABELS: FieldLabels<WindowTypeBackendDict> = {
  name: 'Name',
  shgc: 'SHGC',
  u_value: 'U-Value',
  note: 'Note',
};

export interface WindowTypeBackendDict {
  id: number | null;
  name: string;
  shgc: number;
  u_value: number;
  note: string;
}

export function createWindowTypeForm(
  doorType: WindowTypeBackendDict
): ModelFormGroup {
  return new ModelFormGroup({
    id: new ModelFormControl(doorType.id),
    name: new ModelFormControl(doorType.name, [
      Validators.required,
      Validators.maxLength(128),
    ]),
    shgc: new ModelFormControl(doorType.shgc, [
      Validators.required,
      Validators.min(0),
      Validators.max(1),
      belowWarningRangeValidator(0.05),
      aboveWarningRangeValidator(0.95),
    ]),
    u_value: new ModelFormControl(doorType.u_value, [
      Validators.required,
      Validators.min(0.001),
      Validators.max(2),
      belowWarningRangeValidator(0.05),
      aboveWarningRangeValidator(1.95),
    ]),
    note: new ModelFormControl(doorType.note),
  });
}
