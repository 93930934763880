import { QARequirementType, QAState } from '@/data/qa/models';

export class PSRReportListQAState {
  duration?: number;
  state?: QAState;
  requirement_type?: QARequirementType;
}

export class PSRReportList {
  id: number;
  home: number;
  floorplan: number;
  floorplan_name: string;
  subdivision_id?: number;
  eep_program: number;
  address: string;
  eep_program_name: string;
  subdivision_name?: string;
  state: string;
  qa_states?: PSRReportListQAState[];
}
