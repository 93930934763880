import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, concatLatestFrom } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { mergeMap, catchError, take, switchMap, map } from 'rxjs/operators';
import { SimulationService } from '@/data/simulation/services/simulation.service';
import { DEFAULT_ABOVE_GRADE_WALL } from '@/data/simulation/models/enclosure/AboveGradeWall';
import { Store } from '@ngrx/store';

import * as SimulationActions from './actions';
import * as AboveGradeWallActions from '../above-grade-wall/actions';
import * as FoundationWallActions from '../foundation-wall/actions';
import * as FrameFloorActions from '../frame-floor/actions';
import * as SlabActions from '../slab/actions';
import * as RoofActions from '../roof/actions';
import * as SkylightActions from '../skylight/actions';
import * as RimJoistActions from '../rim-joist/actions';
import * as DoorActions from '../door/actions';
import * as WindowActions from '../window/actions';
import * as MechanicalEquipmetActions from '../mechanical-equipment/actions';
import * as AppliancesActions from '../appliances/actions';
import * as LightsActions from '../lights/actions';
import * as DistributionSystemActions from '../distribution-system/actions';
import * as ThermostatActions from '../thermostat/actions';
import * as PhotovoltaicActions from '../photovoltaic/actions';
import * as WaterSystemActions from '../water-system/actions';
import * as SharedActions from '../shared/shared.actions';
import * as ProjectActions from '../project/actions';
import * as LocationActions from '../location/actions';
import * as UtilityRateActions from '../utility-rate/actions';
import * as InfiltrationActions from '../infiltration/actions';
import * as MechanicalVentilationActions from '../mechanical-ventilation/actions';
import * as NaturalVentilationActions from '../natural-ventilation/actions';
import * as SimulationConfigActions from '../simulation-config/actions';
import { selectSimulationId } from '@/modules/simulation/state/simulation/selectors';
import { ModelGraphService } from '../../services/model-graph.service';
import { SimulationValidator } from '../../validtaors/simulation.validataor';
import { AboveGradeWallValidator } from '../../validtaors/above-grade-wall.validator';
import { DEFAULT_FOUNDATION_WALL } from '@/data/simulation/models/enclosure/FoundationWall';
import { FoundationWallValidator } from '../../validtaors/foundation-wall.validator';
import { RoofValidator } from '../../validtaors/roof.validator';
import { FrameFloorValidator } from '../../validtaors/frame-floor.validator';
import { SlabValidator } from '../../validtaors/slab.validator';
import { DEFAULT_SLAB } from '@/data/simulation/models/enclosure/Slab';
import { DEFAULT_SKYLIGHT } from '@/data/simulation/models/enclosure/Skylight';
import { SkylightValidator } from '../../validtaors/skylight.validator';
import { RimJoistValidator } from '../../validtaors/rim-joist.validator';
import { DoorValidator } from '../../validtaors/door.validator';
import { WindowValidator } from '../../validtaors/window.validator';
import { DistributionSystemValidator } from '../../validtaors/distribution-system.validator';
import { PhotovoltaicValidator } from '../../validtaors/photovoltaic.validator';
import { MechanicalVentilationValidator } from '../../validtaors/mechanical-ventilation.validator';
import { selectSimulationFeature } from '../simulation.selectors';
import { denormalizeSimulation } from '@/data/simulation/models/simulation';

@Injectable()
export class SimulationEffects {
  loadSimulation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.loadSimulation),
      mergeMap(action =>
        this.simulationService.retrieve(action.id).pipe(
          mergeMap(detailedSimulation => {
            const {
              foundation_walls_info: detailedFoundationWalls,
              above_grade_walls_info: detailedAboveGradeWalls,
              frame_floors_info: detailedFrameFloors,
              roofs_info: detailedRoofs,
              slabs_info: detailedSlabs,
              skylights_info: detailedSkylights,
              rim_joists_info: detailedRimJoists,
              doors_info: detailedDoors,
              windows_info: detailedWindows,
              mechanical_equipment_info: detailedEquipment,
              appliances_info: appliances,
              lights_info: lights,
              hvac_distribution_systems_info: detailedDistributionSystems,
              photovoltaics_info: photovoltaics,
              water_system_info: waterSystem,
              project_info: project,
              location_info: location,
              utility_rates_info: utilityRates,
              thermostats_info: thermostats,
              infiltration_info: infiltration,
              mechanical_ventilation_systems_info: mechanicalVentilations,
              natural_ventilation_info: naturalVentilation,
              config_info: simulationConfig,
              ...simulation
            } = detailedSimulation;

            this.modelGraphService.attachModel(
              'simulation',
              simulation.id,
              'aboveGradeWall',
              simulation.above_grade_walls
            );
            this.modelGraphService.attachModel(
              'simulation',
              simulation.id,
              'foudationWall',
              simulation.foundation_walls
            );
            this.modelGraphService.attachModel(
              'simulation',
              simulation.id,
              'roof',
              simulation.roofs
            );
            this.modelGraphService.attachModel(
              'simulation',
              simulation.id,
              'frameFloor',
              simulation.frame_floors
            );
            this.modelGraphService.attachModel(
              'simulation',
              simulation.id,
              'slab',
              simulation.slabs
            );
            this.modelGraphService.attachModel(
              'simulation',
              simulation.id,
              'skylight',
              simulation.skylights
            );
            this.modelGraphService.attachModel(
              'simulation',
              simulation.id,
              'rimJoists',
              simulation.rim_joists
            );
            this.modelGraphService.attachModel(
              'simulation',
              simulation.id,
              'door',
              simulation.doors
            );
            this.modelGraphService.attachModel(
              'simulation',
              simulation.id,
              'distributionSystem',
              simulation.hvac_distribution_systems
            );

            const errors = SimulationValidator.validate([detailedSimulation]);
            return from([
              AboveGradeWallActions.loadDetailedAboveGradeWalls({
                detailedAboveGradeWalls: detailedAboveGradeWalls || [],
              }),
              FoundationWallActions.loadDetailedFoundationWalls({
                detailedFoundationWalls: detailedFoundationWalls || [],
              }),
              FrameFloorActions.loadDetailedFrameFloors({
                detailedFrameFloors: detailedFrameFloors || [],
              }),
              RoofActions.loadDetailedRoofs({
                detailedRoofs: detailedRoofs || [],
              }),
              SlabActions.loadDetailedSlabs({
                detailedSlabs: detailedSlabs || [],
              }),
              SkylightActions.loadDetailedSkylights({
                detailedSkylights: detailedSkylights || [],
              }),
              RimJoistActions.loadDetailedRimJoists({
                detailedRimJoists: detailedRimJoists || [],
              }),
              DoorActions.loadDetailedDoors({
                detailedDoors: detailedDoors || [],
              }),
              WindowActions.loadDetailedWindows({
                detailedWindows: detailedWindows || [],
              }),
              MechanicalEquipmetActions.loadDetailedMechanicalEquipment({
                detailedMechanicalEquipment: detailedEquipment || [],
              }),
              AppliancesActions.loadAppliances({
                appliances: appliances,
              }),
              LightsActions.loadLights({
                lights,
              }),
              DistributionSystemActions.loadDetailedDistributionSystems({
                detailedDistributionSystems: detailedDistributionSystems || [],
              }),
              ThermostatActions.loadThermostats({
                thermostats: thermostats || [],
              }),
              PhotovoltaicActions.loadPhotovoltaics({
                photovoltaics: photovoltaics || [],
              }),
              WaterSystemActions.loadWaterSystems({
                waterSystems: [waterSystem],
              }),
              ProjectActions.loadProjects({
                projects: [project],
              }),
              LocationActions.loadLocations({
                locations: [location],
              }),
              UtilityRateActions.loadDetailedUtilityRates({
                detailedUtilityRates: utilityRates || [],
              }),
              InfiltrationActions.loadInfiltration({
                infiltration: infiltration,
              }),
              MechanicalVentilationActions.loadMechanicalVentilations({
                mechanicalVentilations: mechanicalVentilations || [],
              }),
              NaturalVentilationActions.loadNaturalVentilations({
                naturalVentilations: [naturalVentilation],
              }),
              SimulationConfigActions.loadSimulationConfig({
                simulationConfig,
              }),

              // The loadSimulationSuccess action needs to be the last so that the
              // simulation state is updated only after all the other states have been
              // updated.
              SimulationActions.loadSimulationSuccess({ simulation, errors }),
            ]);
          })
          // catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  updateSimulation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.updateSimulation),
      mergeMap(action =>
        this.simulationService.update(action.id, action.changes).pipe(
          mergeMap(updatedSimulation => {
            return of(
              SimulationActions.loadSimulation({
                id: updatedSimulation.id,
              })
            );
          }),
          catchError(error =>
            of(
              SimulationActions.updateSimulationFailure({
                id: action.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  addNewAboveGradeWall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.addNewAboveGradeWall),
      switchMap(() => this.store.select(selectSimulationId()).pipe(take(1))),
      switchMap(simulationId =>
        this.simulationService
          .addAboveGradeWall(simulationId, DEFAULT_ABOVE_GRADE_WALL)
          .pipe(
            mergeMap(aboveGradeWall => {
              const errors = AboveGradeWallValidator.validate([aboveGradeWall]);
              return of(
                AboveGradeWallActions.loadAboveGradeWallsSuccess({
                  aboveGradeWalls: [aboveGradeWall],
                  errors,
                }),
                SimulationActions.addItemToList({
                  fieldName: 'above_grade_walls',
                  id: aboveGradeWall.id,
                })
              );
            }),
            catchError(error => of(SharedActions.reportAPIFailure({ error })))
          )
      )
    );
  });

  cloneAboveGradeWall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.cloneAboveGradeWall),
      switchMap(action =>
        this.store.select(selectSimulationId()).pipe(
          take(1),
          map(simulationId => ({
            simulationId,
            AboveGradeWall: action.id,
          }))
        )
      ),
      switchMap(({ simulationId, AboveGradeWall }) =>
        this.simulationService
          .cloneAboveGradeWall(simulationId, AboveGradeWall)
          .pipe(
            mergeMap(clonedAboveGradeWall => {
              return of(
                AboveGradeWallActions.loadDetailedAboveGradeWalls({
                  detailedAboveGradeWalls: [clonedAboveGradeWall],
                }),
                SimulationActions.addItemToList({
                  fieldName: 'above_grade_walls',
                  id: clonedAboveGradeWall.id,
                })
              );
            }),
            catchError(error => of(SharedActions.reportAPIFailure({ error })))
          )
      )
    );
  });

  addNewFoundationWall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.addNewFoundationWall),
      switchMap(() => this.store.select(selectSimulationId()).pipe(take(1))),
      switchMap(simulationId =>
        this.simulationService
          .addFoundationWall(simulationId, DEFAULT_FOUNDATION_WALL)
          .pipe(
            mergeMap(foundationWall => {
              const errors = FoundationWallValidator.validate([foundationWall]);
              return of(
                FoundationWallActions.loadFoundationWallsSuccess({
                  foundationWalls: [foundationWall],
                  errors,
                }),
                SimulationActions.addItemToList({
                  fieldName: 'foundation_walls',
                  id: foundationWall.id,
                })
              );
            }),
            catchError(error => of(SharedActions.reportAPIFailure({ error })))
          )
      )
    );
  });

  cloneFoundationWall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.cloneFoundationWall),
      switchMap(action =>
        this.store.select(selectSimulationId()).pipe(
          take(1),
          map(simulationId => ({
            simulationId,
            FoundationWall: action.id,
          }))
        )
      ),
      switchMap(({ simulationId, FoundationWall }) =>
        this.simulationService
          .cloneFoundationWall(simulationId, FoundationWall)
          .pipe(
            mergeMap(clonedFoundationWall => {
              return of(
                FoundationWallActions.loadDetailedFoundationWalls({
                  detailedFoundationWalls: [clonedFoundationWall],
                }),
                SimulationActions.addItemToList({
                  fieldName: 'foundation_walls',
                  id: clonedFoundationWall.id,
                })
              );
            }),
            catchError(error => of(SharedActions.reportAPIFailure({ error })))
          )
      )
    );
  });

  addNewRoof$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.addNewRoof),
      switchMap(() => this.store.select(selectSimulationId()).pipe(take(1))),
      switchMap(simulationId =>
        this.simulationService.addRoof(simulationId).pipe(
          mergeMap(roof => {
            const errors = RoofValidator.validate([roof]);
            return of(
              RoofActions.loadRoofsSuccess({ roofs: [roof], errors }),
              SimulationActions.addItemToList({
                fieldName: 'roofs',
                id: roof.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  cloneRoof$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.cloneRoof),
      switchMap(action =>
        this.store.select(selectSimulationId()).pipe(
          take(1),
          map(simulationId => ({
            simulationId,
            Roof: action.id,
          }))
        )
      ),
      switchMap(({ simulationId, Roof }) =>
        this.simulationService.cloneRoof(simulationId, Roof).pipe(
          mergeMap(clonedRoof => {
            return of(
              RoofActions.loadDetailedRoofs({ detailedRoofs: [clonedRoof] }),
              SimulationActions.addItemToList({
                fieldName: 'roofs',
                id: clonedRoof.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addNewFrameFloor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.addNewFrameFloor),
      switchMap(() => this.store.select(selectSimulationId()).pipe(take(1))),
      switchMap(simulationId =>
        this.simulationService.addFrameFloor(simulationId).pipe(
          mergeMap(frameFloor => {
            const errors = FrameFloorValidator.validate([frameFloor]);
            return of(
              FrameFloorActions.loadFrameFloorsSuccess({
                frameFloors: [frameFloor],
                errors,
              }),
              SimulationActions.addItemToList({
                fieldName: 'frame_floors',
                id: frameFloor.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  cloneFrameFloor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.cloneFrameFloor),
      switchMap(action =>
        this.store.select(selectSimulationId()).pipe(
          take(1),
          map(simulationId => ({
            simulationId,
            FrameFloor: action.id,
          }))
        )
      ),
      switchMap(({ simulationId, FrameFloor }) =>
        this.simulationService.cloneFrameFloor(simulationId, FrameFloor).pipe(
          mergeMap(clonedFrameFloor => {
            return of(
              FrameFloorActions.loadDetailedFrameFloors({
                detailedFrameFloors: [clonedFrameFloor],
              }),
              SimulationActions.addItemToList({
                fieldName: 'frame_floors',
                id: clonedFrameFloor.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addNewSlab$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.addNewSlab),
      switchMap(() => this.store.select(selectSimulationId()).pipe(take(1))),
      switchMap(simulationId =>
        this.simulationService.addSlab(simulationId, DEFAULT_SLAB).pipe(
          mergeMap(slab => {
            const errors = SlabValidator.validate([slab]);
            return of(
              SlabActions.loadSlabsSuccess({ slabs: [slab], errors }),
              SimulationActions.addItemToList({
                fieldName: 'slabs',
                id: slab.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  cloneSlab$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.cloneSlab),
      switchMap(action =>
        this.store.select(selectSimulationId()).pipe(
          take(1),
          map(simulationId => ({
            simulationId,
            Slab: action.id,
          }))
        )
      ),
      switchMap(({ simulationId, Slab }) =>
        this.simulationService.cloneSlab(simulationId, Slab).pipe(
          mergeMap(clonedSlab => {
            return of(
              SlabActions.loadDetailedSlabs({ detailedSlabs: [clonedSlab] }),
              SimulationActions.addItemToList({
                fieldName: 'slabs',
                id: clonedSlab.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addNewSkylight$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.addNewSkylight),
      switchMap(() => this.store.select(selectSimulationId()).pipe(take(1))),
      switchMap(simulationId =>
        this.simulationService.addSkylight(simulationId, DEFAULT_SKYLIGHT).pipe(
          mergeMap(skylight => {
            const errors = SkylightValidator.validate([skylight]);
            return of(
              SkylightActions.loadSkylightsSuccess({
                skylights: [skylight],
                errors,
              }),
              SimulationActions.addItemToList({
                fieldName: 'skylights',
                id: skylight.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  cloneSkylight$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.cloneSkylight),
      switchMap(action =>
        this.store.select(selectSimulationId()).pipe(
          take(1),
          map(simulationId => ({
            simulationId,
            Skylight: action.id,
          }))
        )
      ),
      switchMap(({ simulationId, Skylight }) =>
        this.simulationService.cloneSkylight(simulationId, Skylight).pipe(
          mergeMap(clonedSkylight => {
            return of(
              SkylightActions.loadDetailedSkylights({
                detailedSkylights: [clonedSkylight],
              }),
              SimulationActions.addItemToList({
                fieldName: 'skylights',
                id: clonedSkylight.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addNewRimJoist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.addNewRimJoist),
      switchMap(() => this.store.select(selectSimulationId()).pipe(take(1))),
      switchMap(simulationId =>
        this.simulationService.addRimJoist(simulationId).pipe(
          mergeMap(rimJoist => {
            const errors = RimJoistValidator.validate([rimJoist]);
            return of(
              RimJoistActions.loadRimJoistsSuccess({
                rimJoists: [rimJoist],
                errors,
              }),
              SimulationActions.addItemToList({
                fieldName: 'rim_joists',
                id: rimJoist.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  cloneRimJoist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.cloneRimJoist),
      switchMap(action =>
        this.store.select(selectSimulationId()).pipe(
          take(1),
          map(simulationId => ({
            simulationId,
            RimJoist: action.id,
          }))
        )
      ),
      switchMap(({ simulationId, RimJoist }) =>
        this.simulationService.cloneRimJoist(simulationId, RimJoist).pipe(
          mergeMap(clonedRimJoist => {
            return of(
              RimJoistActions.loadDetailedRimJoists({
                detailedRimJoists: [clonedRimJoist],
              }),
              SimulationActions.addItemToList({
                fieldName: 'rim_joists',
                id: clonedRimJoist.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addNewDoor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.addNewDoor),
      switchMap(() => this.store.select(selectSimulationId()).pipe(take(1))),
      switchMap(simulationId =>
        this.simulationService.addDoor(simulationId).pipe(
          mergeMap(door => {
            const errors = DoorValidator.validate([door]);
            return of(
              DoorActions.loadDoorsSuccess({
                doors: [door],
                errors,
              }),
              SimulationActions.addItemToList({
                fieldName: 'doors',
                id: door.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  cloneDoor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.cloneDoor),
      switchMap(action =>
        this.store.select(selectSimulationId()).pipe(
          take(1),
          map(simulationId => ({
            simulationId,
            door: action.id,
          }))
        )
      ),
      switchMap(({ simulationId, door }) =>
        this.simulationService.cloneDoor(simulationId, door).pipe(
          mergeMap(clonedDoor => {
            return of(
              DoorActions.loadDetailedDoors({ detailedDoors: [clonedDoor] }),
              SimulationActions.addItemToList({
                fieldName: 'doors',
                id: clonedDoor.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addNewWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.addNewWindow),
      switchMap(() => this.store.select(selectSimulationId()).pipe(take(1))),
      switchMap(simulationId =>
        this.simulationService.addWindow(simulationId).pipe(
          mergeMap(window => {
            const errors = WindowValidator.validate([window]);
            return of(
              WindowActions.loadWindowsSuccess({
                windows: [window],
                errors,
              }),
              SimulationActions.addItemToList({
                fieldName: 'windows',
                id: window.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  cloneWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.cloneWindow),
      switchMap(action =>
        this.store.select(selectSimulationId()).pipe(
          take(1),
          map(simulationId => ({
            simulationId,
            window: action.id,
          }))
        )
      ),
      switchMap(({ simulationId, window }) =>
        this.simulationService.cloneWindow(simulationId, window).pipe(
          mergeMap(clonedWindow => {
            return of(
              WindowActions.loadDetailedWindows({
                detailedWindows: [clonedWindow],
              }),
              SimulationActions.addItemToList({
                fieldName: 'windows',
                id: clonedWindow.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addNewEquipment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.addNewEquipment),
      switchMap(action =>
        this.store.select(selectSimulationId()).pipe(
          take(1),
          map(simulationId => ({
            simulationId,
            equipment: action.detailedMechanicalEquipment,
          }))
        )
      ),
      switchMap(({ simulationId, equipment }) =>
        this.simulationService.addEquipment(simulationId, equipment).pipe(
          mergeMap(equipment => {
            return of(
              MechanicalEquipmetActions.loadDetailedMechanicalEquipment({
                detailedMechanicalEquipment: [equipment] || [],
              }),
              SimulationActions.addItemToList({
                fieldName: 'mechanical_equipment',
                id: equipment.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addNewDistributionSystem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.addNewDistributionSystem),
      switchMap(() => this.store.select(selectSimulationId()).pipe(take(1))),
      switchMap(simulationId =>
        this.simulationService.addDistributionSystem(simulationId).pipe(
          mergeMap(distributionSystem => {
            const errors = DistributionSystemValidator.validate([
              distributionSystem,
            ]);
            return of(
              DistributionSystemActions.loadDistributionSystemsSuccess({
                distributionSystems: [distributionSystem],
                errors,
              }),
              SimulationActions.addItemToList({
                fieldName: 'hvac_distribution_systems',
                id: distributionSystem.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  cloneDistributionSystem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.cloneDistributionSystem),
      switchMap(action =>
        this.store.select(selectSimulationId()).pipe(
          take(1),
          map(simulationId => ({
            simulationId,
            DistributionSystem: action.id,
          }))
        )
      ),
      switchMap(({ simulationId, DistributionSystem }) =>
        this.simulationService
          .cloneDistributionSystem(simulationId, DistributionSystem)
          .pipe(
            mergeMap(clonedDistributionSystem => {
              return of(
                DistributionSystemActions.loadDetailedDistributionSystems({
                  detailedDistributionSystems: [clonedDistributionSystem],
                }),
                SimulationActions.addItemToList({
                  fieldName: 'hvac_distribution_systems',
                  id: clonedDistributionSystem.id,
                })
              );
            }),
            catchError(error => of(SharedActions.reportAPIFailure({ error })))
          )
      )
    );
  });

  addNewPhotovoltaic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.addNewPhotovoltaic),
      switchMap(() => this.store.select(selectSimulationId()).pipe(take(1))),
      switchMap(simulationId =>
        this.simulationService.addPhotovoltaic(simulationId).pipe(
          mergeMap(photovoltaic => {
            const errors = PhotovoltaicValidator.validate([photovoltaic]);
            return of(
              PhotovoltaicActions.loadPhotovoltaicsSuccess({
                photovoltaics: [photovoltaic],
                errors,
              }),
              SimulationActions.addItemToList({
                fieldName: 'photovoltaics',
                id: photovoltaic.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  clonePhotovoltaic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.clonePhotovoltaic),
      switchMap(action =>
        this.store.select(selectSimulationId()).pipe(
          take(1),
          map(simulationId => ({
            simulationId,
            Photovoltaic: action.id,
          }))
        )
      ),
      switchMap(({ simulationId, Photovoltaic }) =>
        this.simulationService
          .clonePhotovoltaic(simulationId, Photovoltaic)
          .pipe(
            mergeMap(clonedPhotovoltaic => {
              return of(
                PhotovoltaicActions.loadPhotovoltaics({
                  photovoltaics: [clonedPhotovoltaic],
                }),
                SimulationActions.addItemToList({
                  fieldName: 'photovoltaics',
                  id: clonedPhotovoltaic.id,
                })
              );
            }),
            catchError(error => of(SharedActions.reportAPIFailure({ error })))
          )
      )
    );
  });

  addNewUtilityRate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.addUtilityRate),
      switchMap(action =>
        this.store.select(selectSimulationId()).pipe(
          take(1),
          map(simulationId => ({
            simulationId,
            entity: action.entity,
          }))
        )
      ),
      switchMap(({ simulationId, entity }) =>
        this.simulationService.addUtilityRate(simulationId, entity).pipe(
          mergeMap(utilityRate => {
            return of(
              UtilityRateActions.loadDetailedUtilityRates({
                detailedUtilityRates: [utilityRate],
              }),
              SimulationActions.addItemToList({
                fieldName: 'utility_rates',
                id: utilityRate.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addThermostat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.addThermostat),
      switchMap(() => this.store.select(selectSimulationId()).pipe(take(1))),
      switchMap(simulationId =>
        this.simulationService.addThermostat(simulationId).pipe(
          mergeMap(thermostat => {
            return of(
              ThermostatActions.loadThermostats({
                thermostats: [thermostat],
              }),
              SimulationActions.addItemToList({
                fieldName: 'thermostats',
                id: thermostat.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addNewMechanicalVentilation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.addMechanicalVentilation),
      switchMap(() => this.store.select(selectSimulationId()).pipe(take(1))),
      switchMap(simulationId =>
        this.simulationService.addMechanicalVentilation(simulationId).pipe(
          mergeMap(mechanicalVentilation => {
            const errors = MechanicalVentilationValidator.validate([
              mechanicalVentilation,
            ]);
            return of(
              MechanicalVentilationActions.loadMechanicalVentilationsSuccess({
                mechanicalVentilations: [mechanicalVentilation],
                errors,
              }),
              SimulationActions.addItemToList({
                fieldName: 'mechanical_ventilation_systems',
                id: mechanicalVentilation.id,
              })
            );
          }),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  cloneMechanicalVentilation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.cloneMechanicalVentilation),
      switchMap(action =>
        this.store.select(selectSimulationId()).pipe(
          take(1),
          map(simulationId => ({
            simulationId,
            MechanicalVentilation: action.id,
          }))
        )
      ),
      switchMap(({ simulationId, MechanicalVentilation }) =>
        this.simulationService
          .cloneMechanicalVentilation(simulationId, MechanicalVentilation)
          .pipe(
            mergeMap(clonedMechanicalVentilation => {
              return of(
                MechanicalVentilationActions.loadMechanicalVentilations({
                  mechanicalVentilations: [clonedMechanicalVentilation],
                }),
                SimulationActions.addItemToList({
                  fieldName: 'mechanical_ventilation_systems',
                  id: clonedMechanicalVentilation.id,
                })
              );
            }),
            catchError(error => of(SharedActions.reportAPIFailure({ error })))
          )
      )
    );
  });

  removeItemFromList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.removeItemFromList),
      concatLatestFrom(() => this.store.select(selectSimulationFeature)),
      map(([action, simulationState]) => {
        let simulation =
          simulationState.simulation.entities[
            simulationState.simulation.simulationId
          ];

        simulation = {
          ...simulation,
          [action.fieldName]: (simulation[action.fieldName] as number[]).filter(
            entityId => entityId !== action.id
          ),
        };

        const detailedSimulation = {
          ...denormalizeSimulation(simulationState),
          ...simulation,
        };

        return SimulationActions.loadSimulationSuccess({
          simulation: simulation,
          errors: SimulationValidator.validate([detailedSimulation]),
        });
      })
    );
  });

  addItemToList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationActions.addItemToList),
      concatLatestFrom(() => this.store.select(selectSimulationFeature)),
      map(([action, simulationState]) => {
        let simulation = {
          ...simulationState.simulation.entities[
            simulationState.simulation.simulationId
          ],
        };
        simulation = {
          ...simulation,
          [action.fieldName]: [
            ...(simulation[action.fieldName] as number[]),
            action.id,
          ],
        };

        const detailedSimulation = {
          ...denormalizeSimulation(simulationState),
          ...simulation,
        };
        return SimulationActions.loadSimulationSuccess({
          simulation: simulation,
          errors: SimulationValidator.validate([detailedSimulation]),
        });
      })
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private simulationService: SimulationService,
    public store: Store
  ) {}
}
