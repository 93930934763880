<div class="container-fluid" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <div class="row mb-2">
        <div class="col-6">
          <h3>
            <span *ngIf="!entitiesIsLoading">{{ entitiesCount | number }}</span>
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="entitiesIsLoading"></fa-icon>
            Contact Cards
          </h3>
        </div>
        <div class="col-6 text-right">
          <button mat-raised-button color="accent" (click)="downloadReport($event)" [disabled]="!canExport.hasPermission" [matTooltip]="canExport.message">
            Export
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="filterFromGroup" class="card">
                <h5 class="card-header">
                  Filters
                  <a href="#" matTooltip="Preview applied filters" (click)="showAppliedFiltersDialog($event);"><fa-icon [icon]="['fas', 'filter']"></fa-icon></a>
                  <a href="#" class="small float-right" (click)="resetFilters($event);">Reset filters</a>
                </h5>
                <div class="card-body filter-card-body">
                  <mat-accordion class="filter-accordion" multi>
                    <mat-expansion-panel [expanded]="true">
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Search</mat-label>
                            <app-search-input formControlName="search" placeholder="Type to search something"></app-search-input>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row" *ngIf="currentUser.is_superuser">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Attached To</mat-label>
                            <mat-select
                              formControlName="attached_to" [multiple]="true"
                            >
                              <mat-option
                                *ngFor="
                                    let item of ContactCardRequestParamsAttachedToLabelMapping
                                      | defaultOrderKeyValue
                                  "
                                [value]="item.key"
                              >
                                {{ item.value }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row" *ngIf="showCustomerHIRLFilters">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Builder/Client Type</mat-label>
                            <mat-select
                              formControlName="hirl_project_client_type" [multiple]="true"
                            >
                              <mat-option
                                *ngFor="
                                    let item of ContactCardRequestParamsHIRLProjectClientTypeLabelMapping
                                      | defaultOrderKeyValue
                                  "
                                [value]="item.key"
                              >
                                {{ item.value }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Company Type</mat-label>
                            <mat-select formControlName="company_type" [multiple]="true">
                              <mat-option
                                *ngFor="let item of CompanyTypeLabelMapping | defaultOrderKeyValue"
                                [value]="item.key">
                                {{ item.value }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row" *ngIf="showCustomerHIRLFilters">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Marketing Role</mat-label>
                            <app-generic-model-chips modelClassName="hirl_marketing_role" formControlName="hirl_marketing_role_info" [initialValueIds]="storedParams.hirl_marketing_role"></app-generic-model-chips>
                          </mat-form-field>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row" *ngIf="entitiesIsLoading">
            <div class="col-12">
              <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
            </div>
          </div>
          <table mat-table
                 [dataSource]="entities"
                 matSort
                 [matSortActive]="storedParams.toTableSort().active"
                 [matSortDirection]="storedParams.toTableSort().direction"
                 (matSortChange)="onSort($event)"
                 class="w-100"
          >
            <ng-container matColumnDef="company__name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</th>
              <td mat-cell *matCellDef="let row">
                <app-company-display [company]="row.company_info" displayType="onlyNameAsLink" *ngIf="row.company_info"></app-company-display>
                <app-company-display [company]="row.user_info?.company_info" displayType="onlyNameAsLink" *ngIf="row.user_info?.company_info"></app-company-display>
              </td>
            </ng-container>

            <ng-container matColumnDef="user__first_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>User Name</th>
              <td mat-cell *matCellDef="let row">
                <app-user-display [user]="row.user_info" displayType="onlyNameAsLink"></app-user-display>
              </td>
            </ng-container>

            <ng-container matColumnDef="company__street_line1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Address</th>
              <td mat-cell *matCellDef="let row">
                <app-address-display [geocode]="companyAddressToConfirmedGeocodeResponse(row.company_info)" [geocodeBrokeredResponse]="null" *ngIf="row.company_info" display="contactAddress"></app-address-display>
                <app-address-display [geocode]="companyAddressToConfirmedGeocodeResponse(row.user_info?.company_info)" [geocodeBrokeredResponse]="null" *ngIf="row.user_info?.company_info" display="contactAddress"></app-address-display>
              </td>
            </ng-container>

            <ng-container matColumnDef="phones">
              <th mat-header-cell *matHeaderCellDef>Phone Number</th>
              <td mat-cell *matCellDef="let row">
                <div *ngFor="let phoneContact of row?.phones" [matTooltip]="phoneContact.description">
                  {{ phoneContact.phone | mask : "000-000-0000" }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="emails">
              <th mat-header-cell *matHeaderCellDef>Email</th>
              <td mat-cell *matCellDef="let row">
                <div *ngFor="let emailContact of row.emails" [matTooltip]="emailContact.description">
                  {{ emailContact.email }}
                  <app-copy-to-clipboard-button [value]='emailContact.email' matTooltip="Copy Email Address">
                    <fa-icon [icon]="['far', 'copy']"></fa-icon>
                  </app-copy-to-clipboard-button>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="marketing_role">
              <th mat-header-cell *matHeaderCellDef>Marketing Role</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngFor="let role of row.company_info?.hirl_marketing_roles_info; let last = last">
                  {{ role.name }}{{ !last ? ', ' : '' }}
                </ng-container>
                <ng-container *ngFor="let role of row.user_info?.company_info?.hirl_marketing_roles_info; let last = last">
                  {{ role.name }}{{ !last ? ', ' : '' }}
                </ng-container>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div class="d-flex flex-row">
            <div class="align-self-center">
            </div>
            <div class="d-flex flex-fill justify-content-end">
              <mat-paginator [length]="entitiesCount"
                             [pageIndex]="$any(storedParams.page) - 1"
                             [pageSize]="storedParams.page_size"
                             (page)="onPaginateChange($event)"
                             aria-label="Select page">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
