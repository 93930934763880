import { ListRequestParams } from '@/core/schemes/request-params';
import { environment } from '../../../../environments/environment';
import { AccreditationName } from '@/data/user-management/models/accreditation';
import { Injectable } from '@angular/core';
import { BaseDataService } from '@/core/services/base-data-service';

export class AccreditationRequestParams extends ListRequestParams {
  name?: AccreditationName;
  is_expired?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AccreditationService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/accreditations/`;

  customer_hirl_certificate(accreditationId: number) {
    return this.http.get(
      `${this.baseUrl}${accreditationId}/customer_hirl_certificate/`,
      {
        responseType: 'blob',
      }
    );
  }
}
