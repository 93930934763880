<div class="container" *ngIf="initialized">
    <div class="row mb-2">
        <div class="col-12">
            <app-user-detail-tab></app-user-detail-tab>
        </div>
    </div>
<!--    <div class="row">-->
<!--        <div class="col-12">-->
<!--            <mat-tab-group color="accent" style="min-height: 450px;" disableRipple="true" animationDuration="0ms" dynamicHeight (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedIndex">-->
<!--                <mat-tab label="Accreditation">-->
<!--                    <div class="mt-2 mb-5">-->
<!--                        <app-user-accreditation-tab></app-user-accreditation-tab>-->
<!--                    </div>-->
<!--                </mat-tab>-->
<!--                <mat-tab label="Training">-->
<!--                    <div class="mt-2 mb-5">-->
<!--                    </div>-->
<!--                </mat-tab>-->
<!--                <mat-tab label="Grading">-->
<!--                    <div class="mt-2 mb-5">-->
<!--                    </div>-->
<!--                </mat-tab>-->
<!--                <mat-tab label="Metrics">-->
<!--                    <div class="mt-2 mb-5">-->
<!--                    </div>-->
<!--                </mat-tab>-->
<!--                <mat-tab label="Advanced">-->
<!--                    <div class="mt-2 mb-5">-->
<!--                    </div>-->
<!--                </mat-tab>-->
<!--            </mat-tab-group>-->
<!--        </div>-->
<!--    </div>-->
</div>
