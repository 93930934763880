import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NoteService } from '@/data/simulation/services/note.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as NoteActions from './actions';
import * as SharedActions from '../shared/shared.actions';

@Injectable()
export class NoteEffects {
  loadNotes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NoteActions.loadNotes),
      mergeMap(action => {
        return of(
          NoteActions.loadNotesSuccess({
            notes: action.notes,
            errors: [],
          })
        );
      })
    );
  });

  updateNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NoteActions.updateNote),
      mergeMap(action => {
        return this.noteService.update(action.note).pipe(
          mergeMap(response => {
            return of(
              NoteActions.loadNotesSuccess({
                notes: [{ ...action.note, data: response.note }],
                errors: [],
              })
            );
          }),
          catchError(error =>
            of(
              NoteActions.updateNoteFailure({
                id: action.note.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        );
      })
    );
  });

  constructor(private actions$: Actions, private noteService: NoteService) {}
}
