export interface Note {
  id: string;
  entity_id: number;
  entity_name: string;
  data: string;
}

export function createNote(
  entityName: string,
  entityId: number,
  note: string
): Note {
  return {
    id: entityName + entityId,
    entity_id: entityId,
    entity_name: entityName,
    data: note,
  };
}
