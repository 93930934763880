import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as SkylightTypeActions from './actions';
import * as NoteActions from '../note/actions';
import * as SharedActions from '../shared/shared.actions';
import { SkylightTypeService } from '@/data/simulation/services/skylight-type.service';
import { SkylightTypeValidator } from '../../validtaors/skylight-type.validator';
import { createNote } from '@/data/simulation/models/Note';
import { StateModelName } from '../../state.registry';

@Injectable()
export class SkylightTypeEffects {
  loadDetailedSkylightTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkylightTypeActions.loadSkylightTypes),
      mergeMap(action => {
        const errors = SkylightTypeValidator.validate(action.skylightTypes);
        const notes = [];
        action.skylightTypes.forEach(skylightType => {
          notes.push(
            createNote(
              StateModelName.skylightType,
              skylightType.id,
              skylightType.note
            )
          );
        });
        return of(
          SkylightTypeActions.loadSkylightTypesSuccess({
            skylightTypes: action.skylightTypes,
            errors,
          }),
          NoteActions.loadNotes({ notes })
        );
      })
    );
  });

  updateSkylightType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkylightTypeActions.updateSkylightType),
      mergeMap(action =>
        this.skylightTypeService.update(action.skylightTypeChanges).pipe(
          mergeMap(updatedSkylightType => {
            const errors = SkylightTypeValidator.validate([
              updatedSkylightType,
            ]);
            return of(
              SkylightTypeActions.loadSkylightTypesSuccess({
                skylightTypes: [updatedSkylightType],
                errors,
              })
            );
          }),
          catchError(error =>
            of(
              SkylightTypeActions.updateSkylightTypeFailure({
                id: action.skylightTypeChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private skylightTypeService: SkylightTypeService
  ) {}
}
