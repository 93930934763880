import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as AboveGradeWallTypeActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import * as NoteActions from '../note/actions';
import { AboveGradeWallTypeService } from '@/data/simulation/services/above-grade-wall-type.service';
import { ModelGraphService } from '../../services/model-graph.service';
import { AboveGradeWallTypeValidator } from '../../validtaors/above-grade-wall-type.validator';
import { createNote } from '@/data/simulation/models/Note';
import { StateModelName } from '../../state.registry';

@Injectable()
export class AboveGradeWallTypeEffects {
  loadDetailedAboveGradeWallTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AboveGradeWallTypeActions.loadDetailedAboveGradeWallTypes),
      mergeMap(action => {
        const notes = [];
        action.wallTypes.forEach(detailedWallType => {
          notes.push(
            createNote(
              StateModelName.aboveGradeWallType,
              detailedWallType.id,
              detailedWallType.note
            )
          );
        });
        const errors = AboveGradeWallTypeValidator.validate(action.wallTypes);
        return of(
          AboveGradeWallTypeActions.loadAboveGradeWallTypesSuccess({
            wallTypes: action.wallTypes,
            errors,
          }),
          NoteActions.loadNotes({ notes })
        );
      })
    );
  });

  updateAboveGradeWallType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AboveGradeWallTypeActions.updateAboveGradeWallType),
      mergeMap(action =>
        this.wallTypeService.update(action.wallTypeChanges).pipe(
          mergeMap(updatedWallType => {
            const errors = AboveGradeWallTypeValidator.validate([
              updatedWallType,
            ]);
            return of(
              AboveGradeWallTypeActions.loadAboveGradeWallTypesSuccess({
                wallTypes: [updatedWallType],
                errors,
              })
            );
          }),
          catchError(error =>
            of(
              AboveGradeWallTypeActions.updateAboveGradeWallTypeFailure({
                id: action.wallTypeChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private wallTypeService: AboveGradeWallTypeService
  ) {}
}
