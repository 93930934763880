import { AuthGuard } from '@/core/guards';
import { ApexRequestCreateComponent } from '@/modules/home/pages/apex-request-create/apex-request-create.component';
import { EsgReportComponent } from '@/modules/home/pages/esg-report/esg-report.component';
import { ApexRequestDetailComponent } from '@/modules/home/pages/apex-request-detail/apex-request-detail.component';
import { ApexRequestListComponent } from '@/modules/home/pages/apex-request-list/apex-request-list.component';
import { ProgramEligibilityDashboardComponent } from '@/modules/home/pages/program-eligibility-dashboard/program-eligibility-dashboard.component';
import { ApexRequestUtilityListComponent } from '@/modules/home/pages/apex-request-utility-list/apex-request-utility-list.component';
import { ApexRequestUtilityDetailComponent } from '@/modules/home/pages/apex-request-utility-detail/apex-request-utility-detail.component';
import { PsrListComponent } from '@/modules/home/pages/psr-list/psr-list.component';
import { ApexRequestRESNETQAListComponent } from '@/modules/home/pages/apex-request-resnet-qa-list/apex-request-resnet-qa-list.component';
import { ApexRequestUtilityCreateComponent } from '@/modules/home/pages/apex-request-utility-create/apex-request-utility-create.component';
import { ApexRequestRESNETQACreateComponent } from '@/modules/home/pages/apex-request-resnet-qa-create/apex-request-resnet-qa-create.component';
import { ApexRequestRESNETQADetailComponent } from '@/modules/home/pages/apex-request-resnet-qa-detail/apex-request-resnet-qa-detail.component';

export const HomeRouting = [
  {
    path: 'home',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'esg/report',
        component: EsgReportComponent,
        resolve: {},
      },
      {
        path: 'program/eligibility/dashboard',
        component: ProgramEligibilityDashboardComponent,
        resolve: {},
      },
      {
        path: 'status',
        component: PsrListComponent,
        resolve: {},
      },
      {
        path: 'apex/request',
        children: [
          {
            path: 'list',
            component: ApexRequestListComponent,
            resolve: {},
          },
          {
            path: 'detail/:apexRequestId',
            component: ApexRequestDetailComponent,
            resolve: {},
          },
          {
            path: 'create',
            component: ApexRequestCreateComponent,
            resolve: {},
          },
          {
            path: 'utility-list',
            component: ApexRequestUtilityListComponent,
          },
          {
            path: 'utility-detail/:apexRequestId',
            component: ApexRequestUtilityDetailComponent,
            resolve: {},
          },
          {
            path: 'utility-create',
            component: ApexRequestUtilityCreateComponent,
            resolve: {},
          },
          {
            path: 'resnet-qa-list',
            component: ApexRequestRESNETQAListComponent,
          },
          {
            path: 'resnet-qa-create',
            component: ApexRequestRESNETQACreateComponent,
            resolve: {},
          },
          {
            path: 'resnet-qa-detail/:apexRequestId',
            component: ApexRequestRESNETQADetailComponent,
            resolve: {},
          },
        ],
      },
    ],
  },
];
