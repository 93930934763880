import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { map } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { UserPermissionService } from '@/modules/user/services/user-permission-service';

@Injectable({ providedIn: 'root' })
export class EditUserGuard implements CanActivate {
  constructor(
    public router: Router,
    public store: Store<AppState>,
    public userPermissionService: UserPermissionService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.store.dispatch(toggleLoading({ payload: true }));
    return this.userPermissionService.canEdit(route.params.userId).pipe(
      map(objectPermissionResponse => {
        this.store.dispatch(toggleLoading({ payload: false }));
        if (objectPermissionResponse.hasPermission) {
          return true;
        }
        this.router.navigate(['403'], {
          queryParams: { returnUrl: state.url.split('?')[0] },
        });
        return false;
      })
    );
  }
}
