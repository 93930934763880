<div class="card mb-2 position-relative" appFocusTarget entityName="door" entityId="{{entityId}}">
  <app-loading-overlay *ngIf="loading"></app-loading-overlay>
  <div class="card-body pb-0">
    <app-form formName="doorFormGroup" [formGroup]="doorFormGroup" (save)="onSave($event)">
      <div>
          <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
      </div>
      <div>
          <app-input formControlName="area" [label]="fieldLabels.area" unit="sqft." type="expression"></app-input>
      </div>
      <div>
          <app-select [formControl]="wallControl" [items]="wallLabels" label="Wall location" [isInEditMode]="doorFormGroup.enabled"></app-select>
          <app-model-select modelName="aboveGradeWall" formControlName="above_grade_wall" [hidden]="wallControl.value !== 'above_grade_wall'" label="Wall"></app-model-select>
          <app-model-select modelName="foundationWall" formControlName="foundation_wall" [hidden]="wallControl.value === 'above_grade_wall'" label="Wall"></app-model-select>
      </div>
      <div>
          <app-foreign-model-field
            [name]="doorTypeName"
            [errorCount]="doorTypeErrorCount"
            (add)="addDoorType()"
            (edit)="editDoorType()"
            (remove)="removeDoorType()"
            label="Door type">
          </app-foreign-model-field>
      </div>
    </app-form>
  </div>
  <div class="card-footer p-0 text-right">
    <app-model-action-buttons
      [entityId]="entityId"
      modelName="door"
      (clone)="cloneDoor()"
      (delete)="removeDoor()">
    </app-model-action-buttons>
  </div>
</div>
