import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { HIRLProjectListComponent } from '@/modules/customer-hirl/pages/hirl-projects-list/hirl-project-list.component';
import { HIRLProjectReasonDialogComponent } from '@/modules/customer-hirl/components/hirl-project-reason-dialog/hirl-project-reason-dialog.component';
import { HIRLInvoiceItemGroupsListComponent } from '@/modules/customer-hirl/pages/hirl-invoice-item-groups-list/hirl-invoice-item-groups-list.component';
import { HIRLInvoicesListComponent } from '@/modules/customer-hirl/pages/hirl-invoices-list/hirl-invoices-list.component';
import { HIRLInvoiceDetailComponent } from './pages/hirl-invoice-detail/hirl-invoice-detail.component';
import { HIRLJamisDashboardComponent } from './pages/hirl-jamis-dashboard/hirl-jamis-dashboard.component';
import { HIRLProjectRegistrationListComponent } from './pages/hirl-project-registration-list/hirl-project-registration-list.component';
import { HIRLProjectRegistrationSFFormComponent } from './pages/hirl-project-registration-sf-form/hirl-project-registration-sf-form.component';
import { HIRLProjectRegistrationDetailComponent } from './pages/hirl-project-registration-detail/hirl-project-registration-detail.component';
import { HIRLProjectSFFormComponent } from './pages/hirl-project-sf-form/hirl-project-sf-form.component';
import { HIRLProjectRegistrationMFFormComponent } from './pages/hirl-project-registration-mf-form/hirl-project-registration-mf-form.component';
import { HIRLProjectMFFormComponent } from './pages/hirl-project-mf-form/hirl-project-mf-form.component';
import { HIRLProjectMFAddFormComponent } from './pages/hirl-project-mf-add-form/hirl-project-mf-add-form.component';
import { HIRLProjectTypeComponent } from './components/hirl-project-type/hirl-project-type.component';
import { HIRLProjectRegistrationStateComponent } from './components/hirl-project-registration-state/hirl-project-registration-state.component';
import { HirlProjectRegistrationEntityResponsibleForPaymentComponent } from './components/hirl-project-registration-entity-responsible-for-payment/hirl-project-registration-entity-responsible-for-payment.component';
import { CoiDocumentChangeDialogComponent } from './components/coi-document-change-dialog/coi-document-change-dialog.component';
import { HirlProjectCardComponent } from './components/hirl-project-card/hirl-project-card.component';
import { HirlCreateClientAgreementWithoutDocusignComponent } from './pages/hirl-create-client-agreement-without-docusign/hirl-create-client-agreement-without-docusign.component';
import { HirlCreateClientAgreementWithoutUserComponent } from './pages/hirl-create-client-agreement-without-user/hirl-create-client-agreement-without-user.component';
import { HirlBuilderOrganizationDisplayPrefixComponent } from './components/hirl-builder-organization-display-prefix/hirl-builder-organization-display-prefix.component';
import { HirlVerifierResourcesPageComponent } from './pages/hirl-verifier-resources-page/hirl-verifier-resources-page.component';
import { NgbsFindVerifierComponent } from './pages/ngbs-find-verifier/ngbs-find-verifier.component';
import { NgbsFindGreenVerifierComponent } from './pages/ngbs-find-green-verifier/ngbs-find-green-verifier.component';
import { NgbsFindVerifierDetailDialogComponent } from './components/ngbs-find-verifier-detail-dialog/ngbs-find-verifier-detail-dialog.component';
import { HirlCopyCaComponent } from './pages/hirl-copy-ca/hirl-copy-ca.component';
import { HirlProjectListTabComponent } from './components/hirl-project-list-tab/hirl-project-list-tab.component';
import { NgbsBuilderCentralComponent } from './pages/ngbs-builder-central/ngbs-builder-central.component';
import { HirlRegistrationAbandonConfirmDialogComponent } from './components/hirl-registration-abandon-confirm-dialog/hirl-registration-abandon-confirm-dialog.component';
import { HomeModule } from '@/modules/home/home.module';
import { HirlQaDashboardComponent } from './pages/hirl-qa-dashboard/hirl-qa-dashboard.component';
import { HirlQaNotesDialogComponent } from './components/hirl-qa-notes-dialog/hirl-qa-notes-dialog.component';
import { HirlQaNoteMultipleCreateDialogComponent } from './components/hirl-qa-note-multiple-create-dialog/hirl-qa-note-multiple-create-dialog.component';
import { HirlBulkCertificateDownloadComponent } from './pages/hirl-bulk-certificate-download/hirl-bulk-certificate-download.component';
import { HirlProjectRegistrationLandDevelopmentFormComponent } from './pages/hirl-project-registration-land-development-form/hirl-project-registration-land-development-form.component';
import { HirlProjectLdFormComponent } from './pages/hirl-project-ld-form/hirl-project-ld-form.component';
import { HirlVerifierReportUploadComponent } from './pages/hirl-verifier-report-upload/hirl-verifier-report-upload.component';
import { HirlRpcUpdaterRequestListComponent } from './pages/hirl-rpc-updater-request-list/hirl-rpc-updater-request-list.component';
import { HirlRpcUpdaterRequestCreateComponent } from './pages/hirl-rpc-updater-request-create/hirl-rpc-updater-request-create.component';
import { HirlRpcUpdaterRequestDetailComponent } from './pages/hirl-rpc-updater-request-detail/hirl-rpc-updater-request-detail.component';
import { HirlProjectAddDwellingUnitComponent } from './pages/hirl-project-add-dwelling-unit/hirl-project-add-dwelling-unit.component';
import { HirlProjectSfAddFormComponent } from './pages/hirl-project-sf-add-form/hirl-project-sf-add-form.component';
import { UserModule } from '@/modules/user/user.module';
import { FilehandlingModule } from '@/modules/filehandling/filehandling.module';
import { HirlClientAgreementDetailComponent } from './pages/hirl-client-agreement-detail/hirl-client-agreement-detail.component';
import { HirlClientAgreementListComponent } from './pages/hirl-client-agreement-list/hirl-client-agreement-list.component';
import { HirlClientAgreementCoiListComponent } from './components/hirl-client-agreement-coi-list/hirl-client-agreement-coi-list.component';
import { HirlClientAgreementChangeDialogComponent } from './components/hirl-client-agreement-change-dialog/hirl-client-agreement-change-dialog.component';
import { HirlClientAgreementCreateComponent } from './pages/hirl-client-agreement-create/hirl-client-agreement-create.component';
import { HirlClientAgreementForceStateChangeDialogComponent } from './components/hirl-client-agreement-force-state-change-dialog/hirl-client-agreement-force-state-change-dialog.component';
import { HirlCertificatePreviewComponent } from '@/modules/customer-hirl/components/hirl-certificate-preview/hirl-certificate-preview.component';
import { HirlInvoiceShareDialogComponent } from './components/hirl-invoice-share-dialog/hirl-invoice-share-dialog.component';
import { BatchSubmissionDashboardComponent } from './pages/ngbs-batch-submission-dashboard/ngbs-batch-submission-dashboard.component';
import { HirlCoiDashboardComponent } from './pages/hirl-coi-dashboard/hirl-coi-dashboard.component';
import { AnnotationModule } from '@/modules/annotation/annotation.module';
import { HirlProjectAddStandAloneCommercialSpaceComponent } from './pages/hirl-project-add-stand-alone-commercial-space/hirl-project-add-stand-alone-commercial-space.component';
import { HirlProductAgreementListComponent } from './pages/hirl-product-agreement-list/hirl-product-agreement-list.component';
import { HirlProductAgreementDetailComponent } from './pages/hirl-product-agreement-detail/hirl-product-agreement-detail.component';
import { HirlProductAgreementChangeDialogComponent } from './components/hirl-product-agreement-change-dialog/hirl-product-agreement-change-dialog.component';
import { PaymentRequestListComponent } from './pages/payment-request-list/payment-request-list.component';
import { PaymentRequestChangeDialogComponent } from './components/payment-request-change-dialog/payment-request-change-dialog.component';
import { PaymentRequestDetailComponent } from './pages/payment-request-detail/payment-request-detail.component';
import { PaymentRequestProductsTabComponent } from './components/payment-request-products-tab/payment-request-products-tab.component';
import { HirlInvoiceInvoiceItemGroupTabComponent } from './components/hirl-invoice-invoice-item-group-tab/hirl-invoice-invoice-item-group-tab.component';
import { HirlGcpInvoicesListComponent } from './pages/hirl-gcp-invoices-list/hirl-gcp-invoices-list.component';
import { ProductPracticeItemsTabComponent } from '@/modules/customer-hirl/components/product-practice-items/product-practice-items-tab.component';
import { ProductPracticeItemChangeDialogComponent } from '@/modules/customer-hirl/components/product-practice-item-change-dialog/product-practice-item-change-dialog.component';
import { ProductModule } from '@/modules/product/product.module';
import { HirlSetupInvoicePreferencesDialogComponent } from './components/hirl-setup-invoice-preferences-dialog/hirl-setup-invoice-preferences-dialog.component';
import { PaymentRequestProductChangeDialogComponent } from './components/payment-request-product-change-dialog/payment-request-product-change-dialog.component';
import { HIRLProductListComponent } from '@/modules/customer-hirl/pages/hirl-product-list/hirl-product-list.component';
import { HIRLProductDetailPageComponent } from '@/modules/customer-hirl/pages/hirl-product-detail-page/hirl-product-detail-page.component';
import { HIRLProductChangeDialogComponent } from '@/modules/customer-hirl/components/hirl-product-change-dialog/hirl-product-change-dialog.component';
import { HirlProductPublishRequirementsDialogComponent } from './components/hirl-product-publish-requirements-dialog/hirl-product-publish-requirements-dialog.component';
import { ProductPaymentRequestsTabComponent } from './components/product-payment-requests-tab/product-payment-requests-tab.component';
import { HirlGcpInvoiceChangeDialogComponent } from './components/hirl-gcp-invoice-change-dialog/hirl-gcp-invoice-change-dialog.component';
import { HirlTriggerRenewalNotificationDialogComponent } from './components/hirl-trigger-renewal-notification-dialog/hirl-trigger-renewal-notification-dialog.component';

@NgModule({
  declarations: [
    HIRLProjectReasonDialogComponent,
    HIRLProjectListComponent,
    HIRLInvoicesListComponent,
    HIRLInvoiceItemGroupsListComponent,
    HIRLInvoiceDetailComponent,
    HIRLJamisDashboardComponent,
    HIRLProjectRegistrationListComponent,
    HIRLProjectRegistrationSFFormComponent,
    HIRLProjectRegistrationSFFormComponent,
    HIRLProjectRegistrationDetailComponent,
    HIRLProjectSFFormComponent,
    HIRLProjectRegistrationMFFormComponent,
    HIRLProjectMFFormComponent,
    HIRLProjectMFAddFormComponent,
    HIRLProjectTypeComponent,
    HIRLProjectRegistrationStateComponent,
    HirlProjectRegistrationEntityResponsibleForPaymentComponent,
    CoiDocumentChangeDialogComponent,
    HirlProjectCardComponent,
    HirlCreateClientAgreementWithoutDocusignComponent,
    HirlCreateClientAgreementWithoutUserComponent,
    HirlBuilderOrganizationDisplayPrefixComponent,
    HirlVerifierResourcesPageComponent,
    NgbsFindVerifierComponent,
    NgbsFindGreenVerifierComponent,
    NgbsFindVerifierDetailDialogComponent,
    HirlCopyCaComponent,
    HirlProjectListTabComponent,
    NgbsBuilderCentralComponent,
    HirlRegistrationAbandonConfirmDialogComponent,
    HirlQaDashboardComponent,
    HirlQaNotesDialogComponent,
    HirlQaNoteMultipleCreateDialogComponent,
    HirlBulkCertificateDownloadComponent,
    HirlQaNoteMultipleCreateDialogComponent,
    HirlProjectRegistrationLandDevelopmentFormComponent,
    HirlProjectLdFormComponent,
    HirlVerifierReportUploadComponent,
    HirlRpcUpdaterRequestListComponent,
    HirlRpcUpdaterRequestCreateComponent,
    HirlRpcUpdaterRequestDetailComponent,
    HirlProjectAddDwellingUnitComponent,
    HirlProjectSfAddFormComponent,
    HirlClientAgreementDetailComponent,
    HirlClientAgreementListComponent,
    HirlClientAgreementCoiListComponent,
    HirlClientAgreementChangeDialogComponent,
    HirlClientAgreementCreateComponent,
    HirlClientAgreementForceStateChangeDialogComponent,
    HirlCertificatePreviewComponent,
    HirlInvoiceShareDialogComponent,
    BatchSubmissionDashboardComponent,
    HirlCoiDashboardComponent,
    HirlProjectAddStandAloneCommercialSpaceComponent,
    HirlProductAgreementListComponent,
    HirlProductAgreementDetailComponent,
    HirlProductAgreementChangeDialogComponent,
    PaymentRequestListComponent,
    PaymentRequestChangeDialogComponent,
    PaymentRequestDetailComponent,
    PaymentRequestProductsTabComponent,
    HirlInvoiceInvoiceItemGroupTabComponent,
    HirlGcpInvoicesListComponent,
    ProductPracticeItemsTabComponent,
    ProductPracticeItemChangeDialogComponent,
    HirlSetupInvoicePreferencesDialogComponent,
    PaymentRequestProductChangeDialogComponent,
    HIRLProductListComponent,
    HIRLProductDetailPageComponent,
    HIRLProductChangeDialogComponent,
    HirlProductPublishRequirementsDialogComponent,
    ProductPaymentRequestsTabComponent,
    HirlGcpInvoiceChangeDialogComponent,
    HirlTriggerRenewalNotificationDialogComponent,
  ],
  exports: [HirlBuilderOrganizationDisplayPrefixComponent],
  imports: [
    CommonModule,
    SharedModule,
    HomeModule,
    UserModule,
    FilehandlingModule,
    AnnotationModule,
    ProductModule,
  ],
})
export class CustomerHIRLModule {}
