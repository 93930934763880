import { MechanicalEquipmet } from '@/data/simulation/models/mechanicals';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment-water-heater',
  templateUrl: './equipment-water-heater.component.html',
  styleUrls: ['./equipment-water-heater.component.scss'],
})
export class EquipmentWaterHeaterComponent implements OnInit {
  @Input() equipment: MechanicalEquipmet;

  ngOnInit(): void {}
}
