import { Component, OnInit } from '@angular/core';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { toggleLoading } from '@/state/actions/app.actions';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, switchMap } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { UserService } from '@/data/core/services/user.service';
import { User } from '@/data/core/models/user';

import { HIRLUserProfile } from '@/data/customer-hirl/models';

@Component({
  selector: 'app-user-change-page',
  templateUrl: './user-change-page.component.html',
  styleUrls: ['./user-change-page.component.scss'],
})
export class UserChangePageComponent implements OnInit {
  public initialized = false;
  public selectedIndex = 0;

  public mainInformationFormGroup: UntypedFormGroup;
  public contactFormGroup: UntypedFormGroup;
  public hesFormGroup: UntypedFormGroup;
  public raterFormGroup: UntypedFormGroup;
  public ngbsFormGroup: UntypedFormGroup;
  public providerFormGroup: UntypedFormGroup;

  public currentUser: User;
  public user: User;

  private componentDestroyed$ = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));

    this.activatedRoute.queryParams.pipe(first()).subscribe(params => {
      if (params.step) {
        this.selectedIndex = Number(params.step);
      }
    });

    const sources: { [k: string]: any } = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      user: this.userService
        .retrieve(this.activatedRoute.snapshot.params.userId)
        .pipe(first()),
    };

    forkJoin(sources).subscribe(({ currentUser, user, queryParams }) => {
      this.currentUser = currentUser;
      this.user = user;
      this.initialized = true;
      this.hydrateForm();
      this.store.dispatch(toggleLoading({ payload: false }));
    });

    this.setupMainInformationFormGroup();
    this.setupContactFormGroup();
    this.setupHESFormGroup();
    this.setupRaterFormGroup();
    this.setupNGBSFormData();
    this.setupProviderFormData();
  }

  setupMainInformationFormGroup() {
    this.mainInformationFormGroup = this.fb.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      title: [null],
      department: [null],
    });
  }

  setupContactFormGroup() {
    this.contactFormGroup = this.fb.group({
      email: [
        null,
        Validators.compose([Validators.required, Validators.email]),
      ],
      work_phone: [null],
      cell_phone: [null],
      mailing_address_geocode: [null],
      shipping_address_geocode: [null],
    });
  }

  setupHESFormGroup() {
    this.hesFormGroup = this.fb.group({
      username: [null],
      password: [null],
    });
  }

  setupRaterFormGroup() {
    this.raterFormGroup = this.fb.group({
      rater_roles: [null],
      resnet: [null],
      signature_image: [null],
    });
  }

  setupNGBSFormData() {
    this.ngbsFormGroup = this.fb.group({
      is_qa_designee: [null],
    });
  }

  setupProviderFormData() {
    this.providerFormGroup = this.fb.group({
      resnet_username: [null],
      resnet_password: [null],
    });
  }

  hydrateForm() {
    const payload = Object.assign({}, this.user);

    this.mainInformationFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.contactFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.raterFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.hesFormGroup.patchValue(payload, { emitEvent: false, onlySelf: true });
    this.providerFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });

    this.ngbsFormGroup.patchValue(payload.hirl_user_profile, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  getAllFormGroups(): UntypedFormGroup[] {
    return [
      this.mainInformationFormGroup,
      this.contactFormGroup,
      this.raterFormGroup,
      this.hesFormGroup,
      this.ngbsFormGroup,
      this.providerFormGroup,
    ];
  }

  onStepChange($event: StepperSelectionEvent) {}

  handleEdit(event): void {
    event.preventDefault();

    this.getAllFormGroups().forEach(form => {
      form.markAllAsTouched();
    });

    if (this.getAllFormGroups().some(form => form.invalid)) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const imageData = new FormData();
    imageData.append(
      'signature_image',
      this.raterFormGroup.value.signature_image
    );

    // ngbs data
    const hirlUserProfileData = new HIRLUserProfile();
    hirlUserProfileData.is_qa_designee =
      this.ngbsFormGroup.value.is_qa_designee;

    // send other data as separate request
    const userData = new User();
    userData.rater_roles = this.raterFormGroup.value.rater_roles.map(
      rater_role => rater_role.id
    );
    userData.hirl_user_profile = hirlUserProfileData;
    userData.first_name = this.mainInformationFormGroup.value.first_name;
    userData.last_name = this.mainInformationFormGroup.value.last_name;
    userData.title = this.mainInformationFormGroup.value.title;
    userData.department = this.mainInformationFormGroup.value.department;

    userData.email = this.contactFormGroup.value.email;
    userData.work_phone = this.contactFormGroup.value.work_phone;
    userData.cell_phone = this.contactFormGroup.value.cell_phone;

    userData.resnet_username = this.providerFormGroup.value.resnet_username;
    userData.resnet_password = this.providerFormGroup.value.resnet_password;

    this.userService
      .update(userData, this.user.id)
      .pipe(
        first(),
        switchMap(user => this.userService.update(imageData, user.id))
      )
      .subscribe(
        user => {
          this.user = user;
          this.store.dispatch(toggleLoading({ payload: false }));
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }
}
