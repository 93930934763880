import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@/data/core/services/user.service';
import { toggleLoading } from '@/state/actions/app.actions';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { CompanyType } from '@/data/company/models';
import { ClientAgreementService } from '@/data/customer-hirl/services/client-agreement.service';
import { CreateClientAgreementWithoutDocuSign } from '@/data/customer-hirl/models';
import { CompanyRequestParams } from '@/data/company/services/company-base.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';

@Component({
  selector: 'app-hirl-create-client-agreement-without-docusign',
  templateUrl: './hirl-create-client-agreement-without-docusign.component.html',
  styleUrls: ['./hirl-create-client-agreement-without-docusign.component.scss'],
})
export class HirlCreateClientAgreementWithoutDocusignComponent
  implements OnInit, OnDestroy
{
  public initialized = false;
  public currentUser: User;
  public addFormGroup: UntypedFormGroup;

  public companyRequestParams: CompanyRequestParams;
  private componentDestroyed$ = new Subject();

  constructor(
    public fb: UntypedFormBuilder,
    public store: Store<AppState>,
    public router: Router,
    public clientAgreementService: ClientAgreementService,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    private uiHelperService: UIHelperService
  ) {
    this.companyRequestParams = new CompanyRequestParams();
    this.companyRequestParams.company_type = [
      CompanyType.builder,
      CompanyType.developer,
      CompanyType.communityOwner,
      CompanyType.architect,
    ];
  }

  ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));

    const sources: { [k: string]: any } = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
    };

    forkJoin(sources).subscribe(({ currentUser }) => {
      this.currentUser = currentUser;

      this.store.dispatch(toggleLoading({ payload: false }));
      this.initialized = true;
    });

    this.setupAddFormGroup();
  }

  public ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  handleBack() {
    this.router.navigate(['hi', 'builder-agreements']);
  }

  public setupAddFormGroup() {
    this.addFormGroup = this.fb.group({
      company_info: [null, Validators.required],
    });
  }

  handleCreate($event: MouseEvent) {
    $event.preventDefault();

    this.addFormGroup.markAllAsTouched();

    if (this.addFormGroup.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const formData: CreateClientAgreementWithoutDocuSign =
      new CreateClientAgreementWithoutDocuSign();

    formData.company = this.addFormGroup.value.company_info.id;

    this.clientAgreementService
      .create_without_docusign(formData)
      .pipe(first())
      .subscribe(
        clientAgreement => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.router.navigate([
            `hi`,
            `client_agreements`,
            `detail`,
            clientAgreement.id,
          ]);
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }
}
