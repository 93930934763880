import { ModelErrors } from '../state/shared/base.state';

export interface ValidationResult {
  id: string;
  validationSuccess: boolean;
  data: any;
}

export class BaseValidator {}

export class ValidationErrors<Model> {
  errors: ModelErrors<Model>;

  constructor(errors: ModelErrors<Model>) {
    this.errors = errors;
  }

  addValidationResults(results: ValidationResult[]) {
    results.forEach(result => {
      if (result.validationSuccess) return;
      this.errors.model[result.id] = result.data;
    });
  }
}
