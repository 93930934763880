<h3 class="d-flex justify-content-center">Analysis Reports</h3>
<div class="items" *ngIf="loading || runningSimulation">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div class="items" *ngIf="!loading">
    <div *ngIf="analyses.length === 0 && !runningSimulation">
        <div class="no-item">
            <h3 class="fst-italic">No analysis reports available!</h3>
            <app-run-simulation-button></app-run-simulation-button>
        </div>
    </div>
    <div *ngFor="let analysis of analyses">
        <app-analysis [entity]="analysis" [change]="changes[analysis.id]"></app-analysis>
    </div>
</div>
