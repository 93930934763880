import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { Task, TaskStatusLabelMapping } from '@/data/scheduling/models';
import { TaskService } from '@/data/scheduling/services';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface ITaskChangeDialogData {
  taskId?: number;
  taskData?: Task;
}

@Component({
  selector: 'app-change-task-dialog',
  templateUrl: './change-task-dialog.component.html',
  styleUrls: ['./change-task-dialog.component.scss'],
})
export class ChangeTaskDialogComponent implements OnInit, OnDestroy {
  public initialized = false;

  public editForm = false;
  public object?: Task;
  public form: UntypedFormGroup;
  public isLoading = false;

  public statusLabelMapping = TaskStatusLabelMapping;

  private componentDestroyed$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<ChangeTaskDialogComponent>,
    public fb: UntypedFormBuilder,
    public taskService: TaskService,
    public dialog: MatDialog,
    public uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: ITaskChangeDialogData
  ) {}

  ngOnInit() {
    if (this.data.taskId) {
      this.editForm = true;
      this.taskService
        .retrieve(this.data.taskId)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe(task => {
          this.object = task;
          this.setupForm();
          this.hydrateForm();
          this.initialized = true;
        });
    } else {
      this.object = new Task();
      this.setupForm();
      this.hydrateForm();
      this.initialized = true;
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupForm() {
    this.form = this.fb.group({
      // status: [null, Validators.required],
      note: [],
    });
  }

  hydrateForm() {
    this.form.patchValue(this.object, { emitEvent: false, onlySelf: true });
  }

  onSave(event: any) {
    if (this.editForm) {
      this.onEdit(event);
    } else {
      this.onCreate(event);
    }
  }

  onEdit(event: any) {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const formData = this.getFormData();
    this.dialogRef.close(formData);
  }

  onCreate(event: any) {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const formData = this.getFormData();
    this.dialogRef.close(formData);
  }

  getFormData() {
    const task = new Task();
    // task.status = this.form.value.status;
    task.note = this.form.value.note;
    return task;
  }
}
