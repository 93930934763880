import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ServerResponse } from '@/core/schemes/server-response';

@Component({
  selector: 'app-mtm-display',
  templateUrl: './mtm-display.component.html',
  styleUrls: ['./mtm-display.component.scss'],
})
export class MtmDisplayComponent implements OnInit {
  @Input() display: (value: any) => string;

  private list$: Observable<any>;

  @Input() set list(value: Observable<ServerResponse<any>>) {
    this.list$ = value;
    this.list$
      .pipe(first(), takeUntil(this.componentDestroyed$))
      .subscribe(response => {
        this.listIsLoading = false;
        this.mtmList = response.results;
      });
  }

  get list(): Observable<ServerResponse<any>> {
    return this.list$;
  }

  listIsLoading = true;
  mtmList: any[] = [];

  private componentDestroyed$ = new Subject();

  ngOnInit(): void {}
}
