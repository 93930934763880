import { Component, Input, OnInit } from '@angular/core';
import { Home, HomeInfo } from '@/data/home/models';

export enum DisplayType {
  default = 'default',
  link = 'link',
}

@Component({
  selector: 'app-home-address-display',
  templateUrl: './home-address-display.component.html',
  styleUrls: ['./home-address-display.component.scss'],
})
export class HomeAddressDisplayComponent implements OnInit {
  @Input() home: Home | HomeInfo;
  @Input() display: DisplayType = DisplayType.default;
  displayType = DisplayType;

  ngOnInit(): void {}
}
