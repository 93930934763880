<div class="position-relative">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <app-form formName="windowTypeFormGroup" [formGroup]="windowTypeFormGroup" (save)="onSave()">
        <div>
            <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
        </div>
        <div>
            <app-input formControlName="shgc" type="number" [label]="fieldLabels.shgc"></app-input>
            <app-input formControlName="u_value" type="number" [label]="fieldLabels.u_value"></app-input>
        </div>
    </app-form>
</div>
