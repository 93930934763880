<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-6">
                    <h4>
                        <span *ngIf="!componentLoading">{{ submissionsCount }}</span>
                        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="componentLoading"></fa-icon>
                        Project Tracker Submissions
                    </h4>
                </div>
                <div class="col-6 text-right">
                    <button mat-raised-button color="accent" (click)="reLoad($event);">
                        Refresh
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-3">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="filterForm" class="card">
                                <h5 class="card-header">Filters
                                    <div style="float: right;">
                                        <a href="#" class="small" (click)="resetFilters($event);">Reset filters</a>
                                    </div>
                                </h5>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Search</mat-label>
                                                <app-search-input formControlName="search" placeholder="Type something..."></app-search-input>
                                            </mat-form-field>
                                        </div>

                                        <div class="form-group col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Submissions Status</mat-label>
                                                <mat-select formControlName="submit_status">
                                                    <mat-option *ngFor="let item of submissionStatusMapping | keyvalue" [value]="item.key">
                                                        {{ item.value }}
                                                    </mat-option>
                                                    <mat-option value="">Any</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="form-group col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>EEP Programs</mat-label>
                                                <mat-select formControlName="program">
                                                    <mat-option value="">---</mat-option>
                                                    <mat-option *ngFor="let program of eep_programs" [value]="program.slug">{{program.name}}</mat-option>
                                                    <mat-option value="">Any</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="form-group col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Raters</mat-label>
                                                <mat-select formControlName="rater_slug">
                                                    <mat-option value="">---</mat-option>
                                                    <mat-option *ngFor="let comp of raters" [value]="comp.slug">
                                                        {{comp.name}}
                                                    </mat-option>
                                                    <mat-option value="">Any</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-9">
                    <ngx-datatable class="material" [rows]="submissions" columnMode="force" headerHeight="40"
                        rowHeight="auto" footerHeight="50" limit="25" [loadingIndicator]="componentLoading"
                        externalSorting="true" (sort)="onSort($event)">

                        <ngx-datatable-column name="Project Address" prop="address">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <span *ngIf="row.address; else dash" style="text-overflow: ellipsis !important">
                                    <a [routerLink]="['/', 'home', row.home_id]">
                                        {{ row.address }}
                                    </a>
                                </span>
                            </ng-template>
                            <ng-template #dash ngx-datatable-cell-template>-</ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="ENH Project ID" prop="project_id">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div *ngIf="row.project_id; else dash">{{ row.project_id }}</div>
                                <ng-template #dash ngx-datatable-cell-template>-</ng-template>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="SLE Project ID" prop="project_id">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div *ngIf="row.solar_project_id; else dash">{{ row.solar_project_id }}</div>
                                <ng-template #dash ngx-datatable-cell-template>-</ng-template>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Submitted By" prop="submit_user">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                                <div *ngIf="row.submit_user; else userMissing">
                                    <a [routerLink]="['/', 'profile', row.submit_user_info.id]">
                                        {{ row.submit_user_info.first_name }} {{ row.submit_user_info.last_name }}
                                    </a>
                                </div>
                                <ng-template #userMissing ngx-datatable-cell-template>-</ng-template>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Submission Status" prop="submit_status">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div *ngIf="row.overall_submission_status; else missingID">
                                    {{ row.overall_submission_status }}
                                </div>
                                <ng-template #missingID ngx-datatable-cell-template>-</ng-template>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Submission Time" prop="submit_status">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div *ngIf="row.submit_status; else missingID">
                                    {{ row.modified_date | date:'medium' }}
                                </div>
                                <ng-template #missingID ngx-datatable-cell-template>-</ng-template>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-footer>
                            <ng-template ngx-datatable-footer-template>
                                <div class="col-12 text-center">
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'" [size]="25" [count]="submissionsCount"
                                        (change)="onFooterPage($event)">
                                    </datatable-pager>
                                </div>
                            </ng-template>
                        </ngx-datatable-footer>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
</div>
