<h2 mat-dialog-title>
    <ng-container>
      Create New <b>FloorPlan</b> from Simulation
    </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
    <div class="row">
        <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
            <mat-label>Simulation Name</mat-label>
            <input matInput placeholder="Select from existing Simulation List" [formControl]="existingSimulationControl"
                    [matAutocomplete]="existingSimulationAutocomplete">
            <mat-autocomplete #existingSimulationAutocomplete="matAutocomplete" autoActiveFirstOption [displayWith]="displayExistingSimulationAutocomplete" (optionSelected)='onSimulationSelected($event)'>
                <mat-option
                    *ngFor="let simulation of (existingSimulations$ | async)?.results"
                    [value]="simulation">
                <mat-label>{{ simulation.name }} ({{ simulation.created_date | date: 'M/d/yy h:mma' }})</mat-label>
                </mat-option>
            </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
    <form [formGroup]="form" class="mb-2">
        <div class="row">
            <div class="col-6">
            <mat-form-field class="mb-2 w-100">
                <mat-label>Name</mat-label>
                <input matInput type="text" placeholder="Name" formControlName="name" required>
                <mat-error *ngIf="form.get('name').hasError('required')">
                This field is required
                </mat-error>
            </mat-form-field>
            </div>
            <div class="col-6">
            <mat-form-field class="w-100 mb-2">
                <mat-label>Number</mat-label>
                <input matInput type="text" placeholder="Number" formControlName="number">
            </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
            <mat-form-field class="w-100 mb-2">
                <mat-label>Square Footage</mat-label>
                <input matInput type="text" placeholder="Square Footage"
                    formControlName="square_footage" required>
                <mat-error *ngIf="form.get('square_footage').hasError('required')">
                Square Footage is required
                </mat-error>
            </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
            <mat-form-field appearance="outline" class="mb-2 w-100">
                <mat-label>Comment</mat-label>
                <textarea matInput type="text" placeholder="Comment" formControlName="comment"></textarea>
            </mat-form-field>
            </div>
        </div>
    </form>      
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button color="accent" mat-raised-button (click)="onCreateFromSimulation($event)">Create Floorplan from Simulation</button>
    <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
  