import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';

@Injectable({
  providedIn: 'root',
})
export class InspectionGradePermissionService {
  constructor(public store: Store<AppState>) {}

  canPerformCustomerHIRLInspectionGradeQuarterReport(): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        if (
          (currentUser.company_info?.slug ===
            CustomerHIRLSettings.companySlug &&
            currentUser.is_company_admin) ||
          currentUser.is_superuser
        ) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }
}
