<mat-toolbar class="p-0">
    <div class="nav-breadrcum">
        <button (click)="toggleMenu()" mat-icon-button aria-label="Expand button">
            <mat-icon> {{ isExpanded ? 'menu': 'menu_open' }}</mat-icon>
        </button>
        <a class="nav-logo" [routerLink]="['/']">
            <img src="assets/apex_logo.svg" width="80"/>
        </a>
        <span>/</span>
        <a [routerLink]="['/simulations', 'list']">
            <span>Simulations</span>
        </a>
        <span>/</span>
        <span>Simulation ({{ name }})</span>
    </div>
    <span class="spacer"></span>
    <div class="action-buttons">
        <button mat-icon-button>
            <mat-icon>settings</mat-icon>
        </button>
        <app-run-simulation-button></app-run-simulation-button>
    </div>
</mat-toolbar>
