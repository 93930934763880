import { Injectable } from '@angular/core';
import { AnalysisService } from './analysis.service';
import { BehaviorSubject, Observable, of, throwError, timer } from 'rxjs';

import { catchError, concatMap } from 'rxjs/operators';
import { OpenStudioEriService } from './open-studio-eri.services';

@Injectable({ providedIn: 'root' })
export class ReportsService {
  private analysesSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public analyses$: Observable<boolean> = this.analysesSubject.asObservable();
  isAnalysesLoaded: boolean;

  // Expecting simulation run to take maximum 1 minutes.
  MAX_TOTAL_DURATION_IN_MILLISECS = 60 * 1 * 1000;
  // Make request after every 2 seconds.
  ATTEMPTS_INTERVAL = 2000;
  MAX_ATTEMPTS = this.MAX_TOTAL_DURATION_IN_MILLISECS / this.ATTEMPTS_INTERVAL;

  constructor(
    private analysisService: AnalysisService,
    private openStudioEriService: OpenStudioEriService
  ) {}

  // Recursive function for repeated get calls with attempt count
  public makeRepeatedGetCalls(
    simulationId: number,
    maxCalls: number = this.MAX_ATTEMPTS
  ): Observable<any> {
    this.analysesSubject.next(true);
    return timer(this.ATTEMPTS_INTERVAL).pipe(
      concatMap(() => this.openStudioEriService.get(simulationId)),
      concatMap(response => {
        if (['pending', 'started'].includes(response.status) && maxCalls > 0) {
          return this.makeRepeatedGetCalls(simulationId, maxCalls - 1);
        }
        this.analysesSubject.next(false);

        return of(response);
      }),
      catchError(error => {
        console.error('Error in get call', error);
        return throwError(error);
      })
    );
  }

  refetchAnalyses$(): Observable<boolean> {
    return this.analyses$;
  }
}
