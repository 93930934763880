import { AuthGuard } from '@/core/guards';
import { EditSimulationComponent } from '@/modules/simulation/pages/edit-simulation/edit-simulation.component';
import { UdrhListComponent } from '@/modules/simulation/pages/udrh-list/udrh-list.component';
import { SimulationListPageComponent } from '@/modules/simulation/pages/simulation-list-page/simulation-list-page.component';
import { CompanySimulationConfigComponent } from './components/company-simulation-config/company-simulation-config.component';

export const SimulationRouting = [
  {
    path: 'simulations',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'list',
        component: SimulationListPageComponent,
        resolve: {},
      },
      {
        path: 'edit/:simulationId',
        component: EditSimulationComponent,
        resolve: {},
      },
      {
        path: 'udrh',
        children: [
          {
            path: 'list',
            component: UdrhListComponent,
          },
        ],
      },
      {
        path: 'config',
        component: CompanySimulationConfigComponent,
      },
    ],
  },
];
