import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { catchError, first, map } from 'rxjs/operators';
import { UserService } from '@/data/core/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionService {
  constructor(public router: Router, public userService: UserService) {}

  canEdit(userId: number): Observable<ObjectPermissionResponse> {
    return forkJoin({
      impersonationUser: this.userService.info().pipe(first()),
      user: this.userService
        .retrieve(userId, { ignoreStore: true })
        .pipe(first()),
    }).pipe(
      map(({ impersonationUser, user }) => {
        if (
          user.id === impersonationUser.user.id ||
          impersonationUser.user.is_superuser
        ) {
          return new ObjectPermissionResponse(true);
        }

        if (user.company === impersonationUser.user.company) {
          if (impersonationUser.user.is_company_admin) {
            return new ObjectPermissionResponse(true);
          }
        }

        return new ObjectPermissionResponse(false);
      }),
      catchError(err => of(new ObjectPermissionResponse(false)))
    );
  }
}
