import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as DoorTypeActions from './actions';
import * as NoteActions from '../note/actions';
import * as SharedActions from '../shared/shared.actions';
import { DoorTypeService } from '@/data/simulation/services/door-type.service';
import { DoorTypeValidator } from '../../validtaors/door-type.validator';
import { createNote } from '@/data/simulation/models/Note';
import { StateModelName } from '../../state.registry';

@Injectable()
export class DoorTypeEffects {
  loadDetailedDoorTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DoorTypeActions.loadDetailedDoorTypes),
      mergeMap(action => {
        const errors = DoorTypeValidator.validate(action.doorTypes);
        const notes = [];
        action.doorTypes.forEach(doorType => {
          notes.push(
            createNote(StateModelName.doorType, doorType.id, doorType.note)
          );
        });
        return of(
          DoorTypeActions.loadDoorTypesSuccess({
            doorTypes: action.doorTypes,
            errors,
          }),
          NoteActions.loadNotes({ notes })
        );
      })
    );
  });

  updateDoorType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DoorTypeActions.updateDoorType),
      mergeMap(action =>
        this.doorTypeService.update(action.doorTypeChanges).pipe(
          mergeMap(updatedDoorType => {
            const errors = DoorTypeValidator.validate([updatedDoorType]);
            return of(
              DoorTypeActions.loadDoorTypesSuccess({
                doorTypes: [updatedDoorType],
                errors,
              })
            );
          }),
          catchError(error =>
            of(
              DoorTypeActions.updateDoorTypeFailure({
                id: action.doorTypeChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private doorTypeService: DoorTypeService
  ) {}
}
