<app-loading-overlay *ngIf="loading"></app-loading-overlay>
<app-form formName="groundSourceHeatPumpFormGroup" [formGroup]="groundSourceHeatPumpFormGroup" (save)="onSave($event)">
    <div>
        <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
    </div>
    <div>
        <app-select formControlName="fuel" [label]="fieldLabels.fuel" [items]="fuelTypeLabels"></app-select>
        <app-select formControlName="motor_type" [label]="fieldLabels.motor_type" [items]="mechanicalMotorTypeLabels"></app-select>
    </div>
    <div>
        <app-input
                *ngIf="!groundSourceHeatPumpFormGroup?.controls.auto_size_capacity.value"
                formControlName="heating_capacity"
                type="number"
                [label]="fieldLabels.heating_capacity">
        </app-input>
        <app-select
                *ngIf="!groundSourceHeatPumpFormGroup?.controls.auto_size_capacity.value"
                formControlName="capacity_unit"
                [label]="fieldLabels.capacity_unit"
                [items]="heatingCoolingCapacityUnitLabels">
        </app-select>
    </div>
    <div>
        <app-input
                *ngIf="!groundSourceHeatPumpFormGroup?.controls.auto_size_capacity.value"
                formControlName="cooling_capacity"
                type="number"
                [label]="fieldLabels.cooling_capacity">
        </app-input>
        <app-checkbox formControlName="auto_size_capacity" [label]="fieldLabels.auto_size_capacity"></app-checkbox>
    </div>
    <div>
        <app-input
                formControlName="heating_efficiency"
                type="number"
                [label]="fieldLabels.heating_efficiency">
        </app-input>
        <app-select
                formControlName="heating_efficiency_unit"
                [label]="fieldLabels.heating_efficiency_unit"
                [items]="heatingEfficiencyUnitLabels">
        </app-select>
    </div>
    <div>
        <app-input
                formControlName="cooling_efficiency"
                type="number"
                [label]="fieldLabels.cooling_efficiency">
        </app-input>
        <app-select
                formControlName="cooling_efficiency_unit"
                [label]="fieldLabels.cooling_efficiency_unit"
                [items]="coolingEfficiencyUnitLabels">
        </app-select>
    </div>
    <div>
        <app-input formControlName="sensible_heat_fraction" type="number" [label]="fieldLabels.sensible_heat_fraction"></app-input>
        <app-checkbox formControlName="has_desuperheater" [label]="fieldLabels.has_desuperheater"></app-checkbox>
    </div>
    <div>
        <app-select formControlName="backup_heating_fuel" [label]="fieldLabels.backup_heating_fuel" [items]="fuelTypeLabels"></app-select>
    </div>
    <div>
        <app-input
                formControlName="backup_heating_capacity"
                type="number"
                [unit]="heatingCoolingCapacityUnitLabels[entity?.backup_heating_capacity_unit]"
                [label]="fieldLabels.backup_heating_capacity">
        </app-input>
        <app-select
                formControlName="backup_heating_capacity_unit"
                [label]="fieldLabels.backup_heating_capacity_unit"
                [items]="heatingCoolingCapacityUnitLabels">
        </app-select>
    </div>
    <div>
        <app-input
                formControlName="backup_heating_efficiency"
                type="number"
                [unit]="heatingEfficiencyUnitLabels[entity?.backup_heating_efficiency_unit]"
                [label]="fieldLabels.backup_heating_efficiency">
        </app-input>
        <app-select
                formControlName="backup_heating_efficiency_unit"
                [label]="fieldLabels.backup_heating_efficiency_unit"
                [items]="heatingEfficiencyUnitLabels">
        </app-select>
    </div>
    <div>
        <app-input
                formControlName="switch_over_temperature"
                type="number"
                [label]="fieldLabels.switch_over_temperature"
                [unit]="temperatureUnitLabels[entity?.switch_over_temperature_unit]">
        </app-input>
    </div>
    <div>
        <app-input
                formControlName="fan_power"
                type="number"
                [unit]="fanPowerUnitLabels[entity?.fan_unit]"
                [label]="fieldLabels.fan_power">
        </app-input>
        <app-select
                formControlName="fan_unit"
                [label]="fieldLabels.fan_unit"
                [items]="fanPowerUnitLabels">
        </app-select>
    </div>
    <div>
        <app-input
                formControlName="pump_energy"
                type="number"
                [unit]="pumpEnergyUnitLabels[entity?.pump_energy_unit]"
                [label]="fieldLabels.pump_energy">
        </app-input>
        <app-select
                formControlName="pump_energy_unit"
                [label]="fieldLabels.pump_energy_unit"
                [items]="pumpEnergyUnitLabels">
        </app-select>
    </div>
    <div>
        <app-input
                formControlName="air_flow_defect_ratio"
                type="number"
                [label]="fieldLabels.air_flow_defect_ratio">
        </app-input>
        <app-input
                formControlName="charge_defect_ratio"
                type="number"
                [label]="fieldLabels.charge_defect_ratio">
        </app-input>
    </div>
    <div>
        <app-input formControlName="ahri_reference_number" [label]="fieldLabels.ahri_reference_number"></app-input>
    </div>
    <div>
        <app-input formControlName="note" [label]="fieldLabels.note"></app-input>
    </div>
</app-form>
