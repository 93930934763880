<div class="container" *ngIf="initialized">
    <div class="row mt-3 mb-2">
        <div class="col-8">
            <h5>User Detail: {{ user.first_name }} {{ user.last_name }}</h5>
        </div>
        <div class="col-4 text-right">
            <app-permission-mat-button [permissionResponse]="editPermissionResponse" [routerLink]="['/', 'password_change']">
                Change password
            </app-permission-mat-button>
            <app-permission-mat-button [permissionResponse]="editPermissionResponse" [routerLink]="['./', 'edit']">
                Edit
            </app-permission-mat-button>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="card mb-2">
                <div class="card-header">
                    Main Information
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <b>Full Name:</b>
                        {{ user.first_name }} {{ user.last_name }}
                    </li>
                    <li class="list-group-item">
                        <b>Title:</b>
                        {{ user.title }}
                    </li>
                    <li class="list-group-item">
                        <b>Department:</b>
                        {{ user.department }}
                    </li>
                    <li class="list-group-item">
                        <b>Company:</b>
                        {{ user.company_info?.name }}
                    </li>
                    <li class="list-group-item">
                        <b>Timezone preferences:</b>
                        {{ user.timezone_preference }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-6">
            <div class="card mb-2">
                <div class="card-header">
                    Contacts
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <b>Email:</b>
                        {{ user.email }}
                    </li>
                    <li class="list-group-item">
                        <b>Work: </b>
                        <a href="#">{{ user.work_phone }}</a>
                    </li>
                    <li class="list-group-item">
                        <b>Cell: </b>
                        <a href="#">{{ user.cell_phone }}</a>
                    </li>
                    <li class="list-group-item">
                        <b>
                            Company Address:
                        </b>
                        <app-address-display [geocode]="user.mailing_geocode_info"
                                             [geocodeBrokeredResponse]="user.mailing_geocode_response_info"></app-address-display>
                    </li>
                    <li class="list-group-item">
                        <b>
                            Shipping Address:
                        </b>
                        <app-address-display [geocode]="user.shipping_geocode_info"
                                             [geocodeBrokeredResponse]="user.shipping_geocode_response_info"></app-address-display>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="card mb-2">
                <div class="card-header">
                    Rater Information
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <b>
                            Roles:
                        </b>
                        <app-mtm-display [list]="raterRolesList" [display]="raterRoleDisplay"></app-mtm-display>
                    </li>
                    <li class="list-group-item">
                        <b>
                            RESNET RTIN:
                        </b>
                        {{ user.rater_id }}
                    </li>
                    <li class="list-group-item">
                        <b>
                            Signature:
                        </b>
                        <span *ngIf="user.signature_image">
                            <a href="{{ user.signature_image }}" target="_blank">View</a>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-6">
            <div class="card mb-2">
                <div class="card-header">
                    HES
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <b>
                            Username:
                        </b>
                    </li>
                    <li class="list-group-item">
                        <b>
                            Password:
                        </b>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="card mb-2">
                <div class="card-header">
                    NGBS Settings
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <b>
                            Is QA Designee:
                        </b>
                        {{ user.hirl_user_profile.is_qa_designee }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-6">
            <div class="card mb-2">
                <div class="card-header">
                    Provider Information
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <b>
                            RESNET Username:
                        </b>
                        {{ user.resnet_username }}
                    </li>
                    <li class="list-group-item">
                        <b>
                            RESNET Password:
                        </b>
                        {{ user.resnet_password }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>