import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { User } from '@/data/core/models/user';
import { UserService } from '@/data/core/services/user.service';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { first, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import {
  EEPProgramHomeStatusRequestParams,
  EEPProgramHomeStatusService,
} from '@/data/home/services/eep_program_home_status.service';
import {
  AggregateESGReport,
  EEPProgramHomeStatusProjectReportDownload,
  EEPProgramHomeStatusProjectReportExportField,
  ESGReportList,
} from '@/data/home/models';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  EEPProgramHomeStatusStateLabelMapping,
  HIRLEEPProgramHomeStatusStateLabelMapping,
} from '@/data/home/models/eep_program_home_status';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { QAStatusStateLabelMapping } from '@/data/qa/models';
import * as _ from 'lodash';
import { formatDate } from '@angular/common';
import {
  CompanyAccessRequestParams,
  CompanyAccessService,
} from '@/data/company/services/company-access.service';
import { CompanyType } from '@/data/company/models';

@Component({
  selector: 'app-esg-report',
  templateUrl: './esg-report.component.html',
  styleUrls: ['./esg-report.component.scss'],
})
export class EsgReportComponent implements OnInit, OnDestroy {
  protected readonly CompanyType = CompanyType;
  public MAX_EXPORT_RESULTS = 2000;
  public initialized = false;

  public tableStateLabelMapping = {
    ...EEPProgramHomeStatusStateLabelMapping,
    ...HIRLEEPProgramHomeStatusStateLabelMapping,
  };

  public EEPProgramHomeStatusStateLabelMapping = {
    not_certified: 'Not Certified',
    not_certified_and_not_abandoned: 'Not Certified (exclude Abandoned)',
    ...EEPProgramHomeStatusStateLabelMapping,
    ...HIRLEEPProgramHomeStatusStateLabelMapping,
  };

  public QAStatusStateLabelMapping = {
    not_in_progress: 'Not In Progress',
    addable: 'QA Addable',
    does_not_exist: 'Does Not Exist',
    not_complete: 'Not Complete',
    ...QAStatusStateLabelMapping,
  };

  public displayedColumns = [
    'home__street_line1',
    'home__subdivision__name',
    'floorplan__name',
    'eep_program__name',
    'state',
    'eri_score',
    'co2',
    'co2_savings',
  ];

  public filterFromGroup: FormGroup;

  public currentUser: User;
  public entities: ESGReportList[];
  public entitiesCount: number;
  public entitiesIsLoading = true;

  public aggregateESGReportIsLoading = true;
  public aggregateESGReport: AggregateESGReport;
  public aggregateSubscription$: Subscription;

  public defaultParams: EEPProgramHomeStatusRequestParams =
    new EEPProgramHomeStatusRequestParams(1, '', '-created_date', 25);
  public storedParams: EEPProgramHomeStatusRequestParams = Object.assign(
    new EEPProgramHomeStatusRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private eepProgramHomeStatusService: EEPProgramHomeStatusService,
    private companyAccessService: CompanyAccessService,
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit() {
    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    })
      .pipe(
        takeUntil(this.componentDestroyed$),
        switchMap(({ queryParams, currentUser }) => {
          const params = new CompanyAccessRequestParams();
          params.company = currentUser.company;
          return this.companyAccessService.list(params).pipe(
            first(),
            map(companyAccessesResponse => {
              const companyAccesses = companyAccessesResponse.results;
              let companyAccess = null;
              if (companyAccesses.length) {
                companyAccess = companyAccesses[0];
              }
              return {
                queryParams: queryParams,
                currentUser: currentUser,
                companyAccess: companyAccess,
              };
            })
          );
        })
      )
      .subscribe(({ queryParams, currentUser, companyAccess }) => {
        this.currentUser = currentUser;
        this.storedParams.assignQueryParams(queryParams, ['state']);
        if (currentUser.is_superuser) {
          this.storedParams.company_access = null;
        } else {
          if (!this.storedParams.company_access) {
            this.storedParams.company_access = [companyAccess.id];
          }
        }
        this.initialized = true;

        this.setupFilterForm();
        this.hydrateForm();
        this.list();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    if (this.aggregateSubscription$) {
      this.aggregateSubscription$.unsubscribe();
    }
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      company_access_info: [null],
      state: [null],
      subdivision_info: [null],
      builder_organization_info: [null],
      rater_organization_info: [null],
      utility_organization_info: [null],
      home__city_info: [null],
      eep_program_info: [null],
      city: [null],
      created_date__gte: [null],
      created_date__lte: [null],
      certification_date__gte: [null],
      certification_date__lte: [null],
      home__state_info: [null],
      qa_status__state: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = Object.assign(this.defaultParams, values);
        delete params.ordering;

        if (params.eep_program_info) {
          params.eep_program = params.eep_program_info.map(
            eep_program => eep_program.id
          );
        } else {
          params.eep_program = null;
        }
        delete params.eep_program_info;

        if (params.subdivision_info) {
          params.home__subdivision = params.subdivision_info.map(
            subdivision => subdivision.id
          );
        } else {
          params.home__subdivision = null;
        }
        delete params.subdivision_info;

        if (params.builder_organization_info) {
          params.builder_organization = params.builder_organization_info.map(
            builder_organization => builder_organization.id
          );
        } else {
          params.builder_organization = null;
        }
        delete params.builder_organization_info;

        if (params.rater_organization_info) {
          params.rater_organization = params.rater_organization_info.map(
            rater_organization => rater_organization.id
          );
        } else {
          params.rater_organization = null;
        }
        delete params.rater_organization_info;

        if (params.utility_organization_info) {
          params.utility_organization = params.utility_organization_info.map(
            utility_organization => utility_organization.id
          );
        } else {
          params.utility_organization = null;
        }
        delete params.utility_organization_info;

        if (params.home__city_info) {
          params.home__city = params.home__city_info.map(city => city.id);
        } else {
          params.home__city = null;
        }
        delete params.home__city_info;

        if (params.home__state_info) {
          params.home__state = params.home__state_info.map(state => state.abbr);
        } else {
          params.home__state = null;
        }
        delete params.home__state_info;

        if (params.company_access_info) {
          params.company_access = params.company_access_info.map(
            companyAccess => companyAccess.id
          );
        } else {
          params.company_access = null;
        }
        delete params.company_access_info;

        this.storedParams = Object.assign(this.storedParams, params);

        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  list() {
    this.entitiesIsLoading = true;
    this.aggregateESGReportIsLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.eepProgramHomeStatusService
      .esg_report_list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), take(1))
      .subscribe(data => {
        this.entities = data.results;
        this.entitiesCount = data.count;
        this.entitiesIsLoading = false;
      });

    if (this.aggregateSubscription$) {
      this.aggregateSubscription$.unsubscribe();
    }

    this.aggregateSubscription$ = this.eepProgramHomeStatusService
      .aggregate_esg_report(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), take(1))
      .subscribe(aggregateESGReport => {
        this.aggregateESGReport = aggregateESGReport;
        this.aggregateESGReportIsLoading = false;
      });
  }

  onSort($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  downloadReport($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleLoading({ payload: true }));

    const projectReportDownload =
      new EEPProgramHomeStatusProjectReportDownload();
    projectReportDownload.export_fields = [
      EEPProgramHomeStatusProjectReportExportField.home,
      EEPProgramHomeStatusProjectReportExportField.subdivision,
      EEPProgramHomeStatusProjectReportExportField.eep_program,
      EEPProgramHomeStatusProjectReportExportField.simulation_basic,
      EEPProgramHomeStatusProjectReportExportField.simulation_advanced,
      EEPProgramHomeStatusProjectReportExportField.retain_empty,
    ];
    projectReportDownload.export_filters = this.getAppliedFilters(
      this.storedParams
    );
    const newTab = window.open('', '_blank');
    this.eepProgramHomeStatusService
      .esg_project_report_download(projectReportDownload, this.storedParams)
      .pipe(first())
      .subscribe(
        asynchronousProcessedDocument => {
          newTab.location.href = `/file-operation/document/${asynchronousProcessedDocument.id}`;
          newTab.focus();
          this.store.dispatch(toggleLoading({ payload: false }));
        },
        error => {
          newTab.close();
          this.store.dispatch(toggleLoading({ payload: false }));
          this.uiHelperService.handleUserRequestError(error);
        }
      );
  }

  get canExport(): ObjectPermissionResponse {
    if (this.entitiesIsLoading) {
      return new ObjectPermissionResponse(false, 'Loading... Please wait');
    }
    if (this.entitiesCount > this.MAX_EXPORT_RESULTS) {
      return new ObjectPermissionResponse(
        false,
        `Cannot export more than ${this.MAX_EXPORT_RESULTS} at once. Filter results to reduce count`
      );
    }
    return new ObjectPermissionResponse(true);
  }

  getAppliedFilters(params: EEPProgramHomeStatusRequestParams): {
    [key: string]: string;
  } {
    const appliedFilters: { [key: string]: string } = {};

    Object.keys(params).forEach(key => {
      const value = params[key];

      if (!value || key === 'page' || key === 'page_size') {
        return;
      }

      if (key === 'eep_program') {
        appliedFilters['EEP Programs'] =
          this.filterFromGroup.value.eep_program_info
            ?.map(eep_program => eep_program.name)
            .join(', ');
      } else if (key === 'builder_organization') {
        appliedFilters['Builder Organizations'] =
          this.filterFromGroup.value.builder_organization_info
            ?.map(builder_organization => builder_organization.name)
            .join(', ');
      } else if (key === 'subdivision') {
        appliedFilters['Subdivisions'] =
          this.filterFromGroup.value.subdivision_info
            ?.map(subdivision => subdivision.name)
            .join(', ');
      } else if (key === 'created_date__gte') {
        appliedFilters['Created After'] = formatDate(
          this.filterFromGroup.value.created_date__gte,
          'MM/dd/yyyy',
          'en-US'
        );
      } else {
        appliedFilters[_.startCase(key)] = params[key];
      }
    });
    return appliedFilters;
  }
}
