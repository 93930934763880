import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Annotation } from '@/data/annotation/models';

export class AnnotationRequestParams extends ListRequestParams {
  type__slug?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AnnotationService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/annotations/`;

  retrieve(
    annotationId: number,
    options?: IDataServiceMethodOptions
  ): Observable<Annotation> {
    const ignoreStore: boolean = options?.ignoreStore;
    const chain: Observable<Annotation> = this.http.get<Annotation>(
      `${this.baseUrl}${annotationId}`,
      {}
    );
    return chain;
  }

  update(
    annotationId: number,
    annotation: Annotation | FormData,
    options?: IDataServiceMethodOptions
  ): Observable<Annotation> {
    const ignoreStore = options?.ignoreStore;
    const chain = this.http.patch<Annotation>(
      `${this.baseUrl}${annotationId}/`,
      annotation
    );

    return chain;
  }

  delete(annotationId: number) {
    return this.http.delete(`${this.baseUrl}${annotationId}`);
  }
}
