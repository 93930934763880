import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EepProgramHomeStatusStateComponent } from './components/eep-program-home-status-state/eep-program-home-status-state.component';
import { EEPProgramCertificateLookupResultComponent } from './pages/eep-program-certificate-lookup-result/eep-program-certificate-lookup-result.component';
import { SharedModule } from '@/shared/shared.module';
import { ApexRequestCreateComponent } from './pages/apex-request-create/apex-request-create.component';
import { EsgReportComponent } from './pages/esg-report/esg-report.component';
import { ApexRequestDetailComponent } from './pages/apex-request-detail/apex-request-detail.component';
import { ApexRequestListComponent } from './pages/apex-request-list/apex-request-list.component';
import { ApexRequestDocumentListComponent } from './components/apex-request-document-list/apex-request-document-list.component';
import { FilehandlingModule } from '@/modules/filehandling/filehandling.module';
import { ProgramEligibilityDashboardComponent } from './pages/program-eligibility-dashboard/program-eligibility-dashboard.component';
import { ApexRequestUtilityListComponent } from './pages/apex-request-utility-list/apex-request-utility-list.component';
import { ApexRequestUtilityDetailComponent } from './pages/apex-request-utility-detail/apex-request-utility-detail.component';
import { PsrListComponent } from './pages/psr-list/psr-list.component';
import { PsrExportFieldsDialogComponent } from './components/psr-export-fields-dialog/psr-export-fields-dialog.component';
import { ApexRequestRESNETQAListComponent } from '@/modules/home/pages/apex-request-resnet-qa-list/apex-request-resnet-qa-list.component';
import { ApexRequestUtilityCreateComponent } from '@/modules/home/pages/apex-request-utility-create/apex-request-utility-create.component';
import { ApexRequestRESNETQACreateComponent } from '@/modules/home/pages/apex-request-resnet-qa-create/apex-request-resnet-qa-create.component';
import { ApexRequestRESNETQADetailComponent } from '@/modules/home/pages/apex-request-resnet-qa-detail/apex-request-resnet-qa-detail.component';

@NgModule({
  declarations: [
    EepProgramHomeStatusStateComponent,
    EEPProgramCertificateLookupResultComponent,
    ApexRequestCreateComponent,
    EsgReportComponent,
    ApexRequestDetailComponent,
    ApexRequestListComponent,
    ApexRequestDocumentListComponent,
    ProgramEligibilityDashboardComponent,
    ApexRequestUtilityListComponent,
    ApexRequestUtilityCreateComponent,
    ApexRequestUtilityDetailComponent,
    ApexRequestRESNETQAListComponent,
    ApexRequestRESNETQACreateComponent,
    ApexRequestRESNETQADetailComponent,
    PsrListComponent,
    PsrExportFieldsDialogComponent,
  ],
  exports: [
    EepProgramHomeStatusStateComponent,
    EEPProgramCertificateLookupResultComponent,
  ],
  imports: [CommonModule, SharedModule, FilehandlingModule],
})
export class HomeModule {}
