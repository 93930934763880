import {
  InsulationGradeLabelMapping,
  StudTypeLabels,
} from '@/data/simulation/enumerations';
import {
  FoundationWallTypeBackendDict,
  MaterialLabels,
  FIELD_LABELS,
  FoundationWallTypeFormGroup,
} from '@/data/simulation/models/enclosure/FoundationWallType';
import { updateFoundationWallType } from '@/modules/simulation/state/foundation-wall-type/actions';
import { selectFoundationWallViewSet } from '@/modules/simulation/state/foundation-wall-type/selectors';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-foundation-wall-type',
  templateUrl: './foundation-wall-type.component.html',
  styleUrls: ['./foundation-wall-type.component.scss'],
})
export class FoundationWallTypeComponent implements OnDestroy, OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  materialLabels = MaterialLabels;
  studTypeLabels = StudTypeLabels;

  entity: FoundationWallTypeBackendDict = null;
  foundationWallTypeFormGroup: FormGroup;

  componentDestroyed$ = new Subject();
  calculatorView = false;
  loading: boolean;
  gradeLabels = InsulationGradeLabelMapping;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectFoundationWallViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;
        if (loading) {
          return;
        }

        this.entity = data;
        if (!this.foundationWallTypeFormGroup) {
          this.foundationWallTypeFormGroup = new FoundationWallTypeFormGroup(
            data
          );
        } else {
          this.foundationWallTypeFormGroup.patchValue(data);
          const control =
            this.foundationWallTypeFormGroup.controls[
              'user_defined_assembly_u_value'
            ];
          control.valueChanges.subscribe(value => {
            if (value === this.entity.user_defined_assembly_u_value) return;

            this.onSave();
          });
        }
      });
  }

  onSave() {
    const newValues = this.foundationWallTypeFormGroup.value;
    const changes = {};
    Object.entries(this.entity).forEach(([key, val]) => {
      if (!this.entity.hasOwnProperty(key)) return;
      if (val !== newValues[key]) changes[key] = newValues[key];
    });

    this.store.dispatch(
      updateFoundationWallType({
        wallTypeChanges: {
          ...changes,
          id: this.entityId,
        },
      })
    );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
