<div class="card position-relative">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <div class="card-header">
        <span>Washing machine</span>
    </div>
    <div class="card-body pb-0">
        <app-form formName="clothesWasherForm" [formGroup]="form" (save)="onSave($event)">
            <div>
                <app-select formControlName="clothes_washer_location" [label]="fieldLabels.clothes_washer_location" [items]="locationLabels"></app-select>
            </div>
            <div>
                <app-select formControlName="clothes_washer_preset" [label]="fieldLabels.clothes_washer_preset"
                [items]="washerPresetsLabels"></app-select>
                <app-checkbox formControlName="is_front_loading_clothes_washer" [label]="fieldLabels.is_front_loading_clothes_washer"></app-checkbox>
            </div>
            <div>
                <app-input
                    formControlName="clothes_washer_capacity"
                    [label]="fieldLabels.clothes_washer_capacity"
                    [unit]="entity?.clothes_washer_capacity_units">
                </app-input>
                <app-input
                    formControlName="clothes_washer_efficiency"
                    type="number"
                    [label]="fieldLabels.clothes_washer_efficiency"
                    [unit]="entity?.clothes_washer_efficiency_unit">
                </app-input>
            </div>
            <div>
                <app-input formControlName="clothes_washer_integrated_water_factor" [label]="fieldLabels.clothes_washer_integrated_water_factor"></app-input>
                <app-input formControlName="clothes_washer_loads_per_week" [label]="fieldLabels.clothes_washer_loads_per_week"></app-input>
            </div>
            <div class="mx-auto mb-3">&#9733;&#9733;<strong>Energy Star</strong>&#9733;&#9733;</div>
            <div>
                <app-input
                    formControlName="clothes_washer_energy_star_label_electric_rate"
                    unit="{{entity?.clothes_washer_energy_star_label_electric_rate_unit}}"
                    [label]="fieldLabels.clothes_washer_energy_star_label_electric_rate">
                </app-input>
                <app-input formControlName="clothes_washer_label_electric_consumption"
                    [label]="fieldLabels.clothes_washer_label_electric_consumption"
                    [unit]="entity?.clothes_washer_label_electric_consumption_unit">
                </app-input>
            </div>
            <div>
                <app-input
                    formControlName="clothes_washer_energy_star_label_gas_rate"
                    unit="{{entity?.clothes_washer_energy_star_label_gas_rate_unit}}"
                    [label]="fieldLabels.clothes_washer_energy_star_label_gas_rate">
                </app-input>
                <app-input
                    formControlName="clothes_washer_energy_star_label_gas_annual_cost"
                    unit="{{entity?.clothes_washer_energy_star_label_gas_annual_cost_unit}}"
                    [label]="fieldLabels.clothes_washer_energy_star_label_gas_annual_cost">
                </app-input>
            </div>
        </app-form>
    </div>
</div>
