import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectNotes } from '../../state/note/selectors';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModelGraphService } from '../../services/model-graph.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
  componentDestroyed$ = new Subject();
  entityIds: string[] = [];
  constructor(
    public store: Store,
    public modelGraphService: ModelGraphService
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectNotes())
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(notes => {
        // this.modelGraphService.getParentId()
        // for (const noteId in notes) {
        //   const node = notes[noteId];
        //   if () {
        //   }
        // }
        // this.entityIds = entityIds;
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
