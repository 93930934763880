<div class="row h-100" *ngIf="initialized">
    <div class="col-12">
        <mat-sidenav-container class="h-100" [hasBackdrop]="false">
            <mat-sidenav #selectedSideNav mode="side" [opened]="!!selectedRows.length" position="end"
                         fixedInViewport="true" [fixedTopGap]="50" style="max-width: 300px;">
                <div class="container">
                    <div class="row mt-2">
                        <div class="col-12">
                            <h3>
                                Selected Tasks
                                <a href="#" class="small fa-pull-right" (click)="removeAllSelectedRows($event);">
                                    <fa-icon [icon]="['fas', 'times']" matTooltip="Clear Selection"></fa-icon>
                                </a>
                            </h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item" *ngFor="let row of selectedRows">
                                    [{{ row.task_type_name }}]
                                    <ng-container *ngIf="row.home_info">
                                        <app-home-address-display [home]="row.home_info" display="link"></app-home-address-display>
                                    </ng-container>
                                    <a *ngIf="!row.home_info">
                                        <span *ngFor="let userInfo of row.assignees_info; let isLast=last">
                                            <a [routerLink]="['/', 'profile', userInfo?.id]" matTooltip="View User Profile">
                                                {{ userInfo.first_name }} {{ userInfo.last_name }}
                                            </a> {{ isLast ? '' : ', '}}
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <hr>
                        </div>
                    </div>
                </div>
            </mat-sidenav>
            <mat-sidenav-content>
                <div class="container">
                    <div class="row">
                        <div class="col-6">
                            <h3>
                                <span *ngIf="!isLoading">{{ rowsCount | number }}</span>
                                <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="isLoading"></fa-icon>
                                Tasks
                            </h3>
                        </div>
                        <div class="col-6 text-right">
                            <span [matTooltip]="!selectedRows.length ? 'Select at least 1 Schedule Task' : ''">
                                <button mat-raised-button
                                        color="accent"
                                        class="mr-2"
                                        (click)="onExportToCalendarSelectedRows($event);"
                                        [disabled]="!selectedRows.length"
                                >
                                        Export to Calendar
                                </button>
                            </span>
                            <span [matTooltip]="!selectedRows.length ? 'Select at least 1 Schedule Task' : ''">
                                <button mat-raised-button
                                        color="accent"
                                        (click)="onChangeStatusForSelectedRows($event);"
                                        [disabled]="!selectedRows.length"
                                >
                                    Change Status
                                </button>
                            </span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <hr>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <form [formGroup]="filterFromGroup">
                                            <h5 class="card-header">Filters <a href="#" class="small"
                                                                               (click)="resetFilters($event);">Reset
                                                filters</a></h5>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Search</mat-label>
                                                            <app-search-input formControlName="search"
                                                                              placeholder="Type to search something"></app-search-input>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Approval State</mat-label>
                                                            <mat-select formControlName="approval_state">
                                                                <mat-option [value]="''">
                                                                    Any
                                                                </mat-option>
                                                                <mat-option *ngFor="let item of approvalStateLabelMapping | keyvalue " [value]="item.key">
                                                                    {{ item.value }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Status</mat-label>
                                                            <mat-select formControlName="status">
                                                                <mat-option [value]="''">
                                                                    Any
                                                                </mat-option>
                                                                <mat-option *ngFor="let item of statusLabelMapping | keyvalue " [value]="item.key">
                                                                    {{ item.value }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Date Start</mat-label>
                                                            <input matInput [matDatepicker]="datepicker_date__gte" formControlName="date__gte">
                                                            <mat-datepicker-toggle matSuffix (click)="filterFromGroup.get('date__gte').setValue(null)">
                                                                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker-toggle matSuffix [for]="datepicker_date__gte"></mat-datepicker-toggle>
                                                            <mat-datepicker #datepicker_date__gte></mat-datepicker>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Date End</mat-label>
                                                            <input matInput [matDatepicker]="datepicker_date__lte" formControlName="date__lte">
                                                            <mat-datepicker-toggle matSuffix (click)="filterFromGroup.get('date__lte').setValue(null)">
                                                                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker-toggle matSuffix [for]="datepicker_date__lte"></mat-datepicker-toggle>
                                                            <mat-datepicker #datepicker_date__lte></mat-datepicker>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-9">
                            <ngx-datatable
                                    class="material striped"
                                    [rows]="rows"
                                    columnMode="force"
                                    headerHeight="64"
                                    rowHeight="auto"
                                    footerHeight="50"
                                    [rowIdentity]="getId"
                                    [limit]="storedParams.page_size"
                                    [externalSorting]="true"
                                    [selectAllRowsOnPage]="true"
                                    [selected]="selectedRows"
                                    [selectionType]="'checkbox'"
                                    (sort)="onSort($event)"
                                    (select)="onSelect($event)"
                                    [loadingIndicator]="isLoading">

                                <ngx-datatable-column [width]="40"
                                                      [sortable]="false"
                                                      [canAutoResize]="false"
                                                      [draggable]="false"
                                                      [resizeable]="false">
                                    <ng-template ngx-datatable-cell-template let-value="value"
                                                 let-isSelected="isSelected"
                                                 let-onCheckboxChangeFn="onCheckboxChangeFn">
                                        <input type="checkbox" class="customCheckbox" [checked]="isSelected"
                                               (change)="onCheckboxChangeFn($event)"/>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Assignees" [sortable]="false" [width]="180">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        <span *ngFor="let userInfo of row.assignees_info; let isLast=last">
                                            <a [routerLink]="['/', 'profile', userInfo?.id]" matTooltip="View User Profile">
                                                {{ userInfo.first_name }} {{ userInfo.last_name }}
                                            </a> {{ isLast ? '' : ', '}}
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Task" [sortable]="false">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        {{ row.task_type_name }}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Location" [sortable]="false">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        <app-home-address-display [home]="row.home_info" display="link"></app-home-address-display>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Date" [sortable]="false">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        {{ row.datetime | date: 'MM/dd/YYYY HH:MM' }}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [sortable]="false">
                                    <ng-template let-column="column" ngx-datatable-header-template>
                                        Completion<br>
                                        Status
                                    </ng-template>
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        {{ statusLabelMapping[row.status] }}
                                        <span *ngIf="row.status_annotation">
                                            <i>[{{ row.status_approver_info.first_name }} {{ row.status_approver_info.last_name }} {{ row.status_changed_at }}]: {{ row.status_annotation }}</i>
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Note" [sortable]="false">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        {{ row.note }}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Assigner" [sortable]="false">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        <a [routerLink]="['/', 'profile', row.assigner_info?.id]" matTooltip="View User Profile">
                                            {{ row.assigner_info?.first_name }} {{ row.assigner_info?.last_name }}
                                        </a>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [sortable]="false" [width]="190">
                                    <ng-template let-column="column" ngx-datatable-header-template>
                                        Task<br>
                                        Status
                                    </ng-template>
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        {{ approvalStateLabelMapping[row.approval_state] }}
                                        <span *ngIf="row.approval_annotation">
                                            <i>[{{ row.approver_info.first_name }} {{ row.approver_info.last_name }} {{ row.approval_state_changed_at }}]: {{ row.approval_annotation }}</i>
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Actions" [width]="170" [sortable]="false">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        <app-permission-mat-button class="btn-block w-100 mb-1" [permissionResponse]="rowPermissions[row.id]['canChangeState']" [hideOnFalse]="true" (action)="onChangeState($event, row.id, approvalState.approved)">
                                            Approve
                                        </app-permission-mat-button>
                                        <app-permission-mat-button class="btn-block w-100 mb-1" [permissionResponse]="rowPermissions[row.id]['canChangeState']" [hideOnFalse]="true" (action)="onChangeState($event, row.id, approvalState.rejected)">
                                            Reject
                                        </app-permission-mat-button>
                                        <app-permission-mat-button class="btn-block w-100" [permissionResponse]="rowPermissions[row.id]['canEdit']" [hideOnFalse]="true" (action)="onEditTask($event, row.id)" matTooltip="Edit Task">
                                            Edit
                                        </app-permission-mat-button>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-footer>
                                    <ng-template
                                            ngx-datatable-footer-template>
                                        <div class="page-count col-3 text-center" *ngIf="rowsCount">
                                            Showing {{ storedParams.page }}
                                            to {{ rowsCount | divide: storedParams.page_size }}
                                            of {{ rowsCount }}
                                        </div>
                                        <div class="col-9 text-right">
                                            <datatable-pager
                                                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                    [pagerRightArrowIcon]="'datatable-icon-right'"
                                                    [pagerPreviousIcon]="'datatable-icon-prev'"
                                                    [pagerNextIcon]="'datatable-icon-skip'"
                                                    [page]="storedParams.page"
                                                    [size]="storedParams.page_size"
                                                    [count]="rowsCount"
                                                    (change)="onFooterPage($event)"
                                            >
                                            </datatable-pager>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-footer>
                            </ngx-datatable>
                        </div>
                    </div>
                </div>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</div>
