import { User } from '@/data/core/models/user';
import { Action, createReducer, on } from '@ngrx/store';
import { resetUser, loadUser } from '@/state/actions/user/user.actions';

export const userStateKey = 'user';

export interface UserState {
  detail: User;
}

export const userInitialState: UserState = {
  detail: null,
};

const userReducer = createReducer(
  userInitialState,
  on(resetUser, (state, action) => ({ ...userInitialState })),
  on(loadUser, (state, { payload }) => ({ ...state, detail: payload }))
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}
