import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ThermostatService } from '@/data/simulation/services/thermostat.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ThermostatActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import * as SimulationActions from '../simulation/actions';
import { ThermostatValidator } from '../../validtaors/thermostat.validator';

@Injectable()
export class ThermostatEffects {
  loadThermostats$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ThermostatActions.loadThermostats),
      mergeMap(action => {
        const errors = ThermostatValidator.validate(action.thermostats);
        return of(
          ThermostatActions.loadThermostatsSuccess({
            thermostats: action.thermostats,
            errors,
          })
        );
      })
    );
  });

  updateThermostat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ThermostatActions.updateThermostat),
      mergeMap(action => {
        return this.thermostatService.update(action.thermostatChanges).pipe(
          mergeMap(thermostat => {
            const errors = ThermostatValidator.validate([thermostat]);
            return of(
              ThermostatActions.loadThermostatsSuccess({
                thermostats: [thermostat],
                errors,
              })
            );
          }),
          catchError(error =>
            of(
              ThermostatActions.updateThermostatFailure({
                id: action.thermostatChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        );
      })
    );
  });

  removeThermostat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ThermostatActions.removeThermostat),
      mergeMap(action =>
        this.thermostatService.delete(action.thermostat.id).pipe(
          mergeMap(() =>
            of(
              ThermostatActions.removeThermostatSuccess({
                id: action.thermostat.id,
              }),
              SimulationActions.removeItemFromList({
                fieldName: 'thermostats',
                id: action.thermostat.id,
              })
            )
          ),
          catchError(error =>
            of(
              ThermostatActions.removeThermostatFailure({
                id: action.thermostat.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private thermostatService: ThermostatService
  ) {}
}
