<app-loading-overlay *ngIf="loading"></app-loading-overlay>
<app-form formName="rimJoistTypeFormGroup" [formGroup]="rimJoistTypeFormGroup" (save)="onSave($event)">
    <div>
        <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
    </div>
    <div>
        <app-input formControlName="assembly_u_value" [label]="fieldLabels.assembly_u_value"></app-input>
        <app-checkbox formControlName="user_defined_assembly_u_value" label="Directly defined U-value"></app-checkbox>
    </div>
    <ng-container *ngIf="!rimJoistTypeFormGroup.controls.user_defined_assembly_u_value.value">
        <div>
            <app-input formControlName="continuous_insulation_r_value" type="number" [label]="fieldLabels.continuous_insulation_r_value">
            </app-input>
            <app-input formControlName="cavity_insulation_r_value" type="number"
                [label]="fieldLabels.cavity_insulation_r_value"></app-input>
        </div>
        <div>
            <app-input formControlName="cavity_insulation_thickness" type="number"
                [label]="fieldLabels.cavity_insulation_thickness"></app-input>
            <app-input formControlName="cavity_insulation_grade" type="number" [label]="fieldLabels.cavity_insulation_grade"></app-input>
        </div>
        <div>
            <app-input formControlName="system_u_value" type="number" [label]="fieldLabels.system_u_value">
            </app-input>
            <app-input formControlName="frame_spacing" type="number" [label]="fieldLabels.frame_spacing"></app-input>
        </div>
    </ng-container>
</app-form>
