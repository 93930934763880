import { AnalysisWeatherTypeLabelMapping } from '@/data/simulation/models';
import {
  analysisSourceNameShrinker,
  AnalysisSuiteBackendDict,
  OpenStudioAnalysisConfigWithLabels,
  OpenStudioAnalysisSourceName,
  OpenStudioAnalysisSourceNameLabels,
} from '@/data/simulation/models/reports/Analysis';
import { AnalysisSuiteService } from '@/data/simulation/services/analysis-suite.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-analysis-type-selector',
  templateUrl: './analysis-type-selector.component.html',
  styleUrls: ['./analysis-type-selector.component.scss'],
})
export class AnalysisTypeSelectorComponent implements OnInit {
  @Input() analysisSuitesControl: FormControl = new FormControl([]);
  @Input() weatherTypeControl: FormControl = new FormControl();
  @Input() indicateDefaults = true;
  @Output() onReady = new EventEmitter<any>();

  openStudioAnalysisConfigWithLabels = OpenStudioAnalysisConfigWithLabels;
  openStudioAnalysisSourceNameLabels = OpenStudioAnalysisSourceNameLabels;
  openStudioAnalysisSourceName = OpenStudioAnalysisSourceName;
  analysisWeatherTypeLabel = AnalysisWeatherTypeLabelMapping;
  analysisSuites = {};
  checked = {};
  loading = true;
  defaults = {};

  constructor(public analysisSuiteService: AnalysisSuiteService) {}

  ngOnInit(): void {
    this.analysisSuiteService.list({ apex: 1 }).subscribe(analysisSuites => {
      this.analysisSuites = this.groupSuitesByCalculationType(analysisSuites);
      this.updateDefaults(analysisSuites);

      if (this.analysisSuitesControl.value.length === 0) {
        this.analysisSuitesControl.patchValue(
          Object.keys(this.defaults).map(Number)
        );
        this.checked = { ...this.defaults };
      } else {
        this.analysisSuitesControl.value.forEach(item => {
          this.checked[item] = true;
        });
      }
      this.onReady.emit();
    });

    this.analysisSuitesControl.valueChanges.subscribe(value => {
      this.checked = {};
      value.forEach(item => {
        this.checked[item] = true;
      });
    });
  }

  groupSuitesByCalculationType(
    suites: AnalysisSuiteBackendDict[]
  ): Record<string, AnalysisSuiteBackendDict[]> {
    const result = suites.reduce((result, suite) => {
      const { calculation_type } = suite;

      // Skip suites with null or undefined calculation_type
      if (calculation_type == null) {
        return result;
      }

      if (!result[calculation_type]) {
        result[calculation_type] = [];
      }

      suite.name = analysisSourceNameShrinker[calculation_type](suite.name);
      result[calculation_type].push(suite);
      return result;
    }, {} as Record<string, AnalysisSuiteBackendDict[]>);

    Object.values(result).forEach(item =>
      item.sort((a, b) => a.name.localeCompare(b.name))
    );

    return result;
  }

  updateDefaults(suites: AnalysisSuiteBackendDict[]) {
    suites.map(suite =>
      suite.is_default ? (this.defaults[suite.id] = true) : null
    );
  }

  toggleAnalysisType(id) {
    this.checked[id] = !this.checked[id];

    if (this.checked[id]) {
      this.analysisSuitesControl.patchValue([
        ...this.analysisSuitesControl.value,
        id,
      ]);
    } else {
      this.analysisSuitesControl.patchValue(
        this.analysisSuitesControl.value.filter(item => item !== id)
      );
    }
  }
}
