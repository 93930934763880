import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { UserDetailPageComponent } from './pages/user-detail-page/user-detail-page.component';
import { MailingListPageComponent } from './pages/user-list-page/user-list-page.component';
import { UserDetailTabComponent } from './components/user-detail-tab/user-detail-tab.component';
import { UserChangePageComponent } from './pages/user-change-page/user-change-page.component';
import { UserAccreditationTabComponent } from './components/user-accreditation-tab/user-accreditation-tab.component';
import { ContactCardSelectComponent } from '@/modules/user/components/contact-card-select/contact-card-select.component';
import { ContactCardEditDialogComponent } from '@/modules/user/components/contact-card-edit-dialog/contact-card-edit-dialog.component';
import { ContactCardListComponent } from '@/modules/user/pages/contact-card-list/contact-card-list.component';

@NgModule({
  declarations: [
    UserDetailPageComponent,
    UserDetailTabComponent,
    MailingListPageComponent,
    ContactCardListComponent,
    UserChangePageComponent,
    UserAccreditationTabComponent,
    ContactCardSelectComponent,
    ContactCardEditDialogComponent,
  ],
  exports: [ContactCardSelectComponent],
  imports: [CommonModule, SharedModule],
})
export class UserModule {}
