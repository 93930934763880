import { AreaUnit, Location } from '@/data/simulation/enumerations';
import { RimJoistTypeBackendDict } from './RimJoistType';
import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { enumValidator } from '@/modules/simulation/validtaors/helper';
import { FieldLabels } from '../base';
import { SimulationModelsState } from '@/modules/simulation/state/reducers';

export enum InteriorLocation {
  CONDITIONED_SPACE = Location.CONDITIONED_SPACE,
  ENCLOSED_CRAWL = Location.ENCLOSED_CRAWL,
  CRAWL_SPACE_VENTED = Location.CRAWL_SPACE_VENTED,
  CRAWL_SPACE_UNVENTED = Location.CRAWL_SPACE_UNVENTED,
  CONDITIONED_CRAWL_SPACE = Location.CONDITIONED_CRAWL_SPACE,
  UNCONDITIONED_BASEMENT = Location.UNCONDITIONED_BASEMENT,
  CONDITIONED_BASEMENT = Location.CONDITIONED_BASEMENT,
  SEALED_ATTIC = Location.SEALED_ATTIC,
  VENTED_ATTIC = Location.VENTED_ATTIC,
  GARAGE = Location.GARAGE,
}

export enum ExteriorLocation {
  AMBIENT = Location.AMBIENT,
  VENTED_ATTIC = Location.VENTED_ATTIC,
  SEALED_ATTIC = Location.SEALED_ATTIC,
  UNCONDITIONED_BASEMENT = Location.UNCONDITIONED_BASEMENT,
  CONDITIONED_BASEMENT = Location.CONDITIONED_BASEMENT,
  GARAGE = Location.GARAGE,
  UNCONDITIONED_GARAGE = Location.UNCONDITIONED_GARAGE,
  ENCLOSED_CRAWL = Location.ENCLOSED_CRAWL,
  CRAWL_SPACE_UNVENTED = Location.CRAWL_SPACE_UNVENTED,
  CRAWL_SPACE_VENTED = Location.CRAWL_SPACE_VENTED,
  CONDITIONED_CRAWL_SPACE = Location.CONDITIONED_CRAWL_SPACE,
  CONDITIONED_SPACE = Location.CONDITIONED_SPACE,
  ADIABATIC = Location.ADIABATIC,
  MF_UNRATED_CONDITIONED = Location.MF_UNRATED_CONDITIONED,
  MF_UNRATED_HEATED = Location.MF_UNRATED_HEATED,
  MF_BUFFER = Location.MF_BUFFER,
  MF_NONFREEZING = Location.MF_NONFREEZING,
  MF_UNRATED_ATTIC = Location.MF_UNRATED_ATTIC,
}

// Labels
export const FIELD_LABELS: FieldLabels<RimJoistBackendDict> = {
  name: 'Name',
  type: 'Type',
  interior_location: 'Interior Location',
  exterior_location: 'Exterior Location',
  area: 'Area',
  area_units: 'Area Units',
  note: 'Note',
};

export interface RimJoistBackendDict {
  id: number | null;
  name: string;
  type: number;
  interior_location: InteriorLocation;
  exterior_location: ExteriorLocation;
  area: number;
  area_units: AreaUnit.SQ_FT;
  note: string;
}

export interface DetailedRimJoistBackendDict extends RimJoistBackendDict {
  type_info: RimJoistTypeBackendDict;
}

export function createRimJoistForm(rimjoist: RimJoistBackendDict) {
  return new ModelFormGroup({
    id: new ModelFormControl(rimjoist.id),
    name: new ModelFormControl(rimjoist.name, [
      Validators.required,
      Validators.maxLength(128),
    ]),
    type: new ModelFormControl(rimjoist.type, Validators.required),
    interior_location: new ModelFormControl(rimjoist.interior_location, [
      enumValidator(InteriorLocation),
    ]),
    exterior_location: new ModelFormControl(rimjoist.exterior_location, [
      enumValidator(ExteriorLocation),
    ]),
    area: new ModelFormControl(rimjoist.area, [
      Validators.required,
      Validators.min(0),
    ]),
    area_units: new ModelFormControl(rimjoist.area_units, [
      enumValidator([AreaUnit.SQ_FT]),
    ]),
  });
}

export function denormalizeRimJoists(
  state: SimulationModelsState
): DetailedRimJoistBackendDict[] {
  const denormalizedRimJoist: DetailedRimJoistBackendDict[] = [];
  for (const rimjoist of Object.values(state.rimJoist.entities)) {
    denormalizedRimJoist.push({
      ...rimjoist,
      type_info: state.rimJoistType[rimjoist.type],
    });
  }
  return denormalizedRimJoist;
}
