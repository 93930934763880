<div class="position-relative">
    <app-loading-overlay *ngIf="loading || !selectorReady"></app-loading-overlay>
    <app-analysis-type-selector
        [weatherTypeControl]="weatherTypeControl"
        [analysisSuitesControl]="analysisSuitesControl"
        [indicateDefaults]="indicateDefaults"
        (onReady)="onReady($event)">
        <div class="save-button-container">
            <button type="button"
                    class="save-button text-end"
                    (click)="saveAnalysisType()"
                    [hidden]="!saveAllowed"
                    [disabled]="analysisSuitesControl.value.length === 0"
                    #tooltip="matTooltip"
                    [matTooltip]="analysisSuitesControl.value.length === 0 ? 'Please select atleast one item.': 'Save changes!'">Save</button>
        </div>
    </app-analysis-type-selector>
</div>