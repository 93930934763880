import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { map, switchMap, tap } from 'rxjs/operators';
import { loadInfo, updateUserInfo } from '@/state/actions/info.actions';
import { AuthCookie } from '@/core/services/auth-cookies-handler';
import { environment } from '../../../../environments/environment';
import { ImpersonationUser } from '@/data/core/models/impersonation_user';
import { Observable } from 'rxjs';
import {
  HIRLFindVerifier,
  HIRLFindGreenVerifier,
  User,
  CustomerHirlCOIDashboard,
} from '@/data/core/models/user';
import { ServerResponse } from '@/core/schemes/server-response';
import { TokenObtainPairResponse } from '@/core/services/authentication.service';
import { ModernMessage } from '@/data/messaging/models/modernMessage';
import { ListRequestParams } from '@/core/schemes/request-params';
import { loadUser } from '@/state/actions/user/user.actions';
import { Accreditation } from '@/data/user-management/models';
import { AccreditationRequestParams } from '@/data/user-management/services/accreditation.service';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { AccreditationName } from '@/data/user-management/models/accreditation';
import { VerifierAgreement } from '@/data/customer-hirl/models';
import { VerifierAgreementParams } from '@/data/customer-hirl/services/verifier-agreement.service';
import { CompanyType } from '@/data/company/models';
import { AsynchronousProcessedDocument } from '@/data/filehandling/models';
import { MessageRequestParams } from '@/data/messaging/services/message.service';
import {
  loadRecentMessagesAction,
  loadRecentMessagesCountAction,
} from '@/state/actions/messaging/message.actions';

export class UserRequestParams extends ListRequestParams {
  is_company_admin?: boolean;
  is_active?: boolean;
  company?: number;
  company__slug?: string;
  company__company_type?: CompanyType | CompanyType[];
  company__hirl_marketing_roles__slug?: string | string[];
  hirluserprofile__is_qa_designee?: boolean;
  home_status_related_as_rater_of_record?: boolean;
}

export class HIRLFindVerifierRequestParams extends ListRequestParams {
  us_states?: string | string[];
  company__city__country?: number | number[];
  accreditations__name?: AccreditationName | string;
}

export class HIRLCOIDashboardUserRequestParams extends ListRequestParams {
  coi_requirements_met?: string;
}

@Injectable({ providedIn: 'root' })
export class UserService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/users/`;

  constructor(
    public http: HttpClient,
    public store: Store<AppState>,
    public authCookie: AuthCookie
  ) {
    super(http, store);
  }

  list(params: UserRequestParams) {
    return this.http.get<ServerResponse<User>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  mailing_list_export(params: UserRequestParams) {
    return this.http.get<AsynchronousProcessedDocument>(
      `${this.baseUrl}mailing_list_export/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  retrieve(userId: number, options?: IDataServiceMethodOptions) {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.get<User>(`${this.baseUrl}${userId}`, {});
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(user => this.store.dispatch(loadUser({ payload: user })))
      );
    }
    return chain;
  }

  update(user: User | FormData, userId: number) {
    return this.http.patch<User>(`${this.baseUrl}${userId}/`, user).pipe(
      tap(newUser => {
        this.store.dispatch(updateUserInfo({ payload: newUser }));
      })
    );
  }

  info() {
    return this.http.get<ImpersonationUser>(`${this.baseUrl}info/`).pipe(
      tap(info => {
        if (info) {
          this.store.dispatch(loadInfo({ payload: info }));
        }
      }),
      tap(info => this.authCookie.setSession(info.session_key))
    );
  }

  public impersonation_list(
    impersonationString: string
  ): Observable<ServerResponse<User[]>> {
    const params = new HttpParams().set('search', impersonationString);

    return this.http.get<ServerResponse<User[]>>(
      `${this.baseUrl}impersonation_list/`,
      { params }
    );
  }

  public toggleImpersonate(state: boolean, id?: string) {
    const suffix = state ? `${id}/impersonate_start/` : 'impersonate_stop/';

    return this.http
      .get<TokenObtainPairResponse>(`${this.baseUrl}${suffix}`, {})
      .pipe(
        map(res => {
          this.authCookie.setAccess(JSON.stringify(res.access));
          this.authCookie.setRefresh(JSON.stringify(res.refresh));
          return res;
        }),
        switchMap(res => this.info()),
        tap(res => window.location.reload())
      );
  }

  logout() {
    return this.http.post<any>(`${this.baseUrl}logout/`, {});
  }

  messages(
    userId: number,
    params: MessageRequestParams
  ): Observable<ServerResponse<ModernMessage>> {
    return this.http.get<ServerResponse<ModernMessage>>(
      `${this.baseUrl}${userId}/messages/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  messagesPull(
    userId: number,
    params?: MessageRequestParams
  ): Observable<ServerResponse<ModernMessage>> {
    return this.http
      .get<ServerResponse<ModernMessage>>(
        `${this.baseUrl}${userId}/messages/pull/`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap((response: ServerResponse<ModernMessage>) =>
          this.store.dispatch(
            loadRecentMessagesAction({ payload: response.results })
          )
        ),
        tap((response: ServerResponse<ModernMessage>) =>
          this.store.dispatch(
            loadRecentMessagesCountAction({ payload: response.count })
          )
        )
      );
  }

  messagesMarkAllAsRead(userId: number): Observable<number> {
    return this.http
      .post<number>(`${this.baseUrl}${userId}/messages/mark_all_read/`, {})
      .pipe(
        tap((updatedMessagesCount: number) => {
          this.store.dispatch(loadRecentMessagesAction({ payload: [] }));
        }),
        tap((updatedMessagesCount: number) =>
          this.store.dispatch(loadRecentMessagesCountAction({ payload: 0 }))
        )
      );
  }

  accreditations(userId: number, params?: AccreditationRequestParams) {
    return this.http.get<ServerResponse<Accreditation>>(
      `${this.baseUrl}${userId}/accreditations/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  customer_hirl_find_verifier_list(params?: HIRLFindVerifierRequestParams) {
    return this.http.get<ServerResponse<HIRLFindVerifier>>(
      `${this.baseUrl}customer_hirl_find_verifier_list`,
      {
        params: this.rollParams(params),
      }
    );
  }

  customer_hirl_find_green_verifier_list(
    params?: HIRLFindVerifierRequestParams
  ) {
    return this.http.get<ServerResponse<HIRLFindGreenVerifier>>(
      `${this.baseUrl}customer_hirl_find_green_verifier_list`,
      {
        params: this.rollParams(params),
      }
    );
  }

  verifier_agreements(
    userId: number,
    params?: VerifierAgreementParams
  ): Observable<ServerResponse<VerifierAgreement>> {
    return this.http.get<ServerResponse<VerifierAgreement>>(
      `${this.baseUrl}${userId}/verifier_agreements`,
      {
        params: this.rollParams(params),
      }
    );
  }

  customer_hirl_coi_dashboard_list(params: HIRLCOIDashboardUserRequestParams) {
    return this.http.get<ServerResponse<CustomerHirlCOIDashboard>>(
      `${this.baseUrl}customer_hirl_coi_dashboard_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  customer_hirl_coi_dashboard_report(
    params?: HIRLCOIDashboardUserRequestParams
  ): Observable<AsynchronousProcessedDocument> {
    return this.http.get<AsynchronousProcessedDocument>(
      `${this.baseUrl}customer_hirl_coi_dashboard_report/`,
      {
        params: this.rollParams(params),
      }
    );
  }
}
