import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DoorTypeChangeDialogComponent,
  DoorTypeChangeDialogData,
} from '../door-type-change-dialog/door-type-change-dialog.component';
import { DIALOG_WIDTH } from '@/modules/simulation/constants';
import { selectDoorViewSet } from '@/modules/simulation/state/door/selectors';
import {
  removeDoor,
  updateDoor,
} from '@/modules/simulation/state/door/actions';
import {
  DoorBackendDict,
  createDoorForm,
  FIELD_LABELS,
} from '@/data/simulation/models/enclosure/Door';
import { removeDoorType } from '@/modules/simulation/state/door-type/actions';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { CREATE_MODE, EDIT_MODE } from '@/core/constants';
import { selectDoorTypeNameAndError } from '@/modules/simulation/state/door-type/selectors';
import { cloneDoor } from '@/modules/simulation/state/simulation/actions';

@Component({
  selector: 'app-door',
  templateUrl: './door.component.html',
  styleUrls: ['./door.component.scss'],
})
export class DoorComponent implements OnDestroy, OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  entity: DoorBackendDict = null;
  loading = false;

  private componentDestroyed$ = new Subject();
  typeViewSubscription$ = null;

  public doorTypeName: string;
  public doorTypeErrorCount = 0;

  public doorFormGroup: FormGroup;
  public wallControl = new FormControl(null, Validators.required);
  wallLabels = {
    above_grade_wall: 'Above Grade Wall',
    foundation_wall: 'Foundation Wall',
  };

  constructor(public dialog: MatDialog, public store: Store) {}

  updateTypeDetails(typeId: number) {
    if (this.typeViewSubscription$) {
      this.typeViewSubscription$.unsubscribe();
    }
    if (!typeId) {
      this.doorTypeName = null;
      this.doorTypeErrorCount = 1;
      return;
    }

    this.store
      .select(selectDoorTypeNameAndError(typeId))
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(({ name, errorCount }) => {
        this.doorTypeName = name;
        this.doorTypeErrorCount = errorCount;
      });
  }

  updateWallSelectorFormGroup(isFoundationWall: boolean) {
    this.wallControl.setValue(
      isFoundationWall ? 'foundation_wall' : 'above_grade_wall',
      { emitEvent: false }
    );
  }

  ngOnInit(): void {
    this.wallControl.valueChanges.subscribe(value => {
      this.doorFormGroup.patchValue({
        above_grade_wall: null,
        foundation_wall: null,
      });
    });

    this.store
      .select(selectDoorViewSet(this.entityId))
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val !== null)
      )
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (!this.doorFormGroup) {
          this.doorFormGroup = createDoorForm(data);
          this.updateWallSelectorFormGroup(data.foundation_wall !== null);
          this.doorFormGroup.controls.above_grade_wall.valueChanges.subscribe(
            value => {
              if (value === null) return;
              this.updateWallSelectorFormGroup(false);
            }
          );

          this.doorFormGroup.controls.foundation_wall.valueChanges.subscribe(
            value => {
              if (value === null) return;
              this.updateWallSelectorFormGroup(true);
            }
          );
        }

        this.updateTypeDetails(data.type);
      });
  }

  openEditDoorTypeChangeDialog(data: DoorTypeChangeDialogData) {
    return this.dialog.open(DoorTypeChangeDialogComponent, {
      width: DIALOG_WIDTH,
      disableClose: true,
      data,
    });
  }

  removeDoor() {
    let doorName = this.entity.name;
    if (doorName === null || doorName === '') {
      doorName = 'Untitled';
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${doorName}" door ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(removeDoor({ door: this.entity }));
        }
      });
  }

  cloneDoor() {
    this.store.dispatch(cloneDoor({ id: this.entityId }));
  }

  editDoorType() {
    const updatedDoorType = this.openEditDoorTypeChangeDialog({
      commit: true,
      mode: EDIT_MODE,
      entityId: this.entity.type,
    });

    updatedDoorType.afterClosed().subscribe(() => {
      this.updateTypeDetails(this.entity.type);
    });
  }

  addDoorType() {
    const newDoorDialog = this.openEditDoorTypeChangeDialog({
      commit: true,
      mode: CREATE_MODE,
      parentId: this.entityId,
    });

    newDoorDialog.afterClosed().subscribe(doorType => {
      if (!doorType) {
        return;
      }

      this.doorFormGroup.patchValue({ type: doorType.id });
      this.updateTypeDetails(doorType.id);
    });
  }

  removeDoorType() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${this.doorTypeName}" door type ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            removeDoorType({
              doorId: this.entityId,
              doorTypeId: this.entity.type,
            })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onSave(doorChanges: Partial<DoorBackendDict>): void {
    this.store.dispatch(
      updateDoor({
        doorChanges: doorChanges,
      })
    );
  }
}
