<ng-container>
    <li class="list-group-item header" style="background-color:rgba(0, 0, 0, 0.03); align-items: center;">
        <b>
            Air Conditioner
        </b>
    </li>
    <li class="list-group-item">
        <b>Name:</b>
        {{ equipment.air_conditioner_info.name }}
    </li>
    <li class="list-group-item">
        <b>Fuel Type:</b>
        {{ equipment.air_conditioner_info.fuel_display }}
    </li>

    <li class="list-group-item">
        <b>Capacity:</b>
        {{ equipment.air_conditioner_info.capacity }}
        {{ equipment.air_conditioner_info.capacity_unit_display }}
    </li>
    <li class="list-group-item">
        <b>Efficiency:</b>
        {{ equipment.air_conditioner_info.efficiency }}
        {{ equipment.air_conditioner_info.efficiency_unit_display }}
    </li>
</ng-container>