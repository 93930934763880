import { Note } from '@/data/simulation/models/Note';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadNotes = createAction(
  '[Note] Load Notes',
  props<{ notes: Note[] }>()
);

export const loadNotesSuccess = createAction(
  '[Note] Load Notes Success',
  props<{
    notes: Note[];
    errors: StateErrors<Note>;
  }>()
);

export const loadNotesFailure = createAction(
  '[Note] Load Notes Failure',
  props<{ error: any }>()
);

export const updateNote = createAction(
  '[Note] Update Note',
  props<{ note: Note }>()
);

export const updateNoteSuccess = createAction(
  '[Note] Update Note Success',
  props<{ data: string }>()
);

export const updateNoteFailure = createAction(
  '[Note API] Update Note Failure',
  props<{ id: string }>()
);
