import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as FoundationWallActions from './actions';
import * as FoundationWallTypeActions from '../foundation-wall-type/actions';
import * as SimulationActions from '../simulation/actions';
import * as SharedActions from '../shared/shared.actions';
import { FoundationWallService } from '@/data/simulation/services/foundation-wall.service';
import { FoundationWallTypeService } from '@/data/simulation/services/foundation-wall-type.service';
import { FoundationWallTypeBackendDict } from '@/data/simulation/models/enclosure/FoundationWallType';
import { FoundationWallBackendDict } from '@/data/simulation/models/enclosure/FoundationWall';
import { ModelGraphService } from '../../services/model-graph.service';
import { FoundationWallValidator } from '../../validtaors/foundation-wall.validator';

@Injectable()
export class FoundationWallEffects {
  loadDetailedFoundationWalls$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FoundationWallActions.loadDetailedFoundationWalls),
      mergeMap(action => {
        const detailedWallTypes: FoundationWallTypeBackendDict[] = [];
        const foundationWalls: FoundationWallBackendDict[] = [];
        action.detailedFoundationWalls.forEach(detailedWall => {
          const { type_info: detailedWallType, ...wall } = detailedWall;
          if (detailedWallType) {
            detailedWallTypes.push(detailedWallType);
          }
          this.modelGraphService.attachModel(
            'foundationWall',
            detailedWall.id,
            'foundationWallType',
            [detailedWall.type]
          );
          foundationWalls.push(wall);
        });

        const errors = FoundationWallValidator.validate(
          action.detailedFoundationWalls
        );

        return of(
          FoundationWallActions.loadFoundationWallsSuccess({
            foundationWalls: foundationWalls,
            errors: errors,
          }),
          FoundationWallTypeActions.loadFoundationWallTypes({
            wallTypes: detailedWallTypes,
          })
        );
      })
    );
  });

  updateFoundationWall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FoundationWallActions.updateFoundationWall),
      mergeMap(action =>
        this.foundationwallService
          .update(action.id, action.foundationWallChanges)
          .pipe(
            mergeMap(updatedWall => {
              const errors = FoundationWallValidator.validate([updatedWall]);
              return of(
                FoundationWallActions.updateFoundationWallSuccess({
                  foundationWall: updatedWall,
                  errors: errors[updatedWall.id],
                })
              );
            }),
            catchError(error =>
              of(
                FoundationWallActions.updateFoundationWallFailure({
                  id: action.id,
                }),
                SharedActions.reportAPIFailure({ error })
              )
            )
          )
      )
    );
  });

  removeFoundationWall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FoundationWallActions.removeFoundationWall),
      mergeMap(action =>
        this.foundationwallService.delete(action.foundationWall.id).pipe(
          mergeMap(() => {
            return of(
              FoundationWallActions.removeFoundationWallSuccess({
                id: action.foundationWall.id,
              }),
              SimulationActions.removeItemFromList({
                fieldName: 'foundation_walls',
                id: action.foundationWall.id,
              })
            );
          }),
          catchError(error =>
            of(
              FoundationWallActions.removeFoundationWallFailure({
                id: action.foundationWall.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeFoundationWallType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FoundationWallTypeActions.removeFoundationWallType),
      mergeMap(action =>
        this.foundationwallTypeService.delete(action.wallTypeId).pipe(
          mergeMap(() => [
            FoundationWallActions.setFoundationWallType({
              wallId: action.wallId,
              wallTypeId: null,
            }),
            FoundationWallTypeActions.removeFoundationWallTypeSuccess({
              id: action.wallTypeId,
            }),
          ]),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addFoundationWallType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FoundationWallTypeActions.addFoundationWallType),
      mergeMap(action =>
        this.foundationwallTypeService
          .create(action.wallId, action.wallType)
          .pipe(
            mergeMap(wallType =>
              of(
                FoundationWallTypeActions.loadFoundationWallTypes({
                  wallTypes: [wallType],
                }),
                FoundationWallActions.setFoundationWallType({
                  wallId: action.wallId,
                  wallTypeId: wallType.id,
                })
              )
            ),
            catchError(error => of(SharedActions.reportAPIFailure({ error })))
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private foundationwallService: FoundationWallService,
    private foundationwallTypeService: FoundationWallTypeService
  ) {}
}
