export enum AnalysisEngine {
  REMRATE = 'rem',
  EKOTROPE = 'eko',
  EPLUS = 'eplus',
}

export const AnalysisEngineLabels: Record<AnalysisEngine, string> = {
  [AnalysisEngine.REMRATE]: 'REM/Rate®',
  [AnalysisEngine.EKOTROPE]: 'Ekotrope®',
  [AnalysisEngine.EPLUS]: 'EnergyPlus®',
};

export enum AnalysisType {
  DEFAULT = 'default',
  HERS_DESIGN = 'hers_design',
  HERS_REFERENCE = 'hers_reference',

  UDRH_DESIGN = 'udrh_design',
  UDRH_REFERENCE = 'udrh_reference',

  SWWA_2019_DESIGN = 'swwa_2019_design',
  SWWA_2019_REFERENCE = 'swwa_2019_reference',

  ID_2019_CENTRAL_DESIGN = 'id_2019_central_design',
  ID_2019_CENTRAL_REFERENCE = 'id_2019_central_reference',

  ID_2019_ZONAL_DESIGN = 'id_2019_zonal_design',
  ID_2019_ZONAL_REFERENCE = 'id_2019_zonal_reference',

  MT_2019_CENTRAL_DESIGN = 'mt_2019_central_design',
  MT_2019_CENTRAL_REFERENCE = 'mt_2019_central_reference',

  MT_2019_ZONAL_DESIGN = 'mt_2019_zonal_design',
  MT_2019_ZONAL_REFERENCE = 'mt_2019_zonal_reference',

  OR_2019_CENTRAL_DESIGN = 'or_2019_central_design',
  OR_2019_CENTRAL_REFERENCE = 'or_2019_central_reference',

  OR_2019_ZONAL_DESIGN = 'or_2019_zonal_design',
  OR_2019_ZONAL_REFERENCE = 'or_2019_zonal_reference',

  WA_2019_CENTRAL_SMALL_DESIGN = 'wa_2019_central_small_design',
  WA_2019_CENTRAL_SMALL_REFERENCE = 'wa_2019_central_small_reference',
  WA_2019_CENTRAL_MEDIUM_DESIGN = 'wa_2019_central_medium_design',
  WA_2019_CENTRAL_MEDIUM_REFERENCE = 'wa_2019_central_medium_reference',
  WA_2019_CENTRAL_LARGE_DESIGN = 'wa_2019_central_large_design',
  WA_2019_CENTRAL_LARGE_REFERENCE = 'wa_2019_central_large_reference',

  WA_2019_ZONAL_SMALL_DESIGN = 'wa_2019_zonal_small_design',
  WA_2019_ZONAL_SMALL_REFERENCE = 'wa_2019_zonal_small_reference',
  WA_2019_ZONAL_MEDIUM_DESIGN = 'wa_2019_zonal_medium_design',
  WA_2019_ZONAL_MEDIUM_REFERENCE = 'wa_2019_zonal_medium_reference',
  WA_2019_ZONAL_LARGE_DESIGN = 'wa_2019_zonal_large_design',
  WA_2019_ZONAL_LARGE_REFERENCE = 'wa_2019_zonal_large_reference',

  SWWA_2020_DESIGN = 'swwa_2020_design',
  SWWA_2020_REFERENCE = 'swwa_2020_reference',

  ID_2020_CENTRAL_DESIGN = 'id_2020_central_design',
  ID_2020_CENTRAL_REFERENCE = 'id_2020_central_reference',

  ID_2020_ZONAL_DESIGN = 'id_2020_zonal_design',
  ID_2020_ZONAL_REFERENCE = 'id_2020_zonal_reference',

  MT_2020_CENTRAL_DESIGN = 'mt_2020_central_design',
  MT_2020_CENTRAL_REFERENCE = 'mt_2020_central_reference',

  MT_2020_ZONAL_DESIGN = 'mt_2020_zonal_design',
  MT_2020_ZONAL_REFERENCE = 'mt_2020_zonal_reference',

  OR_2020_CENTRAL_DESIGN = 'or_2020_central_design',
  OR_2020_CENTRAL_REFERENCE = 'or_2020_central_reference',

  OR_2020_ZONAL_DESIGN = 'or_2020_zonal_design',
  OR_2020_ZONAL_REFERENCE = 'or_2020_zonal_reference',

  WA_2020_CENTRAL_SMALL_DESIGN = 'wa_2020_central_small_design',
  WA_2020_CENTRAL_SMALL_REFERENCE = 'wa_2020_central_small_reference',
  WA_2020_CENTRAL_MEDIUM_DESIGN = 'wa_2020_central_medium_design',
  WA_2020_CENTRAL_MEDIUM_REFERENCE = 'wa_2020_central_medium_reference',
  WA_2020_CENTRAL_LARGE_DESIGN = 'wa_2020_central_large_design',
  WA_2020_CENTRAL_LARGE_REFERENCE = 'wa_2020_central_large_reference',

  WA_2020_ZONAL_SMALL_DESIGN = 'wa_2020_zonal_small_design',
  WA_2020_ZONAL_SMALL_REFERENCE = 'wa_2020_zonal_small_reference',
  WA_2020_ZONAL_MEDIUM_DESIGN = 'wa_2020_zonal_medium_design',
  WA_2020_ZONAL_MEDIUM_REFERENCE = 'wa_2020_zonal_medium_reference',
  WA_2020_ZONAL_LARGE_DESIGN = 'wa_2020_zonal_large_design',
  WA_2020_ZONAL_LARGE_REFERENCE = 'wa_2020_zonal_large_reference',

  ID_2021_CENTRAL_DESIGN = 'id_2021_central_design',
  ID_2021_CENTRAL_REFERENCE = 'id_2021_central_reference',

  ID_2021_ZONAL_DESIGN = 'id_2021_zonal_design',
  ID_2021_ZONAL_REFERENCE = 'id_2021_zonal_reference',

  MT_2021_CENTRAL_DESIGN = 'mt_2021_central_design',
  MT_2021_CENTRAL_REFERENCE = 'mt_2021_central_reference',

  MT_2021_ZONAL_DESIGN = 'mt_2021_zonal_design',
  MT_2021_ZONAL_REFERENCE = 'mt_2021_zonal_reference',

  OR_2021_CENTRAL_DESIGN = 'or_2021_central_design',
  OR_2021_CENTRAL_REFERENCE = 'or_2021_central_reference',

  OR_2021_ZONAL_DESIGN = 'or_2021_zonal_design',
  OR_2021_ZONAL_REFERENCE = 'or_2021_zonal_reference',

  OR_2022_CENTRAL_DESIGN = 'or_2022_central_design',
  OR_2022_CENTRAL_REFERENCE = 'or_2022_central_reference',

  OR_2022_ZONAL_DESIGN = 'or_2022_zonal_design',
  OR_2022_ZONAL_REFERENCE = 'or_2022_zonal_reference',

  OR_2023_CENTRAL_DESIGN = 'or_2023_central_design',
  OR_2023_CENTRAL_REFERENCE = 'or_2023_central_reference',

  OR_2023_ZONAL_DESIGN = 'or_2023_zonal_design',
  OR_2023_ZONAL_REFERENCE = 'or_2023_zonal_reference',

  WA_2021_CENTRAL_SMALL_DESIGN = 'wa_2021_central_small_design',
  WA_2021_CENTRAL_SMALL_REFERENCE = 'wa_2021_central_small_reference',
  WA_2021_CENTRAL_MEDIUM_DESIGN = 'wa_2021_central_medium_design',
  WA_2021_CENTRAL_MEDIUM_REFERENCE = 'wa_2021_central_medium_reference',
  WA_2021_CENTRAL_LARGE_DESIGN = 'wa_2021_central_large_design',
  WA_2021_CENTRAL_LARGE_REFERENCE = 'wa_2021_central_large_reference',

  WA_2021_ZONAL_SMALL_DESIGN = 'wa_2021_zonal_small_design',
  WA_2021_ZONAL_SMALL_REFERENCE = 'wa_2021_zonal_small_reference',
  WA_2021_ZONAL_MEDIUM_DESIGN = 'wa_2021_zonal_medium_design',
  WA_2021_ZONAL_MEDIUM_REFERENCE = 'wa_2021_zonal_medium_reference',
  WA_2021_ZONAL_LARGE_DESIGN = 'wa_2021_zonal_large_design',
  WA_2021_ZONAL_LARGE_REFERENCE = 'wa_2021_zonal_large_reference',

  WA_2021_CENTRAL_SMALL_GHEW_DESIGN = 'wa_2021_central_small_ghew_design',
  WA_2021_CENTRAL_SMALL_GHEW_REFERENCE = 'wa_2021_central_small_ghew_reference',
  WA_2021_CENTRAL_MEDIUM_GHEW_DESIGN = 'wa_2021_central_medium_ghew_design',
  WA_2021_CENTRAL_MEDIUM_GHEW_REFERENCE = 'wa_2021_central_medium_ghew_reference',
  WA_2021_CENTRAL_LARGE_GHEW_DESIGN = 'wa_2021_central_large_ghew_design',
  WA_2021_CENTRAL_LARGE_GHEW_REFERENCE = 'wa_2021_central_large_ghew_reference',

  WA_2021_ZONAL_SMALL_EHEW_DESIGN = 'wa_2021_zonal_small_ehew_design',
  WA_2021_ZONAL_SMALL_EHEW_REFERENCE = 'wa_2021_zonal_small_ehew_reference',
  WA_2021_ZONAL_MEDIUM_EHEW_DESIGN = 'wa_2021_zonal_medium_ehew_design',
  WA_2021_ZONAL_MEDIUM_EHEW_REFERENCE = 'wa_2021_zonal_medium_ehew_reference',
  WA_2021_ZONAL_LARGE_EHEW_DESIGN = 'wa_2021_zonal_large_ehew_design',
  WA_2021_ZONAL_LARGE_EHEW_REFERENCE = 'wa_2021_zonal_large_ehew_reference',

  WA_2021_ZONAL_SMALL_EHGW_DESIGN = 'wa_2021_zonal_small_ehgw_design',
  WA_2021_ZONAL_SMALL_EHGW_REFERENCE = 'wa_2021_zonal_small_ehgw_reference',
  WA_2021_ZONAL_MEDIUM_EHGW_DESIGN = 'wa_2021_zonal_medium_ehgw_design',
  WA_2021_ZONAL_MEDIUM_EHGW_REFERENCE = 'wa_2021_zonal_medium_ehgw_reference',
  WA_2021_ZONAL_LARGE_EHGW_DESIGN = 'wa_2021_zonal_large_ehgw_design',
  WA_2021_ZONAL_LARGE_EHGW_REFERENCE = 'wa_2021_zonal_large_ehgw_reference',

  WA_2021_CENTRAL_SMALL_GHGW_DESIGN = 'wa_2021_central_small_ghgw_design',
  WA_2021_CENTRAL_SMALL_GHGW_REFERENCE = 'wa_2021_central_small_ghgw_reference',
  WA_2021_CENTRAL_MEDIUM_GHGW_DESIGN = 'wa_2021_central_medium_ghgw_design',
  WA_2021_CENTRAL_MEDIUM_GHGW_REFERENCE = 'wa_2021_central_medium_ghgw_reference',
  WA_2021_CENTRAL_LARGE_GHGW_DESIGN = 'wa_2021_central_large_ghgw_design',
  WA_2021_CENTRAL_LARGE_GHGW_REFERENCE = 'wa_2021_central_large_ghgw_reference',

  WA_2021_CENTRAL_SMALL_EHGW_DESIGN = 'wa_2021_central_small_ehgw_design',
  WA_2021_CENTRAL_SMALL_EHGW_REFERENCE = 'wa_2021_central_small_ehgw_reference',
  WA_2021_CENTRAL_MEDIUM_EHGW_DESIGN = 'wa_2021_central_medium_ehgw_design',
  WA_2021_CENTRAL_MEDIUM_EHGW_REFERENCE = 'wa_2021_central_medium_ehgw_reference',
  WA_2021_CENTRAL_LARGE_EHGW_DESIGN = 'wa_2021_central_large_ehgw_design',
  WA_2021_CENTRAL_LARGE_EHGW_REFERENCE = 'wa_2021_central_large_ehgw_reference',

  WA_2021_CENTRAL_SMALL_EHEW_DESIGN = 'wa_2021_central_small_ehew_design',
  WA_2021_CENTRAL_SMALL_EHEW_REFERENCE = 'wa_2021_central_small_ehew_reference',
  WA_2021_CENTRAL_MEDIUM_EHEW_DESIGN = 'wa_2021_central_medium_ehew_design',
  WA_2021_CENTRAL_MEDIUM_EHEW_REFERENCE = 'wa_2021_central_medium_ehew_reference',
  WA_2021_CENTRAL_LARGE_EHEW_DESIGN = 'wa_2021_central_large_ehew_design',
  WA_2021_CENTRAL_LARGE_EHEW_REFERENCE = 'wa_2021_central_large_ehew_reference',

  IECC1998_DESIGN = 'iecc_1998_design',
  IECC1998_REFERENCE = 'iecc_1998_reference',
  IECC2000_DESIGN = 'iecc_2000_design',
  IECC2000_REFERENCE = 'iecc_2000_reference',
  IECC2001_DESIGN = 'iecc_2001_design',
  IECC2001_REFERENCE = 'iecc_2001_reference',
  IECC2003_DESIGN = 'iecc_2003_design',
  IECC2003_REFERENCE = 'iecc_2003_reference',
  IECC2004_DESIGN = 'iecc_2004_design',
  IECC2004_REFERENCE = 'iecc_2004_reference',
  IECC2006_DESIGN = 'iecc_2006_design',
  IECC2006_REFERENCE = 'iecc_2006_reference',
  IECC2006_REFERENCE_MIN = 'iecc_2006_reference_min',
  IECC2009_DESIGN = 'iecc_2009_design',
  IECC2009_REFERENCE = 'iecc_2009_reference',
  IECC2009_REFERENCE_MIN = 'iecc_2009_reference_min',
  IECC2012_DESIGN = 'iecc_2012_design',
  IECC2012_REFERENCE = 'iecc_2012_reference',
  IECC2012_REFERENCE_MIN = 'iecc_2012_reference_min',
  IECC2015_DESIGN = 'iecc_2015_design',
  IECC2015_REFERENCE = 'iecc_2015_reference',
  IECC2015_REFERENCE_MIN = 'iecc_2015_reference_min',
  IECC2018_DESIGN = 'iecc_2018_design',
  IECC2018_REFERENCE = 'iecc_2018_reference',
  IECC2018_REFERENCE_ERI = 'iecc_2018_reference_eri',
  IECC2021_DESIGN = 'iecc_2021_design',
  IECC2021_REFERENCE = 'iecc_2021_reference',
  IECC2021_REFERENCE_ERI = 'iecc_2021_reference_eri',
  IECC2024_DESIGN = 'iecc_2024_design',
  IECC2024_REFERENCE = 'iecc_2024_reference',
  IECC2024_REFERENCE_ERI = 'iecc_2024_reference_eri',

  ENERGY_STAR_V2_DESIGN = 'estar_v2_design',
  ENERGY_STAR_V2_REFERENCE = 'estar_v2_reference',
  ENERGY_STAR_V2p5_DESIGN = 'estar_v2p5_design',
  ENERGY_STAR_V2p5_REFERENCE = 'estar_v2p5_reference',
  ENERGY_STAR_V3_DESIGN = 'estar_v3_design',
  ENERGY_STAR_V3_REFERENCE = 'estar_v3_reference',
  ENERGY_STAR_V3_PAC_DESIGN = 'estar_v3_pac_design',
  ENERGY_STAR_V3_PAC_REFERENCE = 'estar_v3_pac_reference',
  ENERGY_STAR_V3p1_FL_DESIGN = 'estar_v3p1_fl_design',
  ENERGY_STAR_V3p1_FL_REFERENCE = 'estar_v3p1_fl_reference',
  ENERGY_STAR_V3p1_DESIGN = 'estar_v3p1_design',
  ENERGY_STAR_V3p1_REFERENCE = 'estar_v3p1_reference',
  ENERGY_STAR_V3p2_DESIGN = 'estar_v3p2_design',
  ENERGY_STAR_V3p2_REFERENCE = 'estar_v3p2_reference',
  ENERGY_STAR_V3p2_ORWA_DESIGN = 'estar_v3p2_orwa_design',
  ENERGY_STAR_V3p2_ORWA_REFERENCE = 'estar_v3p2_orwa_reference',
  ENERGY_STAR_V1_MF_DESIGN = 'estar_v1_mf_design',
  ENERGY_STAR_V1_MF_REFERENCE = 'estar_v1_mf_reference',
  ENERGY_STAR_V1p1_MF_DESIGN = 'estar_v1p1_mf_design',
  ENERGY_STAR_V1p1_MF_REFERENCE = 'estar_v1p1_mf_reference',
  ENERGY_STAR_V1p2_MF_DESIGN = 'estar_v1p2_mf_design',
  ENERGY_STAR_V1p2_MF_REFERENCE = 'estar_v1p2_mf_reference',
  ENERGY_STAR_V1p2_MF_ORWA_DESIGN = 'estar_v1p2_mf_orwa_design',
  ENERGY_STAR_V1p2_MF_ORWA_REFERENCE = 'estar_v1p2_mf_orwa_reference',
  TAX_CREDIT_DESIGN = 'tax_credit_design',
  TAX_CREDIT_REFERENCE = 'tax_credit_reference',
  TAX_CREDIT_REFERENCE_NO_ENV = 'tax_credit_reference_no_env',
  FE_PA_SAVINGS_DESIGN = 'fe_pa_savings_design',
  FE_PA_SAVINGS_REFERENCE = 'fe_pa_savings_reference',
  FE_PA_NEW_HOMES_DESIGN = 'fe_pa_new_homes_design',
  FE_PA_NEW_HOMES_REFERENCE = 'fe_pa_new_homes_reference',
  DOE_ZERO_ENERGY_DESIGN = 'doe_zero_energy_design',
  DOE_ZERO_ENERGY_REFERENCE = 'doe_zero_energy_reference',
  DOE_ZERH_V2p0_DESIGN = 'doe_zerh_2p0_design',
  DOE_ZERH_V2p0_REFERENCE = 'doe_zerh_2p0_reference',
  DOE_ZERH_V2p0_MF_DESIGN = 'doe_zerh_2p0_mf_design',
  DOE_ZERH_V2p0_MF_REFERENCE = 'doe_zerh_2p0_mf_reference',
  DOE_ZERH_V1_DESIGN = 'doe_zerh_1p0_design',
  DOE_ZERH_V1_REFERENCE = 'doe_zerh_1p0_reference',
  NGBS_2015_DESIGN = 'ngbs_2015_design',
  NGBS_2015_REFERENCE = 'ngbs_2015_reference',
  NGBS_2020_DESIGN = 'ngbs_2020_design',
  NGBS_2020_REFERENCE = 'ngbs_2020_reference',
  OS_ERI_2014AEG_DESIGN = 'eri_2014aeg_design',
  OS_ERI_2014AEG_REFERENCE = 'eri_2014aeg_reference',
  OS_ERI_2014AE_DESIGN = 'eri_2014ae_design',
  OS_ERI_2014AE_REFERENCE = 'eri_2014ae_reference',
  OS_ERI_2014A_DESIGN = 'eri_2014a_design',
  OS_ERI_2014A_REFERENCE = 'eri_2014a_reference',
  OS_ERI_2014_DESIGN = 'eri_2014_design',
  OS_ERI_2014_REFERENCE = 'eri_2014_reference',
  OS_ERI_2022C_DESIGN = 'eri_2022c_design',
  OS_ERI_2022C_REFERENCE = 'eri_2022c_reference',
  OS_ERI_2022_DESIGN = 'eri_2022_design',
  OS_ERI_2022_REFERENCE = 'eri_2022_reference',
  OS_ERI_2019ABCD_DESIGN = 'eri_2019abcd_design',
  OS_ERI_2019ABCD_REFERENCE = 'eri_2019abcd_reference',
  OS_ERI_2019AB_DESIGN = 'eri_2019ab_design',
  OS_ERI_2019AB_REFERENCE = 'eri_2019ab_reference',
  OS_ERI_2019A_DESIGN = 'eri_2019a_design',
  OS_ERI_2019A_REFERENCE = 'eri_2019a_reference',
  OS_ERI_2019_DESIGN = 'eri_2019_design',
  OS_ERI_2019_REFERENCE = 'eri_2019_reference',
  OS_ERI_2022C_CO2_DESIGN = 'co2_2022c_design',
  OS_ERI_2022C_CO2_REFERENCE = 'co2_2022c_reference',
  OS_ERI_2022_CO2_DESIGN = 'co2_2022_design',
  OS_ERI_2022_CO2_REFERENCE = 'co2_2022_reference',
  OS_ERI_2019ABCD_CO2_DESIGN = 'co2_2019abcd_design',
  OS_ERI_2019ABCD_CO2_REFERENCE = 'co2_2019abcd_reference',
  EPLUS_ETO_2024_DUCTS_INSIDE_DESIGN = 'eplus_eto_2024_ducts_inside_design',
  EPLUS_ETO_2024_DUCTS_INSIDE_REFERENCE = 'eplus_eto_2024_ducts_inside_reference',
  EPLUS_ETO_2024_DUCTS_DEEP_DESIGN = 'eplus_eto_2024_ducts_deep_design',
  EPLUS_ETO_2024_DUCTS_DEEP_REFERENCE = 'eplus_eto_2024_ducts_deep_reference',
  EPLUS_ETO_2024_CENTRAL_DESIGN = 'eplus_eto_2024_central_design',
  EPLUS_ETO_2024_CENTRAL_REFERENCE = 'eplus_eto_2024_central_reference',
  EPLUS_ETO_2024_ZONAL_DESIGN = 'eplus_eto_2024_zonal_design',
  EPLUS_ETO_2024_ZONAL_REFERENCE = 'eplus_eto_2024_zonal_reference',
}

export const AnalysisTypeLabels: Record<AnalysisType, string> = {
  [AnalysisType.DEFAULT]: 'Engine Defaults',
  [AnalysisType.HERS_DESIGN]: 'HERS Design',
  [AnalysisType.HERS_REFERENCE]: 'HERS Reference',

  [AnalysisType.UDRH_DESIGN]: 'UDRH Design Building',
  [AnalysisType.UDRH_REFERENCE]: 'UDRH Reference Building',

  [AnalysisType.SWWA_2019_DESIGN]: 'Southwest WA 2019 Design Building',
  [AnalysisType.SWWA_2019_REFERENCE]: 'Southwest WA 2019 Reference Building',

  [AnalysisType.ID_2019_CENTRAL_DESIGN]: 'ID 2019 Central Design Building',
  [AnalysisType.ID_2019_CENTRAL_REFERENCE]:
    'ID 2019 Central Reference Building',

  [AnalysisType.ID_2019_ZONAL_DESIGN]: 'ID 2019 Zonal Design Building',
  [AnalysisType.ID_2019_ZONAL_REFERENCE]: 'ID 2019 Zonal Reference Building',

  [AnalysisType.MT_2019_CENTRAL_DESIGN]: 'MT 2019 Central Design Building',
  [AnalysisType.MT_2019_CENTRAL_REFERENCE]:
    'MT 2019 Central Reference Building',

  [AnalysisType.MT_2019_ZONAL_DESIGN]: 'MT 2019 Zonal Design Building',
  [AnalysisType.MT_2019_ZONAL_REFERENCE]: 'MT 2019 Zonal Reference Building',

  [AnalysisType.OR_2019_CENTRAL_DESIGN]: 'OR 2019 Central Design Building',
  [AnalysisType.OR_2019_CENTRAL_REFERENCE]:
    'OR 2019 Central Reference Building',

  [AnalysisType.OR_2019_ZONAL_DESIGN]: 'OR 2019 Zonal Design Building',
  [AnalysisType.OR_2019_ZONAL_REFERENCE]: 'OR 2019 Zonal Reference Building',

  [AnalysisType.WA_2019_CENTRAL_SMALL_DESIGN]:
    'WA 2019 Central Small Design Building',
  [AnalysisType.WA_2019_CENTRAL_SMALL_REFERENCE]:
    'WA 2019 Central Small Reference Building',
  [AnalysisType.WA_2019_CENTRAL_MEDIUM_DESIGN]:
    'WA 2019 Central Medium Design Building',
  [AnalysisType.WA_2019_CENTRAL_MEDIUM_REFERENCE]:
    'WA 2019 Central Medium Reference Building',
  [AnalysisType.WA_2019_CENTRAL_LARGE_DESIGN]:
    'WA 2019 Central Large Design Building',
  [AnalysisType.WA_2019_CENTRAL_LARGE_REFERENCE]:
    'WA 2019 Central Large Reference Building',

  [AnalysisType.WA_2019_ZONAL_SMALL_DESIGN]:
    'WA 2019 Zonal Small Design Building',
  [AnalysisType.WA_2019_ZONAL_SMALL_REFERENCE]:
    'WA 2019 Zonal Small Reference Building',
  [AnalysisType.WA_2019_ZONAL_MEDIUM_DESIGN]:
    'WA 2019 Zonal Medium Design Building',
  [AnalysisType.WA_2019_ZONAL_MEDIUM_REFERENCE]:
    'WA 2019 Zonal Medium Reference Building',
  [AnalysisType.WA_2019_ZONAL_LARGE_DESIGN]:
    'WA 2019 Zonal Large Design Building',
  [AnalysisType.WA_2019_ZONAL_LARGE_REFERENCE]:
    'WA 2019 Zonal Large Reference Building',

  [AnalysisType.SWWA_2020_DESIGN]: 'Southwest WA 2020 Design Building',
  [AnalysisType.SWWA_2020_REFERENCE]: 'Southwest WA 2020 Reference Building',

  [AnalysisType.ID_2020_CENTRAL_DESIGN]: 'ID 2020 Central Design Building',
  [AnalysisType.ID_2020_CENTRAL_REFERENCE]:
    'ID 2020 Central Reference Building',

  [AnalysisType.ID_2020_ZONAL_DESIGN]: 'ID 2020 Zonal Design Building',
  [AnalysisType.ID_2020_ZONAL_REFERENCE]: 'ID 2020 Zonal Reference Building',

  [AnalysisType.MT_2020_CENTRAL_DESIGN]: 'MT 2020 Central Design Building',
  [AnalysisType.MT_2020_CENTRAL_REFERENCE]:
    'MT 2020 Central Reference Building',

  [AnalysisType.MT_2020_ZONAL_DESIGN]: 'MT 2020 Zonal Design Building',
  [AnalysisType.MT_2020_ZONAL_REFERENCE]: 'MT 2020 Zonal Reference Building',

  [AnalysisType.OR_2020_CENTRAL_DESIGN]: 'OR 2020 Central Design Building',
  [AnalysisType.OR_2020_CENTRAL_REFERENCE]:
    'OR 2020 Central Reference Building',

  [AnalysisType.OR_2020_ZONAL_DESIGN]: 'OR 2020 Zonal Design Building',
  [AnalysisType.OR_2020_ZONAL_REFERENCE]: 'OR 2020 Zonal Reference Building',

  [AnalysisType.WA_2020_CENTRAL_SMALL_DESIGN]:
    'WA 2020 Central Small Design Building',
  [AnalysisType.WA_2020_CENTRAL_SMALL_REFERENCE]:
    'WA 2020 Central Small Reference Building',
  [AnalysisType.WA_2020_CENTRAL_MEDIUM_DESIGN]:
    'WA 2020 Central Medium Design Building',
  [AnalysisType.WA_2020_CENTRAL_MEDIUM_REFERENCE]:
    'WA 2020 Central Medium Reference Building',
  [AnalysisType.WA_2020_CENTRAL_LARGE_DESIGN]:
    'WA 2020 Central Large Design Building',
  [AnalysisType.WA_2020_CENTRAL_LARGE_REFERENCE]:
    'WA 2020 Central Large Reference Building',

  [AnalysisType.WA_2020_ZONAL_SMALL_DESIGN]:
    'WA 2020 Zonal Small Design Building',
  [AnalysisType.WA_2020_ZONAL_SMALL_REFERENCE]:
    'WA 2020 Zonal Small Reference Building',
  [AnalysisType.WA_2020_ZONAL_MEDIUM_DESIGN]:
    'WA 2020 Zonal Medium Design Building',
  [AnalysisType.WA_2020_ZONAL_MEDIUM_REFERENCE]:
    'WA 2020 Zonal Medium Reference Building',
  [AnalysisType.WA_2020_ZONAL_LARGE_DESIGN]:
    'WA 2020 Zonal Large Design Building',
  [AnalysisType.WA_2020_ZONAL_LARGE_REFERENCE]:
    'WA 2020 Zonal Large Reference Building',

  [AnalysisType.ID_2021_CENTRAL_DESIGN]: 'ID 2021 Central Design Building',
  [AnalysisType.ID_2021_CENTRAL_REFERENCE]:
    'ID 2021 Central Reference Building',

  [AnalysisType.ID_2021_ZONAL_DESIGN]: 'ID 2021 Zonal Design Building',
  [AnalysisType.ID_2021_ZONAL_REFERENCE]: 'ID 2021 Zonal Reference Building',

  [AnalysisType.MT_2021_CENTRAL_DESIGN]: 'MT 2021 Central Design Building',
  [AnalysisType.MT_2021_CENTRAL_REFERENCE]:
    'MT 2021 Central Reference Building',

  [AnalysisType.MT_2021_ZONAL_DESIGN]: 'MT 2021 Zonal Design Building',
  [AnalysisType.MT_2021_ZONAL_REFERENCE]: 'MT 2021 Zonal Reference Building',

  [AnalysisType.OR_2021_CENTRAL_DESIGN]: 'OR 2021 Central Design Building',
  [AnalysisType.OR_2021_CENTRAL_REFERENCE]:
    'OR 2021 Central Reference Building',

  [AnalysisType.OR_2021_ZONAL_DESIGN]: 'OR 2021 Zonal Design Building',
  [AnalysisType.OR_2021_ZONAL_REFERENCE]: 'OR 2021 Zonal Reference Building',

  [AnalysisType.OR_2022_CENTRAL_DESIGN]: 'OR 2022 Central Design Building',
  [AnalysisType.OR_2022_CENTRAL_REFERENCE]:
    'OR 2022 Central Reference Building',

  [AnalysisType.OR_2022_ZONAL_DESIGN]: 'OR 2022 Zonal Design Building',
  [AnalysisType.OR_2022_ZONAL_REFERENCE]: 'OR 2022 Zonal Reference Building',

  [AnalysisType.OR_2023_CENTRAL_DESIGN]: 'OR 2023 Central Design Building',
  [AnalysisType.OR_2023_CENTRAL_REFERENCE]:
    'OR 2023 Central Reference Building',

  [AnalysisType.OR_2023_ZONAL_DESIGN]: 'OR 2023 Zonal Design Building',
  [AnalysisType.OR_2023_ZONAL_REFERENCE]: 'OR 2023 Zonal Reference Building',

  [AnalysisType.WA_2021_CENTRAL_SMALL_DESIGN]:
    'WA 2021 Central Small Design Building',
  [AnalysisType.WA_2021_CENTRAL_SMALL_REFERENCE]:
    'WA 2021 Central Small Reference Building',
  [AnalysisType.WA_2021_CENTRAL_MEDIUM_DESIGN]:
    'WA 2021 Central Medium Design Building',
  [AnalysisType.WA_2021_CENTRAL_MEDIUM_REFERENCE]:
    'WA 2021 Central Medium Reference Building',
  [AnalysisType.WA_2021_CENTRAL_LARGE_DESIGN]:
    'WA 2021 Central Large Design Building',
  [AnalysisType.WA_2021_CENTRAL_LARGE_REFERENCE]:
    'WA 2021 Central Large Reference Building',

  [AnalysisType.WA_2021_ZONAL_SMALL_DESIGN]:
    'WA 2021 Zonal Small Design Building',
  [AnalysisType.WA_2021_ZONAL_SMALL_REFERENCE]:
    'WA 2021 Zonal Small Reference Building',
  [AnalysisType.WA_2021_ZONAL_MEDIUM_DESIGN]:
    'WA 2021 Zonal Medium Design Building',
  [AnalysisType.WA_2021_ZONAL_MEDIUM_REFERENCE]:
    'WA 2021 Zonal Medium Reference Building',
  [AnalysisType.WA_2021_ZONAL_LARGE_DESIGN]:
    'WA 2021 Zonal Large Design Building',
  [AnalysisType.WA_2021_ZONAL_LARGE_REFERENCE]:
    'WA 2021 Zonal Large Reference Building',

  [AnalysisType.WA_2021_CENTRAL_SMALL_GHEW_DESIGN]:
    'WA 2021 Central Small GHEW Design Building',
  [AnalysisType.WA_2021_CENTRAL_SMALL_GHEW_REFERENCE]:
    'WA 2021 Central Small GHEW Reference Building',
  [AnalysisType.WA_2021_CENTRAL_MEDIUM_GHEW_DESIGN]:
    'WA 2021 Central Medium GHEW Design Building',
  [AnalysisType.WA_2021_CENTRAL_MEDIUM_GHEW_REFERENCE]:
    'WA 2021 Central Medium GHEW Reference Building',
  [AnalysisType.WA_2021_CENTRAL_LARGE_GHEW_DESIGN]:
    'WA 2021 Central Large GHEW Design Building',
  [AnalysisType.WA_2021_CENTRAL_LARGE_GHEW_REFERENCE]:
    'WA 2021 Central Large GHEW Reference Building',

  [AnalysisType.WA_2021_ZONAL_SMALL_EHEW_DESIGN]:
    'WA 2021 Zonal Small EHEW Design Building',
  [AnalysisType.WA_2021_ZONAL_SMALL_EHEW_REFERENCE]:
    'WA 2021 Zonal Small EHEW Reference Building',
  [AnalysisType.WA_2021_ZONAL_MEDIUM_EHEW_DESIGN]:
    'WA 2021 Zonal Medium EHEW Design Building',
  [AnalysisType.WA_2021_ZONAL_MEDIUM_EHEW_REFERENCE]:
    'WA 2021 Zonal Medium EHEW Reference Building',
  [AnalysisType.WA_2021_ZONAL_LARGE_EHEW_DESIGN]:
    'WA 2021 Zonal Large EHEW Design Building',
  [AnalysisType.WA_2021_ZONAL_LARGE_EHEW_REFERENCE]:
    'WA 2021 Zonal Large EHEW Reference Building',

  [AnalysisType.WA_2021_ZONAL_SMALL_EHGW_DESIGN]:
    'WA 2021 Zonal Small EHGW Design Building',
  [AnalysisType.WA_2021_ZONAL_SMALL_EHGW_REFERENCE]:
    'WA 2021 Zonal Small EHGW Reference Building',
  [AnalysisType.WA_2021_ZONAL_MEDIUM_EHGW_DESIGN]:
    'WA 2021 Zonal Medium EHGW Design Building',
  [AnalysisType.WA_2021_ZONAL_MEDIUM_EHGW_REFERENCE]:
    'WA 2021 Zonal Medium EHGW Reference Building',
  [AnalysisType.WA_2021_ZONAL_LARGE_EHGW_DESIGN]:
    'WA 2021 Zonal Large EHGW Design Building',
  [AnalysisType.WA_2021_ZONAL_LARGE_EHGW_REFERENCE]:
    'WA 2021 Zonal Large EHGW Reference Building',

  [AnalysisType.WA_2021_CENTRAL_SMALL_GHGW_DESIGN]:
    'WA 2021 Central Small GHGW Design Building',
  [AnalysisType.WA_2021_CENTRAL_SMALL_GHGW_REFERENCE]:
    'WA 2021 Central Small GHGW Reference Building',
  [AnalysisType.WA_2021_CENTRAL_MEDIUM_GHGW_DESIGN]:
    'WA 2021 Central Medium GHGW Design Building',
  [AnalysisType.WA_2021_CENTRAL_MEDIUM_GHGW_REFERENCE]:
    'WA 2021 Central Medium GHGW Reference Building',
  [AnalysisType.WA_2021_CENTRAL_LARGE_GHGW_DESIGN]:
    'WA 2021 Central Large GHGW Design Building',
  [AnalysisType.WA_2021_CENTRAL_LARGE_GHGW_REFERENCE]:
    'WA 2021 Central Large GHGW Reference Building',

  [AnalysisType.WA_2021_CENTRAL_SMALL_EHGW_DESIGN]:
    'WA 2021 Central Small EHGW Design Building',
  [AnalysisType.WA_2021_CENTRAL_SMALL_EHGW_REFERENCE]:
    'WA 2021 Central Small EHGW Reference Building',
  [AnalysisType.WA_2021_CENTRAL_MEDIUM_EHGW_DESIGN]:
    'WA 2021 Central Medium EHGW Design Building',
  [AnalysisType.WA_2021_CENTRAL_MEDIUM_EHGW_REFERENCE]:
    'WA 2021 Central Medium EHGW Reference Building',
  [AnalysisType.WA_2021_CENTRAL_LARGE_EHGW_DESIGN]:
    'WA 2021 Central Large EHGW Design Building',
  [AnalysisType.WA_2021_CENTRAL_LARGE_EHGW_REFERENCE]:
    'WA 2021 Central Large EHGW Reference Building',

  [AnalysisType.WA_2021_CENTRAL_SMALL_EHEW_DESIGN]:
    'WA 2021 Central Small EHEW Design Building',
  [AnalysisType.WA_2021_CENTRAL_SMALL_EHEW_REFERENCE]:
    'WA 2021 Central Small EHEW Reference Building',
  [AnalysisType.WA_2021_CENTRAL_MEDIUM_EHEW_DESIGN]:
    'WA 2021 Central Medium EHEW Design Building',
  [AnalysisType.WA_2021_CENTRAL_MEDIUM_EHEW_REFERENCE]:
    'WA 2021 Central Medium EHEW Reference Building',
  [AnalysisType.WA_2021_CENTRAL_LARGE_EHEW_DESIGN]:
    'WA 2021 Central Large EHEW Design Building',
  [AnalysisType.WA_2021_CENTRAL_LARGE_EHEW_REFERENCE]:
    'WA 2021 Central Large EHEW Reference Building',

  [AnalysisType.IECC1998_DESIGN]: '1998 IECC Code Design Building',
  [AnalysisType.IECC1998_REFERENCE]: '1998 IECC Code Reference Building',
  [AnalysisType.IECC2000_DESIGN]: '2000 IECC Code Design Building',
  [AnalysisType.IECC2000_REFERENCE]: '2000 IECC Code Reference Building',
  [AnalysisType.IECC2001_DESIGN]: '2001 IECC Code Design Building',
  [AnalysisType.IECC2001_REFERENCE]: '2001 IECC Code Reference Building',
  [AnalysisType.IECC2003_DESIGN]: '2003 IECC Code Design Building',
  [AnalysisType.IECC2003_REFERENCE]: '2003 IECC Code Reference Building',
  [AnalysisType.IECC2004_DESIGN]: '2004 IECC Code Design Building',
  [AnalysisType.IECC2004_REFERENCE]: '2004 IECC Code Reference Building',
  [AnalysisType.IECC2006_DESIGN]: '2006 IECC Code Design Building',
  [AnalysisType.IECC2006_REFERENCE]: '2006 IECC Code Reference Building',
  [AnalysisType.IECC2006_REFERENCE_MIN]:
    '2006 IECC Code Reference Building Federal Min',
  [AnalysisType.IECC2009_DESIGN]: '2009 IECC Code Design Building',
  [AnalysisType.IECC2009_REFERENCE]: '2009 IECC Code Reference Building',
  [AnalysisType.IECC2009_REFERENCE_MIN]:
    '2009 IECC Code Reference Building Federal Min',
  [AnalysisType.IECC2012_DESIGN]: '2012 IECC Code Design Building',
  [AnalysisType.IECC2012_REFERENCE]: '2012 IECC Code Reference Building',
  [AnalysisType.IECC2012_REFERENCE_MIN]:
    '2012 IECC Code Reference Building Federal Min',
  [AnalysisType.IECC2015_DESIGN]: '2015 IECC Code Design Building',
  [AnalysisType.IECC2015_REFERENCE]: '2015 IECC Code Reference Building',
  [AnalysisType.IECC2015_REFERENCE_MIN]:
    '2015 IECC Code Reference Building Federal Min',
  [AnalysisType.IECC2018_DESIGN]: '2018 IECC Code Design Building',
  [AnalysisType.IECC2018_REFERENCE]: '2018 IECC Code Reference Building',
  [AnalysisType.IECC2018_REFERENCE_ERI]:
    '2018 IECC Code ERI Reference Building',
  [AnalysisType.IECC2021_DESIGN]: '2021 IECC Code Design Building',
  [AnalysisType.IECC2021_REFERENCE]: '2021 IECC Code Reference Building',
  [AnalysisType.IECC2021_REFERENCE_ERI]:
    '2021 IECC Code ERI Reference Building',
  [AnalysisType.IECC2024_DESIGN]: '2024 IECC Code Design Building',
  [AnalysisType.IECC2024_REFERENCE]: '2024 IECC Code Reference Building',
  [AnalysisType.IECC2024_REFERENCE_ERI]:
    '2024 IECC Code ERI Reference Building',

  [AnalysisType.ENERGY_STAR_V2_DESIGN]: 'ENERGY STAR V2 Code Design Building',
  [AnalysisType.ENERGY_STAR_V2_REFERENCE]:
    'ENERGY STAR V2 Code Reference Building',
  [AnalysisType.ENERGY_STAR_V2p5_DESIGN]:
    'ENERGY STAR V2.5 Code Design Building',
  [AnalysisType.ENERGY_STAR_V2p5_REFERENCE]:
    'ENERGY STAR V2.5 Code Reference Building',
  [AnalysisType.ENERGY_STAR_V3_DESIGN]: 'ENERGY STAR V3 Code Design Building',
  [AnalysisType.ENERGY_STAR_V3_REFERENCE]:
    'ENERGY STAR V3 Code Reference Building',
  [AnalysisType.ENERGY_STAR_V3_PAC_DESIGN]:
    'ENERGY STAR V3 Pacific Code Design Building',
  [AnalysisType.ENERGY_STAR_V3_PAC_REFERENCE]:
    'ENERGY STAR V3 Pacific Code Reference Building',
  [AnalysisType.ENERGY_STAR_V3p1_FL_DESIGN]:
    'ENERGY STAR V3.1 FL Code Design Building',
  [AnalysisType.ENERGY_STAR_V3p1_FL_REFERENCE]:
    'ENERGY STAR V3.1 FL Code Reference Building',
  [AnalysisType.ENERGY_STAR_V3p1_DESIGN]:
    'ENERGY STAR V3.1 Code Design Building',
  [AnalysisType.ENERGY_STAR_V3p1_REFERENCE]:
    'ENERGY STAR V3.1 Code Reference Building',
  [AnalysisType.ENERGY_STAR_V3p2_DESIGN]:
    'ENERGY STAR V3.2 Code Design Building',
  [AnalysisType.ENERGY_STAR_V3p2_REFERENCE]:
    'ENERGY STAR V3.2 Code Reference Building',
  [AnalysisType.ENERGY_STAR_V3p2_ORWA_DESIGN]:
    'ENERGY STAR V3.2 OR/WA Code Design Building',
  [AnalysisType.ENERGY_STAR_V3p2_ORWA_REFERENCE]:
    'ENERGY STAR V3.2 OR/WA Code Reference Building',
  [AnalysisType.ENERGY_STAR_V1_MF_DESIGN]:
    'ENERGY STAR V1.0 MF Design Building',
  [AnalysisType.ENERGY_STAR_V1_MF_REFERENCE]:
    'ENERGY STAR V1.0 MF Reference Building',
  [AnalysisType.ENERGY_STAR_V1p1_MF_DESIGN]:
    'ENERGY STAR V1.1 MF Design Building',
  [AnalysisType.ENERGY_STAR_V1p1_MF_REFERENCE]:
    'ENERGY STAR V1.1 MF Reference Building',
  [AnalysisType.ENERGY_STAR_V1p2_MF_DESIGN]:
    'ENERGY STAR V1.2 MF Design Building',
  [AnalysisType.ENERGY_STAR_V1p2_MF_REFERENCE]:
    'ENERGY STAR V1.2 MF Reference Building',
  [AnalysisType.ENERGY_STAR_V1p2_MF_ORWA_DESIGN]:
    'ENERGY STAR V1.2 MF OR/WA Design Building',
  [AnalysisType.ENERGY_STAR_V1p2_MF_ORWA_REFERENCE]:
    'ENERGY STAR V1.2 MF OR/WA Reference Building',
  [AnalysisType.TAX_CREDIT_DESIGN]: 'Tax Credit Design Building',
  [AnalysisType.TAX_CREDIT_REFERENCE]: 'Tax Credit Reference Building',
  [AnalysisType.TAX_CREDIT_REFERENCE_NO_ENV]:
    'Tax Credit Reference Building (No Environment)',
  [AnalysisType.FE_PA_SAVINGS_DESIGN]: 'FE PA Savings Design Building',
  [AnalysisType.FE_PA_SAVINGS_REFERENCE]: 'FE PA Savings Reference Building',
  [AnalysisType.FE_PA_NEW_HOMES_DESIGN]: 'FE PA New Homes Design Building',
  [AnalysisType.FE_PA_NEW_HOMES_REFERENCE]:
    'FE PA New Homes Reference Building',
  [AnalysisType.DOE_ZERO_ENERGY_DESIGN]: 'DOE Zero Energy Design Building',
  [AnalysisType.DOE_ZERO_ENERGY_REFERENCE]:
    'DOE Zero Energy Reference Building',
  [AnalysisType.DOE_ZERH_V2p0_DESIGN]: 'DOE ZERH V2.0 Design Building',
  [AnalysisType.DOE_ZERH_V2p0_REFERENCE]: 'DOE ZERH V2.0 Reference Building',
  [AnalysisType.DOE_ZERH_V2p0_MF_DESIGN]: 'DOE ZERH V2.0 MF Design Building',
  [AnalysisType.DOE_ZERH_V2p0_MF_REFERENCE]:
    'DOE ZERH V2.0 MF Reference Building',
  [AnalysisType.DOE_ZERH_V1_DESIGN]: 'DOE ZERH V1.0 Design Building',
  [AnalysisType.DOE_ZERH_V1_REFERENCE]: 'DOE ZERH V1.0 Reference Building',
  [AnalysisType.NGBS_2015_DESIGN]: 'NGBS 2015 Design Building',
  [AnalysisType.NGBS_2015_REFERENCE]: 'NGBS 2015 Reference Building',
  [AnalysisType.NGBS_2020_DESIGN]: 'NGBS 2020 Design Building',
  [AnalysisType.NGBS_2020_REFERENCE]: 'NGBS 2020 Reference Building',
  [AnalysisType.OS_ERI_2014AEG_DESIGN]: 'OS ERI 2014 AEG Design Building',
  [AnalysisType.OS_ERI_2014AEG_REFERENCE]: 'OS ERI 2014 AEG Reference Building',
  [AnalysisType.OS_ERI_2014AE_DESIGN]: 'OS ERI 2014 AE Design Building',
  [AnalysisType.OS_ERI_2014AE_REFERENCE]: 'OS ERI 2014 AE Reference Building',
  [AnalysisType.OS_ERI_2014A_DESIGN]: 'OS ERI 2014 A Design Building',
  [AnalysisType.OS_ERI_2014A_REFERENCE]: 'OS ERI 2014 A Reference Building',
  [AnalysisType.OS_ERI_2014_DESIGN]: 'OS ERI 2014 Design Building',
  [AnalysisType.OS_ERI_2014_REFERENCE]: 'OS ERI 2014 Reference Building',
  [AnalysisType.OS_ERI_2022C_DESIGN]: 'OS ERI 2022C Design Building',
  [AnalysisType.OS_ERI_2022C_REFERENCE]: 'OS ERI 2022C Reference Building',
  [AnalysisType.OS_ERI_2022_DESIGN]: 'OS ERI 2022 Design Building',
  [AnalysisType.OS_ERI_2022_REFERENCE]: 'OS ERI 2022 Reference Building',
  [AnalysisType.OS_ERI_2019ABCD_DESIGN]: 'OS ERI 2019ABCD Design Building',
  [AnalysisType.OS_ERI_2019ABCD_REFERENCE]:
    'OS ERI 2019ABCD Reference Building',
  [AnalysisType.OS_ERI_2019AB_DESIGN]: 'OS ERI 2019AB Design Building',
  [AnalysisType.OS_ERI_2019AB_REFERENCE]: 'OS ERI 2019AB Reference Building',
  [AnalysisType.OS_ERI_2019A_DESIGN]: 'OS ERI 2019A Design Building',
  [AnalysisType.OS_ERI_2019A_REFERENCE]: 'OS ERI 2019A Reference Building',
  [AnalysisType.OS_ERI_2019_DESIGN]: 'OS ERI 2019 Design Building',
  [AnalysisType.OS_ERI_2019_REFERENCE]: 'OS ERI 2019 Reference Building',
  [AnalysisType.OS_ERI_2022C_CO2_DESIGN]: 'OS ERI 2022C CO2 Design Building',
  [AnalysisType.OS_ERI_2022C_CO2_REFERENCE]:
    'OS ERI 2022C CO2 Reference Building',
  [AnalysisType.OS_ERI_2022_CO2_DESIGN]: 'OS ERI 2022 CO2 Design Building',
  [AnalysisType.OS_ERI_2022_CO2_REFERENCE]:
    'OS ERI 2022 CO2 Reference Building',
  [AnalysisType.OS_ERI_2019ABCD_CO2_DESIGN]:
    'OS ERI 2019ABCD CO2 Design Building',
  [AnalysisType.OS_ERI_2019ABCD_CO2_REFERENCE]:
    'OS ERI 2019ABCD CO2 Reference Building',
  [AnalysisType.EPLUS_ETO_2024_DUCTS_INSIDE_DESIGN]:
    'EPLUS ETO 2024 Ducts Inside Design Building',
  [AnalysisType.EPLUS_ETO_2024_DUCTS_INSIDE_REFERENCE]:
    'EPLUS ETO 2024 Ducts Inside Reference Building',
  [AnalysisType.EPLUS_ETO_2024_DUCTS_DEEP_DESIGN]:
    'EPLUS ETO 2024 Ducts Deep Design Building',
  [AnalysisType.EPLUS_ETO_2024_DUCTS_DEEP_REFERENCE]:
    'EPLUS ETO 2024 Ducts Deep Reference Building',
  [AnalysisType.EPLUS_ETO_2024_CENTRAL_DESIGN]:
    'EPLUS ETO 2024 Central Design Building',
  [AnalysisType.EPLUS_ETO_2024_CENTRAL_REFERENCE]:
    'EPLUS ETO 2024 Central Reference Building',
  [AnalysisType.EPLUS_ETO_2024_ZONAL_DESIGN]:
    'EPLUS ETO 2024 Zonal Design Building',
  [AnalysisType.EPLUS_ETO_2024_ZONAL_REFERENCE]:
    'EPLUS ETO 2024 Zonal Reference Building',
};

export interface AnalysisListItem {
  id: number;
  engine: AnalysisEngine;
  type: AnalysisType;
  valid: boolean;
  version: string;
  simulation_date: string;
  energy_rating_index: number;
  eri_score: number;
  costs: {
    id: number;
    annual_heating_cost: number;
    annual_cooling_cost: number;
    annual_hot_water_cost: number;
    annual_light_and_appliances_cost: number;
    annual_generation: number;
    total_annual_costs: number;
    total_annual_costs_with_generation: number;
  };
  consumption: {
    total_heating_consumption: number;
    total_cooling_consumption: number;
    total_hot_water_consumption: number;
    total_light_and_appliances_consumption: number;
    total_onsite_generation: number;
    total_energy_consumption: number;
  };
  urls: {
    html_results_document: string;
    open_studio_hpxml: string;
  };
  result: any;
  status: string;
  source_name: string;
  source_qualifier: string;
  eri_score_no_pv: number;
  simulation_datetime: Date;
  summary: AnalysisSummary;
  fuel_usages?: FuelUsage[];
  compliance?: Compliance;
  component_loads?: ComponentLoads;
  emissions?: Emissions;
}

interface AnalysisSummary {
  id: number;
  heating_consumption: number;
  cooling_consumption: number;
  water_heating_consumption: number;
  lighting_and_appliances_consumption: number;
  solar_generation: number;
  total_consumption_no_pv: number;
  total_consumption: number;
  consumption_units: string;
  heating_cost: number;
  cooling_cost: number;
  water_heating_cost: number;
  lighting_and_appliances_cost: number;
  service_charges: number;
  solar_generation_savings: number;
  total_cost_no_pv: number;
  total_cost: number;
  cost_units: string;
  total_winter_electric_power_peak: number;
  total_summer_electric_power_peak: number;
  power_peak_units: string;
}

export interface FuelUsage {
  id: number;
  fuel: string;
  cost: number;
  heating_consumption: number;
  cooling_consumption: number;
  water_heating_consumption: number;
  lighting_and_appliances_consumption: number;
  total_consumption: number;
  consumption_units: string;
  cost_units: string;
}

interface Compliance {
  id: number;
  ashrae_62p2_2010: boolean | null;
  ashrae_62p2_2013: boolean | null;
  doe_zero_energy_ready: boolean;
  energy_star_v2: boolean;
  energy_star_v2p5: boolean;
  energy_star_v3: boolean;
  energy_star_v3p1: boolean;
  energy_star_v3p2: boolean;
  energy_star_v1p0_mf: boolean | null;
  energy_star_v1p1_mf: boolean | null;
  energy_star_v1p2_mf: boolean | null;
  iecc_2004_performance: boolean;
  iecc_2004_prescriptive: boolean;
  iecc_2006_performance: boolean;
  iecc_2006_prescriptive: boolean;
  iecc_2009_performance: boolean;
  iecc_2009_prescriptive: boolean;
  iecc_2012_performance: boolean;
  iecc_2012_prescriptive: boolean;
  iecc_2015_eri: boolean | null;
  iecc_2015_performance: boolean;
  iecc_2015_prescriptive: boolean;
  iecc_2018_eri: boolean | null;
  iecc_2018_performance: boolean;
  iecc_2018_prescriptive: boolean;
  iecc_2021_eri: boolean | null;
  iecc_2021_performance: boolean | null;
  iecc_2021_prescriptive: boolean | null;
  indoor_air_plus: boolean | null;
  lead_source_energy_budget: boolean | null;
  ngbs_2015: boolean | null;
  ngbs_2020: boolean | null;
  ngbs_2020_eri: boolean | null;
  nc_2012_performance: boolean;
  nc_2012_prescriptive: boolean;
  srp: boolean | null;
  tax_credit_45l: boolean | null;
}

export interface Emissions {
  id: number;
  heating_co2: number;
  heating_nox: number;
  heating_so2: number;

  cooling_co2: number;
  cooling_nox: number;
  cooling_so2: number;

  water_heating_co2: number;
  water_heating_nox: number;
  water_heating_so2: number;

  lighting_and_appliances_co2: number;
  lighting_and_appliances_nox: number;
  lighting_and_appliances_so2: number;

  pv_offset_co2: number;
  pv_offset_nox: number;
  pv_offset_so2: number;

  total_co2: number;
  total_nox: number;
  total_so2: number;
}

export interface ComponentLoads {
  id: number;
  heating_roofs: number;
  heating_ceilings: number;
  heating_above_ground_walls: number;
  heating_rim_joists: number;
  heating_foundation_walls: number;
  heating_doors: number;
  heating_windows_solar: number | null;
  heating_windows_conduction: number | null;
  heating_windows: number;
  heating_skylights_solar: number | null;
  heating_skylights_conduction: number | null;
  heating_skylights: number;
  heating_frame_floors: number;
  heating_slabs: number;
  heating_internal_mass: number;
  heating_infiltration: number;
  heating_natural_ventilation: number;
  heating_mechanical_ventilation: number;
  heating_whole_house_fan: number;
  heating_ducts: number;
  heating_sunspace: number | null;
  heating_internal_gains: number;
  heating_lighting: number;
  cooling_roofs: number;
  cooling_ceilings: number;
  cooling_above_ground_walls: number;
  cooling_rim_joists: number;
  cooling_foundation_walls: number;
  cooling_doors: number;
  cooling_windows_solar: number | null;
  cooling_windows_conduction: number | null;
  cooling_windows: number;
  cooling_skylights_solar: number | null;
  cooling_skylights_conduction: number | null;
  cooling_skylights: number;
  cooling_frame_floors: number;
  cooling_slabs: number;
  cooling_internal_mass: number;
  cooling_infiltration: number;
  cooling_natural_ventilation: number;
  cooling_mechanical_ventilation: number;
  cooling_whole_house_fan: number;
  cooling_ducts: number;
  cooling_sunspace: number | null;
  cooling_internal_gains: number;
  cooling_lighting: number;
  load_units: string;
}

export interface AnalysisBackendDict {
  id: number;
  type: string;
  source_name: string;
  source_qualifier: string;
  eri_score: number;
  eri_score_no_pv: number;
  engine: string;
  version: string;
  simulation_datetime: Date;
  summary: AnalysisSummary;
  fuel_usages?: FuelUsage[];
  compliance?: Compliance;
  component_loads?: ComponentLoads;
  emissions?: Emissions;
}

export enum ERICalculationChoices {
  ERI2014 = '2014',
  ERI2014A = '2014A',
  ERI2014AE = '2014AE',
  ERI2014AEG = '2014AEG',
  ERI2019 = '2019',
  ERI2019A = '2019A',
  ERI2019AB = '2019AB',
  ERI2019ABCD = '2019ABCD',
  ERI2022 = '2022',
  ERI2022C = '2022C',
  LATEST = 'latest',
}

export const ERICalculationChoicesLabels: Record<
  ERICalculationChoices,
  string
> = {
  [ERICalculationChoices.ERI2014]: '2014',
  [ERICalculationChoices.ERI2014A]: '2014A',
  [ERICalculationChoices.ERI2014AE]: '2014AE',
  [ERICalculationChoices.ERI2014AEG]: '2014AEG',
  [ERICalculationChoices.ERI2019]: '2019',
  [ERICalculationChoices.ERI2019A]: '2019A',
  [ERICalculationChoices.ERI2019AB]: '2019AB',
  [ERICalculationChoices.ERI2019ABCD]: '2019ABCD with CO2 Index',
  [ERICalculationChoices.ERI2022]: '2022 with CO2 Index',
  [ERICalculationChoices.ERI2022C]: '2022C with CO2 Index',
  [ERICalculationChoices.LATEST]: 'Latest with CO2 Index',
};

export enum CO2CalculationChoices {
  CO2019ABCD = '2019ABCD',
  CO2022 = '2022',
  CO2022C = '2022C',
  LATEST = 'latest',
}

export const CO2CalculationChoicesLabels: Record<
  CO2CalculationChoices,
  string
> = {
  [CO2CalculationChoices.CO2019ABCD]: '2019ABCD',
  [CO2CalculationChoices.CO2022]: '2022',
  [CO2CalculationChoices.CO2022C]: '2022C',
  [CO2CalculationChoices.LATEST]: 'Latest',
};

export enum IECCCalculationChoices {
  IECC2015 = '2015',
  IECC2018 = '2018',
  IECC2021 = '2021',
  IECC2024 = '2024',
}

export const IECCCalculationChoicesLabels: Record<
  IECCCalculationChoices,
  string
> = {
  [IECCCalculationChoices.IECC2015]: '2015',
  [IECCCalculationChoices.IECC2018]: '2018',
  [IECCCalculationChoices.IECC2021]: '2021',
  [IECCCalculationChoices.IECC2024]: '2024',
};

export enum ESTARCalculationChoices {
  ESTARSFNAT3p2 = 'SF_National_3.2',
  ESTARSFNAT3p1 = 'SF_National_3.1',
  ESTARSFNAT3p0 = 'SF_National_3.0',
  ESTARSFPAC3p0 = 'SF_Pacific_3.0',
  ESTARSFFL3p1 = 'SF_Florida_3.1',
  ESTARSFORWA3p2 = 'SF_OregonWashington_3.2',
  ESTARMFNAT1p2 = 'MF_National_1.2',
  ESTARMFNAT1p1 = 'MF_National_1.1',
  ESTARMFNAT1p0 = 'MF_National_1.0',
  ESTARMFORWA1p0 = 'MF_OregonWashington_1.2',
}

export const ESTARCalculationChoicesLabels: Record<
  ESTARCalculationChoices,
  string
> = {
  [ESTARCalculationChoices.ESTARSFNAT3p2]: 'Single Family National 3.2',
  [ESTARCalculationChoices.ESTARSFNAT3p1]: 'Single Family National 3.1',
  [ESTARCalculationChoices.ESTARSFNAT3p0]: 'Single Family National 3.0',
  [ESTARCalculationChoices.ESTARSFPAC3p0]: 'Single Family Pacific 3.0',
  [ESTARCalculationChoices.ESTARSFFL3p1]: 'Single Family Florida 3.1',
  [ESTARCalculationChoices.ESTARSFORWA3p2]: 'SF OR/WA 3.2',
  [ESTARCalculationChoices.ESTARMFNAT1p2]: 'Multi-Family National 1.2',
  [ESTARCalculationChoices.ESTARMFNAT1p1]: 'Multi-Family National 1.1',
  [ESTARCalculationChoices.ESTARMFNAT1p0]: 'Multi-Family National 1.0',
  [ESTARCalculationChoices.ESTARMFORWA1p0]: 'Multi-Family OR/WA 1.2',
};

export enum ZERHCalculationChoices {
  MF2p0 = 'MF_2.0',
  SF2p0 = 'SF_2.0',
  SF1p0 = '1.0',
}

export const ZERHCalculationChoicesLabels: Record<
  ZERHCalculationChoices,
  string
> = {
  [ZERHCalculationChoices.MF2p0]: 'Multi Family 2.0',
  [ZERHCalculationChoices.SF2p0]: 'Single Family 2.0',
  [ZERHCalculationChoices.SF1p0]: 'Single Family 1.0',
};

export enum UDRHCalculationChoices {
  ETO2024DuctsInside = 'ETO_2024_Ducts_Inside',
  ETO2024DuctsDeeplyBuried = 'ETO_2024_Ducts_Deep',
}

export const UDRHCalculationChoicesLabels: Record<
  UDRHCalculationChoices,
  string
> = {
  [UDRHCalculationChoices.ETO2024DuctsInside]:
    'Energy Trust of Oregon 2024 - Ducts Inside',
  [UDRHCalculationChoices.ETO2024DuctsDeeplyBuried]:
    'Energy Trust of Oregon 2024 - Ducts Deeply Buried',
};

export enum OpenStudioAnalysisSourceName {
  ERI_CALCULATION = 'ERICalculation',
  CO2_CALCULATION = 'CO2IndexCalculation',
  IECC_CALCULATION = 'IECCERICalculation',
  ESTAR_CALCULATION = 'EnergyStarCalculation',
  ZERH_CALCULATION = 'ZERHCalculation',
  UDRH_CALCULATION = 'UDRHCalculation',
}

export const analysisSourceNameShrinker: Record<
  OpenStudioAnalysisSourceName,
  (name: string) => string
> = {
  [OpenStudioAnalysisSourceName.ERI_CALCULATION]: (name: string) =>
    name.replace(/^Open Studio ERI /g, ''),
  [OpenStudioAnalysisSourceName.CO2_CALCULATION]: (name: string) =>
    name.replace(/^Open Studio CO2 (.*) Index/g, '$1'),
  [OpenStudioAnalysisSourceName.IECC_CALCULATION]: (name: string) =>
    name.replace(/IECC Code$/g, ''),
  [OpenStudioAnalysisSourceName.ESTAR_CALCULATION]: (name: string) =>
    name.replace(/^ENERGY STAR /g, ''),
  [OpenStudioAnalysisSourceName.ZERH_CALCULATION]: (name: string) =>
    name.replace(/(.*) Zero Energy Ready Home (.*)/g, '$1 $2'),
  [OpenStudioAnalysisSourceName.UDRH_CALCULATION]: (name: string) =>
    name.replace(/^UDRH /g, ''),
};

export const OpenStudioAnalysisSourceNameLabels: Record<
  OpenStudioAnalysisSourceName,
  string
> = {
  [OpenStudioAnalysisSourceName.ERI_CALCULATION]: 'ERI',
  [OpenStudioAnalysisSourceName.CO2_CALCULATION]: 'CO2 Index',
  [OpenStudioAnalysisSourceName.IECC_CALCULATION]: 'IECC',
  [OpenStudioAnalysisSourceName.ESTAR_CALCULATION]: 'ENERGY STAR',
  [OpenStudioAnalysisSourceName.ZERH_CALCULATION]: 'ZERH',
  [OpenStudioAnalysisSourceName.UDRH_CALCULATION]: 'UDRH',
};

export const OpenStudioAnalysisConfigWithLabels = {
  [OpenStudioAnalysisSourceName.ERI_CALCULATION]: ERICalculationChoicesLabels,
  [OpenStudioAnalysisSourceName.CO2_CALCULATION]: CO2CalculationChoicesLabels,
  [OpenStudioAnalysisSourceName.IECC_CALCULATION]: IECCCalculationChoicesLabels,
  [OpenStudioAnalysisSourceName.ESTAR_CALCULATION]:
    ESTARCalculationChoicesLabels,
  [OpenStudioAnalysisSourceName.ZERH_CALCULATION]: ZERHCalculationChoicesLabels,
  [OpenStudioAnalysisSourceName.UDRH_CALCULATION]: UDRHCalculationChoicesLabels,
};

export interface AnalysisDataTypeBackendDict {
  name: string;
  slug: string;

  rem_id?: number;
  rem_checksum_data?: string;
  rem_udrh_name_data?: string;

  eko_building_type?: string;
  eko_requested: boolean;
}

export interface AnalysisSuiteBackendDict {
  id: number;
  name: string;
  design: AnalysisDataTypeBackendDict;
  reference: AnalysisDataTypeBackendDict;
  eplus_images: EplusImage[];
  calculation_type: OpenStudioAnalysisSourceName;
  calculation_version: string;
  slug: string;
  is_default: boolean;
}

export interface EplusImage {
  id: number;
  major: number;
  minor: number;
  patch: number;
  image_type: string;
  image_name: string;
  image_id: string;
  image_checksum: string;
  preview_release: boolean;
  latest: boolean;
}
