<mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header class="headers-align">
        <mat-panel-title>
            <strong>
                [{{analysisEngineLabels[entity.engine]}}]&nbsp; {{analysisTypeLabels[entity.type]}}
            </strong>
        </mat-panel-title>
        <mat-panel-description>
            <div class="d-flex justify-content-between w-100">
                <div>
                    {{entity.simulation_date | date:'medium'}}
                </div>
                <div class="result-changes" >
                    <span class="font-weight-bold" *ngIf="entity.eri_score">ERI Score: {{entity.eri_score | number:'1.2-2'}}</span>
                    <span class="badge" *ngIf="change && entity.status === 'complete'" [ngClass]="{'badge-success': change > 0, 'badge-danger': change < 0}">{{ change > 0 ? '+' : ''}}{{change | number: '1.2-2'}}%</span>
                    <mat-icon [class]="statusIcon + '-icon'">{{statusIcon}}</mat-icon>
                </div>
            </div>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>

        <mat-card *ngIf="entity">
            <mat-card-content>
                <div class="report-container" *ngIf="entity.status === 'complete'">
                    <app-analysis-summary [entity]="entity"></app-analysis-summary>
                    <app-emissions [entity]="detailedAnalysis.emissions" *ngIf="detailedAnalysis.emissions"></app-emissions>
                    <app-component-loads [entity]="detailedAnalysis.component_loads"
                        *ngIf="detailedAnalysis.component_loads"></app-component-loads>
                    <app-fuel-usage [fuelUsages]="detailedAnalysis.fuel_usages"
                        *ngIf="detailedAnalysis.fuel_usages"></app-fuel-usage>
                </div>

                <div class="pending-report-container" *ngIf="entity.status === 'pending' || entity.status === 'started'">
                    <i *ngIf="entity.status === 'pending'" class="queue">Is in queue!</i>
                    <strong *ngIf="entity.status === 'started'">Analysis Running!</strong>
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>

                <div *ngIf="entity.status === 'failed'">
                    <p>Analysis failed!</p>
                    <pre><code>{{entity.result}}</code></pre>
                </div>

            </mat-card-content>
            <mat-card-actions *ngIf="entity.status === 'complete'">
                <a *ngIf="entity.engine === eplusEngine && entity.urls.html_results_document" color="accent" mat-raised-button href="{{ entity.urls.html_results_document }}">
                    Download Results Document<mat-icon>download</mat-icon>
                </a>
                <a color="accent" *ngIf="entity.engine === eplusEngine && entity.urls.open_studio_hpxml" mat-raised-button href="{{ entity.urls.open_studio_hpxml }}">
                    Download OpenStudio HPXML<mat-icon>download</mat-icon>
                </a>
            </mat-card-actions>
        </mat-card>
    </ng-template>
</mat-expansion-panel>
