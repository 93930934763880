export class ESGReportList {
  id: number;
  home: number;
  floorplan: number;
  floorplan_name: string;
  subdivision_id?: number;
  eep_program: number;
  address: string;
  eep_program_name: string;
  subdivision_name?: string;
  state: string;
  eri_score?: number;
  co2?: number;
  co2_savings?: number;
}
