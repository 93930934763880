import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { FieldLabels } from '../base';
import { enumValidator } from '@/modules/simulation/validtaors/helper';
import { FuelType } from '@/data/simulation/enumerations';

// Labels
export const FIELD_LABELS: FieldLabels<WaterHeaterBackendDict> = {
  name: 'Name',
  style: 'Style',
  tank_size: 'Tank Size',
  tank_units: 'Tank Units',
  fuel: 'Fuel',
  efficiency: 'Efficiency',
  efficiency_unit: 'Efficiency Unit',
  // capacity: 'Capacity',
  // capacity_unit: 'Capacity Unit',
  // first_hour_rating: 'First Hour Rating',
  ahri_reference_number: 'AHRI Reference Number',
  recovery_efficiency: 'Recovery Efficiency',
  extra_tank_insulation_r_value: 'Extra Tank Insulation R-Value',
  note: 'Note',
  is_heat_pump: 'Is Heat Pump',
};

export enum WaterHeaterStyle {
  CONVENTIONAL = 'conventional',
  TANKLESS = 'tankless',
  AIR_SOURCE_HEAT_PUMP = 'ashp',
  GROUND_SOURCE_HEAT_PUMP = 'gshp',
  // INTEGRATED = "integrated",
}

export const WaterHeaterStyleLabels: Record<WaterHeaterStyle, string> = {
  [WaterHeaterStyle.CONVENTIONAL]: 'Conventional',
  [WaterHeaterStyle.TANKLESS]: 'Tankless',
  [WaterHeaterStyle.AIR_SOURCE_HEAT_PUMP]: 'Air Source Heat Pump',
  [WaterHeaterStyle.GROUND_SOURCE_HEAT_PUMP]: 'Ground Source Heat Pump',
  // [WaterHeaterStyle.INTEGRATED]: 'Integrated',
};

export enum HotWaterEfficiencyUnit {
  ENERGY_FACTOR = 'ef',
  UNIFORM_ENERGY_FACTOR = 'uef',
}

export const HotWaterEfficiencyUnitLabels: Record<
  HotWaterEfficiencyUnit,
  string
> = {
  [HotWaterEfficiencyUnit.ENERGY_FACTOR]: 'EF',
  [HotWaterEfficiencyUnit.UNIFORM_ENERGY_FACTOR]: 'UEF',
};

export enum WaterHeaterLiquidVolume {
  GALLON = 'gallon',
  LITER = 'liter',
}

export const WaterHeaterLiquidVolumeLabels: Record<
  WaterHeaterLiquidVolume,
  string
> = {
  [WaterHeaterLiquidVolume.GALLON]: 'Gallon',
  [WaterHeaterLiquidVolume.LITER]: 'Liter',
};

export interface WaterHeaterBackendDict {
  id: number;
  name: string;
  style: WaterHeaterStyle;
  tank_size: number;
  tank_units: WaterHeaterLiquidVolume;
  fuel: FuelType;
  efficiency: number;
  efficiency_unit: HotWaterEfficiencyUnit;
  recovery_efficiency: number;
  // capacity: number,
  // capacity_unit: HeatingCoolingCapacityUnit,
  // first_hour_rating: number,
  extra_tank_insulation_r_value: number;
  ahri_reference_number: number;
  note: string;
  is_heat_pump: boolean;
}

export function createWaterHeaterForm(
  data: WaterHeaterBackendDict
): ModelFormGroup {
  return new ModelFormGroup(
    {
      id: new ModelFormControl(data.id),
      name: new ModelFormControl(data.name, [
        Validators.required,
        Validators.maxLength(128),
      ]),
      style: new ModelFormControl(data.style, enumValidator(WaterHeaterStyle)),
      tank_size: new ModelFormControl(data.tank_size, [
        Validators.required,
        Validators.min(0),
      ]),
      tank_units: new ModelFormControl(
        data.tank_units,
        enumValidator(WaterHeaterLiquidVolume)
      ),
      fuel: new ModelFormControl(data.fuel, enumValidator(FuelType)),
      efficiency: new ModelFormControl(data.efficiency, [
        Validators.required,
        Validators.min(0),
      ]),
      efficiency_unit: new ModelFormControl(
        data.efficiency_unit,
        enumValidator(HotWaterEfficiencyUnit)
      ),
      recovery_efficiency: new ModelFormControl(data.recovery_efficiency, [
        Validators.nullValidator,
        Validators.min(0),
        Validators.max(1),
      ]),
      // capacity: new ModelFormControl(data.capacity, ),
      // capacity_unit: new ModelFormControl(data.capacity_unit, enumValidator(HeatingCoolingCapacityUnit)),
      // first_hour_rating: new ModelFormControl(data.first_hour_rating),
      extra_tank_insulation_r_value: new ModelFormControl(
        data.extra_tank_insulation_r_value,
        [Validators.nullValidator, Validators.min(0)]
      ),
      ahri_reference_number: new ModelFormControl(data.ahri_reference_number),
      note: new ModelFormControl(data.note),
    },
    {
      validators: [
        validateHeatpumpEfficiency,
        validateTankSize,
        vaidateTankunits,
      ],
    }
  );
}

function validateHeatpumpEfficiency(controls: ModelFormGroup) {
  const heatPump = isHeatPump(controls.value);
  const efficiency = controls.get('efficiency').value;

  if (heatPump && (efficiency < 1 || efficiency > 5)) {
    return { invalidEfficiencyHeatPump: true };
  } else if (!heatPump && (efficiency < 0.01 || efficiency > 1)) {
    return { invalidEfficiency: { efficiency } };
  }
}

function validateTankSize(controls: ModelFormGroup) {
  const style = controls.get('style').value;
  const tankSize = controls.get('tank_size').value;
  if (style !== WaterHeaterStyle.TANKLESS && tankSize <= 5) {
    return { tankSizeTooSmall: { tankSize } };
  }
}

function vaidateTankunits(controls: ModelFormGroup) {
  const tankUnits = controls.get('tank_units').value;
  if (tankUnits !== WaterHeaterLiquidVolume.GALLON) {
    return { invalidTankUnits: { tankUnits } };
  }
}

function isHeatPump(waterHeater: WaterHeaterBackendDict): boolean {
  const style = waterHeater.style;
  const efficiency = waterHeater.efficiency;
  const fuel = waterHeater.fuel;

  if (
    style === WaterHeaterStyle.AIR_SOURCE_HEAT_PUMP ||
    style === WaterHeaterStyle.GROUND_SOURCE_HEAT_PUMP
  ) {
    return true;
  } else if (
    style === WaterHeaterStyle.CONVENTIONAL &&
    efficiency >= 1.0 &&
    fuel === FuelType.ELECTRIC
  ) {
    return true;
  }
  return false;
}
