<div mat-dialog-title>
    <span>
        <span *ngIf="!data?.geocodeAddressInfo">Add</span>
        <span *ngIf="data?.geocodeAddressInfo">Edit</span>
        Address <i class="fas fa-map-marker-alt"></i>
    </span>
    <button type="button" class="close" aria-label="Close Address dialog" (click)="handleClose()">
        <span aria-hidden="true">&times;</span>
    </button>
    <hr>
</div>
<div mat-dialog-content>
    <ng-container *ngIf="step === HomeAddressToGeocodeResponseDialogStep.initial">
        <div class="row mt-2" [formGroup]="form">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="w-100 mb-2">
                            <mat-label>Lot Number</mat-label>
                            <input matInput placeholder="Lot Number" formControlName="lot_number">
                        </mat-form-field>
                        <mat-error *ngIf="form.get('lot_number').hasError('max')">
                            Max 30 characters long
                        </mat-error>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="w-100 mb-2">
                            <mat-label>Street Line 1</mat-label>
                            <input matInput placeholder="Street Line 1" formControlName="street_line1" [readonly]="isLoading">
                            <mat-error *ngIf="form.controls['street_line1'].hasError('required')">
                                Street Line is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="w-100 mb-2">
                            <mat-label>Street Line 2</mat-label>
                            <input matInput placeholder="Street Line 2" formControlName="street_line2" [readonly]="isLoading">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="w-100 mb-2">
                            <mat-label>City/County/State</mat-label>
                            <app-generic-model-autocomplete formControlName="city_info" modelClassName="city" [filterParams]="{ordering: 'name', is_attched: true}"></app-generic-model-autocomplete>
                            <mat-error *ngIf="form.controls.city_info.hasError('required')">City is required -
                                Must select option from list
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="w-100 mb-2">
                            <mat-label>ZIP Code</mat-label>
                            <input matInput placeholder="Zip code" formControlName="zipcode" [readonly]="isLoading">
                            <mat-error *ngIf="form.controls['zipcode'].hasError('required')">
                                ZIP Code is required
                            </mat-error>
                            <mat-error *ngIf="form.controls['zipcode'].hasError('maxlength')">
                                ZIP Code must contains 5 digits
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
      <div class="row mb-2">
        <div class="col-12 text-center">
          <div class="d-flex justify-content-center">
            <mat-spinner [diameter]="50" *ngIf="isLoading"></mat-spinner>
          </div>
          <ng-container *ngIf="!isLoading">
            <button mat-raised-button color="accent"
                    (click)="handleAdd()">
              <ng-container *ngIf="!data?.geocodeAddressInfo">
                Add Address
              </ng-container>
              <ng-container *ngIf="data?.geocodeAddressInfo">
                Change Address
              </ng-container>
            </button>
          </ng-container>
        </div>
      </div>
    </ng-container>
  <ng-container *ngIf="step === HomeAddressToGeocodeResponseDialogStep.approximateAddress">

    <ng-container [formGroup]="form">
      <div class="row mt-2">
        <div class="col-4">
          <mat-form-field class="w-100 mb-2">
            <mat-label>City/County/State</mat-label>
            <app-generic-model-autocomplete formControlName="city_info" modelClassName="city"
                                            [filterParams]="{ordering: 'name'}"></app-generic-model-autocomplete>
            <mat-error *ngIf="form.controls.city_info.hasError('required')">City is required -
              Must select option from list
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field class="w-100 mb-2">
            <mat-label>ZIP Code</mat-label>
            <input matInput placeholder="Zip code" formControlName="zipcode" [readonly]="isLoading">
            <mat-error *ngIf="form.controls['zipcode'].hasError('required')">
              ZIP Code is required
            </mat-error>
            <mat-error *ngIf="form.controls['zipcode'].hasError('maxlength')">
              ZIP Code must contains 5 digits
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          If accurate address is not known yet, you can select approximate location on map:
        </div>
      </div>

      <google-map
          height="450px"
          width="100%"
          [zoom]="12"
          [center]="googleMapsCenter"
          [options]="googleMapsOptions"
      >
        <map-marker
            #markerElem="mapMarker"
            *ngFor="let marker of googleMapsMarkers; let i = index"
            [position]="marker.position"
            [label]="marker.label"
            [title]="marker.title"
            [options]="marker.options"
            (mapDragend)="onDragend(marker, $event)"
            (mapClick)="googleMapsOpenInfo(markerElem, marker.info)"
        >
        </map-marker>
        <map-info-window>{{ googleMapsInfoContent }}</map-info-window>
      </google-map>

      <div class="row mt-2">
        <div class="col-4">
          <mat-form-field class="w-100 mb-2">
            <mat-label>Latitude</mat-label>
            <input matInput type="number" placeholder="Latitude" formControlName="lat" [readonly]="isLoading">
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field class="w-100 mb-2">
            <mat-label>Longitude</mat-label>
            <input matInput type="number" placeholder="Longitude" formControlName="lng" [readonly]="isLoading">
          </mat-form-field>
        </div>
      </div>
    </ng-container>



    <div class="row mb-2 mt-2">
      <div class="col-12 text-center">
        <div class="d-flex justify-content-center">
          <mat-spinner [diameter]="50" *ngIf="isLoading"></mat-spinner>
        </div>
        <ng-container *ngIf="!isLoading">
          <button mat-raised-button color="accent" (click)="approximateAddressConfirm()" class="mr-2">
            <ng-container *ngIf="!data?.geocodeAddressInfo">
              Add Address
            </ng-container>
            <ng-container *ngIf="data?.geocodeAddressInfo">
              Change Address
            </ng-container>
          </button>
          <button mat-raised-button (click)="toInitialStep($event)">
            Back
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>


    <ng-container *ngIf="step === HomeAddressToGeocodeResponseDialogStep.confirmAddress">
        <p *ngIf="geocode.valid_responses.length >  1">
            We found multiple addresses
        </p>
        <p *ngIf="geocode.valid_responses.length == 1">
            Please confirm Home address
        </p>
        <p *ngIf="geocode.valid_responses.length == 0">
            We can't find any valid address on Map.
        </p>

      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <div class="text-center">
                <div>
                  <button *ngFor="let geocodeResponse of geocode?.valid_responses; let idx=index;"
                          class="btn btn-outline-primary mt-2 w-100"
                          (click)="handleConfirmAddress(geocodeResponse)"
                          cdkFocusInitial>
                                <span>{{geocodeResponse.street_line1}}<span *ngIf="geocodeResponse.street_line2"> {{geocodeResponse.street_line2}}</span>,
                                  {{geocodeResponse.city_info.name}}, {{geocodeResponse.city_info.county_info.name}},
                                  {{geocodeResponse.city_info.county_info.state}} {{geocodeResponse.zipcode}}
                                  <span *ngIf="this.form.get('lot_number').value"> Lot Number: {{ this.form.get('lot_number').value }}</span>
                                </span>
                  </button>
                  <button class="col-12 btn btn-outline-primary mt-2"
                          (click)="handleUseAddressAsEntered()">
                                <span *ngIf="geocode.valid_responses.length > 1">
                                    This addresses is not correct, I want to use address <b>As Entered</b>: "{{rawAddress}}"
                                </span>
                    <span *ngIf="geocode.valid_responses.length == 1">
                                    This address is not correct. I want to use address <b>As Entered</b>: "{{rawAddress}}"
                                </span>
                    <span *ngIf="geocode.valid_responses.length == 0">
                                    I want to use address <b>As Entered</b>: "{{rawAddress}}"
                                </span>
                  </button>
                  <button class="btn btn-outline-dark mt-2 w-100"
                          (click)="toInitialStep($event)">Back
                  </button>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </ng-container>

</div>
