import { MechanicalEquipmet } from '@/data/simulation/models/mechanicals';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment-ground-source-heat-pump',
  templateUrl: './equipment-ground-source-heat-pump.component.html',
  styleUrls: ['./equipment-ground-source-heat-pump.component.scss'],
})
export class EquipmentGroundSourceHeatPumpComponent implements OnInit {
  @Input() equipment: MechanicalEquipmet;

  ngOnInit(): void {}
}
