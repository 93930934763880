import { MechanicalEquipmet } from '@/data/simulation/models/mechanicals';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment-dehumidifier',
  templateUrl: './equipment-dehumidifier.component.html',
  styleUrls: ['./equipment-dehumidifier.component.scss'],
})
export class EquipmentDehumidifierComponent implements OnInit {
  @Input() equipment: MechanicalEquipmet;

  ngOnInit(): void {}
}
