import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DIALOG_WIDTH } from '@/modules/simulation/constants';
import { LocationLabels } from '@/data/simulation/enumerations';
import {
  FoundationWallTypeChangeDialogComponent,
  FoundationWallTypeChangeDialogData,
} from '../foundation-wall-type-change-dialog/foundation-wall-type-change-dialog.component';
import {
  ExteriorLocation,
  FoundationWallBackendDict,
  InteriorLocation,
  createFoundationWallForm,
  FIELD_LABELS,
} from '@/data/simulation/models/enclosure/FoundationWall';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectFoundationWallViewSet } from '@/modules/simulation/state/foundation-wall/selectors';
import { filter, first, takeUntil } from 'rxjs/operators';
import { selectFoundationWallTypeNameAndError } from '@/modules/simulation/state/foundation-wall-type/selectors';
import {
  removeFoundationWall,
  updateFoundationWall,
} from '@/modules/simulation/state/foundation-wall/actions';
import { CREATE_MODE, EDIT_MODE } from '@/core/constants';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { removeFoundationWallType } from '@/modules/simulation/state/foundation-wall-type/actions';
import { cloneFoundationWall } from '@/modules/simulation/state/simulation/actions';

@Component({
  selector: 'app-foundation-wall-form',
  templateUrl: './foundation-wall-form.component.html',
  styleUrls: ['./foundation-wall-form.component.scss'],
})
export class FoundationWallFormComponent implements OnDestroy, OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  entity: FoundationWallBackendDict = null;
  loading = false;

  private componentDestroyed$ = new Subject();
  typeViewSubscription$ = null;
  public exteriorLocationLabelMapping = {};
  public interiorLocationLabelMapping = {};

  public foundationWallTypeName: string;
  public foundationWallTypeRValue: number;
  public foundationWallTypeErrorCount = 0;

  public foundationWallFormGroup: FormGroup;
  constructor(public dialog: MatDialog, public store: Store) {
    Object.values(InteriorLocation).forEach(value => {
      this.interiorLocationLabelMapping[value] = LocationLabels[value];
    });
    Object.values(ExteriorLocation).forEach(value => {
      this.exteriorLocationLabelMapping[value] = LocationLabels[value];
    });
  }

  updateTypeDetails(typeId: number) {
    if (this.typeViewSubscription$) {
      this.typeViewSubscription$.unsubscribe();
    }
    if (!typeId) {
      this.foundationWallTypeName = null;
      this.foundationWallTypeRValue = null;
      this.foundationWallTypeErrorCount = 1;
      return;
    }

    this.store
      .select(selectFoundationWallTypeNameAndError(typeId))
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(({ name, rValue, errorCount }) => {
        this.foundationWallTypeName = name;
        this.foundationWallTypeRValue = rValue;
        this.foundationWallTypeErrorCount = errorCount;
      });
  }

  ngOnInit(): void {
    this.store
      .select(selectFoundationWallViewSet(this.entityId))
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val !== null)
      )
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;
        if (!this.foundationWallFormGroup) {
          this.foundationWallFormGroup = createFoundationWallForm(data);
        }

        this.updateTypeDetails(data.type);
      });
  }

  openEditWallTypeChangeDialog(data: FoundationWallTypeChangeDialogData) {
    return this.dialog.open(FoundationWallTypeChangeDialogComponent, {
      width: DIALOG_WIDTH,
      disableClose: true,
      data,
    });
  }

  removeFoundationWall() {
    let wallName = this.entity.name;
    if (wallName === null || wallName === '') {
      wallName = 'Untitled';
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${wallName}" wall ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            removeFoundationWall({ foundationWall: this.entity })
          );
        }
      });
  }

  clone() {
    this.store.dispatch(cloneFoundationWall({ id: this.entityId }));
  }

  editWallType() {
    const updatedWallType = this.openEditWallTypeChangeDialog({
      commit: true,
      mode: EDIT_MODE,
      entityId: this.entity.type,
    });

    updatedWallType.afterClosed().subscribe(() => {
      this.updateTypeDetails(this.entity.type);
    });
  }

  addWallType() {
    const newWallDialog = this.openEditWallTypeChangeDialog({
      commit: true,
      mode: CREATE_MODE,
      parentId: this.entityId,
    });

    newWallDialog.afterClosed().subscribe(wallType => {
      if (!wallType) {
        return;
      }

      this.foundationWallFormGroup.patchValue({ type: wallType.id });
      this.updateTypeDetails(wallType.id);
    });
  }

  removeWallType() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${this.foundationWallTypeName}" wall type ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            removeFoundationWallType({
              wallId: this.entityId,
              wallTypeId: this.entity.type,
            })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onSave(foundationWallChanges: Partial<FoundationWallBackendDict>): void {
    this.store.dispatch(
      updateFoundationWall({
        id: this.entityId,
        foundationWallChanges: foundationWallChanges,
      })
    );
  }
}
