<div class="card position-relative" appFocusTarget entityName="frameFloor" entityId="{{entityId}}">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <div class="card-body pb-0" *ngIf="frameFloorFormGroup">
        <app-form formName="frameFloorFormGroup" [formGroup]="frameFloorFormGroup" (save)="onSave($event)">
            <div>
                <app-input formControlName="name" [label]="fieldLabels.name" cdkFocusInitial></app-input>
            </div>
            <div>
                <app-select formControlName="interior_location" [label]="fieldLabels.interior_location"
                    [items]="interiorLocationLabelMapping"></app-select>
                <app-select formControlName="exterior_location" [label]="fieldLabels.exterior_location"
                    [items]="exteriorLocationLabelMapping"></app-select>
            </div>
            <div>
                <app-input formControlName="area" [label]="fieldLabels.area" unit="sqft." type="expression"></app-input>
                <app-checkbox formControlName="represents_ceiling" [label]="fieldLabels.represents_ceiling"></app-checkbox>
            </div>
            <div>
                <app-foreign-model-field
                    [name]="frameFloorTypeName"
                    [errorCount]="frameFloorTypeErrorCount"
                    (add)="addFloorType()"
                    (edit)="editFloorType()"
                    (remove)="removeFloorType()"
                    label="Floor type">
                </app-foreign-model-field>
            </div>
        </app-form>
    </div>
    <div class="card-footer p-0 text-right">
        <app-model-action-buttons [entityId]="entityId" modelName="frame_floor" (clone)="clone()" (delete)="removeFrameFloor()">
        </app-model-action-buttons>
    </div>
</div>
