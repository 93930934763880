export enum EEPProgramHomeStatusProjectReportExportField {
  home = 'home',
  status = 'status',
  eep_program = 'eep_program',
  subdivision = 'subdivision',
  community = 'community',
  relationships = 'relationships',
  ipp = 'ipp',
  invoicing = 'invoicing',
  floorplan = 'floorplan',
  sampleset = 'sampleset',
  simulation_basic = 'simulation_basic',
  simulation_advanced = 'simulation_advanced',
  annotations = 'annotations',
  checklist_answers = 'checklist_answers',
  qa = 'qa',
  customer_aps = 'customer_aps',
  customer_eto = 'customer_eto',
  ngbs_data = 'ngbs_data',
  hes_data = 'hes_data',
  retain_empty = 'retain_empty',
}

export const EEPProgramHomeStatusProjectReportExportFieldLabelMapping: Record<
  EEPProgramHomeStatusProjectReportExportField,
  string
> = {
  [EEPProgramHomeStatusProjectReportExportField.home]: 'Project Data',
  [EEPProgramHomeStatusProjectReportExportField.status]:
    'Energy Efficiency Programs',
  [EEPProgramHomeStatusProjectReportExportField.subdivision]:
    'Subdivision / MF Development Data',
  [EEPProgramHomeStatusProjectReportExportField.community]: 'Community Data',
  [EEPProgramHomeStatusProjectReportExportField.relationships]:
    'Company Association Data',
  [EEPProgramHomeStatusProjectReportExportField.eep_program]: 'Program Data',
  [EEPProgramHomeStatusProjectReportExportField.invoicing]: 'Invoicing Data',
  [EEPProgramHomeStatusProjectReportExportField.floorplan]: 'Floorplan Data',
  [EEPProgramHomeStatusProjectReportExportField.ipp]: 'Incentive Payments',
  [EEPProgramHomeStatusProjectReportExportField.sampleset]: 'Sampleset Data',
  [EEPProgramHomeStatusProjectReportExportField.simulation_basic]:
    'Simulation Basic',
  [EEPProgramHomeStatusProjectReportExportField.simulation_advanced]:
    'Simulation Advanced',
  [EEPProgramHomeStatusProjectReportExportField.annotations]: 'Annotations',
  [EEPProgramHomeStatusProjectReportExportField.checklist_answers]:
    'Checklist Answer Data',
  [EEPProgramHomeStatusProjectReportExportField.qa]: 'QA Data',
  [EEPProgramHomeStatusProjectReportExportField.customer_aps]: 'APS Metersets',
  [EEPProgramHomeStatusProjectReportExportField.customer_eto]: 'ETO Data',
  [EEPProgramHomeStatusProjectReportExportField.ngbs_data]: 'NGBS Data',
  [EEPProgramHomeStatusProjectReportExportField.hes_data]: 'HES Data',
  [EEPProgramHomeStatusProjectReportExportField.retain_empty]:
    'Retain Empty Columns',
};

export class EEPProgramHomeStatusProjectReportDownload {
  export_fields: EEPProgramHomeStatusProjectReportExportField[];
  export_filters: { [key: string]: string };
}
