import { AppState } from '@/state/reducers';
import { userStateKey } from '@/state/reducers/user/user.reducer';
import { createSelector } from '@ngrx/store';

export const selectCompanyState = (state: AppState) => state[userStateKey];

export const getUser = createSelector(
  selectCompanyState,
  store => store.detail
);
