import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DIALOG_WIDTH } from '@/modules/simulation/constants';
import {
  SlabTypeChangeDialogComponent,
  SlabTypeChangeDialogData,
} from '../slab-type-change-dialog/slab-type-change-dialog.component';
import {
  FIELD_LABELS,
  SlabBackendDict,
  createSlabForm,
} from '@/data/simulation/models/enclosure/Slab';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectSlabViewSet } from '@/modules/simulation/state/slab/selectors';
import { filter, first, takeUntil } from 'rxjs/operators';
import { selectSlabTypeNameAndError } from '@/modules/simulation/state/slab-type/selectors';
import {
  removeSlab,
  updateSlab,
} from '@/modules/simulation/state/slab/actions';
import { CREATE_MODE, EDIT_MODE } from '@/core/constants';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { removeSlabType } from '@/modules/simulation/state/slab-type/actions';
import { InteriorLocation } from '@/data/simulation/models/enclosure/Slab';
import { LocationLabels } from '@/data/simulation/enumerations';
import { cloneSlab } from '@/modules/simulation/state/simulation/actions';

@Component({
  selector: 'app-slab',
  templateUrl: './slab.component.html',
  styleUrls: ['./slab.component.scss'],
})
export class SlabComponent implements OnDestroy, OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  entity: SlabBackendDict = null;
  loading = false;

  private componentDestroyed$ = new Subject();
  typeViewSubscription$ = null;
  public exteriorLocationLabelMapping = {};
  public interiorLocationLabelMapping = {};

  public slabTypeName: string;
  public slabTypeErrorCount = 0;

  public slabFormGroup: FormGroup;

  constructor(public dialog: MatDialog, public store: Store) {
    Object.values(InteriorLocation).forEach(value => {
      this.interiorLocationLabelMapping[value] = LocationLabels[value];
    });
  }

  updateTypeDetails(typeId: number) {
    if (this.typeViewSubscription$) {
      this.typeViewSubscription$.unsubscribe();
    }
    if (!typeId) {
      this.slabTypeName = null;
      this.slabTypeErrorCount = 1;
      return;
    }

    this.store
      .select(selectSlabTypeNameAndError(typeId))
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(({ name, errorCount }) => {
        this.slabTypeName = name;
        this.slabTypeErrorCount = errorCount;
      });
  }

  ngOnInit(): void {
    this.store
      .select(selectSlabViewSet(this.entityId))
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val !== null)
      )
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (!this.slabFormGroup) {
          this.slabFormGroup = createSlabForm(data);
        }
        this.updateTypeDetails(data.type);
      });
  }

  openEditSlabTypeChangeDialog(data: SlabTypeChangeDialogData) {
    return this.dialog.open(SlabTypeChangeDialogComponent, {
      width: DIALOG_WIDTH,
      disableClose: true,
      data,
    });
  }

  removeSlab() {
    let slabName = this.entity.name;
    if (slabName === null || slabName === '') {
      slabName = 'Untitled';
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${slabName}" slab ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(removeSlab({ slab: this.entity }));
        }
      });
  }

  editSlabType() {
    const updatedSlabType = this.openEditSlabTypeChangeDialog({
      commit: true,
      mode: EDIT_MODE,
      entityId: this.entity.type,
    });

    updatedSlabType.afterClosed().subscribe(() => {
      this.updateTypeDetails(this.entity.type);
    });
  }

  addSlabType() {
    const newSlabDialog = this.openEditSlabTypeChangeDialog({
      commit: true,
      mode: CREATE_MODE,
      parentId: this.entityId,
    });

    newSlabDialog.afterClosed().subscribe(slabType => {
      if (!slabType) {
        return;
      }

      this.slabFormGroup.patchValue({ type: slabType.id });
      this.updateTypeDetails(slabType.id);
    });
  }

  removeSlabType() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${this.slabTypeName}" slab type ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            removeSlabType({
              slabId: this.entityId,
              slabTypeId: this.entity.type,
            })
          );
        }
      });
  }

  clone() {
    this.store.dispatch(cloneSlab({ id: this.entityId }));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onSave(slabChanges: Partial<SlabBackendDict>): void {
    this.store.dispatch(
      updateSlab({
        id: this.entityId,
        slabChanges: slabChanges,
      })
    );
  }
}
