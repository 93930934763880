<div class="row">
    <div class="col-8">
        <h2 mat-dialog-title>
            <ng-container *ngIf="!editForm">
                Add New Task
            </ng-container>
            <ng-container *ngIf="editForm">
                Edit Task
            </ng-container>
        </h2>
    </div>
    <div class="col-4 text-right">
        <app-history-mat-button modelClassName="scheduling_task" [objectId]="object.id" *ngIf="initialized">
            History
        </app-history-mat-button>
    </div>
</div>

<ng-container *ngIf="initialized; else notInitialized">
    <mat-dialog-content class="mat-typography">
        <form [formGroup]="form" class="mb-2">
<!--            <div class="row">-->
<!--                <div class="col-12">-->
<!--                    <mat-form-field appearance="outline" class="w-100">-->
<!--                        <mat-label>Status</mat-label>-->
<!--                        <mat-select formControlName="status">-->
<!--                            <mat-option *ngFor="let item of statusLabelMapping | keyvalue " [value]="item.key">-->
<!--                                {{ item.value }}-->
<!--                            </mat-option>-->
<!--                        </mat-select>-->
<!--                        <mat-error *ngIf="form.get('status').hasError('required')">-->
<!--                            This field is required-->
<!--                        </mat-error>-->
<!--                    </mat-form-field>-->
<!--                </div>-->
<!--            </div>-->
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline" class="w-100 mb-2">
                        <mat-label>Note</mat-label>
                        <textarea matInput type="text" placeholder="Note" formControlName="note"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>
    <div class="row" *ngIf="!isLoading">
        <div class="col-12 text-center">
            <button mat-raised-button color="accent" class="mr-2" (click)="onSave($event)">
                <span *ngIf="editForm">Save</span>
                <span *ngIf="!editForm">Create</span>
            </button>
            <button mat-raised-button [mat-dialog-close]="null" cdkFocusInitial>
                Cancel
            </button>
        </div>
    </div>
    <div class="row" *ngIf="isLoading">
        <div class="col-12">
            <div class="d-flex justify-content-center">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #notInitialized>
    <mat-dialog-content>
        <div class="col-12">
            <div class="d-flex justify-content-center">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>