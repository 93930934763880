<h2 mat-dialog-title>
    NGBS Green Verifier Insurance Requirements
</h2>
<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <p>
              Home Innovation requires NGBS Green participants to maintain, at participant’s expense,
              General Liability insurance as shown below.
              Obtaining insurance certificates is typically a routine matter
              for your insurance agent/broker and the information below should
              help expedite the process. Please ask your insurance agent
              to provide a current Certificate of Insurance (COI) –
              including the additional insured verbiage below – and upload
              to your AXIS record at your earliest convenience.
            </p>

            <p>
              The chart below shows the MINIMUM required coverage per occurrence:

            </p>
            <table class="table table-bordered">
                <tbody>
                  <tr>
                      <td>GENERAL LIABILITY</td>
                      <td>$1,000,000</td>
                  </tr>
                </tbody>
            </table>

            <p>
              Please note: the insurance policy <b>must</b> name
              <b>Home Innovation Research Labs</b> and its officers,
              directors, agents, and employees, as <b>additional insureds</b>
              for liability with respect to or arising out of the
              actual or alleged acts or omissions of Manufacturer.
            </p>
            <p>
              Your insurance certificate must:
            </p>
            <ul>
              <li>
                List “Certificate holder” as:
                <ul>
                  <li>
                    <i>
                      Home Innovation Research Labs, 400 Prince George’s Boulevard, Upper Marlboro, MD 20774-8731, and
                    </i>
                  </li>
                </ul>
              </li>
              <li>
                Include the following paragraph to meet the additional insured requirements:
                <ul style="">
                  <li>
                    <i>
                      "Home Innovation Research Labs and its officers, directors, agents, affiliates,
                      and employees are included as additional where required by
                      written contract in accordance with the policy provisions of
                      the General Liability policy."
                    </i>
                  </li>
                </ul>
              </li>
            </ul>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="btn btn-primary" [mat-dialog-close]="true" cdkFocusInitial>
        Close
    </button>
</mat-dialog-actions>
