import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as WaterSystemActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { WaterSystemService } from '@/data/simulation/services/water-system.service';
import { WaterSystemValidator } from '../../validtaors/water-system.validator';

@Injectable()
export class WaterSystemEffects {
  loadDetailedWaterSystems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaterSystemActions.loadWaterSystems),
      mergeMap(action => {
        const errors = WaterSystemValidator.validate(action.waterSystems);

        return of(
          WaterSystemActions.loadWaterSystemsSuccess({
            waterSystems: action.waterSystems,
            errors: errors,
          })
        );
      })
    );
  });

  updateWaterSystem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaterSystemActions.updateWaterSystem),
      mergeMap(action =>
        this.watersystemService.update(action.waterSystemChanges).pipe(
          mergeMap(updatedWaterSystem => {
            const errors = WaterSystemValidator.validate([updatedWaterSystem]);
            return of(
              WaterSystemActions.updateWaterSystemSuccess({
                waterSystem: updatedWaterSystem,
                errors: errors[updatedWaterSystem.id],
              })
            );
          }),
          catchError(error =>
            of(
              WaterSystemActions.updateWaterSystemFailure({
                id: action.waterSystemChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private watersystemService: WaterSystemService
  ) {}
}
