import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as SeasonalRateActions from './actions';
import * as BlockRateActions from '../block-rate/actions';
import { SeasonalRateService } from '@/data/simulation/services/seasonal-rate.service';
import { SeasonalRateBackendDict } from '@/data/simulation/models/utility/SeasonalRate';
import { SeasonalRateValidator } from '../../validtaors/seasonal-rate.validator';
import { BlockRateBackendDict } from '@/data/simulation/models/utility/BlockRate';

@Injectable()
export class SeasonalRateEffects {
  loadDetailedSeasonalRates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SeasonalRateActions.loadDetailedSeasonalRates),
      mergeMap(action => {
        const blockRates: BlockRateBackendDict[] = [];
        const seasonalRates: SeasonalRateBackendDict[] = [];

        action.detailedSeasonalRates.forEach(detailedSeasonalRate => {
          const { block_rates_info: currentBlockRates, ...seasonalRate } =
            detailedSeasonalRate;

          if (currentBlockRates) {
            blockRates.push(...currentBlockRates);
          }

          seasonalRates.push(seasonalRate);
        });

        const errors = SeasonalRateValidator.validate(
          action.detailedSeasonalRates
        );

        return of(
          SeasonalRateActions.loadSeasonalRatesSuccess({
            seasonalRates: seasonalRates,
            errors: errors,
          }),
          BlockRateActions.loadBlockRates({
            blockRates: blockRates,
          })
        );
      })
    );
  });

  // updateSeasonalRate$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(SeasonalRateActions.updateSeasonalRate),
  //     mergeMap(action =>
  //       this.seasonalrateService.update(action.seasonalRateChanges).pipe(
  //         mergeMap(updatedFloor => {
  //           let errors = SeasonalRateValidator.validate([updatedFloor]);
  //           return of(
  //             SeasonalRateActions.updateSeasonalRateSuccess({
  //               seasonalRate: updatedFloor,
  //               errors: errors[updatedFloor.id],
  //             })
  //           );
  //         }),
  //         catchError(error =>
  //           of(
  //             SeasonalRateActions.updateSeasonalRateFailure({
  //               id: action.seasonalRateChanges.id,
  //             }),
  //             SharedActions.reportAPIFailure({ error })
  //           )
  //         )
  //       )
  //     )
  //   )
  // );

  // removeSeasonalRate$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(SeasonalRateActions.removeSeasonalRate),
  //     mergeMap(action =>
  //       this.seasonalrateService.delete(action.seasonalRate.id).pipe(
  //         mergeMap(() =>
  //           of(
  //             SeasonalRateActions.removeSeasonalRateSuccess({
  //               id: action.seasonalRate.id,
  //             }),
  //             SimulationActions.removeSeasonalRateSuccess({
  //               id: action.seasonalRate.id,
  //             })
  //           )
  //         ),
  //         catchError(error =>
  //           of(
  //             SeasonalRateActions.removeSeasonalRateFailure({
  //               id: action.seasonalRate.id,
  //             }),
  //             SharedActions.reportAPIFailure({ error })
  //           )
  //         )
  //       )
  //     )
  //   )
  // );

  constructor(
    private actions$: Actions,
    private seasonalrateService: SeasonalRateService
  ) {}
}
