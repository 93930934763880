import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { first, takeUntil } from 'rxjs/operators';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UserService } from '@/data/core/services/user.service';
import { getUser } from '@/state/selectors/user/user.selector';

@Component({
  selector: 'app-user-detail-page',
  templateUrl: './user-detail-page.component.html',
  styleUrls: ['./user-detail-page.component.scss'],
})
export class UserDetailPageComponent implements OnInit, OnDestroy {
  initialized = false;

  selectedIndex = 0;
  user: User;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    public injector: Injector,
    private router: Router,
    public userService: UserService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    public uiHelper: UIHelperService
  ) {
    this.store
      .select(getUser)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(user => {
        this.user = user;
      });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(first()).subscribe(params => {
      if (params.tab) {
        this.selectedIndex = Number(params.tab) || 0;
      }
    });

    this.userService
      .retrieve(this.activatedRoute.snapshot.params.userId)
      .pipe(first())
      .subscribe(
        _ => {
          this.initialized = true;
        },
        error => this.uiHelper.handleMainRequestError(error)
      );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onTabChange(event: MatTabChangeEvent) {
    // https://github.com/swimlane/ngx-datatable/issues/1325
    window.dispatchEvent(new Event('resize'));
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: { tab: event.index },
      })
      .toString();
    this.location.replaceState(url);
  }
}
