import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GroundSourceHeatPumpService } from '@/data/simulation/services/ground-source-heat-pump.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as GroundSourceHeatPumpActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { GroundSourceHeatPumpValidator } from '../../validtaors/ground-source-heat-pump.validator';

@Injectable()
export class GroundSourceHeatPumpEffects {
  loadGroundSourceHeatPumps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GroundSourceHeatPumpActions.loadGroundSourceHeatPumps),
      mergeMap(action => {
        const errors = GroundSourceHeatPumpValidator.validate(
          action.groundSourceHeatPumps
        );
        return of(
          GroundSourceHeatPumpActions.loadGroundSourceHeatPumpsSuccess({
            groundSourceHeatPumps: action.groundSourceHeatPumps,
            errors,
          })
        );
      })
    );
  });

  updateGroundSourceHeatPump$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GroundSourceHeatPumpActions.updateGroundSourceHeatPump),
      mergeMap(action => {
        return this.groundSourceHeatPumpService
          .update(action.groundSourceHeatPumpChanges)
          .pipe(
            mergeMap(groundSourceHeatPump => {
              const errors = GroundSourceHeatPumpValidator.validate([
                groundSourceHeatPump,
              ]);
              return of(
                GroundSourceHeatPumpActions.loadGroundSourceHeatPumpsSuccess({
                  groundSourceHeatPumps: [groundSourceHeatPump],
                  errors,
                })
              );
            }),
            catchError(error =>
              of(
                GroundSourceHeatPumpActions.updateGroundSourceHeatPumpFailure({
                  id: action.groundSourceHeatPumpChanges.id,
                }),
                SharedActions.reportAPIFailure({ error })
              )
            )
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private groundSourceHeatPumpService: GroundSourceHeatPumpService
  ) {}
}
