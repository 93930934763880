import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { LocationLabels, Location } from '../../enumerations';
import { Validators } from '@angular/forms';
import {
  aboveWarningRangeValidator,
  belowWarningRangeValidator,
} from '@/modules/simulation/validtaors/helper';

export enum DuctType {
  SUPPLY = 'supply',
  RETURN = 'return',
}

export const DuctTypeLabels: Record<DuctType, string> = {
  [DuctType.SUPPLY]: 'Supply',
  [DuctType.RETURN]: 'Return',
};

export const DuctLocationLabels = {
  [Location.CONDITIONED_SPACE]: LocationLabels[Location.CONDITIONED_SPACE],
  [Location.CONDITIONED_BASEMENT]:
    LocationLabels[Location.CONDITIONED_BASEMENT],
  [Location.CONDITIONED_ATTIC]: LocationLabels[Location.CONDITIONED_ATTIC],
  [Location.UNCONDITIONED_BASEMENT]:
    LocationLabels[Location.UNCONDITIONED_BASEMENT],
  [Location.ATTIC_VENTED]: LocationLabels[Location.ATTIC_VENTED],
  [Location.SEALED_ATTIC]: LocationLabels[Location.SEALED_ATTIC],
  [Location.ATTIC_POOR_VENTED]: LocationLabels[Location.ATTIC_POOR_VENTED],
  [Location.ATTIC_VENTED_RADIANT]:
    LocationLabels[Location.ATTIC_VENTED_RADIANT],
  [Location.ATTIC_POOR_VENTED_RADIANT]:
    LocationLabels[Location.ATTIC_POOR_VENTED_RADIANT],
  [Location.ATTIC_UNDER_INS_VENTED]:
    LocationLabels[Location.ATTIC_UNDER_INS_VENTED],
  [Location.ATTIC_UNDER_INS_POOR_VENTED]:
    LocationLabels[Location.ATTIC_UNDER_INS_POOR_VENTED],
  [Location.ATTIC_UNDER_INS_VENTED_RAIANT]:
    LocationLabels[Location.ATTIC_UNDER_INS_VENTED_RAIANT],
  [Location.ATTIC_UNDER_INS_POOR_VENTED_RADIANT]:
    LocationLabels[Location.ATTIC_UNDER_INS_POOR_VENTED_RADIANT],
  [Location.CRAWL_SPACE_UNVENTED_INSULATED]:
    LocationLabels[Location.CRAWL_SPACE_UNVENTED_INSULATED],
  [Location.CRAWL_SPACE_UNVENTED_UNINSULATED]:
    LocationLabels[Location.CRAWL_SPACE_UNVENTED_UNINSULATED],
  [Location.CRAWL_SPACE_UNVENTED_INSULATED_FLOOR]:
    LocationLabels[Location.CRAWL_SPACE_UNVENTED_INSULATED_FLOOR],
  [Location.CRAWL_SPACE_VENTED_INSULATED]:
    LocationLabels[Location.CRAWL_SPACE_VENTED_INSULATED],
  [Location.CRAWL_SPACE_VENTED_UNINSULATED]:
    LocationLabels[Location.CRAWL_SPACE_VENTED_UNINSULATED],
  [Location.CRAWL_SPACE_VENTED_INSULATED_FLOOR]:
    LocationLabels[Location.CRAWL_SPACE_VENTED_INSULATED_FLOOR],
  [Location.BASEMENT_UNINSULATED]:
    LocationLabels[Location.BASEMENT_UNINSULATED],
  [Location.BASEMENT_INSULATED_WALLS]:
    LocationLabels[Location.BASEMENT_INSULATED_WALLS],
  [Location.BASEMENT_INSULATED_CEILING]:
    LocationLabels[Location.BASEMENT_INSULATED_CEILING],
  [Location.UNDER_SLAB]: LocationLabels[Location.UNDER_SLAB],
  [Location.EXTERIOR_WALL]: LocationLabels[Location.EXTERIOR_WALL],
  [Location.EXPOSED_EXTERIOR_WALL]:
    LocationLabels[Location.EXPOSED_EXTERIOR_WALL],
  [Location.MOBILE_HOME_BELLY]: LocationLabels[Location.MOBILE_HOME_BELLY],
  [Location.GARAGE]: LocationLabels[Location.GARAGE],
  [Location.GARAGE_WALL]: LocationLabels[Location.GARAGE_WALL],
  [Location.GARAGE_CEILING]: LocationLabels[Location.GARAGE_CEILING],
};

// Labels
export const FIELD_LABELS = {
  type: 'Type',
  area_percent: 'Area Percent',
  r_value: 'R-Value',
  location: 'Location',
};

export interface DuctBackendDict {
  id: number;
  type: DuctType;
  area_percent: number;
  r_value: number;
  location: keyof typeof LocationLabels;
}
export class DuctForm extends ModelFormGroup {
  constructor(data: DuctBackendDict) {
    super({
      id: new ModelFormControl(data.id),
      type: new ModelFormControl(data.type, Validators.required),
      area_percent: new ModelFormControl(data.area_percent, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
        belowWarningRangeValidator(1),
      ]),
      r_value: new ModelFormControl(data.r_value, [
        Validators.required,
        Validators.min(0),
        Validators.max(300),
        belowWarningRangeValidator(0.5),
        aboveWarningRangeValidator(295),
      ]),
      location: new ModelFormControl(data.location, Validators.required),
    });
  }
}
