import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as RimJoistTypeActions from './actions';
import * as NoteActions from '../note/actions';
import * as SharedActions from '../shared/shared.actions';
import { RimJoistTypeService } from '@/data/simulation/services/rim-joist-type.service';
import { ModelGraphService } from '../../services/model-graph.service';
import { RimJoistTypeValidator } from '../../validtaors/rim-joist-type.validator';
import { createNote } from '@/data/simulation/models/Note';
import { StateModelName } from '../../state.registry';

@Injectable()
export class RimJoistTypeEffects {
  loadDetailedRimJoistTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RimJoistTypeActions.loadRimJoistTypes),
      mergeMap(action => {
        const errors = RimJoistTypeValidator.validate(action.rimJoistTypes);
        const notes = [];
        action.rimJoistTypes.forEach(rimJoistType => {
          notes.push(
            createNote(
              StateModelName.rimJoistType,
              rimJoistType.id,
              rimJoistType.note
            )
          );
        });
        return of(
          RimJoistTypeActions.loadRimJoistTypesSuccess({
            rimJoistTypes: action.rimJoistTypes,
            errors,
          }),
          NoteActions.loadNotes({ notes })
        );
      })
    );
  });

  updateRimJoistType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RimJoistTypeActions.updateRimJoistType),
      mergeMap(action =>
        this.rimJoistTypeService
          .update(action.id, action.rimJoistTypeChanges)
          .pipe(
            mergeMap(updatedRimJoistType => {
              const errors = RimJoistTypeValidator.validate([
                updatedRimJoistType,
              ]);
              return of(
                RimJoistTypeActions.loadRimJoistTypesSuccess({
                  rimJoistTypes: [updatedRimJoistType],
                  errors,
                })
              );
            }),
            catchError(error =>
              of(
                RimJoistTypeActions.updateRimJoistTypeFailure({
                  id: action.rimJoistTypeChanges.id,
                }),
                SharedActions.reportAPIFailure({ error })
              )
            )
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private rimJoistTypeService: RimJoistTypeService
  ) {}
}
