import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, map } from 'rxjs/operators';
import { EEPProgramHomeStatusState } from '@/data/home/models/eep_program_home_status';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { Router } from '@angular/router';
import { AppState } from '@/state/reducers';
import {
  QaStatusRequestParams,
  QaStatusService,
} from '@/data/qa/services/qa-status.service';
import { HIRLProjectRegistrationService } from '@/data/customer-hirl/services/hirl-project-registration.service';
import { EEPProgramHomeStatusService } from '@/data/home/services/eep_program_home_status.service';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import {
  HIRLProjectRegistration,
  HIRLProjectRegistrationState,
} from '@/data/customer-hirl/models';
import { EEPProgramRequestParams } from '@/data/eep-program/services/eep-program.service';

@Injectable({
  providedIn: 'root',
})
export class HIRLProjectRegistrationPermissionService {
  constructor(
    public router: Router,
    public store: Store<AppState>,
    public qaStatusService: QaStatusService,
    public hirlProjectRegistrationService: HIRLProjectRegistrationService,
    public eepProgramHomeStatusService: EEPProgramHomeStatusService
  ) {}

  canEdit(
    hirlProjectRegistration: HIRLProjectRegistration | string,
    qaCount?: number,
    certifiedProjectsCount?: number
  ): Observable<ObjectPermissionResponse> {
    let hirlProjectRegistration$: Observable<HIRLProjectRegistration>;
    let hirlProjectRegistrationId: string;

    if (typeof hirlProjectRegistration === 'string') {
      hirlProjectRegistration$ = this.hirlProjectRegistrationService.retrieve(
        hirlProjectRegistration,
        { ignoreStore: true }
      );
      hirlProjectRegistrationId = hirlProjectRegistration;
    } else {
      hirlProjectRegistration$ = of(hirlProjectRegistration);
      hirlProjectRegistrationId = hirlProjectRegistration?.id;
    }

    let qaCount$: Observable<number>;
    if (typeof qaCount === 'number') {
      qaCount$ = of(qaCount);
    } else {
      qaCount$ = this.qaStatusService
        .list(
          Object.assign(new QaStatusRequestParams(), {
            home_status__customer_hirl_project__registration:
              hirlProjectRegistrationId,
          })
        )
        .pipe(
          first(),
          map(response => response.count)
        );
    }

    let certifiedProjectsCount$: Observable<number>;
    if (typeof certifiedProjectsCount === 'number') {
      certifiedProjectsCount$ = of(certifiedProjectsCount);
    } else {
      certifiedProjectsCount$ = this.eepProgramHomeStatusService
        .list(
          Object.assign(new EEPProgramRequestParams(), {
            customer_hirl_project__registration: hirlProjectRegistrationId,
            state: EEPProgramHomeStatusState.complete,
          })
        )
        .pipe(
          first(),
          map(response => response.count)
        );
    }

    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      qaCount: qaCount$,
      entity: hirlProjectRegistration$,
      certifiedProjectsCount: certifiedProjectsCount$,
    }).pipe(
      map(({ currentUser, entity, qaCount, certifiedProjectsCount }) => {
        if (entity.state === HIRLProjectRegistrationState.rejected) {
          return new ObjectPermissionResponse(
            false,
            'Registration has been Rejected.'
          );
        }
        if (entity.state === HIRLProjectRegistrationState.abandon) {
          return new ObjectPermissionResponse(
            false,
            'Registration has been Abandoned.'
          );
        }
        if (!certifiedProjectsCount) {
          if (
            currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
          ) {
            return new ObjectPermissionResponse(true);
          }
          if (qaCount) {
            return new ObjectPermissionResponse(
              false,
              'Project in this Registration received Verification Report.'
            );
          }

          if (entity.registration_user_info?.company === currentUser.company) {
            return new ObjectPermissionResponse(true);
          }
          if (entity.builder_organization === currentUser.company_info?.id) {
            return new ObjectPermissionResponse(true);
          }
          if (
            entity.legacy_builder_organization === currentUser.company_info?.id
          ) {
            return new ObjectPermissionResponse(true);
          }
          if (
            entity.community_owner_organization === currentUser.company_info?.id
          ) {
            return new ObjectPermissionResponse(true);
          }
          if (entity.architect_organization === currentUser.company_info?.id) {
            return new ObjectPermissionResponse(true);
          }
          if (entity.developer_organization === currentUser.company_info?.id) {
            return new ObjectPermissionResponse(true);
          }
        } else {
          return new ObjectPermissionResponse(
            false,
            'Project in this Registration has been certified.'
          );
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }

  canDelete(
    hirlProjectRegistration: HIRLProjectRegistration | string
  ): Observable<ObjectPermissionResponse> {
    let hirlProjectRegistration$: Observable<HIRLProjectRegistration>;
    if (typeof hirlProjectRegistration === 'string') {
      hirlProjectRegistration$ = this.hirlProjectRegistrationService.retrieve(
        hirlProjectRegistration,
        { ignoreStore: true }
      );
    } else {
      hirlProjectRegistration$ = of(hirlProjectRegistration);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: hirlProjectRegistration$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (entity.state === HIRLProjectRegistrationState.new) {
          if (entity.registration_user_info?.company === currentUser.company) {
            return new ObjectPermissionResponse(true);
          }
          if (
            (currentUser.company_info?.slug ===
              CustomerHIRLSettings.companySlug &&
              currentUser.is_company_admin) ||
            currentUser.is_superuser
          ) {
            return new ObjectPermissionResponse(true);
          }
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }

  canAddProject(
    hirlProjectRegistration: HIRLProjectRegistration | string
  ): Observable<ObjectPermissionResponse> {
    let hirlProjectRegistration$: Observable<HIRLProjectRegistration>;
    if (typeof hirlProjectRegistration === 'string') {
      hirlProjectRegistration$ = this.hirlProjectRegistrationService.retrieve(
        hirlProjectRegistration,
        { ignoreStore: true }
      );
    } else {
      hirlProjectRegistration$ = of(hirlProjectRegistration);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: hirlProjectRegistration$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (entity.state === HIRLProjectRegistrationState.rejected) {
          return new ObjectPermissionResponse(
            false,
            'Registration has been Rejected.'
          );
        }
        if (entity.state === HIRLProjectRegistrationState.abandon) {
          return new ObjectPermissionResponse(
            false,
            'Registration has been Abandoned.'
          );
        }

        return new ObjectPermissionResponse(true);
      })
    );
  }

  canAbandon(
    hirlProjectRegistration: HIRLProjectRegistration | string,
    certifiedProjectsCount?: number
  ): Observable<ObjectPermissionResponse> {
    let hirlProjectRegistration$: Observable<HIRLProjectRegistration>;
    let hirlProjectRegistrationId: string;

    if (typeof hirlProjectRegistration === 'string') {
      hirlProjectRegistration$ = this.hirlProjectRegistrationService.retrieve(
        hirlProjectRegistration,
        { ignoreStore: true }
      );
      hirlProjectRegistrationId = hirlProjectRegistration;
    } else {
      hirlProjectRegistration$ = of(hirlProjectRegistration);
      hirlProjectRegistrationId = hirlProjectRegistration?.id;
    }

    let certifiedProjectsCount$: Observable<number>;
    if (typeof certifiedProjectsCount === 'number') {
      certifiedProjectsCount$ = of(certifiedProjectsCount);
    } else {
      certifiedProjectsCount$ = this.eepProgramHomeStatusService
        .list(
          Object.assign(new EEPProgramRequestParams(), {
            customer_hirl_project__registration: hirlProjectRegistrationId,
            state: EEPProgramHomeStatusState.complete,
          })
        )
        .pipe(
          first(),
          map(response => response.count)
        );
    }

    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: hirlProjectRegistration$,
      certifiedProjectsCount: certifiedProjectsCount$,
    }).pipe(
      map(({ currentUser, entity, certifiedProjectsCount }) => {
        if (certifiedProjectsCount) {
          return new ObjectPermissionResponse(
            false,
            'Project in this Registration has been certified.'
          );
        }
        if (
          entity.state === HIRLProjectRegistrationState.active ||
          entity.state === HIRLProjectRegistrationState.pending
        ) {
          if (
            (currentUser.company_info?.slug ===
              CustomerHIRLSettings.companySlug &&
              currentUser.is_company_admin) ||
            currentUser.is_superuser
          ) {
            return new ObjectPermissionResponse(true);
          }
        }

        return new ObjectPermissionResponse(false);
      })
    );
  }
}
