import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { ServerResponse } from '@/core/schemes/server-response';
import { RaterRole } from '@/data/core/models';
import { User } from '@/data/core/models/user';
import { environment } from '../../../../environments/environment';

export class RaterRoleRequestParams extends ListRequestParams {
  user?: number;
  hidden?: boolean;
}

@Injectable({ providedIn: 'root' })
export class RaterRoleService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/rater_roles/`;

  list(params: RaterRoleRequestParams) {
    return this.http.get<ServerResponse<RaterRole>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(userId: number, options?: IDataServiceMethodOptions) {
    const ignoreStore = options?.ignoreStore;
    return this.http.get<User>(`${this.baseUrl}${userId}`, {});
  }
}
