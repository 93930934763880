import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectNotes = () =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.note.entities
  );

export const selectNote = (id: string) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.note.entities[id]
  );

const selectNoteLoading = (id: string) =>
  createSelector(selectSimulationFeature, state => state.note.loading[id]);

export const selectNoteViewSet = (id: string) =>
  createSelector(selectNote(id), selectNoteLoading(id), (data, loading) => ({
    data,
    loading,
  }));
