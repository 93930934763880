import { ModelFormControl, createConditionalValidator } from '../ModelForm';
import { Validators } from '@angular/forms';
import { FloorCovering, StudType } from '../../enumerations';
import {
  aboveWarningRangeValidator,
  belowWarningRangeValidator,
  enumValidator,
  withinAshraeFramingFactorRangeValidator,
  withinRValueRange,
} from '@/modules/simulation/validtaors/helper';
import { FieldLabels } from '../base';
import {
  LayerBackendDict,
  LayerFrameType,
  createNewLayer,
} from './AssemblyLayer';
import { BaseModelWithAssemblyFormGroup } from './AssemblyCalculator';
import { MaterialItem, getMaterial } from './Material';

// Labels
export const FIELD_LABELS: FieldLabels<FrameFloorTypeBackendDict> = {
  name: 'Name',
  layers: 'Layers',
  joist_type: 'Joist Type',
  joist_width: 'Joist Width',
  joist_depth: 'Joist Depth',
  joist_spacing: 'Joist Spacing',
  framing_factor: 'Framing Factor',
  use_default_framing_factor: 'Use default framing factor',
  cavity_insulation_r_value: 'Cavity Insulation R-Value',
  cavity_insulation_thickness: 'Cavity Insulation Thickness',
  cavity_insulation_grade: 'Cavity Insulation Grade',
  continuous_insulation_r_value: 'Continuous Insulation R-Value',
  units: 'Units',
  floor_covering: 'Floor Covering',
  assembly_u_value: 'Assembly U-Value',
  user_defined_assembly_u_value: 'Contains Assembly Details',
  note: 'Note',
};

export const DEFAULT_FRAME_FLOOR_TYPE: FrameFloorTypeBackendDict = {
  id: null,
  name: '',
  joist_width: 0.0,
  joist_depth: 0.0,
  joist_spacing: 0.0,
  framing_factor: 0.0,
  cavity_insulation_r_value: 0.0,
  cavity_insulation_thickness: 0.0,
  cavity_insulation_grade: 0.0,
  continuous_insulation_r_value: 0.0,
  units: 'inch',
  floor_covering: FloorCovering.TILE,
  assembly_u_value: 0.0,
  layers: [],
  joist_type: null,
  use_default_framing_factor: false,
  user_defined_assembly_u_value: false,
  note: '',
};

export interface FrameFloorTypeBackendDict {
  id: number | null;
  name: string;
  layers: LayerBackendDict[];
  joist_type: StudType;
  joist_width: number;
  joist_depth: number;
  joist_spacing: number;
  framing_factor: number;
  use_default_framing_factor: boolean;
  cavity_insulation_r_value: number;
  cavity_insulation_thickness: number;
  cavity_insulation_grade: number;
  continuous_insulation_r_value: number;
  units: string;
  floor_covering: FloorCovering;
  assembly_u_value: number;
  user_defined_assembly_u_value: boolean;
  note: string;
}

export const FloorCoveringToMaterial = {
  [FloorCovering.CARPET]: MaterialItem.FLOOR_COVERING_CARPET,
  [FloorCovering.CODE_CARPET]: MaterialItem.FLOOR_COVERING_CARPET,
  [FloorCovering.TILE]: MaterialItem.FLOOR_COVERING_TILE,
  [FloorCovering.HARDWOOD]: MaterialItem.FLOOR_COVERING_HARDWOOD,
  [FloorCovering.VINYL]: MaterialItem.FLOOR_COVERING_LINOLEUM,
  [FloorCovering.RIGID_R3_INSULATION]: MaterialItem.INSULATION_RIGID_FIBERGLASS,
};

export class FrameFloorTypeFormGroup extends BaseModelWithAssemblyFormGroup {
  frameType: LayerFrameType = LayerFrameType.FRAME_FLOOR;
  convertClassicFormToLayer() {
    const layers: LayerBackendDict[] = [];
    const instance = this.value;

    if (
      instance.joist_type ||
      (instance.joist_depth && instance.joist_width && instance.joist_spacing)
    ) {
      // We can assume floor joists here
      if (instance.joist_type === null) {
        instance.joist_type = StudType.WOOD_STUD;
      }
      const layer = this.getStudWall(instance);
      layers.push(layer);
    }

    if (instance.continuous_insulation_r_value) {
      layers.push(this.getContinuousInsulationLayer(instance));
    }

    // Sheathing
    layers.push({
      ...createNewLayer(),
      materials: [getMaterial(MaterialItem.SHEATHING_PLYWOOD_0P625)],
    });

    if (instance.floor_covering) {
      layers.push({
        ...createNewLayer(),
        materials: [
          {
            ...getMaterial(FloorCoveringToMaterial[instance.floor_covering]),
          },
        ],
      });
    }

    return layers;
  }

  constructor(frameFloorType) {
    const validateOnlyIfClassicForm = createConditionalValidator(
      parentControls =>
        parentControls.layers.value.length === 0 &&
        !parentControls.user_defined_assembly_u_value.value,
      ['layers', 'user_defined_assembly_u_value']
    );
    const validateOnlyIfClassicJoist = createConditionalValidator(
      parentControls =>
        parentControls.layers.value.length === 0 &&
        parentControls.joist_type.value !== null,
      ['joist_type']
    );

    const validateOnlyIfClassicJoistWithoutDefaultFraming =
      createConditionalValidator(
        parentControls =>
          parentControls.layers.value.length === 0 &&
          parentControls.joist_type.value !== null &&
          !parentControls.use_default_framing_factor.value,
        ['joist_type', 'use_default_framing_factor']
      );
    super({
      id: new ModelFormControl(frameFloorType.id, Validators.required),
      name: new ModelFormControl(frameFloorType.name, [
        Validators.required,
        Validators.maxLength(128),
      ]),
      layers: new ModelFormControl(frameFloorType.layers),
      joist_type: new ModelFormControl(
        frameFloorType.joist_type,
        ...validateOnlyIfClassicForm([enumValidator(StudType, true)])
      ),
      joist_width: new ModelFormControl(
        frameFloorType.joist_width,
        ...validateOnlyIfClassicJoist([Validators.required, Validators.min(0)])
      ),
      joist_depth: new ModelFormControl(
        frameFloorType.joist_depth,
        ...validateOnlyIfClassicJoist([Validators.required, Validators.min(0)])
      ),
      joist_spacing: new ModelFormControl(
        frameFloorType.joist_spacing,
        ...validateOnlyIfClassicJoist([Validators.required, Validators.min(0)])
      ),
      framing_factor: new ModelFormControl(
        frameFloorType.framing_factor,
        ...validateOnlyIfClassicJoistWithoutDefaultFraming([
          Validators.required,
          withinAshraeFramingFactorRangeValidator(0.12),
        ])
      ),
      use_default_framing_factor: new ModelFormControl(
        frameFloorType.use_default_framing_factor
      ),
      cavity_insulation_r_value: new ModelFormControl(
        frameFloorType.cavity_insulation_r_value,
        ...validateOnlyIfClassicJoist([withinRValueRange()])
      ),
      cavity_insulation_thickness: new ModelFormControl(
        frameFloorType.cavity_insulation_thickness,
        ...validateOnlyIfClassicForm([Validators.required, Validators.min(0)])
      ),
      cavity_insulation_grade: new ModelFormControl(
        frameFloorType.cavity_insulation_grade,
        ...validateOnlyIfClassicJoist([Validators.required, Validators.min(0)])
      ),
      continuous_insulation_r_value: new ModelFormControl(
        frameFloorType.continuous_insulation_r_value,
        ...validateOnlyIfClassicForm([
          belowWarningRangeValidator(5),
          aboveWarningRangeValidator(95),
        ])
      ),
      floor_covering: new ModelFormControl(
        frameFloorType.floor_covering,
        ...validateOnlyIfClassicForm([enumValidator(FloorCovering)])
      ),
      assembly_u_value: new ModelFormControl(frameFloorType.assembly_u_value, [
        Validators.required,
        Validators.min(0.0),
      ]),
      user_defined_assembly_u_value: new ModelFormControl(
        frameFloorType.user_defined_assembly_u_value
      ),
    });
  }
}
