<div class="container" *ngIf="initialized">
  <div class="row mt-3">
    <div class="col-6">
      <h5>
        <button
          mat-raised-button
          color="accent"
          appBackButton
          [fallbackUrl]="'hi/projects'"
        >
          <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
        </button>
        {{
          HIRLProjectProjectRegistrationTypeLabelMapping[
            hirlProjectRegistration.project_type
          ]
        }}
        Registration
        <app-hirl-project-registration-state
          [projectRegistration]="hirlProjectRegistration"
          [display]="'badge'"
        ></app-hirl-project-registration-state>
      </h5>
    </div>
    <div class="col-6 text-right">
      <app-history-mat-button
        [objectId]="hirlProjectRegistration.id"
        [modelClassName]="'hirl_project_registration'"
        class="mr-1"
      >
        History
      </app-history-mat-button>
      <app-permission-mat-button
        [permissionResponse]="deletePermissionResponse"
        [hideOnFalse]="true"
        class="mr-1"
        (action)="onDeleteRegistration()"
      >
        Delete
      </app-permission-mat-button>
      <app-permission-mat-button
        [permissionResponse]="abandonPermissionResponse"
        [hideOnFalse]="true"
        class="mr-1"
        (action)="onAbandonRegistration()"
      >
        Abandon
      </app-permission-mat-button>
      <app-permission-mat-button
        [permissionResponse]="editPermissionResponse"
        class="mr-1"
        (action)="onEditRegistration()"
      >
        Edit
      </app-permission-mat-button>
      <app-permission-mat-button
        [appMenuTriggerFor]="addProjectMenu"
        [permissionResponse]="addProjectPermissionResponse"
        *ngIf="
          (hirlProjectRegistration.project_type ===
            HIRLProjectRegistrationType.singleFamily &&
            !isWRIProgramSelected()) ||
          hirlProjectRegistration.project_type ===
            HIRLProjectRegistrationType.multiFamily
        "
      >
        Add New Project
      </app-permission-mat-button>
      <mat-menu #addProjectMenu="matMenu">
        <button
          mat-menu-item
          [routerLink]="[
            '/',
            'hi',
            'project_registrations',
            hirlProjectRegistration.id,
            'add',
            'building'
          ]"
          [queryParams]="{ is_accessory_structure: false }"
          *ngIf="
            hirlProjectRegistration.project_type ===
            HIRLProjectRegistrationType.multiFamily
          "
        >
          Add New Building
        </button>
        <button
          mat-menu-item
          [routerLink]="[
            '/',
            'hi',
            'project_registrations',
            hirlProjectRegistration.id,
            'add',
            'home'
          ]"
          [queryParams]="{ is_accessory_structure: false }"
          *ngIf="
            hirlProjectRegistration.project_type ===
            HIRLProjectRegistrationType.singleFamily
          "
        >
          Add New Home
        </button>
        <button
          mat-menu-item
          *ngIf="!isWRIProgramSelected()"
          [routerLink]="[
            '/',
            'hi',
            'project_registrations',
            hirlProjectRegistration.id,
            'add',
            'building'
          ]"
          [queryParams]="{ is_accessory_structure: true }"
        >
          Add New Accessory Structure
        </button>
        <button
          mat-menu-item
          *ngIf="!isWRIProgramSelected()"
          [routerLink]="[
            '/',
            'hi',
            'project_registrations',
            hirlProjectRegistration.id,
            'add',
            'stand-alone-commercial-space'
          ]"
        >
          Add New Entire Building Commercial Space
        </button>
        <button
          mat-menu-item
          *ngIf="
            !isWRIProgramSelected() &&
            hirlProjectRegistration.project_type ===
              HIRLProjectRegistrationType.singleFamily
          "
          [routerLink]="[
            '/',
            'hi',
            'project_registrations',
            hirlProjectRegistration.id,
            'add',
            'accessory-dwelling-unit'
          ]"
        >
          Add New Accessory Dwelling Unit
        </button>
      </mat-menu>
    </div>
  </div>

  <div
    class="row mt-2"
    *ngIf="
      hirlProjectRegistration.project_type ==
      HIRLProjectRegistrationType.multiFamily
    "
  >
    <div class="col-12">
      <div class="row">
        <div class="col-8">
          <div class="row">
            <div class="col-12">
              <div class="card mb-2">
                <div class="card-header">MF Development Information</div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <b>Program:</b>
                    <a
                      [routerLink]="[
                        '',
                        'eep_program',
                        hirlProjectRegistration.eep_program
                      ]"
                      target="_blank"
                      matTooltip="Open Program in new window"
                    >
                      {{ hirlProjectRegistration.eep_program_info.name }}
                    </a>
                  </li>
                  <li class="list-group-item">
                    <b>MF Development Name:</b>
                    <span
                      *ngIf="
                        hirlProjectRegistration.project_name &&
                        !hirlProjectRegistration.subdivision
                      "
                    >
                      {{ hirlProjectRegistration.project_name }}
                    </span>
                    <a
                      *ngIf="
                        hirlProjectRegistration.project_name &&
                        hirlProjectRegistration.subdivision
                      "
                      [routerLink]="[
                        '/',
                        'subdivision',
                        hirlProjectRegistration.subdivision
                      ]"
                      target="_blank"
                    >
                      {{ hirlProjectRegistration.project_name }}
                    </a>
                  </li>
                  <li class="list-group-item">
                    <b>Estimated Completion Date:</b>
                    {{
                      hirlProjectRegistration.estimated_completion_date
                        | date : "MMMM yyyy"
                    }}
                  </li>
                  <li class="list-group-item">
                    <b>Registered By:</b>
                    <a
                      [routerLink]="[
                        '',
                        'profile',
                        hirlProjectRegistration.registration_user
                      ]"
                      target="_blank"
                      matTooltip="Open Profile in new window"
                    >
                      {{
                        hirlProjectRegistration.registration_user_info
                          .first_name
                      }}
                      {{
                        hirlProjectRegistration.registration_user_info.last_name
                      }}
                    </a>
                  </li>
                  <li class="list-group-item">
                    <b>Company:</b>&nbsp;{{
                      hirlProjectRegistration.registration_user_info?.company_info?.name
                    }}
                    <a
                      [routerLink]="[
                        '',
                        'company',
                        hirlProjectRegistration.registration_user_info
                          ?.company_info?.company_type,
                        hirlProjectRegistration.registration_user_info.company
                      ]"
                      target="_blank"
                      matTooltip="Open company page in new window"
                    >
                      <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="row">
            <div class="col-12">
              <div class="card mb-2">
                <div class="card-header">Client Company</div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <app-company-display
                      [company]="
                        hirlProjectRegistration.builder_organization_info
                      "
                      [displayType]="'onlyNameAsLink'"
                    ></app-company-display>
                  </li>
                  <li class="list-group-item">
                    <b>Client NGBS ID:</b>
                    {{ hirlProjectRegistration.client_ngbs_id }}
                  </li>
                  <li
                    class="list-group-item"
                    *ngIf="
                      hirlProjectRegistration.client_ngbs_id_before_company_consolidation
                    "
                  >
                    <b>Legacy Client NGBS ID:</b>
                    {{
                      hirlProjectRegistration.client_ngbs_id_before_company_consolidation
                    }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isAppealsProjectsCountIsMoreThanThreshold">
            <div class="col-12">
              <mat-error
              >
                Client <app-company-display
                [company]="
                  hirlProjectRegistration.builder_organization_info
                "
                [displayType]="'onlyNameAsLink'"
              ></app-company-display> has had 3 previous NGBS appeals projects.
              </mat-error>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngTemplateOutlet="approveContainer"></ng-container>

      <div class="row">
        <div class="col-12">
          <mat-tab-group animationDuration="0ms" disableRipple="true">
            <mat-tab>
              <ng-template mat-tab-label>
                Projects
                <fa-icon
                  [icon]="['fas', 'spinner']"
                  [spin]="true"
                  class="ml-1"
                  *ngIf="projectsCount === -1"
                ></fa-icon>
                <span
                  class="badge badge-primary ml-1"
                  *ngIf="projectsCount !== -1"
                >
                  {{ projectsCount }}
                </span>
              </ng-template>
              <div class="mt-2 mb-2">
                <app-hirl-project-list-tab></app-hirl-project-list-tab>
              </div>
            </mat-tab>
            <mat-tab label="General Information">
              <div class="mt-2">
                <div class="col-12">
                  <div class="card mb-2">
                    <div class="card-header">General Information</div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <b>Is Build-to-Rent:</b>
                        {{ hirlProjectRegistration.is_build_to_rent | yesNo }}
                      </li>
                      <li class="list-group-item">
                        <b>Additional emails copied on invoice:</b>
                        {{ hirlProjectRegistration.billing_email || "N/A" }}
                      </li>
                      <li class="list-group-item">
                        <b>Is COI held by a company different than the Builder/Client?:</b>
                        <ng-container *ngIf="hirlProjectRegistration.coi_held_by_different_company">
                          {{ hirlProjectRegistration.coi_held_by_different_company }}
                        </ng-container>
                        <ng-container *ngIf="!hirlProjectRegistration.coi_held_by_different_company">
                          No
                        </ng-container>
                      </li>
                      <li class="list-group-item">
                        <b>Community named on certificate:</b>
                        {{
                          hirlProjectRegistration.community_named_on_certificate
                            | yesNo
                        }}
                      </li>
                      <li class="list-group-item">
                        <b>Application packet completion:</b>
                        {{
                          responsibleNameTypeLabelMapping[
                            hirlProjectRegistration
                              .application_packet_completion
                          ]
                        }}
                      </li>
                      <li class="list-group-item">
                        <b>Party named on certificate:</b>
                        {{
                          responsibleNameTypeLabelMapping[
                            hirlProjectRegistration.party_named_on_certificate
                          ]
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Contacts">
              <div class="col-12 mt-2">
                <div class="row">
                  <div class="col-4">
                    <div class="card mb-2">
                      <div class="card-header">Client</div>
                      <ul class="list-group list-group-flush">
                        <ng-container
                          [ngTemplateOutlet]="contactCardList"
                          [ngTemplateOutletContext]="{
                            contactCard:
                              hirlProjectRegistration.builder_organization_contact_info
                          }"
                        >
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card mb-2">
                      <div class="card-header">Builder Organization</div>
                      <ul class="list-group list-group-flush">
                        <ng-container
                          [ngTemplateOutlet]="contactCardList"
                          [ngTemplateOutletContext]="{
                            contactCard:
                              hirlProjectRegistration.legacy_builder_organization_contact_info
                          }"
                        >
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card mb-2">
                      <div class="card-header">Architect</div>
                      <ul class="list-group list-group-flush">
                        <ng-container
                          [ngTemplateOutlet]="contactCardList"
                          [ngTemplateOutletContext]="{
                            contactCard:
                              hirlProjectRegistration.architect_organization_contact_info
                          }"
                        >
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card mb-2">
                      <div class="card-header">Community Owner</div>
                      <ul class="list-group list-group-flush">
                        <ng-container
                          [ngTemplateOutlet]="contactCardList"
                          [ngTemplateOutletContext]="{
                            contactCard:
                              hirlProjectRegistration.community_owner_organization_contact_info
                          }"
                        >
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card mb-2">
                      <div class="card-header">Developer</div>
                      <ul class="list-group list-group-flush">
                        <ng-container
                          [ngTemplateOutlet]="contactCardList"
                          [ngTemplateOutletContext]="{
                            contactCard:
                              hirlProjectRegistration.developer_organization_contact_info
                          }"
                        >
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Sales & Marketing">
              <div class="container mt-2">
                <div class="row">
                  <div class="col-4">
                    <div class="card mb-2">
                      <div class="card-header">Sales Contact</div>
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                          <b>Website:</b>
                          <a
                            *ngIf="hirlProjectRegistration.sales_website_url"
                            href="{{
                              hirlProjectRegistration.sales_website_url
                            }}"
                            target="_blank"
                          >
                            {{ hirlProjectRegistration.sales_website_url }}
                          </a>
                        </li>
                        <li class="list-group-item">
                          <b>Email:</b>&nbsp;{{
                            hirlProjectRegistration.sales_email
                          }}
                        </li>
                        <li class="list-group-item">
                          <b>Phone:</b>&nbsp;{{
                            hirlProjectRegistration.sales_phone
                              | mask : "000-000-0000"
                          }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="card mb-2">
                      <div class="card-header">Marketing Information</div>
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                          <b>Name:</b>
                          {{ hirlProjectRegistration.marketing_first_name }}
                          {{ hirlProjectRegistration.marketing_last_name }}
                        </li>
                        <li class="list-group-item">
                          <b>Email:</b>&nbsp;{{
                            hirlProjectRegistration.marketing_email
                          }}
                        </li>
                        <li class="list-group-item">
                          <b>Phone:</b>&nbsp;{{
                            hirlProjectRegistration.marketing_phone
                              | mask : "000-000-0000"
                          }}
                        </li>
                        <li class="list-group-item">
                          <b>Website:</b>
                          <a
                            *ngIf="hirlProjectRegistration.project_website_url"
                            href="{{
                              hirlProjectRegistration.project_website_url
                            }}"
                            target="_blank"
                          >
                            {{ hirlProjectRegistration.project_website_url }}
                          </a>
                        </li>
                        <li class="list-group-item">
                          <b>Project Description:</b>
                          {{ hirlProjectRegistration.project_description }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Financial Information">
              <div class="mt-2">
                <div class="col-12">
                  <div class="card mb-2">
                    <div class="card-header">Financial Information</div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <b
                          >Building(s) will include non-residential space
                          (retail/commercial) ?:</b
                        >
                        {{
                          hirlProjectRegistration.building_will_include_non_residential_space
                            | yesNo
                        }}
                      </li>
                      <li class="list-group-item">
                        <b
                          >Seeking HUD Mortgage Insurance Premium Reduction?:</b
                        >
                        {{
                          hirlProjectRegistration.seeking_hud_mortgage_insurance_premium_reduction
                            | yesNo
                        }}
                      </li>
                      <li class="list-group-item">
                        <b
                          >Seeking HUD Green and Resilient Retrofit Program??:</b
                        >
                        {{
                          hirlProjectRegistration.seeking_hud_green_and_resilient_retrofit_program
                            | yesNo
                        }}
                      </li>
                      <li class="list-group-item">
                        <b>Seeking Fannie Mae Green financing?:</b>&nbsp;
                        {{
                          hirlProjectRegistration.seeking_fannie_mae_green_financing
                            | yesNo
                        }}
                      </li>
                      <li class="list-group-item">
                        <b>Seeking Freddie Mac Green financing?:</b>&nbsp;{{
                          hirlProjectRegistration.seeking_freddie_mac_green_financing
                            | yesNo
                        }}
                      </li>
                      <li class="list-group-item">
                        <b>Intended to be affordable housing?:</b>&nbsp;{{
                          hirlProjectRegistration.intended_to_be_affordable_housing
                            | yesNo
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row mt-2"
    *ngIf="
      hirlProjectRegistration.project_type ==
      HIRLProjectRegistrationType.singleFamily
    "
  >
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="card mb-2">
            <div class="card-header">Project Information</div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <b>Program:</b>&nbsp;{{
                  hirlProjectRegistration.eep_program_info.name
                }}&nbsp;<a
                  [routerLink]="[
                    '',
                    'eep_program',
                    hirlProjectRegistration.eep_program
                  ]"
                  target="_blank"
                  matTooltip="Open Program in new window"
                >
                  <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                </a>
              </li>
              <li class="list-group-item">
                <b>Registered By:</b>&nbsp;{{
                  hirlProjectRegistration.registration_user_info.first_name
                }}
                {{ hirlProjectRegistration.registration_user_info.last_name }}
                <a
                  [routerLink]="[
                    '',
                    'profile',
                    hirlProjectRegistration.registration_user
                  ]"
                  target="_blank"
                  matTooltip="Open Profile in new window"
                >
                  <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                </a>
              </li>
              <li class="list-group-item">
                <b>Company:</b>&nbsp;{{
                  hirlProjectRegistration.registration_user_info?.company_info?.name
                }}
                <a
                  [routerLink]="[
                    '',
                    'company',
                    hirlProjectRegistration.registration_user_info?.company_info?.company_type,
                    hirlProjectRegistration.registration_user_info.company
                  ]"
                  target="_blank"
                  matTooltip="Open company page in new window"
                >
                  <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                </a>
              </li>
              <li
                class="list-group-item"
                *ngIf="hirlProjectRegistration.subdivision"
              >
                <b>Subdivision:</b>
                <a
                  [routerLink]="[
                    '/',
                    'subdivision',
                    hirlProjectRegistration.subdivision
                  ]"
                  target="_blank"
                >
                  {{ hirlProjectRegistration.subdivision_info?.name }}
                </a>
              </li>
              <li class="list-group-item">
                <b>Is this project Build-To-Rent?:</b>
                {{ hirlProjectRegistration.is_build_to_rent | yesNo }}
              </li>
              <li class="list-group-item">
                <b>Client:</b>
                <app-company-display
                  [company]="hirlProjectRegistration.builder_organization_info"
                  [displayType]="'onlyNameAsLink'"
                ></app-company-display>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <div class="card mb-2">
                <div class="card-header">Client Company</div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <app-company-display
                      [company]="
                        hirlProjectRegistration.builder_organization_info
                      "
                      [displayType]="'onlyNameAsLink'"
                    ></app-company-display>
                  </li>
                  <li class="list-group-item">
                    <b>Client NGBS ID:</b>
                    {{ hirlProjectRegistration.client_ngbs_id }}
                  </li>
                  <li
                    class="list-group-item"
                    *ngIf="
                      hirlProjectRegistration.client_ngbs_id_before_company_consolidation
                    "
                  >
                    <b>Legacy Client NGBS ID:</b>
                    {{
                      hirlProjectRegistration.client_ngbs_id_before_company_consolidation
                    }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isAppealsProjectsCountIsMoreThanThreshold">
            <div class="col-12">
              <mat-error
              >
                Client <app-company-display
                [company]="
                  hirlProjectRegistration.builder_organization_info
                "
                [displayType]="'onlyNameAsLink'"
              ></app-company-display> has had 3 previous NGBS appeals projects.
              </mat-error>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngTemplateOutlet="approveContainer"></ng-container>

      <div class="row">
        <div class="col-12">
          <mat-tab-group animationDuration="0ms" disableRipple="true">
            <mat-tab>
              <ng-template mat-tab-label>
                Projects
                <fa-icon
                  [icon]="['fas', 'spinner']"
                  [spin]="true"
                  class="ml-1"
                  *ngIf="projectsCount === -1"
                ></fa-icon>
                <span
                  class="badge badge-primary ml-1"
                  *ngIf="projectsCount !== -1"
                >
                  {{ projectsCount }}
                </span>
              </ng-template>
              <div class="mt-2 mb-2">
                <app-hirl-project-list-tab></app-hirl-project-list-tab>
              </div>
            </mat-tab>
            <mat-tab label="General Information">
              <div class="mt-2">
                <div class="col-12">
                  <div class="card mb-2">
                    <div class="card-header">General Information</div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <b>Is Build-to-Rent:</b>
                        {{ hirlProjectRegistration.is_build_to_rent | yesNo }}
                      </li>
                      <li class="list-group-item">
                        <b>Additional emails copied on invoice:</b>
                        {{ hirlProjectRegistration.billing_email || "N/A" }}
                      </li>
                      <li class="list-group-item">
                        <b>Is COI held by a company different than the Builder/Client?:</b>
                        <ng-container *ngIf="hirlProjectRegistration.coi_held_by_different_company">
                          {{ hirlProjectRegistration.coi_held_by_different_company }}
                        </ng-container>
                        <ng-container *ngIf="!hirlProjectRegistration.coi_held_by_different_company">
                          No
                        </ng-container>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Contacts">
              <div class="col-12 mt-2">
                <div class="row">
                  <div class="col-4">
                    <div class="card mb-2">
                      <div class="card-header">Client</div>
                      <ul class="list-group list-group-flush">
                        <ng-container
                          [ngTemplateOutlet]="contactCardList"
                          [ngTemplateOutletContext]="{
                            contactCard:
                              hirlProjectRegistration.builder_organization_contact_info
                          }"
                        >
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card mb-2">
                      <div class="card-header">Builder</div>
                      <ul class="list-group list-group-flush">
                        <ng-container
                          [ngTemplateOutlet]="contactCardList"
                          [ngTemplateOutletContext]="{
                            contactCard:
                              hirlProjectRegistration.legacy_builder_organization_contact_info
                          }"
                        >
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card mb-2">
                      <div class="card-header">Developer</div>
                      <ul class="list-group list-group-flush">
                        <ng-container
                          [ngTemplateOutlet]="contactCardList"
                          [ngTemplateOutletContext]="{
                            contactCard:
                              hirlProjectRegistration.developer_organization_contact_info
                          }"
                        >
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Financial Information">
              <div class="mt-2">
                <div class="col-12">
                  <div class="card mb-2">
                    <div class="card-header">Financial Information</div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <b
                          >Building(s) will include non-residential space
                          (retail/commercial) ?:</b
                        >
                        {{
                          hirlProjectRegistration.building_will_include_non_residential_space
                            | yesNo
                        }}
                      </li>
                      <li class="list-group-item">
                        <b
                          >Seeking HUD Mortgage Insurance Premium Reduction?:</b
                        >
                        {{
                          hirlProjectRegistration.seeking_hud_mortgage_insurance_premium_reduction
                            | yesNo
                        }}
                      </li>
                      <li class="list-group-item">
                        <b
                          >Seeking HUD Green and Resilient Retrofit Program?:</b
                        >
                        {{
                          hirlProjectRegistration.seeking_hud_green_and_resilient_retrofit_program
                            | yesNo
                        }}
                      </li>
                      <li class="list-group-item">
                        <b>Seeking Fannie Mae Green financing?:</b>&nbsp;
                        {{
                          hirlProjectRegistration.seeking_fannie_mae_green_financing
                            | yesNo
                        }}
                      </li>
                      <li class="list-group-item">
                        <b>Seeking Freddie Mac Green financing?:</b>&nbsp;{{
                          hirlProjectRegistration.seeking_freddie_mac_green_financing
                            | yesNo
                        }}
                      </li>
                      <li class="list-group-item">
                        <b>Intended to be affordable housing?:</b>&nbsp;{{
                          hirlProjectRegistration.intended_to_be_affordable_housing
                            | yesNo
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row mt-2"
    *ngIf="
      hirlProjectRegistration.project_type ==
      HIRLProjectRegistrationType.landDevelopment
    "
  >
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="card mb-2">
            <div class="card-header">Project Information</div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <b>Program:</b>&nbsp;{{
                  hirlProjectRegistration.eep_program_info.name
                }}&nbsp;<a
                  [routerLink]="[
                    '',
                    'eep_program',
                    hirlProjectRegistration.eep_program
                  ]"
                  target="_blank"
                  matTooltip="Open Program in new window"
                >
                  <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                </a>
              </li>
              <li class="list-group-item">
                <b>Registered By:</b>&nbsp;{{
                  hirlProjectRegistration.registration_user_info.first_name
                }}
                {{ hirlProjectRegistration.registration_user_info.last_name }}
                <a
                  [routerLink]="[
                    '',
                    'profile',
                    hirlProjectRegistration.registration_user
                  ]"
                  target="_blank"
                  matTooltip="Open Profile in new window"
                >
                  <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                </a>
              </li>
              <li class="list-group-item">
                <b>Company:</b>&nbsp;{{
                  hirlProjectRegistration.registration_user_info?.company_info?.name
                }}
                <a
                  [routerLink]="[
                    '',
                    'company',
                    hirlProjectRegistration.registration_user_info?.company_info?.company_type,
                    hirlProjectRegistration.registration_user_info.company
                  ]"
                  target="_blank"
                  matTooltip="Open company page in new window"
                >
                  <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <div class="card mb-2">
                <div class="card-header">Client Company</div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <app-company-display
                      [company]="
                        hirlProjectRegistration.builder_organization_info
                      "
                      [displayType]="'onlyNameAsLink'"
                    ></app-company-display>
                  </li>
                  <li class="list-group-item">
                    <b>Client NGBS ID:</b>
                    {{ hirlProjectRegistration.client_ngbs_id }}
                  </li>
                  <li
                    class="list-group-item"
                    *ngIf="
                      hirlProjectRegistration.client_ngbs_id_before_company_consolidation
                    "
                  >
                    <b>Legacy Client NGBS ID:</b>
                    {{
                      hirlProjectRegistration.client_ngbs_id_before_company_consolidation
                    }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isAppealsProjectsCountIsMoreThanThreshold">
            <div class="col-12">
              <mat-error>
                Client <app-company-display
                [company]="
                  hirlProjectRegistration.builder_organization_info
                "
                [displayType]="'onlyNameAsLink'"
              ></app-company-display> has had 3 previous NGBS appeals projects.
              </mat-error>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngTemplateOutlet="approveContainer"></ng-container>

      <div class="row">
        <div class="col-12">
          <mat-tab-group animationDuration="0ms" disableRipple="true">
            <mat-tab>
              <ng-template mat-tab-label>
                Projects
                <fa-icon
                  [icon]="['fas', 'spinner']"
                  [spin]="true"
                  class="ml-1"
                  *ngIf="projectsCount === -1"
                ></fa-icon>
                <span
                  class="badge badge-primary ml-1"
                  *ngIf="projectsCount !== -1"
                >
                  {{ projectsCount }}
                </span>
              </ng-template>
              <div class="mt-2 mb-2">
                <app-hirl-project-list-tab></app-hirl-project-list-tab>
              </div>
            </mat-tab>
            <mat-tab label="Contacts">
              <div class="col-12 mt-2">
                <div class="row">
                  <div class="col-4">
                    <div class="card mb-2">
                      <div class="card-header">Client</div>
                      <ul class="list-group list-group-flush">
                        <ng-container
                          [ngTemplateOutlet]="contactCardList"
                          [ngTemplateOutletContext]="{
                            contactCard:
                              hirlProjectRegistration.builder_organization_contact_info
                          }"
                        >
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card mb-2">
                      <div class="card-header">Developer</div>
                      <ul class="list-group list-group-flush">
                        <ng-container
                          [ngTemplateOutlet]="contactCardList"
                          [ngTemplateOutletContext]="{
                            contactCard:
                              hirlProjectRegistration.developer_organization_contact_info
                          }"
                        >
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #approveContainer>
  <div
    class="row mb-2"
    *ngIf="
      currentUser?.company_info?.slug === customerHIRLSettings.companySlug &&
      hirlProjectRegistration.state === HIRLProjectRegistrationState.new
    "
  >
    <div class="col-12">
      <div>
        <div class="card text-center">
          <div class="card-body">
            <button
              mat-raised-button
              color="accent"
              class="mr-2"
              (click)="approve()"
            >
              Approve
            </button>
            <button mat-raised-button color="primary" (click)="reject()">
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contactCardList let-contactCard="contactCard">
  <li class="list-group-item">
    <b>Company: </b>
    <app-company-display
      displayType="onlyNameAsLink"
      [company]="contactCard?.company_info"
    ></app-company-display>
  </li>
  <li class="list-group-item">
    <b>First Name:</b> {{ contactCard?.first_name }}
  </li>
  <li class="list-group-item">
    <b>Last Name:</b> {{ contactCard?.last_name }}
  </li>
  <li class="list-group-item" *ngFor="let emailContact of contactCard?.emails">
    <b>Email:</b>&nbsp;{{ emailContact.email }}
  </li>
  <li
    class="list-group-item"
    *ngIf="!contactCard || !contactCard.emails.length"
  >
    <b>Email:</b>
  </li>
  <li class="list-group-item" *ngFor="let phoneContact of contactCard?.phones">
    <b>Phone:</b>&nbsp;{{ phoneContact.phone | mask : "000-000-0000" }}
  </li>
  <li
    class="list-group-item"
    *ngIf="!contactCard || !contactCard.phones.length"
  >
    <b>Phone:</b>
  </li>
</ng-template>
