import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AirSourceHeatPumpService } from '@/data/simulation/services/air-source-heat-pump.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as AirSourceHeatPumpActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { AirSourceHeatPumpValidator } from '../../validtaors/air-source-heat-pump.validator';

@Injectable()
export class AirSourceHeatPumpEffects {
  loadAirSourceHeatPumps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AirSourceHeatPumpActions.loadAirSourceHeatPumps),
      mergeMap(action => {
        const errors = AirSourceHeatPumpValidator.validate(
          action.airSourceHeatPumps
        );
        return of(
          AirSourceHeatPumpActions.loadAirSourceHeatPumpsSuccess({
            airSourceHeatPumps: action.airSourceHeatPumps,
            errors,
          })
        );
      })
    );
  });

  updateAirSourceHeatPump$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AirSourceHeatPumpActions.updateAirSourceHeatPump),
      mergeMap(action => {
        return this.airSourceHeatPumpService
          .update(action.airSourceHeatPumpChanges)
          .pipe(
            mergeMap(airSourceHeatPump => {
              const errors = AirSourceHeatPumpValidator.validate([
                airSourceHeatPump,
              ]);
              return of(
                AirSourceHeatPumpActions.loadAirSourceHeatPumpsSuccess({
                  airSourceHeatPumps: [airSourceHeatPump],
                  errors,
                })
              );
            }),
            catchError(error =>
              of(
                AirSourceHeatPumpActions.updateAirSourceHeatPumpFailure({
                  id: action.airSourceHeatPumpChanges.id,
                }),
                SharedActions.reportAPIFailure({ error })
              )
            )
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private airSourceHeatPumpService: AirSourceHeatPumpService
  ) {}
}
