import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { StudType } from '../../enumerations';
import {
  DEFAULT_MATERIAL,
  Material,
  MaterialBackendDict,
  createMaterialForm,
} from './Material';
import { FormArray } from '@angular/forms';

export enum LayerFrameType {
  ABOVE_GRADE_WALL = 'above_grade_wall',
  FOUNDATION_WALL = 'foundation_wall',
  FRAME_FLOOR = 'frame_floor',
  ROOF = 'roof',
}

export interface LayerBackendDict {
  id: number;
  stud_type: StudType;
  stud_reference: string;
  stud_depth: number;
  stud_width: number;
  stud_spacing: number;
  stud_r_value: number;
  flange_thickness: number;
  framing_type: LayerFrameType;
  framing_fraction: number;
  user_defined_framing_fraction: boolean;
  materials: MaterialBackendDict[];
}

export function createAssemblyLayerForm(
  assemblyLayer: LayerBackendDict
): ModelFormGroup {
  const materialsForm = new FormArray([]);
  (assemblyLayer.materials || []).forEach(material => {
    materialsForm.push(createMaterialForm(material));
  });

  return new ModelFormGroup(
    {
      id: new ModelFormControl(assemblyLayer.id),
      framing_fraction: new ModelFormControl(assemblyLayer.framing_fraction),
      user_defined_framing_fraction: new ModelFormControl(
        assemblyLayer.user_defined_framing_fraction
      ),
      stud_type: new ModelFormControl(assemblyLayer.stud_type),
      stud_depth: new ModelFormControl(assemblyLayer.stud_depth),
      stud_width: new ModelFormControl(assemblyLayer.stud_width),
      stud_r_value: new ModelFormControl(assemblyLayer.stud_r_value),
      stud_spacing: new ModelFormControl(assemblyLayer.stud_spacing),
      flange_thickness: new ModelFormControl(assemblyLayer.flange_thickness),
      materials: materialsForm,
    },
    {
      validators: [
        (controls: ModelFormGroup) => {
          const materials = controls.get('materials')
            .value as MaterialBackendDict[];
          const studDepth = controls.get('stud_depth').value as number;
          const studType = controls.get('stud_type').value;

          const totalMaterialThickness = materials.reduce(
            (acc, material) => acc + material.thickness,
            0
          );
          if (studType && totalMaterialThickness > studDepth) {
            return {
              materialsThicknessGraterThanStudDepth: {
                materialsThickness: totalMaterialThickness,
                studDepth: studDepth,
              },
            };
          } else {
            return null;
          }
        },
        (controls: ModelFormGroup) => {
          const flangeThickness = controls.get('flange_thickness')
            .value as number;
          const studType = controls.get('stud_type').value as StudType;
          if (studType === StudType.STEEL_FRAME && flangeThickness === null) {
            return {
              flangeThicknessRequiredForSteelStud: true,
            };
          }
        },
        (controls: ModelFormGroup) => {
          const studType = controls.get('stud_type').value as StudType;
          const framingFraction = controls.get('framing_fraction')
            .value as number;

          if (studType) {
            const invalidFields = [];
            for (const field of [
              'stud_width',
              'stud_spacing',
              'stud_depth',
              'stud_r_value',
            ]) {
              if (!controls.get(field).value) {
                invalidFields.push(field);
              }
            }

            if (controls.get('user_defined_framing_fraction').value) {
              if (framingFraction === null) {
                invalidFields.push('framing_fraction');
              }
            }

            if (invalidFields.length > 0) {
              return {
                studDetailsRequired: {
                  invalidFields: invalidFields.join(', '),
                },
              };
            }
          }
        },
      ],
    }
  );
}

export enum CalculationMethod {
  PARALLEL_PATH = 0,
  ISOTHERMAL_PLANES = 1,
}

export const StudTypeToRValue = {
  [StudType.WOOD_STUD]: 0.45,
  [StudType.STEEL_FRAME]: 0.61,
  [StudType.SOLID_CONCRETE]: 0.11,
};

export const MaterialRValue = {
  INTERIOR_AIR_FILM: 0.68,
  EXTERIOR_AIR_FILM: 0.17,
};

export function createNewLayer(): LayerBackendDict {
  return {
    id: null,
    framing_fraction: null,
    user_defined_framing_fraction: false,
    stud_type: null,
    stud_depth: null,
    stud_width: null,
    stud_spacing: null,
    flange_thickness: null,
    framing_type: null,
    stud_r_value: null,
    stud_reference: null,
    materials: [{ ...DEFAULT_MATERIAL }],
  };
}

export class Layer {
  materials: Material[];
  name?: string;
  description?: string;
  framing_type: LayerFrameType;
  framing_fraction?: number;
  user_defined_framing_fraction?: boolean;
  stud_type: StudType;
  stud_reference?: string;
  stud_depth?: number;
  stud_width?: number;
  stud_spacing?: number;
  stud_r_value?: number;
  flange_thickness?: number;
  r_value?: number;

  constructor(data: LayerBackendDict) {
    Object.assign(this, data);
    this.materials = data.materials.map(
      materialData => new Material(materialData)
    );
  }

  get thickness(): number {
    return (
      this.stud_depth ??
      this.materials.reduce((sum, material) => sum + material.thickness, 0)
    );
  }

  static getDefaultFramingFraction(
    framing_type: LayerFrameType,
    stud_type: StudType,
    stud_width: number,
    stud_spacing: number
  ): number {
    if (framing_type === LayerFrameType.ABOVE_GRADE_WALL) {
      if (stud_type === StudType.WOOD_STUD) {
        return stud_width / stud_spacing + 0.13625;
      } else if (stud_type === StudType.STEEL_FRAME) {
        return stud_width / stud_spacing + 0.0813;
      } else if (stud_type === StudType.SOLID_CONCRETE) {
        return stud_width / stud_spacing + 0.0;
      }
    } else if (framing_type === LayerFrameType.ROOF) {
      return stud_width / stud_spacing + 0.0475;
    } else if (framing_type === LayerFrameType.FRAME_FLOOR) {
      return stud_width / stud_spacing + 0.03625;
    }
  }

  getFramingFactors() {
    let framing_factor = 0;
    if (!this.user_defined_framing_fraction) {
      this.framing_fraction = Layer.getDefaultFramingFraction(
        this.framing_type,
        this.stud_type,
        this.stud_width,
        this.stud_spacing
      );
    }

    const cavity_grade_area = { 1: 0.0, 2: 0.02, 3: 0.05 }[
      this.materials[0].insulation_grade
    ];
    framing_factor = this.framing_fraction ?? 0;
    return [
      framing_factor,
      1 - framing_factor - cavity_grade_area,
      cavity_grade_area,
    ];
  }
}
