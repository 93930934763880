import { UserInfo } from '@/data/core/models/user';
import { HomeInfo } from '@/data/home/models';

export enum TaskApprovalState {
  new = 'new',
  approved = 'approved',
  rejected = 'rejected',
}

export const TaskApprovalStateLabelMapping: Record<TaskApprovalState, any> = {
  [TaskApprovalState.new]: 'New',
  [TaskApprovalState.approved]: 'Approved',
  [TaskApprovalState.rejected]: 'Rejected',
};

export enum TaskStatus {
  scheduledConfirmed = 'scheduled_confirmed',
  scheduledTentative = 'scheduled_tentative',
  postponed = 'postponed',
  cancelled = 'cancelled',
  completed = 'completed',
}

export const TaskStatusLabelMapping: Record<TaskStatus, any> = {
  [TaskStatus.scheduledConfirmed]: 'Scheduled (Confirmed)',
  [TaskStatus.scheduledTentative]: 'Scheduled (Tentative)',
  [TaskStatus.postponed]: 'Postponed',
  [TaskStatus.cancelled]: 'Cancelled',
  [TaskStatus.completed]: 'Completed',
};

export class Task {
  id?: number;
  assignees?: number[];
  assignees_info?: UserInfo[];
  note?: string;
  approval_state?: TaskApprovalState;
  datetime?: string;
  status?: string;
  assigner?: number;
  assigner_info?: UserInfo;
  home_info?: HomeInfo;
  created_at?: string;
}

export class FlatListTask {
  id?: number;
  assignees?: number[];
  assignees_info?: UserInfo[];
  task_type_name?: string;
  note?: string;
  approval_state?: TaskApprovalState;
  datetime?: string;
  status?: string;
  assigner?: number;
  assigner_info?: UserInfo;
  home_info?: HomeInfo;
  created_at?: string;
}

export class TaskChangeState {
  task_ids: number[];
  new_state: TaskApprovalState;
  approval_annotation: string;
}

export class TaskChangeStatus {
  task_ids: number[];
  new_status: TaskStatus;
  status_annotation?: string;
}

export class TaskExportToCal {
  task_ids: number[];
}
