import {
  AnalysisBackendDict,
  AnalysisEngine,
  AnalysisEngineLabels,
  AnalysisListItem,
  AnalysisTypeLabels,
} from '@/data/simulation/models/reports/Analysis';
import { AnalysisService } from '@/data/simulation/services/analysis.service';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss'],
})
export class AnalysisComponent {
  @Input() entity: AnalysisListItem;
  @Input() change: number;

  expanded = false;
  statusIcon: string;
  detailedAnalysis: AnalysisBackendDict;
  eplusEngine = AnalysisEngine.EPLUS;
  analysisTypeLabels = AnalysisTypeLabels;
  analysisEngineLabels = AnalysisEngineLabels;

  constructor(private analysisService: AnalysisService) {}

  ngOnInit() {
    switch (this.entity.status) {
      case 'failed':
        this.statusIcon = 'error';
        break;
      case 'complete':
        this.statusIcon = 'check_circle';
        break;
      case 'pending':
        this.statusIcon = 'pending';
        break;
      case 'started':
        this.statusIcon = 'pending';
        break;
      default:
        this.statusIcon = null;
        break;
    }
    this.detailedAnalysis = this.entity;
  }
}
