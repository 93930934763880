import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { loadSimulation } from '../../state/simulation/actions';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectSimulation } from '../../state/simulation/selectors';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { SimulationBackendDict } from '@/data/simulation/models/simulation';
import {
  EditorNavigationService,
  Tab,
} from '../../services/editor-navigation.service';
import { SimulationRightsService } from '../../services/simulation-rights.service';
import { flushSimulationStore } from '../../state/shared/shared.actions';

@Component({
  selector: 'app-edit-simulation',
  templateUrl: './edit-simulation.component.html',
  styleUrls: ['./edit-simulation.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        showError: true,
      },
    },
  ],
})
export class EditSimulationComponent implements OnInit, OnDestroy {
  public initialized = false;
  tabs = Tab;
  activeTabLink: string;

  public simulation: SimulationBackendDict;
  private componentDestroyed$ = new Subject();
  isExpanded = true;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private editorNavigationService: EditorNavigationService,
    private simulationRightsService: SimulationRightsService,
    private navigation: NavigationService
  ) {}

  ngOnInit() {
    this.store.dispatch(toggleLoading({ payload: true }));
    this.store.dispatch(flushSimulationStore());
    this.editorNavigationService.init();

    this.editorNavigationService
      .getActiveTabLinkObservable()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(tab => {
        this.activeTabLink = tab;
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.store.dispatch(
          loadSimulation({
            id: params.simulationId,
          })
        );
      });

    this.store
      .select(selectSimulation())
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(simulation => simulation !== null && simulation !== undefined),
        take(1)
      )
      .subscribe(simulation => {
        this.store.dispatch(toggleLoading({ payload: false }));
        this.initialized = true;
        this.simulation = simulation;
        this.simulationRightsService.init(simulation);
      });
  }

  ngOnDestroy(): void {
    this.store.dispatch(flushSimulationStore());
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
