import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { User } from '@/data/core/models/user';
import { getUser } from '@/state/selectors/user/user.selector';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { UserPermissionService } from '@/modules/user/services/user-permission-service';
import { RaterRole } from '@/data/core/models';
import {
  RaterRoleRequestParams,
  RaterRoleService,
} from '@/data/core/services/rater-role.service';
import { ServerResponse } from '@/core/schemes/server-response';

@Component({
  selector: 'app-user-detail-tab',
  templateUrl: './user-detail-tab.component.html',
  styleUrls: ['./user-detail-tab.component.scss'],
})
export class UserDetailTabComponent implements OnInit, OnDestroy {
  initialized = false;
  user: User;

  public editPermissionResponse: Observable<ObjectPermissionResponse>;
  public raterRolesList: Observable<ServerResponse<RaterRole>>;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    public injector: Injector,
    private router: Router,
    public userPermissionService: UserPermissionService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private raterRoleService: RaterRoleService,
    public uiHelper: UIHelperService
  ) {
    this.store
      .select(getUser)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(user => {
        this.user = user;
        this.editPermissionResponse = userPermissionService.canEdit(user.id);
        this.raterRolesList = this.raterRoleService.list(
          Object.assign({ user: user.id }, new RaterRoleRequestParams())
        );
        this.initialized = true;
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  raterRoleDisplay(raterRole: RaterRole): string {
    return raterRole.title;
  }
}
