import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppState } from '@/state/reducers';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import {
  FlatListTask,
  Task,
  TaskApprovalState,
} from '@/data/scheduling/models';
import { TaskService } from '@/data/scheduling/services';

@Injectable({
  providedIn: 'root',
})
export class TaskPermissionService {
  constructor(
    public router: Router,
    public store: Store<AppState>,
    public taskService: TaskService
  ) {}

  canEdit(
    task: number | Task | FlatListTask
  ): Observable<ObjectPermissionResponse> {
    const currentUser = this.store.select(getInfoUser).pipe(first());
    let instance = of(task);

    if (typeof task === 'number') {
      instance = this.taskService
        .retrieve(task, { ignoreStore: true })
        .pipe(first());
    }

    return forkJoin([currentUser, instance]).pipe(
      map(([currentUser, instance]) => {
        instance = instance as Task;
        if (
          instance.assignees.indexOf(currentUser.id) !== -1 ||
          instance.assigner === currentUser.id ||
          currentUser.is_superuser
        ) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }

  canChangeState(
    task: number | Task | FlatListTask
  ): Observable<ObjectPermissionResponse> {
    const currentUser = this.store.select(getInfoUser).pipe(first());
    let instance = of(task);

    if (typeof task === 'number') {
      instance = this.taskService
        .retrieve(task, { ignoreStore: true })
        .pipe(first());
    }

    return forkJoin([currentUser, instance]).pipe(
      map(([currentUser, instance]) => {
        instance = instance as Task;
        if (
          instance.approval_state === TaskApprovalState.approved ||
          instance.approval_state === TaskApprovalState.rejected
        ) {
          return new ObjectPermissionResponse(false);
        }
        if (
          instance.assignees.indexOf(currentUser.id) !== -1 ||
          currentUser.is_superuser
        ) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }
}
