import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as SlabActions from './actions';
import * as SlabTypeActions from '../slab-type/actions';
import * as NoteActions from '../note/actions';
import * as SimulationActions from '../simulation/actions';
import * as SharedActions from '../shared/shared.actions';
import { SlabService } from '@/data/simulation/services/slab.service';
import { SlabTypeService } from '@/data/simulation/services/slab-type.service';
import { SlabTypeBackendDict } from '@/data/simulation/models/enclosure/SlabType';
import { SlabBackendDict } from '@/data/simulation/models/enclosure/Slab';
import { ModelGraphService } from '../../services/model-graph.service';
import { SlabValidator } from '../../validtaors/slab.validator';
import { createNote } from '@/data/simulation/models/Note';
import { StateModelName } from '../../state.registry';

@Injectable()
export class SlabEffects {
  loadDetailedSlabs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SlabActions.loadDetailedSlabs),
      mergeMap(action => {
        const detailedSlabTypes: SlabTypeBackendDict[] = [];
        const slabs: SlabBackendDict[] = [];
        const notes = [];
        action.detailedSlabs.forEach(detailedSlab => {
          const { type_info: detailedSlabType, ...slab } = detailedSlab;
          if (detailedSlabType) {
            detailedSlabTypes.push(detailedSlabType);
          }
          this.modelGraphService.attachModel(
            'slab',
            detailedSlab.id,
            'slabType',
            [detailedSlab.type]
          );
          slabs.push(slab);
          notes.push(
            createNote(StateModelName.slab, detailedSlab.id, detailedSlab.note)
          );
        });

        const errors = SlabValidator.validate(action.detailedSlabs);

        return of(
          SlabActions.loadSlabsSuccess({
            slabs: slabs,
            errors: errors,
          }),
          SlabTypeActions.loadSlabTypes({
            slabTypes: detailedSlabTypes,
          }),
          NoteActions.loadNotes({ notes })
        );
      })
    );
  });

  updateSlab$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SlabActions.updateSlab),
      mergeMap(action =>
        this.slabService.update(action.id, action.slabChanges).pipe(
          mergeMap(updatedSlab => {
            const errors = SlabValidator.validate([updatedSlab]);
            return of(
              SlabActions.updateSlabSuccess({
                slab: updatedSlab,
                errors: errors[updatedSlab.id],
              })
            );
          }),
          catchError(error =>
            of(
              SlabActions.updateSlabFailure({
                id: action.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeSlab$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SlabActions.removeSlab),
      mergeMap(action =>
        this.slabService.delete(action.slab.id).pipe(
          mergeMap(() => {
            return of(
              SlabActions.removeSlabSuccess({ id: action.slab.id }),
              SimulationActions.removeItemFromList({
                fieldName: 'slabs',
                id: action.slab.id,
              })
            );
          }),
          catchError(error =>
            of(
              SlabActions.removeSlabFailure({ id: action.slab.id }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeSlabType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SlabTypeActions.removeSlabType),
      mergeMap(action =>
        this.slabTypeService.delete(action.slabTypeId).pipe(
          mergeMap(() => [
            SlabActions.setSlabType({
              slabId: action.slabId,
              slabTypeId: null,
            }),
            SlabTypeActions.removeSlabTypeSuccess({ id: action.slabTypeId }),
          ]),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addSlabType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SlabTypeActions.addSlabType),
      mergeMap(action =>
        this.slabTypeService.create(action.slabId, action.slabType).pipe(
          mergeMap(slabType =>
            of(
              SlabTypeActions.loadSlabTypes({ slabTypes: [slabType] }),
              SlabActions.setSlabType({
                slabId: action.slabId,
                slabTypeId: slabType.id,
              })
            )
          ),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private slabService: SlabService,
    private slabTypeService: SlabTypeService
  ) {}
}
