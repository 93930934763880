export enum SubmissionStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  RETRY = 'RETRY',
}

export const SubmissionStatusMapping: Record<SubmissionStatus, string> = {
  [SubmissionStatus.PENDING]: 'Pending',
  [SubmissionStatus.SUCCESS]: 'Success',
  [SubmissionStatus.FAILURE]: 'Failed',
  [SubmissionStatus.RETRY]: 'Retry',
};

export class ProjectTrackerItem {
  id: number;
  address: string;
  project_id?: string;
  solar_project_id?: string;
  submit_user?: string;
  submit_status?: string;
  solar_submit_status?: string;
  created_date?: string;
  modified_date?: string;
  submission_count?: number;
  overall_submission_status?: string;
}
