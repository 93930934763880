import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { PracticeStandard } from '@/data/customer-hirl/models/practice-standard';
import { ServerResponse } from '@/core/schemes/server-response';
import { PracticeScoringPath } from '@/data/customer-hirl/models';

export class PracticeScoringPathRequestParams extends ListRequestParams {
  standard?: number;
}

@Injectable({
  providedIn: 'root',
})
export class PracticeScoringPathService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/practice_scoring_paths/`;

  list(params?: PracticeScoringPathRequestParams) {
    return this.http.get<ServerResponse<PracticeStandard>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    practiceScoringPathId: number,
    options?: IDataServiceMethodOptions
  ): Observable<PracticeScoringPath> {
    return this.http.get<PracticeScoringPath>(
      `${this.baseUrl}${practiceScoringPathId}`,
      {}
    );
  }

  update(
    practiceScoringPath: PracticeScoringPath,
    practiceScoringPathId: number
  ) {
    return this.http.patch<PracticeScoringPath>(
      `${this.baseUrl}${practiceScoringPathId}/`,
      practiceScoringPath
    );
  }

  delete(practiceScoringPathId: number) {
    return this.http.delete(`${this.baseUrl}${practiceScoringPathId}`);
  }
}
