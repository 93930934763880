import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as WindowTypeActions from './actions';
import * as NoteActions from '../note/actions';
import * as SharedActions from '../shared/shared.actions';
import { WindowTypeService } from '@/data/simulation/services/window-type.service';
import { WindowTypeValidator } from '../../validtaors/window-type.validator';
import { createNote } from '@/data/simulation/models/Note';
import { StateModelName } from '../../state.registry';

@Injectable()
export class WindowTypeEffects {
  loadDetailedWindowTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WindowTypeActions.loadWindowTypes),
      mergeMap(action => {
        const errors = WindowTypeValidator.validate(action.windowTypes);
        const notes = [];
        action.windowTypes.forEach(windowType => {
          notes.push(
            createNote(
              StateModelName.windowType,
              windowType.id,
              windowType.note
            )
          );
        });
        return of(
          WindowTypeActions.loadWindowTypesSuccess({
            windowTypes: action.windowTypes,
            errors,
          }),
          NoteActions.loadNotes({ notes })
        );
      })
    );
  });

  updateWindowType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WindowTypeActions.updateWindowType),
      mergeMap(action =>
        this.windowTypeService.update(action.windowTypeChanges).pipe(
          mergeMap(updatedWindowType => {
            const errors = WindowTypeValidator.validate([updatedWindowType]);
            return of(
              WindowTypeActions.loadWindowTypesSuccess({
                windowTypes: [updatedWindowType],
                errors,
              })
            );
          }),
          catchError(error =>
            of(
              WindowTypeActions.updateWindowTypeFailure({
                id: action.windowTypeChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private windowTypeService: WindowTypeService
  ) {}
}
