import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as AppliancesActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { AppliancesService } from '@/data/simulation/services/appliances.service';
import { AppliancesValidator } from '../../validtaors/appliances.validator';

@Injectable()
export class AppliancesEffects {
  loadAppliancess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppliancesActions.loadAppliances),
      mergeMap(action => {
        const errors = AppliancesValidator.validate([action.appliances]);
        return of(
          AppliancesActions.loadAppliancesSuccess({
            appliances: action.appliances,
            errors: errors,
          })
        );
      })
    );
  });

  updateAppliances$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppliancesActions.updateAppliances),
      mergeMap(action =>
        this.appliancesService.update(action.id, action.appliancesChanges).pipe(
          mergeMap(updatedAppliances => {
            const errors = AppliancesValidator.validate([updatedAppliances]);
            return of(
              AppliancesActions.updateAppliancesSuccess({
                appliances: updatedAppliances,
                errors: errors[updatedAppliances.id],
              })
            );
          }),
          catchError(error =>
            of(
              AppliancesActions.updateAppliancesFailure({
                id: action.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private appliancesService: AppliancesService
  ) {}
}
