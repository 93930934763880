import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as SkylightActions from './actions';
import * as SkylightTypeActions from '../skylight-type/actions';
import * as SimulationActions from '../simulation/actions';
import * as SharedActions from '../shared/shared.actions';
import { SkylightService } from '@/data/simulation/services/skylight.service';
import { SkylightTypeService } from '@/data/simulation/services/skylight-type.service';
import { SkylightTypeBackendDict } from '@/data/simulation/models/enclosure/SkylightType';
import { SkylightBackendDict } from '@/data/simulation/models/enclosure/Skylight';
import { ModelGraphService } from '../../services/model-graph.service';
import { SkylightValidator } from '../../validtaors/skylight.validator';

@Injectable()
export class SkylightEffects {
  loadDetailedSkylights$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkylightActions.loadDetailedSkylights),
      mergeMap(action => {
        const detailedSkylightTypes: SkylightTypeBackendDict[] = [];
        const skylights: SkylightBackendDict[] = [];
        action.detailedSkylights.forEach(detailedSkylight => {
          const { type_info: detailedSkylightType, ...skylight } =
            detailedSkylight;
          if (detailedSkylightType) {
            detailedSkylightTypes.push(detailedSkylightType);
          }
          this.modelGraphService.attachModel(
            'skylight',
            detailedSkylight.id,
            'skylightType',
            [detailedSkylight.type]
          );
          skylights.push(skylight);
        });

        const errors = SkylightValidator.validate(action.detailedSkylights);

        return of(
          SkylightActions.loadSkylightsSuccess({
            skylights: skylights,
            errors: errors,
          }),
          SkylightTypeActions.loadSkylightTypes({
            skylightTypes: detailedSkylightTypes,
          })
        );
      })
    );
  });

  updateSkylight$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkylightActions.updateSkylight),
      mergeMap(action =>
        this.skylightService.update(action.id, action.skylightChanges).pipe(
          mergeMap(updatedSkylight => {
            const errors = SkylightValidator.validate([updatedSkylight]);
            return of(
              SkylightActions.updateSkylightSuccess({
                skylight: updatedSkylight,
                errors: errors[updatedSkylight.id],
              })
            );
          }),
          catchError(error =>
            of(
              SkylightActions.updateSkylightFailure({
                id: action.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeSkylight$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkylightActions.removeSkylight),
      mergeMap(action =>
        this.skylightService.delete(action.skylight.id).pipe(
          mergeMap(() => {
            return of(
              SkylightActions.removeSkylightSuccess({ id: action.skylight.id }),
              SimulationActions.removeItemFromList({
                fieldName: 'skylights',
                id: action.skylight.id,
              })
            );
          }),
          catchError(error =>
            of(
              SkylightActions.removeSkylightFailure({ id: action.skylight.id }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeSkylightType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkylightTypeActions.removeSkylightType),
      mergeMap(action =>
        this.skylightTypeService.delete(action.skylightTypeId).pipe(
          mergeMap(() => [
            SkylightActions.setSkylightType({
              skylightId: action.skylightId,
              skylightTypeId: null,
            }),
            SkylightTypeActions.removeSkylightTypeSuccess({
              id: action.skylightTypeId,
            }),
          ]),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addSkylightType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkylightTypeActions.addSkylightType),
      mergeMap(action =>
        this.skylightTypeService
          .create(action.skylightId, action.skylightType)
          .pipe(
            mergeMap(skylightType =>
              of(
                SkylightTypeActions.loadSkylightTypes({
                  skylightTypes: [skylightType],
                }),
                SkylightActions.setSkylightType({
                  skylightId: action.skylightId,
                  skylightTypeId: skylightType.id,
                })
              )
            ),
            catchError(error => of(SharedActions.reportAPIFailure({ error })))
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private skylightService: SkylightService,
    private skylightTypeService: SkylightTypeService
  ) {}
}
