import { UserInfo } from '@/data/core/models/user';
import { AnnotationTypeInfo } from '@/data/annotation/models/annotation-type';

export class AnnotationInfo {
  id?: number;
  content?: string;
  user?: number;
  user_info?: UserInfo;
  type?: number;
  type_info?: AnnotationTypeInfo;
}

export class Annotation {
  id?: number;
  content?: string;
  user?: number;
  user_info?: UserInfo;
  type?: number;
  type_info?: AnnotationTypeInfo;
  is_public?: boolean;
}
