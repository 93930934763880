import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as WindowActions from './actions';
import * as WindowTypeActions from '../window-type/actions';
import * as SimulationActions from '../simulation/actions';
import * as SharedActions from '../shared/shared.actions';
import { WindowService } from '@/data/simulation/services/window.service';
import { WindowTypeService } from '@/data/simulation/services/window-type.service';
import { WindowTypeBackendDict } from '@/data/simulation/models/enclosure/WindowType';
import { WindowBackendDict } from '@/data/simulation/models/enclosure/Window';
import { ModelGraphService } from '../../services/model-graph.service';
import { WindowValidator } from '../../validtaors/window.validator';

@Injectable()
export class WindowEffects {
  loadDetailedWindows$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WindowActions.loadDetailedWindows),
      mergeMap(action => {
        const windowTypes: WindowTypeBackendDict[] = [];
        const windows: WindowBackendDict[] = [];
        action.detailedWindows.forEach(detailedWindow => {
          const { type_info: windowType, ...window } = detailedWindow;
          if (windowType) {
            windowTypes.push(windowType);
          }
          this.modelGraphService.attachModel(
            'window',
            detailedWindow.id,
            'windowType',
            [detailedWindow.type]
          );
          windows.push(window);
        });

        const errors = WindowValidator.validate(action.detailedWindows);

        return of(
          WindowActions.loadWindowsSuccess({
            windows: windows,
            errors: errors,
          }),
          WindowTypeActions.loadWindowTypes({
            windowTypes: windowTypes,
          })
        );
      })
    );
  });

  updateWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WindowActions.updateWindow),
      mergeMap(action =>
        this.windowService.update(action.id, action.windowChanges).pipe(
          mergeMap(updatedWindow => {
            const errors = WindowValidator.validate([updatedWindow]);
            return of(
              WindowActions.updateWindowSuccess({
                window: updatedWindow,
                errors: errors[updatedWindow.id],
              })
            );
          }),
          catchError(error =>
            of(
              WindowActions.updateWindowFailure({
                id: action.windowChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WindowActions.removeWindow),
      mergeMap(action =>
        this.windowService.delete(action.window.id).pipe(
          mergeMap(() =>
            of(
              WindowActions.removeWindowSuccess({
                id: action.window.id,
              }),
              SimulationActions.removeItemFromList({
                fieldName: 'windows',
                id: action.window.id,
              })
            )
          ),
          catchError(error =>
            of(
              WindowActions.removeWindowFailure({
                id: action.window.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeWindowType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WindowTypeActions.removeWindowType),
      mergeMap(action =>
        this.windowTypeService.delete(action.windowTypeId).pipe(
          mergeMap(() => [
            WindowActions.setWindowType({
              windowId: action.windowId,
              windowTypeId: null,
            }),
            WindowTypeActions.removeWindowTypeSuccess({
              id: action.windowTypeId,
            }),
          ]),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addWindowType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WindowTypeActions.addWindowType),
      mergeMap(action =>
        this.windowTypeService.create(action.windowId, action.windowType).pipe(
          mergeMap(windowType =>
            of(
              WindowTypeActions.loadWindowTypes({
                windowTypes: [windowType],
              }),
              WindowActions.setWindowType({
                windowId: action.windowId,
                windowTypeId: windowType.id,
              })
            )
          ),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private windowService: WindowService,
    private windowTypeService: WindowTypeService
  ) {}
}
