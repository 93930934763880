import {
  Layer,
  LayerBackendDict,
  LayerFrameType,
  createAssemblyLayerForm,
  createNewLayer,
} from '@/data/simulation/models/enclosure/AssemblyLayer';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { BaseAppControlValueAccessor } from '../fields/base-control-value-accessor.directive';
import { WallAssemblyCalculator } from '@/data/simulation/models/enclosure/AssemblyCalculator';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayerValidator } from '../../validtaors/layer.validator';

@Component({
  selector: 'app-assembly-layers',
  templateUrl: './assembly-layers.component.html',
  styleUrls: ['./assembly-layers.component.scss'],
})
export class AssemblyLayersComponent extends BaseAppControlValueAccessor {
  @Output() onChange = new EventEmitter();
  @Input() assembledOn: LayerFrameType = LayerFrameType.ABOVE_GRADE_WALL;

  activeLayerIndex = -1;
  layersForm = new FormArray([]);
  changesExist = false;

  tableHeaders: string[];
  tableData = [];
  showCalculationTable: any;
  methodUsedForCalculation: string;
  layerValidations = [];

  componentDestroyed$ = new Subject();
  foundErrorsInLayers = false;
  activeLayerErrors = {};

  calculateRvalue(value: LayerBackendDict[]) {
    const calc = new WallAssemblyCalculator(value);
    calc.calculate();
    this.tableHeaders = calc.table_headers;
    this.tableData = calc.table_data;
    this.methodUsedForCalculation = calc.methodUsedForCalculation;
  }

  addFormEvent(layerForm) {
    layerForm.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        if (layerForm.controls.user_defined_framing_fraction.value) return;
        const ff = Layer.getDefaultFramingFraction(
          this.assembledOn,
          layerForm.controls.stud_type.value,
          layerForm.controls.stud_width.value,
          layerForm.controls.stud_spacing.value
        );
        layerForm.controls.framing_fraction.setValue(ff, { emitEvent: false });
      });
  }

  updateLayerValidationStaus(layers) {
    layers.forEach((layer, index) => {
      const errors = LayerValidator.validate([layer]);
      if (Object.values(errors[0].field).some(value => value !== null)) {
        this.layerValidations.push(true);
      } else if (Object.keys(errors[0].model).length !== 0) {
        this.layerValidations.push(true);
      } else {
        this.layerValidations.push(false);
      }

      if (this.activeLayerIndex === index) {
        this.activeLayerErrors = errors[0].model;
      }

      this.foundErrorsInLayers = this.layerValidations.some(value => value);
    });
  }

  ngOnInit() {
    super.ngOnInit();
    const layersInfo = this.control.value || [];

    layersInfo.forEach(layer => {
      const layerForm = createAssemblyLayerForm(layer);
      this.addFormEvent(layerForm);
      this.layersForm.push(layerForm);
    });
    this.calculateRvalue(layersInfo);
    this.updateLayerValidationStaus(layersInfo);

    if (layersInfo.length > 0) {
      this.activeLayerIndex = 0;
    }

    this.layersForm.valueChanges.subscribe(value => {
      this.control.setValue(value);
      this.changesExist = true;
      this.calculateRvalue(value);

      this.layerValidations = [];
      this.updateLayerValidationStaus(value);
    });
  }

  removeLayer(index: number) {
    this.layersForm.removeAt(index);
  }

  push() {
    const newForm = createAssemblyLayerForm(createNewLayer());
    this.layersForm.push(newForm);
    this.addFormEvent(newForm);
  }

  append() {
    const newForm = createAssemblyLayerForm(createNewLayer());
    this.layersForm.insert(0, newForm);
    this.addFormEvent(newForm);
  }

  onActiveLayer(index: any) {
    this.activeLayerIndex = index;
    this.activeLayerErrors = LayerValidator.validate([
      this.layersForm.at(index).value,
    ])[0].model;
  }

  onSave() {
    this.onChange.emit();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
