import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as DistributionSystemActions from './actions';
import * as DuctActions from '../duct/actions';
import * as SimulationActions from '../simulation/actions';
import * as SharedActions from '../shared/shared.actions';
import { DistributionSystemService } from '@/data/simulation/services/distribution-system.service';
import { DuctBackendDict } from '@/data/simulation/models/mechanicals/Duct';
import { DistributionSystemBackendDict } from '@/data/simulation/models/mechanicals/DistributionSystem';
import { ModelGraphService } from '../../services/model-graph.service';
import { DistributionSystemValidator } from '../../validtaors/distribution-system.validator';
import { DuctService } from '@/data/simulation/services/duct.service';

@Injectable()
export class DistributionSystemEffects {
  loadDetailedDistributionSystems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DistributionSystemActions.loadDetailedDistributionSystems),
      mergeMap(action => {
        const ducts: DuctBackendDict[] = [];
        const distributionSystems: DistributionSystemBackendDict[] = [];
        action.detailedDistributionSystems.forEach(
          detailedDistributionSystem => {
            const { ducts_info: ductInfos, ...distributionSystem } =
              detailedDistributionSystem;

            if (ductInfos) {
              ducts.push(...ductInfos);
            }
            this.modelGraphService.attachModel(
              'distributionSystem',
              detailedDistributionSystem.id,
              'duct',
              detailedDistributionSystem.ducts
            );
            distributionSystems.push(distributionSystem);
          }
        );

        const errors =
          DistributionSystemValidator.validate(distributionSystems);

        return of(
          DistributionSystemActions.loadDistributionSystemsSuccess({
            distributionSystems: distributionSystems,
            errors: errors,
          }),
          DuctActions.loadDucts({
            ducts: ducts,
          })
        );
      })
    );
  });

  updateDistributionSystem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DistributionSystemActions.updateDistributionSystem),
      mergeMap(action =>
        this.distributionsystemService
          .update(action.distributionSystemChanges)
          .pipe(
            mergeMap(updatedDistributionSystem => {
              const errors = DistributionSystemValidator.validate([
                updatedDistributionSystem,
              ]);
              return of(
                DistributionSystemActions.updateDistributionSystemSuccess({
                  distributionSystem: updatedDistributionSystem,
                  errors: errors[updatedDistributionSystem.id],
                })
              );
            }),
            catchError(error =>
              of(
                DistributionSystemActions.updateDistributionSystemFailure({
                  id: action.distributionSystemChanges.id,
                }),
                SharedActions.reportAPIFailure({ error })
              )
            )
          )
      )
    );
  });

  removeDistributionSystem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DistributionSystemActions.removeDistributionSystem),
      mergeMap(action =>
        this.distributionsystemService
          .delete(action.distributionSystem.id)
          .pipe(
            mergeMap(() =>
              of(
                DistributionSystemActions.removeDistributionSystemSuccess({
                  id: action.distributionSystem.id,
                }),
                SimulationActions.removeItemFromList({
                  fieldName: 'hvac_distribution_systems',
                  id: action.distributionSystem.id,
                })
              )
            ),
            catchError(error =>
              of(
                DistributionSystemActions.removeDistributionSystemFailure({
                  id: action.distributionSystem.id,
                }),
                SharedActions.reportAPIFailure({ error })
              )
            )
          )
      )
    );
  });

  removeDuct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DuctActions.removeDuct),
      mergeMap(action =>
        this.ductService.delete(action.ductId).pipe(
          mergeMap(() => {
            return of(
              DuctActions.removeDuctSuccess({
                id: action.ductId,
              }),
              DistributionSystemActions.removeDuctSuccess({
                ductId: action.ductId,
                distributionSystemId: action.distributionSystemId,
              })
            );
          }),
          catchError(error =>
            of(
              DuctActions.updateDuctFailure({
                id: action.ductId,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  addDuct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DuctActions.addDuct),
      mergeMap(action =>
        this.ductService.create(action.distributionSystemId, action.duct).pipe(
          mergeMap(duct =>
            of(
              DuctActions.loadDucts({ ducts: [duct] }),
              DistributionSystemActions.addDuctSuccess({
                distributionSystemId: action.distributionSystemId,
                ductId: duct.id,
              })
            )
          ),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private distributionsystemService: DistributionSystemService,
    private ductService: DuctService
  ) {}
}
