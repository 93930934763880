import { CompanyInfo } from '@/data/company/models';
import { SubdivisionInfo } from '@/data/subdivision/models';
import {
  AsynchronousProcessedDocument,
  CustomerDocument,
} from '@/data/filehandling/models';
import { UserInfo } from '@/data/core/models/user';
import {
  AnalysisInfo,
  SimulationInfoBackendDict,
} from '@/data/simulation/models';


import { SimulationConfig } from '@/data/simulation/models/SimulationConfig';

export enum ApexRequestType {
  simulate = 'simulate',
  simulate_and_create_fp = 'simulate_and_create_fp',
  simulate_and_create_home = 'simulate_and_create_home',
  resnet_qa = 'resnet_qa',
}

export const ApexRequestTypeLabelMapping: Record<ApexRequestType, string> = {
  [ApexRequestType.simulate]: 'Run APEX',
  [ApexRequestType.simulate_and_create_fp]: 'Run APEX & Create AXIS Floorplan',
  [ApexRequestType.simulate_and_create_home]:
    'Run APEX & Create AXIS Floorplan and Home',
  [ApexRequestType.resnet_qa]: 'Resnet QA',
};

export enum ApexRequestEnergyModelType {
  preliminary_model = 'preliminary_model',
  projected_rating = 'projected_rating',
  confirmed_rating = 'confirmed_rating',
  sampled_rating = 'sampled_rating',
  threshold_rating = 'threshold_rating',
}

export const ApexRequestEnergyModelTypeLabelMapping: Record<
  ApexRequestEnergyModelType,
  string
> = {
  [ApexRequestEnergyModelType.preliminary_model]: 'Preliminary Model',
  [ApexRequestEnergyModelType.projected_rating]: 'Projected Rating',
  [ApexRequestEnergyModelType.confirmed_rating]: 'Confirmed Rating',
  [ApexRequestEnergyModelType.sampled_rating]: 'Sampled Rating',
  [ApexRequestEnergyModelType.threshold_rating]: 'Threshold Rating',
};

export enum ApexRequestDocumentState {
  IN_QUEUE = 'in_queue',
  IN_PROGRESS = 'in_progress',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export const ApexRequestDocumentStateLabelMapping: Record<
  ApexRequestDocumentState,
  string
> = {
  [ApexRequestDocumentState.IN_QUEUE]: 'In queue',
  [ApexRequestDocumentState.IN_PROGRESS]: 'In progress',
  [ApexRequestDocumentState.SUCCESS]: 'Success',
  [ApexRequestDocumentState.FAILURE]: 'Failure',
};

export enum RESENTQAType {
  DUCT_SYSTEM = 'duct_system',
  HERS_IAD = 'hers_iad',
  HERS_METHOD = 'hers_method',
  HERS_METHOD_2014_PRE_E = 'hers_method_2014_pre_e',
  HERS_METHOD_2019_PRE_A = 'hers_method_2019_pre_a',
  HERS_REFERENCE = 'hers_reference',
  HERS_REFERENCE_2014 = 'hers_reference_2014',
  HERS_REFERENCE_2019_PRE_A = 'hers_reference_2019_pre_a',
  HOT_WATER = 'hot_water',
  HOT_WATER_2014_PRE_A = 'hot_water_2014_pre_a',
  HOT_WATER_2019_PRE_A = 'hot_water_2019_pre_a',
  HVAC = 'hvac',
  STANDARD_140 = 'standard_140',
}

export const RESENTQATypeLabelMapping: Record<RESENTQAType, string> = {
  [RESENTQAType.DUCT_SYSTEM]: 'Duct System',
  [RESENTQAType.HERS_IAD]: 'HERS IAD',
  [RESENTQAType.HERS_METHOD]: 'HERS Method',
  [RESENTQAType.HERS_METHOD_2014_PRE_E]: 'HERS Method 2014 Pre Addendum E',
  [RESENTQAType.HERS_METHOD_2019_PRE_A]: 'HERS Method 2019 Pre Addendum A',
  [RESENTQAType.HERS_REFERENCE]: 'HERS Reference',
  [RESENTQAType.HERS_REFERENCE_2014]: 'HERS Reference 2014',
  [RESENTQAType.HERS_REFERENCE_2019_PRE_A]:
    'HERS Reference 2019 Pre Addendum A',
  [RESENTQAType.HOT_WATER]: 'Hot Water',
  [RESENTQAType.HOT_WATER_2014_PRE_A]: 'Hot Water 2014 Pre Addendum A',
  [RESENTQAType.HOT_WATER_2019_PRE_A]: 'Hot Water 2019 Pre Addendum A',
  [RESENTQAType.HVAC]: 'HVAC',
  [RESENTQAType.STANDARD_140]: 'Standard 140',
};

export class ApexRequest {
  id?: number;
  builder_organization?: number;
  builder_organization_info?: CompanyInfo;

  readonly user: number;
  readonly user_info: UserInfo;

  subdivision?: number;
  subdivision_info?: SubdivisionInfo;

  request_type: ApexRequestType;
  simulation_config: number;
  simulation_config_info: SimulationConfig;
  energy_model_type: ApexRequestEnergyModelType;
  resnet_qa_type?: RESENTQAType;

  updated_at?: string;
  created_at?: string;
}

export class ApexRequestCreate {
  builder_organization?: number;
  subdivision?: number;
  readonly user: number;
  readonly user_info: UserInfo;
  request_type: ApexRequestType;
  energy_model_type?: ApexRequestEnergyModelType;
  simulation_config: SimulationConfig;
  resnet_qa_type?: RESENTQAType;
  documents: number[];
}

export class ApexRequestDocument {
  id?: number;
  customer_document: number;
  customer_document_info: CustomerDocument;

  asynchronous_process_document?: number;
  asynchronous_process_document_info?: AsynchronousProcessedDocument;

  state: ApexRequestDocumentState;

  simulation?: number;
  simulation_info?: SimulationInfoBackendDict;

  analysis?: number;
  analysis_info?: AnalysisInfo;

  home_status: number;
}

export class ApexRequestFlatList {
  id?: number;
  builder_organization_info: CompanyInfo;
}
