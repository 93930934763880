import {
  createConditionalValidator,
  ModelFormControl,
  ModelFormGroup,
} from '../ModelForm';
import { Validators } from '@angular/forms';
import { FieldLabels } from '../base';
import {
  CoolingEfficiencyUnit,
  FuelType,
  HeatingCoolingCapacityUnit,
  HeatingEfficiencyUnit,
  MechanicalMotorType,
  TemperatureUnit,
} from '@/data/simulation/enumerations';
import { enumValidator } from '@/modules/simulation/validtaors/helper';

// Labels
export const FIELD_LABELS: FieldLabels<AirSourceHeatPumpBackendDict> = {
  name: 'Name',
  fuel: 'Fuel',
  auto_size_capacity: {
    label: 'Auto Size Capacity',
    description:
      'Auto sizing can be used to autosize the equipment for research purposes or to run tests.',
  },
  heating_capacity_17f: 'Heating Capacity 17°F',
  heating_capacity_47f: 'Heating Capacity 47°F',
  heating_efficiency: 'Heating Efficiency',
  heating_efficiency_unit: 'Heating Efficiency Unit',
  cooling_capacity: 'Cooling Capacity',
  capacity_unit: 'Capacity Unit',
  cooling_efficiency: 'Cooling Efficiency',
  cooling_efficiency_unit: 'Cooling Efficiency Unit',
  motor_type: 'Motor Type',
  sensible_heat_fraction: 'Sensible Heat Fraction',
  has_desuperheater: 'Has Desuperheater',
  backup_heating_fuel: 'Backup Heating Fuel',
  backup_heating_capacity: 'Backup Heating Capacity',
  backup_heating_capacity_unit: 'Backup Heating Capacity Unit',
  backup_heating_efficiency: 'Backup Heating Efficiency',
  backup_heating_efficiency_unit: 'Backup Heating Efficiency Unit',
  switch_over_temperature: 'Switch Over Temperature',
  switch_over_temperature_unit: 'Switch Over Temperature Unit',
  fan_power: 'Fan Power',
  air_flow_defect_ratio: 'Air Flow defect ratio',
  charge_defect_ratio: 'Charge defect ratio',
  ahri_reference_number: 'AHRI Reference Number',
  note: 'Note',
};

export interface AirSourceHeatPumpBackendDict {
  id: number;
  name: string;
  fuel: FuelType;
  heating_efficiency: number;
  heating_efficiency_unit: HeatingEfficiencyUnit;
  auto_size_capacity: boolean;
  heating_capacity_17f: number;
  heating_capacity_47f: number;
  cooling_efficiency: number;
  cooling_efficiency_unit: CoolingEfficiencyUnit;
  cooling_capacity: number;
  capacity_unit: HeatingCoolingCapacityUnit;
  motor_type: MechanicalMotorType;
  sensible_heat_fraction: number;
  has_desuperheater: boolean;
  backup_heating_fuel: FuelType;
  backup_heating_efficiency: number;
  backup_heating_efficiency_unit: HeatingEfficiencyUnit;
  backup_heating_capacity: number;
  backup_heating_capacity_unit: HeatingCoolingCapacityUnit;
  switch_over_temperature: number;
  switch_over_temperature_unit: TemperatureUnit;
  fan_power: number;
  air_flow_defect_ratio: number;
  charge_defect_ratio: number;
  ahri_reference_number: string;
  note: string;
}

export function createAirSourceHeatPumpForm(
  data: AirSourceHeatPumpBackendDict
): ModelFormGroup {
  const validateOnlyIfAutoSizeDisabled = createConditionalValidator(
    parentControls => !parentControls.auto_size_capacity.value,
    ['auto_size_capacity']
  );

  return new ModelFormGroup(
    {
      id: new ModelFormControl(data.id),
      name: new ModelFormControl(data.name, [
        Validators.required,
        Validators.maxLength(128),
      ]),
      fuel: new ModelFormControl(data.fuel, enumValidator(FuelType)),
      heating_efficiency: new ModelFormControl(data.heating_efficiency, [
        Validators.required,
        Validators.min(0),
      ]),
      heating_efficiency_unit: new ModelFormControl(
        data.heating_efficiency_unit,
        enumValidator(HeatingEfficiencyUnit)
      ),
      auto_size_capacity: new ModelFormControl(data.auto_size_capacity),
      heating_capacity_47f: new ModelFormControl(
        data.heating_capacity_47f,
        ...validateOnlyIfAutoSizeDisabled([Validators.required])
      ),
      heating_capacity_17f: new ModelFormControl(
        data.heating_capacity_17f,
        ...validateOnlyIfAutoSizeDisabled([Validators.nullValidator])
      ),
      cooling_efficiency: new ModelFormControl(data.cooling_efficiency, [
        Validators.required,
        Validators.min(0),
      ]),
      cooling_efficiency_unit: new ModelFormControl(
        data.cooling_efficiency_unit,
        enumValidator(CoolingEfficiencyUnit)
      ),
      cooling_capacity: new ModelFormControl(
        data.cooling_capacity,
        Validators.required
      ),
      capacity_unit: new ModelFormControl(
        data.capacity_unit,
        ...validateOnlyIfAutoSizeDisabled([
          enumValidator(HeatingCoolingCapacityUnit),
        ])
      ),
      motor_type: new ModelFormControl(
        data.motor_type,
        enumValidator(MechanicalMotorType, true)
      ),
      sensible_heat_fraction: new ModelFormControl(
        data.sensible_heat_fraction,
        [Validators.nullValidator, Validators.min(0.5), Validators.max(1.0)]
      ),
      has_desuperheater: new ModelFormControl(data.has_desuperheater),
      backup_heating_fuel: new ModelFormControl(
        data.backup_heating_fuel,
        enumValidator(FuelType)
      ),
      backup_heating_efficiency: new ModelFormControl(
        data.backup_heating_efficiency,
        [Validators.nullValidator, Validators.min(0)]
      ),
      backup_heating_efficiency_unit: new ModelFormControl(
        data.backup_heating_efficiency_unit,
        enumValidator(HeatingEfficiencyUnit, true)
      ),
      backup_heating_capacity: new ModelFormControl(
        data.backup_heating_capacity,
        Validators.nullValidator
      ),
      backup_heating_capacity_unit: new ModelFormControl(
        data.backup_heating_capacity_unit,
        enumValidator(HeatingCoolingCapacityUnit)
      ),
      switch_over_temperature: new ModelFormControl(
        data.switch_over_temperature,
        [Validators.nullValidator, Validators.min(0)]
      ),
      switch_over_temperature_unit: new ModelFormControl(
        data.switch_over_temperature_unit,
        enumValidator(TemperatureUnit)
      ),
      fan_power: new ModelFormControl(data.fan_power, [
        Validators.nullValidator,
        Validators.min(0),
      ]),
      air_flow_defect_ratio: new ModelFormControl(data.air_flow_defect_ratio, [
        Validators.nullValidator,
        Validators.min(-0.9),
        Validators.max(9.0),
      ]),
      charge_defect_ratio: new ModelFormControl(data.charge_defect_ratio, [
        Validators.nullValidator,
        Validators.min(-0.25),
        Validators.max(0.25),
      ]),
      ahri_reference_number: new ModelFormControl(data.ahri_reference_number),
      note: new ModelFormControl(data.note),
    },
    {
      validators: [
        validateAutoSizeCapacity,
        validateHeatingEfficiencyUnit,
        validateBackupHeatingEfficiencyUnit,
        validateSwitchOverTemperatureUnit,
      ],
    }
  );
}

export function validateAutoSizeCapacity(controls: ModelFormGroup) {
  const autoSizeCapacity = controls.get('auto_size_capacity')?.value;

  // Auto size capacity is not allowed
  if (autoSizeCapacity) {
    return { autoSizeCapacityError: true, autoSizeCapacityWarn: true };
  }
}

export function validateHeatingEfficiencyUnit(controls: ModelFormGroup) {
  const heatingEfficiencyUnit = controls.get('heating_efficiency_unit')?.value;
  const heatingEfficiency = controls.get('heating_efficiency')?.value;
  const allowedUnits = [
    HeatingEfficiencyUnit.HSPF,
    HeatingEfficiencyUnit.HSPF2,
    HeatingEfficiencyUnit.COP,
  ];

  if (heatingEfficiency && !allowedUnits.includes(heatingEfficiencyUnit)) {
    return { invalidHeatingEfficiencyUnit: true };
  }
}

export function validateBackupHeatingEfficiencyUnit(controls: ModelFormGroup) {
  const backupHeatingEfficiencyUnit = controls.get(
    'backup_heating_efficiency_unit'
  )?.value;
  const backupHeatingEfficiency = controls.get(
    'backup_heating_efficiency'
  )?.value;
  const allowedUnits = [
    HeatingEfficiencyUnit.AFUE,
    HeatingEfficiencyUnit.PCT_EFF,
  ];

  if (
    backupHeatingEfficiency &&
    !allowedUnits.includes(backupHeatingEfficiencyUnit)
  ) {
    return { invalidBackupHeatingEfficiencyUnit: true };
  }
}

export function validateSwitchOverTemperatureUnit(controls: ModelFormGroup) {
  const switchOverTemperatureUnit = controls.get(
    'switch_over_temperature_unit'
  )?.value;
  const switchOverTemperature = controls.get('switch_over_temperature')?.value;
  const allowedUnits = [TemperatureUnit.F];

  if (
    switchOverTemperature &&
    !allowedUnits.includes(switchOverTemperatureUnit)
  ) {
    return { invalidSwitchOverTemperatureUnit: true };
  }
}
