<div class="container-fluid" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <div class="row mb-2">
        <div class="col-6">
          <h3>
            <span *ngIf="!entitiesIsLoading">{{ entitiesCount | number }}</span>
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="entitiesIsLoading"></fa-icon>
            Program Eligibility Records
          </h3>
        </div>
        <div class="col-6 text-right">
          <button mat-raised-button color="accent" (click)="downloadReport($event)" [disabled]="!canExport.hasPermission" [matTooltip]="canExport.message">
            Export
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="filterFromGroup" class="card">
                <h5 class="card-header">
                  Filters
                  <a href="#" class="small float-right" (click)="resetFilters($event);">Reset filters</a>
                </h5>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Search</mat-label>
                        <app-search-input formControlName="search" placeholder="Type to search something"></app-search-input>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row" *ngIf="!currentUser.is_superuser">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Included Companies</mat-label>
                        <app-generic-model-chips formControlName="company_access_info" modelClassName="company_access" [panelWidth]="640" [initialValueIds]="storedParams.company_access" [filterParams]="{'user': currentUser.id}"></app-generic-model-chips>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Project Status</mat-label>
                        <mat-select formControlName="state" [multiple]="true">
                          <mat-option [value]="''"> Any </mat-option>
                          <mat-option
                              *ngFor="
                                    let item of EEPProgramHomeStatusStateLabelMapping
                                      | defaultOrderKeyValue
                                  "
                              [value]="item.key"
                          >
                            {{ item.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Subdivision</mat-label>
                        <app-generic-model-chips formControlName="subdivision_info" modelClassName="subdivision" [panelWidth]="640" [initialValueIds]="storedParams.home__subdivision"></app-generic-model-chips>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Client/Builder</mat-label>
                        <app-generic-model-chips formControlName="builder_organization_info" modelClassName="company" [filterParams]="{company_type: 'builder'}" [panelWidth]="640" [initialValueIds]="storedParams.builder_organization"></app-generic-model-chips>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Rater/Verification Company</mat-label>
                        <app-generic-model-chips formControlName="rater_organization_info" modelClassName="company" [filterParams]="{company_type: 'rater'}" [panelWidth]="640" [initialValueIds]="storedParams.rater_organization"></app-generic-model-chips>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Utility Company</mat-label>
                        <app-generic-model-chips formControlName="utility_organization_info" modelClassName="company" [filterParams]="{company_type: 'utility'}" [panelWidth]="640" [initialValueIds]="storedParams.utility_organization"></app-generic-model-chips>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Program</mat-label>
                        <app-generic-model-chips formControlName="eep_program_info" modelClassName="eep_program" [panelWidth]="640" [initialValueIds]="storedParams.eep_program"></app-generic-model-chips>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>City</mat-label>
                        <app-generic-model-chips formControlName="home__city_info" modelClassName="city" [filterParams]="{ordering: 'name'}" [panelWidth]="640" [initialValueIds]="storedParams.home__city"></app-generic-model-chips>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>US State</mat-label>
                        <app-generic-model-chips formControlName="home__state_info" modelClassName="us_state" [filterParams]="{ordering: 'name'}" [panelWidth]="640" [initialValueIds]="storedParams.home__state"></app-generic-model-chips>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Program Created Date</mat-label>
                        <mat-date-range-input [rangePicker]="created_date_picker">
                          <input matStartDate formControlName="created_date__gte" placeholder="Start Date">
                          <input matEndDate formControlName="created_date__lte" placeholder="End Date">
                        </mat-date-range-input>
                        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix (click)="filterFromGroup.get('created_date__gte').setValue(null); filterFromGroup.get('created_date__lte').setValue(null);">
                          <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker-toggle matSuffix [for]="created_date_picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #created_date_picker></mat-date-range-picker>

                        <mat-error *ngIf="filterFromGroup.controls.created_date__gte.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                        <mat-error *ngIf="filterFromGroup.controls.created_date__lte.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Program Certification Date</mat-label>
                        <mat-date-range-input [rangePicker]="certification_date_picker">
                          <input matStartDate formControlName="certification_date__gte" placeholder="Start Date">
                          <input matEndDate formControlName="certification_date__lte" placeholder="End Date">
                        </mat-date-range-input>
                        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix (click)="filterFromGroup.get('certification_date__gte').setValue(null); filterFromGroup.get('certification_date__lte').setValue(null);">
                          <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker-toggle matSuffix [for]="certification_date_picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #certification_date_picker></mat-date-range-picker>

                        <mat-error *ngIf="filterFromGroup.controls.certification_date__gte.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                        <mat-error *ngIf="filterFromGroup.controls.certification_date__lte.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>QA Status</mat-label>
                        <mat-select formControlName="qa_status__state" [multiple]="true">
                          <mat-option [value]="''"> Any </mat-option>
                          <mat-option
                              *ngFor="
                                    let item of QAStatusStateLabelMapping
                                      | defaultOrderKeyValue
                                  "
                              [value]="item.key"
                          >
                            {{ item.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row" *ngIf="entitiesIsLoading">
            <div class="col-12">
              <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
            </div>
          </div>
          <table mat-table
                 [dataSource]="entities"
                 matSort
                 [matSortActive]="storedParams.toTableSort().active"
                 [matSortDirection]="storedParams.toTableSort().direction"
                 (matSortChange)="onSort($event)"
                 class="w-100"
          >
            <ng-container matColumnDef="home__street_line1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
              <td mat-cell *matCellDef="let row">
                <a [routerLink]="['/', 'home', row.home]" target="_blank">
                  {{ row.address }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="home__subdivision__name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Subdivision/MF Development</th>
              <td mat-cell *matCellDef="let row">
                <a [routerLink]="['/', 'subdivision', row.subdivision_id]" target="_blank">
                  {{ row.subdivision_name }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="floorplan__name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Floorplan</th>
              <td mat-cell *matCellDef="let row">
                <a [routerLink]="['/', 'floorplans', row.floorplan]" target="_blank">
                  {{ row.floorplan_name }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="eep_program__name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Program</th>
              <td mat-cell *matCellDef="let row">
                <a [routerLink]="['/', 'eep_program', row.eep_program]" target="_blank">
                  {{ row.eep_program_name }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Project Status</th>
              <td mat-cell *matCellDef="let row">
                <a [routerLink]="['/', 'home', row.home]" target="_blank">
                  {{ tableStateLabelMapping[row.state] }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="energy_star_32">
              <th mat-header-cell *matHeaderCellDef>ENERGY STAR 3.2</th>
              <td mat-cell *matCellDef="let row">

              </td>
            </ng-container>

            <ng-container matColumnDef="doe_zerh">
              <th mat-header-cell *matHeaderCellDef>DOE ZERH</th>
              <td mat-cell *matCellDef="let row">

              </td>
            </ng-container>

            <ng-container matColumnDef="mbs">
              <th mat-header-cell *matHeaderCellDef>MBS</th>
              <td mat-cell *matCellDef="let row">

              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div class="d-flex flex-row">
            <div class="align-self-center">
            </div>
            <div class="d-flex flex-fill justify-content-end">
              <mat-paginator [length]="entitiesCount"
                             [pageIndex]="$any(storedParams.page) - 1"
                             [pageSize]="storedParams.page_size"
                             (page)="onPaginateChange($event)"
                             aria-label="Select page">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
