import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as RoofTypeActions from './actions';
import * as NoteActions from '../note/actions';
import * as SharedActions from '../shared/shared.actions';
import { RoofTypeService } from '@/data/simulation/services/roof-type.service';
import { ModelGraphService } from '../../services/model-graph.service';
import { RoofTypeValidator } from '../../validtaors/roof-type.validator';
import { createNote } from '@/data/simulation/models/Note';
import { StateModelName } from '../../state.registry';

@Injectable()
export class RoofTypeEffects {
  loadDetailedRoofTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoofTypeActions.loadDetailedRoofTypes),
      mergeMap(action => {
        const notes = [];
        action.roofTypes.forEach(detailedRoofType => {
          notes.push(
            createNote(
              StateModelName.roofType,
              detailedRoofType.id,
              detailedRoofType.note
            )
          );
        });
        const errors = RoofTypeValidator.validate(action.roofTypes);
        return of(
          RoofTypeActions.loadRoofTypesSuccess({
            roofTypes: action.roofTypes,
            errors,
          }),
          NoteActions.loadNotes({ notes })
        );
      })
    );
  });

  updateRoofType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoofTypeActions.updateRoofType),
      mergeMap(action =>
        this.roofTypeService.update(action.id, action.roofTypeChanges).pipe(
          mergeMap(updatedRoofType => {
            const errors = RoofTypeValidator.validate([updatedRoofType]);
            return of(
              RoofTypeActions.loadRoofTypesSuccess({
                roofTypes: [updatedRoofType],
                errors,
              })
            );
          }),
          catchError(error =>
            of(
              RoofTypeActions.updateRoofTypeFailure({
                id: action.roofTypeChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private roofTypeService: RoofTypeService
  ) {}
}
