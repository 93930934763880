import {
  createConditionalValidator,
  ModelFormControl,
  ModelFormGroup,
} from '../ModelForm';
import { Validators } from '@angular/forms';
import { FieldLabels } from '../base';
import {
  CoolingEfficiencyUnit,
  FuelType,
  MechanicalMotorType,
} from '../../enumerations';
import {
  aboveWarningRangeValidator,
  enumValidator,
} from '@/modules/simulation/validtaors/helper';

// Labels
export const FIELD_LABELS: FieldLabels<AirConditionerBackendDict> = {
  name: 'Name',
  fuel: 'Fuel',
  system_type: 'System Type',
  auto_size_capacity: {
    label: 'Auto Size Capacity',
    description:
      'Auto sizing can be used to autosize the equipment for research purposes or to run tests.',
  },
  capacity: 'Capacity',
  capacity_unit: 'Capacity Unit',
  efficiency: 'Efficiency',
  efficiency_unit: 'Efficiency Unit',
  motor_type: 'Motor Type',
  sensible_heat_fraction: 'Sensible Heat Fraction',
  fan_power: 'Fan Power',
  air_flow_defect_ratio: 'Air Flow defect ratio',
  charge_defect_ratio: 'Charge defect ratio',
  ahri_reference_number: 'AHRI Reference Number',
  note: 'Note',
  fuel_display: 'Fuel',
  capacity_unit_display: 'Capacity Unit',
  efficiency_unit_display: 'Efficiency Unit',
};

export enum CoolingSystemType {
  AIR_CONDITIONER = 'ac',
  EVAPORATIVE_COOLER = 'evap',
  ROOM_AC = 'room_ac',
  CHILLER = 'chiller',
  COOLING_TOWER = 'tower',
}

export const CoolingSystemTypeLabels: Record<CoolingSystemType, string> = {
  [CoolingSystemType.AIR_CONDITIONER]: 'Air Conditioner',
  [CoolingSystemType.EVAPORATIVE_COOLER]: 'Evaporative Cooler',
  [CoolingSystemType.ROOM_AC]: 'Room Air Conditioner',
  [CoolingSystemType.CHILLER]: 'Chiller',
  [CoolingSystemType.COOLING_TOWER]: 'Cooling Tower',
};

export interface AirConditionerBackendDict {
  id: number;
  name: string;
  fuel: FuelType;
  system_type: CoolingSystemType;
  capacity: number;
  auto_size_capacity: boolean;
  capacity_unit: string;
  efficiency: number;
  efficiency_unit: CoolingEfficiencyUnit;
  motor_type: MechanicalMotorType;
  sensible_heat_fraction: number;
  fan_power: number;
  air_flow_defect_ratio: number;
  charge_defect_ratio: number;
  ahri_reference_number: number;
  note: string;
  fuel_display?: string;
  capacity_unit_display?: string;
  efficiency_unit_display?: string;
}

export class AirConditionerForm extends ModelFormGroup {
  constructor(data: AirConditionerBackendDict) {
    const validateOnlyIfAutoSizeDisabled = createConditionalValidator(
      parentControls => !parentControls.auto_size_capacity.value,
      ['auto_size_capacity']
    );
    super(
      {
        id: new ModelFormControl(data.id),
        name: new ModelFormControl(data.name, [
          Validators.required,
          Validators.maxLength(128),
        ]),
        fuel: new ModelFormControl(data.fuel, enumValidator(FuelType)),
        system_type: new ModelFormControl(data.system_type, [
          enumValidator(CoolingSystemType),
        ]),
        auto_size_capacity: new ModelFormControl(data.auto_size_capacity),
        capacity: new ModelFormControl(
          data.capacity,
          ...validateOnlyIfAutoSizeDisabled([
            Validators.required,
            Validators.min(0),
          ])
        ),
        capacity_unit: new ModelFormControl(
          data.capacity_unit,
          ...validateOnlyIfAutoSizeDisabled([Validators.required])
        ),
        efficiency: new ModelFormControl(data.efficiency, [
          Validators.required,
          Validators.min(0),
        ]),
        efficiency_unit: new ModelFormControl(data.efficiency_unit, [
          Validators.required,
          enumValidator(CoolingEfficiencyUnit),
        ]),
        motor_type: new ModelFormControl(data.motor_type),
        sensible_heat_fraction: new ModelFormControl(
          data.sensible_heat_fraction,
          [
            Validators.required,
            Validators.min(0.0),
            Validators.max(1.0),
            aboveWarningRangeValidator(0.9),
          ]
        ),
        fan_power: new ModelFormControl(data.fan_power, [
          Validators.nullValidator,
          Validators.min(0),
        ]),
        air_flow_defect_ratio: new ModelFormControl(
          data.air_flow_defect_ratio,
          [Validators.nullValidator, Validators.min(-0.9), Validators.max(9.0)]
        ),
        charge_defect_ratio: new ModelFormControl(data.charge_defect_ratio, [
          Validators.nullValidator,
          Validators.min(-0.25),
          Validators.max(0.25),
        ]),
        ahri_reference_number: new ModelFormControl(data.ahri_reference_number),
        note: new ModelFormControl(data.note),
      },
      {
        validators: [validateCapacity, validateEfficiency],
      }
    );
  }
}

function validateCapacity(controls: ModelFormGroup) {
  const systemType = controls.get('system_type').value;
  const capacity = controls.get('capacity').value;
  const autosize = controls.get('auto_size_capacity').value;

  // Ignore capacity validation if auto size is enabled
  if (autosize) {
    return {
      autoSizeCapacityError: true,
      autoSizeCapacityWarn: true,
    };
  } else if (systemType !== CoolingSystemType.COOLING_TOWER) {
    if (capacity < 4 || capacity > 1000) {
      return {
        invalidAirConditionerCapacity: { capacity: capacity },
      };
    } else if (capacity < 10 || capacity > 995) {
      return {
        warningAirConditionerCapacity: { capacity: capacity },
      };
    }
  }
}

function validateEfficiency(controls: ModelFormGroup) {
  const systemType = controls.get('system_type').value;
  const efficiency = controls.get('efficiency').value;
  const efficiencyUnit = controls.get('efficiency_unit').value;

  // Thresholds similar to backend logic
  const EFFICIENCY_THRESHOLDS = {
    [CoolingEfficiencyUnit.SEER]: [4, 6, 30, 50],
    [CoolingEfficiencyUnit.SEER2]: [4, 6, 30, 50],
    [CoolingEfficiencyUnit.EER]: [4, 6, 30, 50],
    [CoolingEfficiencyUnit.PCT_EFF]: [70, 90, 500, 1000],
    [CoolingEfficiencyUnit.COP]: [3.8, 6, 8.8, 14.6],
  };

  // Ignore validation for Cooling Tower
  if (systemType !== CoolingSystemType.COOLING_TOWER) {
    const thresholds = EFFICIENCY_THRESHOLDS[efficiencyUnit];

    if (!thresholds) {
      return {
        invalidEfficiencyUnit: {
          efficiencyUnit: efficiencyUnit,
        },
      };
    }

    const [errorIfBelow, warnIfBelow, warnIfAbove, errorIfAbove] = thresholds;

    // Error if efficiency is out of range
    if (efficiency <= errorIfBelow || efficiency >= errorIfAbove) {
      return {
        efficiencyOutOfRange: {
          efficiency: efficiency,
          efficiencyUnit: efficiencyUnit,
          below: errorIfBelow,
          above: errorIfAbove,
        },
      };
    }
    // Warning if efficiency is below warning range
    if (efficiency < warnIfBelow) {
      return {
        efficiencyLowWarning: {
          efficiency: efficiency,
          efficiencyUnit: efficiencyUnit,
        },
      };
    }
    // Warning if efficiency is above warning range
    if (efficiency > warnIfAbove) {
      return {
        efficiencyHighWarning: {
          efficiency: efficiency,
          efficiencyUnit: efficiencyUnit,
        },
      };
    }
  }
}
