<div class="container-lg" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" [routerLink]="['/', ]">Home</a></li>
          <li class="breadcrumb-item"><a href="#" [routerLink]="['/', 'floorplans',]">Floorplans</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ floorplan.name }}
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="d-flex-column mb-2">
    <div class="d-flex flex-row">
      <div class="flex-fill align-self-end">
        <h3 class="mb-0">
          Floorplan: {{ floorplan.name }}
        </h3>
      </div>
      <div class="text-right">
        <div class="mr-1 downloadButton">
          <mat-menu #downLoadMenu="matMenu">
            <button mat-menu-item [disabled]="!floorplan?.simulation || !floorplan.remrate_data_file" (click)="onDownloadREMFile($event)">
              REM XML
            </button>
            <button mat-menu-item [disabled]="!isSingleDetached" (click)="onDownloadHESHPXML($event)">
              HPXML (HES)
            </button>
            <button mat-menu-item (click)="onDownloadOSERIHPXML($event)">
              HPXML (OS-ERI)
            </button>
          </mat-menu>
          
          <button mat-raised-button [matMenuTriggerFor]="downLoadMenu">Download</button>
        </div>
        <app-history-mat-button [objectId]="floorplan.id" [modelClassName]="'floorplan'" class="mr-1">
          History
        </app-history-mat-button>
        <app-permission-mat-button [permissionResponse]="deletePermissionResponse" (action)="onDelete($event)"
                                   class="mr-1" [hideOnFalse]="true">
          Delete
        </app-permission-mat-button>
        <app-permission-mat-button [permissionResponse]="editPermissionResponse" (action)="onEdit($event)">
          Edit
        </app-permission-mat-button>
      </div>
    </div>
    <mat-divider class="mt-1 mb-2"></mat-divider>

    <div class="d-flex flex-row">
      <div class="d-flex-column flex-fill">
        <div class="d-flex flex-row">
          <div class="company-attr-name">
            Name:
          </div>
          <div class="flex-fill">
            {{ floorplan.name }}
          </div>
        </div>
        
        <div class="d-flex flex-row">
          <div class="company-attr-name">
            Number:
          </div>
          <div class="flex-fill">
            {{ floorplan.number === null ? '-' : floorplan.number }}
          </div>
        </div>

        <div class="d-flex flex-row">
          <div class="company-attr-name">
            Square Feet:
          </div>
          <div class="flex-fill">
            {{ floorplan.square_footage === null ? '-' : floorplan.square_footage }}
          </div>
        </div>

        <div class="d-flex flex-row">
          <div class="company-attr-name">
            Owner:
          </div>
          <div class="flex-fill">
            <app-company-display [company]="floorplan.owner_info"
                                 displayType="onlyNameAsLink"></app-company-display>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="company-attr-name">
            Comment:
          </div>
          <div class="flex-fill">
            {{ floorplan.comment === "null" || floorplan.comment === null ? '-' : floorplan.comment }}
          </div>
        </div>
        <ng-container *ngIf="simulationSummaryIsLoading; else simulationSummaryContainer">
          <div class="d-flex flex-row">
            <div class="company-attr-name">
              Simulation Result:
            </div>
            <div class="flex-fill">
              <mat-spinner [diameter]="15"></mat-spinner>
            </div>
          </div>
        </ng-container>
        <ng-template #simulationSummaryContainer>
          <ng-container *ngIf="simulationSummary; else simulationDoesNotExists">
            <div class="d-flex flex-row">
              <div class="company-attr-name">
                Simulation Result:
              </div>
              <div class="flex-fill">
                {{ simulationSummary.as_string }}
              </div>
            </div>
            <div class="d-flex flex-row">
              <div class="company-attr-name">
                Source Type:
              </div>
              <div class="flex-fill">
                <ng-container *ngIf="!isEkotropeType; else remrateTypeContainer">
                  {{ simulationSourceTypeLabelMap[floorplan.simulation_info?.source_type] }}
                </ng-container>
                <ng-template #remrateTypeContainer>
                  Ekotrope Data Type
                </ng-template>
              </div>
            </div>

            <div class="d-flex flex-row">
              <div class="company-attr-name">
                Source:
              </div>
              <div class="flex-fill">
                <ng-container *ngIf="isEkotropeType; else remrateFileContainer">
                  <a *ngIf="floorplan.ekotrope_houseplan" href="https://app.ekotrope.com/#project:LA5P7DKL"><fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon></a>
                  <a *ngIf="isEkotropeType" [routerLink]="['/', 'floorplan', 'input', 'ekotrope', floorplan.ekotrope_houseplan]">
                      View Ektrope Detail
                  </a>
                </ng-container>
                <ng-template #remrateFileContainer>
                  <div class="flex-fill remrate-file-attr">                    
                    <ng-container *ngIf="floorplan.remrate_data_file_name === null; else downloadLinkContainer">
                      -
                    </ng-container>
                    <ng-template #downloadLinkContainer>
                      <a *ngIf="floorplan.remrate_target" [routerLink]="['/', 'floorplan', 'input', 'remrate', floorplan.remrate_target]">
                        <div *ngIf="floorplan.simulation_info">
                            {{ floorplan.simulation_info.name }}
                        </div>
                      </a>

                      <a href="{{ floorplan.remrate_data_file_url }}" target="_blank">
                        <fa-icon [icon]="['fas', 'download']" matTooltip="Download BLG File"></fa-icon>
                        {{ floorplan.remrate_data_file_name }}
                      </a>
                    </ng-template>
                  </div>
                </ng-template>
              </div>
            </div>
          
            <div class="d-flex flex-row mb-2">
              <div class="company-attr-name">
                Analyses:
              </div>
              <div class="flex-fill">
                {{ floorplanAnalysisCount }} Total
              </div>
            </div>
          </ng-container>
          <ng-template #simulationDoesNotExists>
            <div class="d-flex flex-row mb-2">
              <div class="company-attr-name">
                Simulation Result:
              </div>
              <div class="flex-fill">
                Simulation data does not exists
              </div>
            </div>
          </ng-template>
        </ng-template>
      </div>
      <div class="d-flex-column" style="min-width: 350px; min-height: 250px;">
        <app-associated-companies [modelClassName]="modelClassName" [objectId]="floorplan.id"
                                  (onChange)="onAssociationChange($event)">
        </app-associated-companies>
      </div>
    </div>

    <div class="container mt-1">
      <div class="row">
        <div class="col-12">
          <mat-tab-group color="accent" style="min-height: 450px;" disableRipple="true" animationDuration="0ms"
                         dynamicHeight>

            <mat-tab label="Simulation Details">
              <ng-container *ngIf="floorplan.simulation">
                <div class="mt-2 mb-5">
                  <app-simulation-detail-tab [simulationId]="floorplan.simulation">
                  </app-simulation-detail-tab>
                </div>
              </ng-container>
            </mat-tab>

            <mat-tab label="Documents">
              <ng-template mat-tab-label>
                Documents
                <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                         *ngIf="floorplanDocumentsCount === -1"></fa-icon>
                <span class="badge badge-primary ml-1" *ngIf="floorplanDocumentsCount !== -1">
                            {{ floorplanDocumentsCount }}
                        </span>
              </ng-template>
              <div class="mt-2 mb-5">
                <app-customer-document-tab [modelClassName]="'floorplan'" [objectId]="floorplan.id"></app-customer-document-tab>
              </div>
            </mat-tab>

            <mat-tab label="Simulation Compare">
              <div class="mt-2 mb-5">
                <app-simulation-compare></app-simulation-compare>
              </div>
            </mat-tab>

            <mat-tab label="Homes">
              <ng-template mat-tab-label>
                Homes
                <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                         *ngIf="floorplanHomeStatusesCount === -1"></fa-icon>
                <span class="badge badge-primary ml-1" *ngIf="floorplanHomeStatusesCount !== -1">
                            {{ floorplanHomeStatusesCount }}
                        </span>
              </ng-template>
              <div class="mt-2 mb-5">
                <app-floorplan-homes-tab></app-floorplan-homes-tab>
              </div>
            </mat-tab>
            <mat-tab label="Analysis">
              <ng-template mat-tab-label>
                Analysis
                <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                         *ngIf="floorplanAnalysisCount === -1"></fa-icon>
                <span class="badge badge-primary ml-1" *ngIf="floorplanAnalysisCount !== -1">
                            {{ floorplanAnalysisCount }}
                        </span>
              </ng-template>
              <div class="mt-2 mb-5">
                <app-floorplan-analytics-tab></app-floorplan-analytics-tab>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>
