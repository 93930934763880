<div class="card position-relative">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <div class="card-header">
        <span>Celing Fans</span>
    </div>
    <div class="card-body pb-0">
        <app-form formName="fansForm" [formGroup]="form" (save)="onSave($event)">
            <div>
                <app-input
                    formControlName="ceiling_fan_count"
                    type="number"
                    [label]="fieldLabels.ceiling_fan_count">
                </app-input>
            </div>
            <div>
                <app-input
                    formControlName="ceiling_fan_consumption"
                    type="number"
                    [label]="fieldLabels.ceiling_fan_consumption"
                    [unit]="entity?.ceiling_fan_consumption_unit">
                </app-input>
            </div>
        </app-form>
    </div>
</div>
