import { Component, Input, OnInit } from '@angular/core';
import {
  EEPProgramHomeStatus,
  EEPProgramHomeStatusInfo,
} from '@/data/home/models';
import { Geocode, GeocodeBrokeredResponse } from '@/data/geocoder/models';

export enum HomeStatusDisplayComponentType {
  address = 'address',
  addressAsLink = 'addressAsLink',
  addressAsLinkNewWindow = 'addressAsLinkNewWindow',
}

@Component({
  selector: 'app-home-status-display',
  templateUrl: './home-status-display.component.html',
  styleUrls: ['./home-status-display.component.scss'],
})
export class HomeStatusDisplayComponent implements OnInit {
  protected HomeStatusDisplayComponentType = HomeStatusDisplayComponentType;
  @Input() displayType:
    | HomeStatusDisplayComponentType
    | keyof typeof HomeStatusDisplayComponentType =
    HomeStatusDisplayComponentType.addressAsLink;

  private home_status$: EEPProgramHomeStatus | EEPProgramHomeStatusInfo;
  public geocode: Geocode;
  public geocodeBrokeredResponse?: GeocodeBrokeredResponse;

  @Input()
  set home_status(
    home_status: EEPProgramHomeStatus | EEPProgramHomeStatusInfo
  ) {
    this.home_status$ = home_status;

    this.geocode = new Geocode();
    this.geocode.raw_street_line1 = home_status.home_info?.street_line1;
    this.geocode.raw_street_line2 = home_status.home_info?.street_line2;
    this.geocode.raw_city = home_status.home_info?.city;
    this.geocode.raw_city_info = home_status.home_info?.city_info;
    this.geocode.raw_zipcode = home_status.home_info?.zipcode;
    this.geocodeBrokeredResponse = home_status.home_info?.geocode_response_info;
  }

  get home_status(): EEPProgramHomeStatus | EEPProgramHomeStatusInfo {
    return this.home_status$;
  }

  ngOnInit(): void {}
}
