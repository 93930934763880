import {
  SimulationBackendDict,
  SimulationResidenceTypeLabelMapping,
  SimulationSourceTypeLabelMapping,
  SimulationStatusTypeLabelMapping,
  createSimulationFrom,
  FIELD_LABELS,
  SimulationResidenceType,
} from '@/data/simulation/models/simulation';
import { updateSimulation } from '@/modules/simulation/state/simulation/actions';
import { selectSimulationViewSet } from '@/modules/simulation/state/simulation/selectors';
import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-simulation',
  templateUrl: './simulation.component.html',
  styleUrls: ['./simulation.component.scss'],
})
export class SimulationComponent {
  componentDestroyed$ = new Subject();
  entity: SimulationBackendDict;
  form: FormGroup;
  loading = false;
  sourceTypeLabels = SimulationSourceTypeLabelMapping;
  statusTypeLabels = SimulationStatusTypeLabelMapping;
  fieldLabels = FIELD_LABELS;
  residenceTypeLabels = SimulationResidenceTypeLabelMapping;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectSimulationViewSet())
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;

        if (loading) return;

        if (!this.form) {
          this.form = createSimulationFrom(data);
        } else {
          this.form.patchValue(data);
        }
      });
  }

  isResidencyTypeDuplexOrMultiFamily(): boolean {
    if (!this.form) return false;

    return [
      SimulationResidenceType.dplxEnd,
      SimulationResidenceType.dplxWhole,
      SimulationResidenceType.mfWhole,
    ].includes(this.form.get('residence_type').value);
  }

  onSave(changes): void {
    this.store.dispatch(
      updateSimulation({
        id: this.entity.id,
        changes,
      })
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
