import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const reportAPIFailure = createAction(
  '[API] Report API Failure',
  props<{ error: HttpErrorResponse }>()
);

export const addModelErrorsInState = createAction(
  '[Validation service] Add model errors in state',
  props<{ modelName: string; modelId: number; errors: any }>()
);

export const updateErrorCountOnFlagChanges = createAction(
  '[QA Flag] QA Flag changes',
  props<{ flags: string[] }>()
);

export const flushSimulationStore = createAction('FLUSH');
