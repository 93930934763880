import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CustomerHIRLListInvoice,
  Invoice,
  InvoiceAggregateByState,
  InvoiceItemGroup,
  InvoiceState,
  InvoiceType,
  MultipleInvoiceImport,
} from '@/data/invoicing/models';
import { ServerResponse } from '@/core/schemes/server-response';
import { environment } from '../../../../environments/environment';
import { InvoiceItemGroupRequestParams } from '@/data/invoicing/services/invoice-item-group.service';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { ListRequestParams } from '@/core/schemes/request-params';
import { AsynchronousProcessedDocument } from '@/data/filehandling/models';
import { tap } from 'rxjs/operators';
import {
  loadInvoice,
  loadInvoiceInvoiceItemGroupsAction,
  loadInvoiceInvoiceItemGroupsCountAction,
} from '@/state/actions/invoicing/invoice.actions';

export class InvoiceRequestParams extends ListRequestParams {
  search?: string;
  page?: number;
  ordering?: string;
  state?: InvoiceState;
  partially_paid?: string;
  contains_fees_by_name?: string[];
  company_access?: number | number[];
  invoice_type?: InvoiceType | InvoiceType[];
}

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/invoices/`;

  list(params?: InvoiceRequestParams) {
    return this.http.get<ServerResponse<Invoice>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  customer_hirl_list(
    params?: InvoiceRequestParams
  ): Observable<ServerResponse<CustomerHIRLListInvoice>> {
    return this.http.get<ServerResponse<CustomerHIRLListInvoice>>(
      `${this.baseUrl}customer_hirl_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  retrieve(
    invoiceId: string,
    options?: IDataServiceMethodOptions
  ): Observable<Invoice> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.get<Invoice>(`${this.baseUrl}${invoiceId}`);
    if (!ignoreStore) {
      chain = chain.pipe(
        tap((invoice: Invoice) =>
          this.store.dispatch(loadInvoice({ payload: invoice }))
        )
      );
    }
    return chain;
  }

  update(
    invoiceId: string,
    invoice: Invoice | FormData,
    options?: IDataServiceMethodOptions
  ): Observable<Invoice> {
    const ignoreStore = options?.ignoreStore;

    let chain = this.http.patch<Invoice>(
      `${this.baseUrl}${invoiceId}/`,
      invoice
    );

    if (!ignoreStore) {
      chain = chain.pipe(
        tap((invoice: Invoice) =>
          this.store.dispatch(loadInvoice({ payload: invoice }))
        )
      );
    }
    return chain;
  }

  auto_pay(
    invoiceId: string,
    options?: IDataServiceMethodOptions
  ): Observable<Invoice> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.post<Invoice>(
      `${this.baseUrl}${invoiceId}/auto_pay/`,
      {}
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap((invoice: Invoice) =>
          this.store.dispatch(loadInvoice({ payload: invoice }))
        )
      );
    }
    return chain;
  }

  customer_hirl_send_invoice_copy_for_additional_emails(
    invoiceId: string
  ): Observable<Invoice> {
    return this.http.post<Invoice>(
      `${this.baseUrl}${invoiceId}/customer_hirl_send_invoice_copy_for_additional_emails/`,
      {}
    );
  }

  aggregate_by_state(params?: InvoiceRequestParams) {
    return this.http.get<InvoiceAggregateByState>(
      `${this.baseUrl}aggregate_by_state/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  delete(invoiceId: string) {
    return this.http.delete<Invoice>(`${this.baseUrl}${invoiceId}`);
  }

  hirl_create_invoice(
    invoiceItemIds: string[],
    note?: string,
    options?: IDataServiceMethodOptions
  ) {
    const ignoreStore = options?.ignoreStore;
    const body = {
      invoice_item_groups: invoiceItemIds,
      note,
    };
    let chain = this.http.post<Invoice>(
      `${this.baseUrl}hirl_create_invoice/`,
      body
    );

    if (!ignoreStore) {
      chain = chain.pipe(
        tap((invoice: Invoice) =>
          this.store.dispatch(loadInvoice({ payload: invoice }))
        )
      );
    }
    return chain;
  }

  customer_hirl_pdf_invoice_report(invoiceId: string) {
    return this.http.get(
      `${this.baseUrl}${invoiceId}/customer_hirl_pdf_invoice_report/`,
      {
        responseType: 'blob',
      }
    );
  }

  customer_hirl_pdf_invoice_report_multiple_download(
    data: MultipleInvoiceImport
  ): Observable<AsynchronousProcessedDocument> {
    return this.http.post<AsynchronousProcessedDocument>(
      `${this.baseUrl}customer_hirl_pdf_invoice_report_multiple_download/`,
      data
    );
  }

  invoice_item_groups(
    invoiceId: string,
    params?: InvoiceItemGroupRequestParams
  ) {
    return this.http
      .get<ServerResponse<InvoiceItemGroup>>(
        `${this.baseUrl}${invoiceId}/invoice_item_groups/`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadInvoiceInvoiceItemGroupsAction({
              payload: serverResponse.results,
            })
          )
        ),
        tap(serverResponse =>
          this.store.dispatch(
            loadInvoiceInvoiceItemGroupsCountAction({
              payload: serverResponse.count,
            })
          )
        )
      );
  }
}
