import { UserInfo } from '@/data/core/models/user';

export enum AccreditationName {
  NGBS2020 = 'ngbs-2020',
  NGBS2015 = 'ngbs-2015',
  NGBS2012 = 'ngbs-2012',
  NGBS2008 = 'ngbs-2008',
  NGBSMasterVerifier = 'master-verifier',
  NGBSWRIVerifier = 'ngbs-wri-verifier',
  NGBSGreenFieldRep = 'ngbs-green-field-rep',
}

export const AccreditationNameLabelMapping: Record<AccreditationName, string> =
  {
    [AccreditationName.NGBS2020]: 'NGBS 2020',
    [AccreditationName.NGBS2015]: 'NGBS 2015',
    [AccreditationName.NGBS2012]: 'NGBS 2012',
    [AccreditationName.NGBS2008]: 'NGBS 2008',
    [AccreditationName.NGBSMasterVerifier]: 'NGBS Master Verifier',
    [AccreditationName.NGBSWRIVerifier]: 'NGBS WRI Verifier',
    [AccreditationName.NGBSGreenFieldRep]: 'NGBS Green Field Rep',
  };

export class Accreditation {
  id?: number;
  trainee?: number;
  trainee_info?: UserInfo;
  name?: AccreditationName;
  accreditation_id?: string;
  role?: string;
  state?: string;
  state_changed_at?: string;
  approver?: number;
  approver_info?: UserInfo;
  accreditation_cycle?: string;
  date_initial?: string;
  date_last?: string;
  manual_expiration_date?: string;
  updated_at?: string;
  created_at?: string;
}
