import { Simulation } from '@/data/simulation/models';
import { SimulationService } from '@/data/simulation/services/simulation.service';
import { resetSimulation } from '@/state/actions/floorplan/simulation.actions';
import { AppState } from '@/state/reducers';
import { getSimulation } from '@/state/selectors/floorplan/simulation.selector';
import { Component, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { AnalyticsConsumptionComponent } from './analytics-consumption/analytics-consumption.component';
import { AnalyticsFuelUsageComponent } from './analytics-fuel-usage/analytics-fuel-usage.component';
import { ListRequestParams } from '@/core/schemes/request-params';

@Component({
  selector: 'app-floorplan-analytics-tab',
  templateUrl: './floorplan-analytics-tab.component.html',
  styleUrls: ['./floorplan-analytics-tab.component.scss'],
})
export class FloorplanAnalyticsTabComponent implements OnInit {
  public initialized = false;
  public invalidResponse = false;
  public simulation: Simulation;
  public count: number;
  public analytics: object[];
  public isLoading = false;
  public storedParams: ListRequestParams = new ListRequestParams(1, '', '');
  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public simulationService: SimulationService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.store
      .select(getSimulation)
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val != null)
      )
      .subscribe(
        simulation => {
          this.simulation = simulation;
          this.getAnalytics(simulation.id);
        },
        error => {
          this.invalidResponse = true;
          this.initialized = true;
          this.isLoading = false;
        }
      );
  }

  getAnalytics(simulationId: number) {
    this.isLoading = true;
    this.simulationService
      .getObject(simulationId, 'analytics', this.storedParams)
      .pipe(first(), takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.analytics = data['results'];
          this.count = data['count'];
          this.isLoading = false;
          this.initialized = true;
        },
        error => {
          this.initialized = true;
          this.invalidResponse = true;
          this.isLoading = false;
        }
      );
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.getAnalytics(this.simulation.id);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
    this.store.dispatch(resetSimulation());
  }

  onShowConsumption($event: MouseEvent, data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Consumption Breakdown',
      consumption: data,
    };

    dialogConfig.width = '50%';

    const dialogRef = this.dialog.open(
      AnalyticsConsumptionComponent,
      dialogConfig
    );
  }

  onShowFuelUsage($event: MouseEvent, data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Fuel Usage',
      fuelUsage: data,
    };
    dialogConfig.data.title = data.fuel_type_display;

    dialogConfig.width = '50%';

    const dialogRef = this.dialog.open(
      AnalyticsFuelUsageComponent,
      dialogConfig
    );
  }
}
