<div *ngIf="initialized; else loading;">

    <div class="row mt-2">
        <ng-container *ngFor="let equipment of mechanicals; let index=index">
            <div class="col-6 mt-2">
                <div class="card">
                    <div class="card-content">
                        <ul class="list-group list-group-flush">
                            <ng-container *ngFor="let equipment_type of equipmentTypes | keyvalue">
                                <app-equipment-heater
                                    *ngIf="equipment_type.key == 'heater' && equipment[equipment_type.key]"
                                    [equipment]="equipment">
                                </app-equipment-heater>
                                <app-equipment-air-conditioner
                                    *ngIf="equipment_type.key == 'air_conditioner' && equipment[equipment_type.key]"
                                    [equipment]="equipment">
                                </app-equipment-air-conditioner>
                                <app-equipment-water-heater
                                    *ngIf="equipment_type.key == 'water_heater' && equipment[equipment_type.key]"
                                    [equipment]="equipment">
                                </app-equipment-water-heater>

                                <app-equipment-air-source-heat-pump
                                    *ngIf="equipment_type.key == 'air_source_heat_pump' && equipment[equipment_type.key]"
                                    [equipment]="equipment">
                                </app-equipment-air-source-heat-pump>
                                <app-equipment-ground-source-heat-pump
                                    *ngIf="equipment_type.key == 'ground_source_heat_pump' && equipment[equipment_type.key]"
                                    [equipment]="equipment">
                                </app-equipment-ground-source-heat-pump>
                                <app-equipment-dehumidifier
                                    *ngIf="equipment_type.key == 'dehumidifier' && equipment[equipment_type.key]"
                                    [equipment]="equipment">
                                </app-equipment-dehumidifier>

                            </ng-container>
                            <div class="card-content">
                                <li class="list-group-item">
                                    <b>Location:</b>
                                    {{ equipment.location_display }}
                                    <span style="float:right;">
                                        <b>Quantity:</b>
                                        {{ equipment.qty_installed }}
                                    </span>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="w-100" *ngIf="(index+1) % 2 === 0"></div>
        </ng-container>
    </div>
</div>

<ng-template #loading>
    <div class="row">
        <div class="col-12">
            <div class="d-flex justify-content-center" style="height: 320px;">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
        </div>
    </div>
</ng-template>