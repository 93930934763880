import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SimulationConfigService } from '@/data/simulation/services/simulation-config.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as SimulationConfigActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { SimulationConfigValidator } from '../../validtaors/simulation-config.validator';

@Injectable()
export class SimulationConfigEffects {
  loadSimulationConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationConfigActions.loadSimulationConfig),
      mergeMap(action => {
        const errors = SimulationConfigValidator.validate([
          action.simulationConfig,
        ]);
        return of(
          SimulationConfigActions.loadSimulationConfigSuccess({
            simulationConfig: action.simulationConfig,
            errors,
          }),
          SharedActions.updateErrorCountOnFlagChanges({
            flags: action.simulationConfig.qa_flags.map(flag => flag.name),
          })
        );
      })
    );
  });

  updateSimulationConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SimulationConfigActions.updateSimulationConfig),
      mergeMap(action => {
        return this.simulationConfigService
          .update(action.id, action.simulationConfigChanges)
          .pipe(
            mergeMap(simulationConfig => {
              const errors = SimulationConfigValidator.validate([
                simulationConfig,
              ]);
              const actionsToDispatch: any[] = [
                SimulationConfigActions.loadSimulationConfigSuccess({
                  simulationConfig: simulationConfig,
                  errors,
                }),
              ];
              if (action.simulationConfigChanges.hasOwnProperty('qa_flags')) {
                actionsToDispatch.push(
                  SharedActions.updateErrorCountOnFlagChanges({
                    flags: simulationConfig.qa_flags.map(flag => flag.name),
                  })
                );
              }
              return of(...actionsToDispatch);
            }),
            catchError(error =>
              of(
                SimulationConfigActions.updateSimulationConfigFailure({
                  id: action.simulationConfigChanges.id,
                }),
                SharedActions.reportAPIFailure({ error })
              )
            )
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private simulationConfigService: SimulationConfigService
  ) {}
}
