import { City } from '@/data/geographic/models';
import { GeocodeBrokeredResponse } from '@/data/geocoder/models';

/* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
export class HomeInfo {
  id: number;
  street_line1: string;
  street_line2?: string;
  city: number;
  city_info: City;
  zipcode: string;
  geocode_response: number;
  // this field is not exists yet on backend
  geocode_response_info?: GeocodeBrokeredResponse;

  /* TODO do not use this field. It is too heave and must be removed from backend*/
  home_address: string;
}

export class Home {
  id: number;
  street_line1: string;
  street_line2?: string;
  city: number;
  city_info: City;
  zipcode: string;
  geocode_response: number;
}

export class HomeAddressIsUniqueRequestData {
  home?: number;
  street_line1: string;
  street_line2?: string;
  is_multi_family: boolean;
  city: number;
  city_info: City;
  zipcode: string;
  geocode_response: number;
}
/* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
