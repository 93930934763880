import {
  InsulationGrade,
  Material,
  MaterialItem,
  MaterialItemFromWallCavityMaterial,
  Materials,
  getMaterial,
} from './Material';
import {
  Layer,
  LayerBackendDict,
  LayerFrameType,
  createNewLayer,
} from './AssemblyLayer';
import { ModelFormGroup } from '../ModelForm';
import { StudType } from '../../enumerations';

// AssemblyCalculator Class
class AssemblyCalculator {
  framingType: LayerFrameType;
  outdoorAirFilm = 0.0;
  indoorAirFilm = 0.0;
  layers: Layer[];
  table_data: any[] = [];
  table_headers: string[] = [];
  systemRValue: number | null = null;
  assemblyRValue: number | null = null;
  user_defined_framing_fraction: boolean | null = null;
  methodUsedForCalculation: string;

  constructor(layers: LayerBackendDict[]) {
    this.layers = layers.map(layerData => {
      const layer = new Layer({
        ...layerData,
        framing_type: this.getFramingType(),
      });
      if (layer.stud_type) {
        const air_gap_thickness =
          layer.stud_depth -
          layer.materials.reduce(
            (sum, material) => sum + material.thickness,
            0
          );
        if (air_gap_thickness > 0) {
          layer.materials.push(this.getAirGapMaterial(air_gap_thickness));
        }
      }
      return layer;
    });
  }

  getFramingType(): LayerFrameType {
    return this.framingType;
  }

  getAirGapRValue(thickness: number): number {
    throw new Error('Not implemented');
  }

  getAirGapMaterial(thickness: number): Material {
    return new Material({
      name: Materials.AIR_GAP.name,
      r_value: this.getAirGapRValue(thickness),
      r_value_per_inch: Materials.AIR_GAP.r_value_per_inch,
      thickness: Materials.AIR_GAP.thickness,
      insulation_grade: InsulationGrade.GradeI,
    });
  }

  get system_r_value(): number {
    if (this.systemRValue === null) {
      this.calculate();
    }
    return this.systemRValue!;
  }

  get system_u_value(): number {
    return 1 / this.system_r_value;
  }

  get assembly_r_value(): number {
    if (this.assemblyRValue === null) {
      this.calculate();
    }
    return this.assemblyRValue!;
  }

  get assembly_u_value(): number {
    return 1 / this.assembly_r_value;
  }

  get one_dimensional_applies(): boolean {
    return this.layers.every(layer => !layer.stud_type);
  }

  get parallel_path_applies(): boolean {
    let parallel_path_found = 0;
    for (const layer of this.layers) {
      if (!layer.stud_type) continue;

      parallel_path_found += 1;
      if (layer.stud_type !== StudType.WOOD_STUD) {
        return false;
      }
    }
    return parallel_path_found === 1;
  }

  get zone_method_applies(): boolean {
    for (const layer of this.layers) {
      if (layer.stud_type === StudType.STEEL_FRAME) {
        return true;
      }
    }
    return false;
  }

  calculateOneDimensionalRValues(): void {
    this.systemRValue = 0.0;
    this.assemblyRValue = 0.0;
    this.user_defined_framing_fraction = false;
    this.table_data = [['Outdoor Air film', this.outdoorAirFilm]];
    this.assemblyRValue += this.outdoorAirFilm;

    for (const layer of this.layers) {
      for (const composing_material of layer.materials) {
        this.table_data.push([
          composing_material.name,
          composing_material.r_value,
        ]);
        this.assemblyRValue += composing_material.r_value;
        this.systemRValue += composing_material.r_value;
      }
    }

    this.table_data.push(['Indoor Air film', this.indoorAirFilm], []);
    this.assemblyRValue += this.indoorAirFilm;
    this.table_data.push(['Total System R-Value', this.systemRValue]);
    this.table_data.push(['Total Assembly R-Value', this.assemblyRValue]);
    this.table_headers = ['Material', 'R-Value'];
  }

  calculateIsoThermalPlanesRValues(): void {
    this.systemRValue = 0.0;
    this.assemblyRValue = 0.0;
    this.table_data = [['Outdoor Air film', null, this.outdoorAirFilm]];
    this.assemblyRValue += this.outdoorAirFilm;

    for (const layer of this.layers) {
      if (layer.stud_type) {
        this.table_data.push([
          layer.stud_type + ' (Stud)',
          layer.stud_r_value,
          null,
        ]);
        for (const composing_material of layer.materials) {
          this.table_data.push([
            composing_material.name,
            composing_material.r_value,
            null,
          ]);
        }

        const [framing_frac, cavity_frac, cavity_grade_frac] =
          layer.getFramingFactors();
        this.user_defined_framing_fraction =
          layer.user_defined_framing_fraction;
        const frame_u_value = (1 / layer.stud_r_value) * framing_frac;
        const cavity_u_value =
          (1 /
            layer.materials.reduce(
              (sum, material) => sum + material.r_value,
              0
            )) *
          cavity_frac;
        const cavity_grade_u_value =
          (1 / this.getAirGapRValue(layer.stud_depth)) * cavity_grade_frac;

        if (cavity_grade_frac !== 0.0) {
          const label =
            'Cavity Grade ' +
            'I'.repeat(layer.materials[0].insulation_grade) +
            ` (${(cavity_grade_frac * 100).toFixed(0)}%)`;
          this.table_data.push([
            label,
            (1 / cavity_grade_u_value) * cavity_grade_frac,
            null,
          ]);
        }

        const total =
          1 / (frame_u_value + cavity_u_value + cavity_grade_u_value);
        this.assemblyRValue += total;
        this.systemRValue += total;
        this.table_data.push(['R(avs)', null, total]);

        let label = 'Framing Fraction';
        if (this.user_defined_framing_fraction) {
          label += '*';
        }
        this.table_data.push([
          label + ` ${framing_frac.toFixed(3)}`,
          null,
          null,
        ]);
      } else {
        let total = 0.0;
        if (layer.materials.length > 1) {
          for (const composing_material of layer.materials) {
            total += composing_material.r_value;
            this.table_data.push([
              composing_material.name,
              composing_material.r_value,
              null,
            ]);
          }
          this.table_data.push([layer.name || 'R(tot)', null, total]);
        } else {
          for (const composing_material of layer.materials) {
            total += composing_material.r_value;
            this.table_data.push([
              composing_material.name,
              null,
              composing_material.r_value,
            ]);
          }
        }
        this.assemblyRValue += total;
        this.systemRValue += total;
      }
    }

    this.table_data.push(['Indoor Air film', null, this.indoorAirFilm], []);
    this.assemblyRValue += this.indoorAirFilm;
    this.table_data.push(['Total System R-Value', null, this.systemRValue]);
    this.table_data.push(['Total Assembly R-Value', null, this.assemblyRValue]);
    this.table_headers = ['Items', 'Studs/Cavity', 'Studs/Avg Cavity/Grade'];
  }

  calculateParallelPathAssemblyRValues(): void {
    if (!this.parallel_path_applies) {
      throw new Error('Parallel path cannot be calculated');
    }

    this.systemRValue = 0.0;
    this.assemblyRValue = 0.0;
    this.table_data = [
      [
        'Outdoor Air film',
        this.outdoorAirFilm,
        this.outdoorAirFilm,
        this.outdoorAirFilm,
      ],
    ];

    for (const layer of this.layers) {
      if (layer.stud_type) {
        this.table_data.push([
          layer.stud_type + ' (Stud)',
          layer.stud_r_value,
          null,
          null,
        ]);
        for (const composing_material of layer.materials) {
          this.table_data.push([
            composing_material.name,
            null,
            composing_material.r_value,
            null,
          ]);
        }

        const grade = layer.materials[0].insulation_grade;
        if (grade > 1) {
          const label = 'Cavity Grade ' + 'I'.repeat(grade);
          this.table_data.push([
            label,
            null,
            null,
            this.getAirGapRValue(layer.stud_depth),
          ]);
        }
      } else {
        for (const composing_material of layer.materials) {
          this.table_data.push([
            composing_material.name,
            composing_material.r_value,
            composing_material.r_value,
            composing_material.r_value,
          ]);
        }
      }
    }

    this.table_data.push([
      'Indoor Air film',
      this.indoorAirFilm,
      this.indoorAirFilm,
      this.indoorAirFilm,
    ]);

    const sys_r_values = [
      this.table_data
        .slice(1, -1)
        .filter(row => row[1])
        .reduce((sum, row) => sum + row[1], 0),
      this.table_data
        .slice(1, -1)
        .filter(row => row[2])
        .reduce((sum, row) => sum + row[2], 0),
      this.table_data
        .slice(1, -1)
        .filter(row => row[3])
        .reduce((sum, row) => sum + row[3], 0),
    ];

    const stud_layer = this.layers.find(layer => layer.stud_type);
    if (stud_layer) {
      const [framing_frac, cavity_frac, cavity_grade_frac] =
        stud_layer.getFramingFactors();
      this.user_defined_framing_fraction =
        stud_layer.user_defined_framing_fraction;
      const sys_frame_u_value = (1 / sys_r_values[0]) * framing_frac;
      const sys_cavity_u_value = (1 / sys_r_values[1]) * cavity_frac;
      const sys_cavity_grade_u_value =
        (1 / sys_r_values[2]) * cavity_grade_frac;

      this.systemRValue =
        1 / (sys_frame_u_value + sys_cavity_u_value + sys_cavity_grade_u_value);

      const asy_r_values = [
        this.table_data
          .filter(row => row[1])
          .reduce((sum, row) => sum + row[1], 0),
        this.table_data
          .filter(row => row[2])
          .reduce((sum, row) => sum + row[2], 0),
        this.table_data
          .filter(row => row[3])
          .reduce((sum, row) => sum + row[3], 0),
      ];

      const asy_frame_u_value = (1 / asy_r_values[0]) * framing_frac;
      const asy_cavity_u_value = (1 / asy_r_values[1]) * cavity_frac;
      const asy_cavity_grade_u_value =
        (1 / asy_r_values[2]) * cavity_grade_frac;

      this.assemblyRValue =
        1 / (asy_frame_u_value + asy_cavity_u_value + asy_cavity_grade_u_value);

      this.table_data.push([null, null, null, null]);
      this.table_data.push(['R(a_tot)', ...asy_r_values]);
      let label = 'Framing Fraction';
      if (this.user_defined_framing_fraction) {
        label += '*';
      }
      this.table_data.push([
        label,
        framing_frac,
        cavity_frac,
        cavity_grade_frac,
      ]);
      this.table_data.push([null, null, null, null]);

      if (cavity_grade_frac === 0) {
        this.table_data = this.table_data.map(row => row.slice(0, 3));
      }

      this.table_data.push(['Total System R-Value', this.systemRValue]);
      this.table_data.push(['Total Assembly R-Value', this.assemblyRValue]);
    }

    this.table_headers = ['Items', 'Studs/Plates', 'Cavity', 'Cavity Grade'];
  }

  get_zone_factor(ratio: number, stud_depth: number): number {
    let table: [number, number][];
    if (stud_depth >= 5.0) {
      table = [
        [3.0, 2.97],
        [2.8, 2.965],
        [2.6, 2.96],
        [2.4, 2.84],
        [2.2, 2.82],
        [2.0, 2.81],
        [1.8, 2.5],
        [1.6, 2.32],
        [1.4, 2.24],
        [1.2, 2.16],
        [1.0, 2.07],
        [0.8, 2.0],
        [0.6, 1.96],
        [0.4, 1.81],
      ];
    } else if (stud_depth > 3.5) {
      table = [
        [3.0, 2.32],
        [2.8, 2.23],
        [2.6, 2.25],
        [2.4, 2.2],
        [2.2, 2.1],
        [2.0, 2.07],
        [1.8, 2.01],
        [1.6, 1.98],
        [1.4, 1.97],
        [1.2, 1.96],
        [1.0, 1.88],
        [0.8, 1.81],
        [0.6, 1.4],
        [0.4, 1.24],
      ];
    } else {
      table = [
        [3.0, 2.07],
        [2.8, 2.05],
        [2.6, 2.02],
        [2.4, 2.0],
        [2.2, 1.98],
        [2.0, 1.97],
        [1.8, 1.965],
        [1.6, 1.95],
        [1.445, 1.71], // Here for the ASHRAE Example.
        [1.4, 1.88],
        [1.2, 1.85],
        [1.0, 1.64],
        [0.8, 1.41],
        [0.6, 1.35],
        [0.4, 1.07],
      ];
    }

    const correction = 0.0;
    for (const [zone_factor, corr] of table) {
      if (ratio >= zone_factor) {
        return corr;
      }
    }
    return correction;
  }

  calculateModifiedZoneAWidth(): number {
    let r_sheathing = 0,
      sheathing_depth = 0;
    let r_cavity = 0,
      stud_depth = 0;
    let r_interior = 0,
      interior_depth = 0;
    let calculate_sheathing = true;
    let stud_width = 0.0,
      flange_thickness = 0.0,
      stud_spacing = 0.0;

    for (const layer of this.layers) {
      if (layer.stud_type) {
        stud_depth = layer.stud_depth ?? 0;
        stud_width = layer.stud_width ?? 0;
        stud_spacing = layer.stud_spacing ?? 0;
        flange_thickness = layer.flange_thickness ?? 0;
        for (const composing_material of layer.materials) {
          r_cavity += composing_material.r_value;
        }
        calculate_sheathing = false;
        continue;
      }
      for (const composing_material of layer.materials) {
        if (calculate_sheathing) {
          r_sheathing += composing_material.r_value;
          sheathing_depth += composing_material.thickness;
        } else {
          r_interior += composing_material.r_value;
          interior_depth += composing_material.thickness;
        }
      }
    }

    const di =
      sheathing_depth > interior_depth ? sheathing_depth : interior_depth;
    const dr = sheathing_depth > interior_depth ? r_sheathing : r_sheathing;

    const sheathing_resistivity = dr / di;
    const cavity_resistivity = r_cavity / stud_depth;
    const ratio = sheathing_resistivity / cavity_resistivity;
    const zone_factor = this.get_zone_factor(ratio, stud_depth);
    const interior_between_flanges = stud_depth - 2 * flange_thickness;
    const zone_thickness = stud_width + zone_factor * di;

    this.table_data = [
      ['Stud Spacing', 's', stud_spacing],
      ['Resistivity of Sheathing', 'r_i', sheathing_resistivity],
      ['Resistivity of Cavity', 'r_i', cavity_resistivity],
      ['Ratio r_i/r_s', null, ratio],
      ['Zone Factor', null, zone_factor],
      [],
      ['Cavity Thickness', 'd_s', stud_depth],
      ['Thickness of Metal', 'd_ii', flange_thickness],
      ['Interior dimension between flanges', 'd_i', interior_between_flanges],
      ['Thickness of exterior insulating materials', null, sheathing_depth],
      ['Flange Length', 'L', stud_width],
      ['Affected Zone Thickness', 'W', zone_thickness],
    ];

    return zone_thickness;
  }

  calculateZoneAssemblyRValues(): void {
    const zoneAWidth = this.calculateModifiedZoneAWidth();
    const studLayer = this.layers.find(layer => layer.stud_type !== undefined);

    if (this.outdoorAirFilm !== null) {
      this.table_data = [
        [
          'Outdoor Air film',
          this.outdoorAirFilm,
          this.outdoorAirFilm,
          this.outdoorAirFilm,
          this.outdoorAirFilm,
          this.outdoorAirFilm,
          this.outdoorAirFilm,
          this.outdoorAirFilm,
        ],
      ];
    }

    const calculateSheathing = true;
    let rInterior = 0;
    let rSheathing = 0;
    let rCavity = 0;

    this.layers.forEach(layer => {
      if (layer.stud_type) {
        this.table_data.push([
          layer.stud_type,
          null,
          null,
          layer.flange_thickness * layer.stud_r_value,
          null,
          null,
          null,
          null,
        ]);
        layer.materials.forEach(material => {
          const grade = layer.materials[0].insulation_grade;
          if (grade === 1) {
            this.table_data.push([
              material.name,
              material.r_value,
              null,
              null,
              material.r_value,
              null,
              material.r_value,
              null,
            ]);
          } else {
            this.table_data.push([
              material.name,
              material.r_value,
              this.getAirGapRValue((zoneAWidth - studLayer.stud_width) / 2),
              null,
              material.r_value,
              this.getAirGapRValue((zoneAWidth - studLayer.stud_width) / 2),
              material.r_value,
              this.getAirGapRValue(studLayer.stud_spacing - zoneAWidth),
            ]);
          }
          rCavity += material.r_value;
        });
      } else {
        layer.materials.forEach(material => {
          this.table_data.push([
            material.name,
            material.r_value,
            material.r_value,
            material.r_value,
            material.r_value,
            material.r_value,
            material.r_value,
            material.r_value,
          ]);
          if (calculateSheathing) {
            rSheathing += material.r_value;
          } else {
            rInterior += material.r_value;
          }
        });
      }
    });

    if (this.indoorAirFilm !== null) {
      this.table_data.push([
        'Indoor Air film',
        this.indoorAirFilm,
        this.indoorAirFilm,
        this.indoorAirFilm,
        this.indoorAirFilm,
        this.indoorAirFilm,
        this.indoorAirFilm,
        this.indoorAirFilm,
      ]);
    }

    const assemblyZoneW = [
      this.table_data.reduce((sum, row) => sum + (row[1] || 0), 0),
      this.table_data.reduce((sum, row) => sum + (row[2] || 0), 0),
      this.table_data.reduce((sum, row) => sum + (row[3] || 0), 0),
      this.table_data.reduce((sum, row) => sum + (row[4] || 0), 0),
      this.table_data.reduce((sum, row) => sum + (row[5] || 0), 0),
    ];

    const assemblyZoneCav = [
      this.table_data.reduce((sum, row) => sum + (row[6] || 0), 0),
      this.table_data.reduce((sum, row) => sum + (row[7] || 0), 0),
    ];

    const assemblyRTotal = [...assemblyZoneW, ...assemblyZoneCav];

    const systemZoneW = [
      this.table_data.slice(1, -1).reduce((sum, row) => sum + (row[1] || 0), 0),
      this.table_data.slice(1, -1).reduce((sum, row) => sum + (row[2] || 0), 0),
      this.table_data.slice(1, -1).reduce((sum, row) => sum + (row[3] || 0), 0),
      this.table_data.slice(1, -1).reduce((sum, row) => sum + (row[4] || 0), 0),
      this.table_data.slice(1, -1).reduce((sum, row) => sum + (row[5] || 0), 0),
    ];

    const systemZoneCav = [
      this.table_data.slice(1, -1).reduce((sum, row) => sum + (row[6] || 0), 0),
      this.table_data.slice(1, -1).reduce((sum, row) => sum + (row[7] || 0), 0),
    ];

    this.table_data.push([], ['R(a_tot)', ...assemblyRTotal]);

    const cavityGradeArea = { 1: 0.0, 2: 0.02, 3: 0.05 }[
      studLayer.materials[0].insulation_grade
    ];
    const zoneACavityGradeWidth =
      (zoneAWidth - studLayer.stud_width) * cavityGradeArea;
    const zoneACavityGradeFraction = zoneACavityGradeWidth / zoneAWidth;
    const zoneACavityWidth =
      zoneAWidth - studLayer.stud_width - zoneACavityGradeWidth;
    const zoneACavityFraction = zoneACavityWidth / zoneAWidth;
    const zoneAStudFraction = studLayer.stud_width / zoneAWidth;
    const zoneAFractions = [
      zoneACavityFraction / 2,
      zoneACavityGradeFraction / 2,
      zoneAStudFraction,
      zoneACavityFraction / 2,
      zoneACavityGradeFraction / 2,
    ];

    const zoneBCavityGradeWidth =
      (studLayer.stud_spacing - zoneAWidth) * cavityGradeArea;
    const zoneBCavityGradeFraction =
      zoneBCavityGradeWidth / (studLayer.stud_spacing - zoneAWidth);
    const zoneBCavityWidth =
      studLayer.stud_spacing - zoneAWidth - zoneBCavityGradeWidth;
    const zoneBCavityFraction =
      zoneBCavityWidth / (studLayer.stud_spacing - zoneAWidth);
    const zoneBFractions = [zoneBCavityFraction, zoneBCavityGradeFraction];

    const rFracRow = ['Zonal Fractions', ...zoneAFractions, ...zoneBFractions];
    this.table_data.push(rFracRow);

    const zoneUValue = zoneAFractions.reduce(
      (sum, frac, i) => sum + (1 / assemblyZoneW[i]) * frac,
      0
    );
    const cavityUValue = zoneBFractions.reduce(
      (sum, frac, i) => sum + (1 / assemblyZoneCav[i]) * frac,
      0
    );

    this.table_data.push(
      [],
      ['Zone U-Value(System)', null, null, zoneUValue, null, null, null, null],
      [
        'Cavity U-Value(System)',
        null,
        null,
        cavityUValue,
        null,
        null,
        null,
        null,
      ],
      [
        'Zone U-Value(Assembly)',
        null,
        null,
        zoneUValue,
        null,
        null,
        null,
        null,
      ],
      [
        'Cavity U-Value(Assembly)',
        null,
        null,
        cavityUValue,
        null,
        null,
        null,
        null,
      ]
    );

    this.systemRValue = 1 / zoneUValue;
    this.assemblyRValue = 1 / cavityUValue;
  }

  calculate(): void {
    if (this.one_dimensional_applies) {
      this.calculateOneDimensionalRValues();
      this.methodUsedForCalculation = 'One Dimensional Method';
    } else if (this.parallel_path_applies) {
      this.calculateParallelPathAssemblyRValues();
      this.methodUsedForCalculation = 'Parallel Path Method';
    } else if (this.zone_method_applies) {
      this.calculateZoneAssemblyRValues();
      this.methodUsedForCalculation = 'Zone Method';
    } else {
      this.calculateIsoThermalPlanesRValues();
      this.methodUsedForCalculation = 'ISO Thermal Planes Method';
    }
  }
}

// WallAssemblyCalculator Class
export class WallAssemblyCalculator extends AssemblyCalculator {
  framingType = LayerFrameType.ABOVE_GRADE_WALL;
  outdoorAirFilm = 0.17;
  indoorAirFilm = 0.68;

  getAirGapRValue(thickness: number): number {
    if (thickness >= 5.5) {
      return 0.93;
    }
    if (thickness >= 3.5) {
      return 0.91;
    }
    if (thickness >= 1.5) {
      return 0.9;
    }
    return 0.9;
  }

  getFramingType(): LayerFrameType {
    return LayerFrameType.ABOVE_GRADE_WALL;
  }
}

// RoofAssemblyCalculator Class
class RoofAssemblyCalculator extends AssemblyCalculator {
  framingType = LayerFrameType.ROOF;
  outdoorAirFilm = 0.25;
  indoorAirFilm = 0.92;

  getAirGapRValue(thickness: number): number {
    if (thickness >= 5.5) {
      return 0.93;
    }
    if (thickness >= 3.5) {
      return 0.91;
    }
    if (thickness >= 1.5) {
      return 0.9;
    }
    return 0.9;
  }
}

export class BaseModelWithAssemblyFormGroup extends ModelFormGroup {
  frameType: LayerFrameType;

  convertClassicFormToLayer(): LayerBackendDict[] {
    throw new Error('Not implemented');
  }

  getStudWall(instance) {
    let ref_name = 'frame';
    let stud_type = 'stud_type';
    if (this.frameType === LayerFrameType.FRAME_FLOOR) {
      stud_type = 'joist_type';
      ref_name = 'joist';
    }

    if (instance[stud_type] !== StudType.WOOD_STUD) {
      return null;
    }

    if (instance[stud_type] === StudType.SOLID_CONCRETE) {
      throw new Error("We don't yet support calculations on Concrete Walls");
    } else if (instance[stud_type] === StudType.STEEL_FRAME) {
      throw new Error(
        "We don't yet support Zone Calculation on Steel Stud walls"
      );
    }

    let cavityMaterial = MaterialItem.INSULATION_GENERIC_CAVITY;
    if (instance.cavity_insulation_material) {
      cavityMaterial =
        MaterialItemFromWallCavityMaterial[
          instance.cavity_insulation_material
        ] || cavityMaterial;
    }

    const layer: LayerBackendDict = {
      ...createNewLayer(),
      stud_type: instance[stud_type],
      stud_spacing: instance[`${ref_name}_spacing`],
      stud_width: instance[`${ref_name}_width`],
      stud_depth: instance[`${ref_name}_depth`],
      user_defined_framing_fraction: instance.user_defined_framing_fraction,
      framing_fraction: instance.framing_factor,
      framing_type: this.frameType,
      materials: [
        {
          ...getMaterial(cavityMaterial),
          thickness:
            instance.cavity_insulation_thickness ||
            instance[`${ref_name}_depth`],
          insulation_grade: instance.cavity_insulation_grade,
          r_value:
            instance.cavity_insulation_r_value ||
            getMaterial(cavityMaterial).r_value ||
            0.01,
        },
      ],
    };

    if (instance.cavity_insulation_r_value === 0.0) {
      layer.materials = [];
    }
    return layer;
  }

  getGypsumLayer(instance) {
    let reference = MaterialItem.INTERIOR_FINISH_GYPSUM_0P625;
    if (instance.gypsum_thickness <= 0.5) {
      reference = MaterialItem.INTERIOR_FINISH_GYPSUM_0P5;
    }

    return {
      ...createNewLayer(),
      materials: [
        {
          ...getMaterial(reference),
          thickness: instance.gypsum_thickness,
        },
      ],
    };
  }

  getContinuousInsulationLayer(instance) {
    return {
      ...createNewLayer(),
      materials: [
        {
          ...getMaterial(MaterialItem.INSULATION_GENERIC_CONTINUOUS),
          r_value: instance.continuous_insulation_r_value,
          thickness: 1.0, // Not needed or used.
        },
      ],
    };
  }
}
