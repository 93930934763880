/*
 * Almost all common axis endpoints support search and pagination
 * */

import { formatDate } from '@angular/common';
import { Params } from '@angular/router';
import * as moment from 'moment';
import { Sort } from '@angular/material/sort';
import * as _ from 'lodash';

export class RequestParams {
  /**
   * Convert query string params to dictionary
   * @param params A collection of matrix and query URL parameters.
   * @param arrayParams Array of names that must be converted to array even if this is a single string
   */
  assignQueryParams(params: Params, arrayParams?: string[]) {
    Object.keys(params).forEach(key => {
      if (key === 'page') {
        this[key] = Number(params[key]);
      } else if (moment(params[key], 'MM/DD/YYYY', true).isValid()) {
        this[key] = moment(params[key], 'MM/DD/YYYY', true).toDate();
      } else {
        if (
          arrayParams &&
          arrayParams.includes(key) &&
          typeof params[key] === 'string'
        ) {
          this[key] = [params[key]];
        } else {
          this[key] = params[key];
        }
      }
    });
    return this;
  }

  toQueryParams() {
    const data = Object.assign(this, {});
    Object.keys(data).forEach(key => {
      if (data[key] instanceof Date) {
        data[key] = `${formatDate(data[key], 'MM/dd/yyyy', 'en-US')}`;
      }
    });
    return data;
  }

  /**
   * Convert query string params to dictionary with human-readable labels and names
   * overwritePropertyValues - using it to provide FK object name, instead of ID
   * For example:
   *  overwritePropertyValues: {
   *         eep_program: this.filterFromGroup.value.eep_program_info
   *           ?.map((eep_program: EEPProgram) => eep_program.name)
   *           .join(', '),
   * }
   *
   * overwritePropertyLabels - using to provide better name
   *
   * excludedProperties - By default it is page and page_size
   */
  toAppliedFilters({
    overwritePropertyValues,
    overwritePropertyLabels,
    excludedProperties,
  }: {
    overwritePropertyValues?: { [key: string]: string };
    overwritePropertyLabels?: { [key: string]: string };
    excludedProperties?: string[];
  } = {}): {
    [key: string]: string;
  } {
    const appliedFilters: { [key: string]: string } = {};

    if (!overwritePropertyValues) {
      overwritePropertyValues = {};
    }

    if (!overwritePropertyLabels) {
      overwritePropertyLabels = {};
    }

    if (!excludedProperties) {
      excludedProperties = ['page', 'page_size'];
    }
    const methods = Object.keys(this).filter(
      key => typeof this[key as keyof RequestParams] === 'function'
    );

    excludedProperties = [...excludedProperties, ...methods];

    Object.keys(this).forEach(key => {
      let value = this[key];

      if (overwritePropertyValues.hasOwnProperty(key)) {
        value = overwritePropertyValues[key];
      }

      if (!value || excludedProperties.includes(key)) {
        return;
      }

      let label = _.startCase(key);

      if (overwritePropertyLabels.hasOwnProperty(key)) {
        label = overwritePropertyLabels[key];
      }
      if (!_.isEmpty(value)) {
        if (_.isArray(value)) {
          appliedFilters[label] = value.join(', ');
        } else {
          appliedFilters[label] = value;
        }
      }
    });

    return appliedFilters;
  }

  /**
   * Assign reactive form values to query params.
   * @param formValues form.values object. Re-define complex objects, and then exclude them with excludedProperties
   * For example:
   * {
   *             eep_program: params.eep_program_info?.map(
   *               (eep_program: EEPProgramInfo) => eep_program.id
   *             ),
   * }
   * @param excludedProperties remove unnecessary fields. ['ordering', ] is using by default
   *
   * excludedProperties: [
   *             'ordering',
   * ],
   */
  assignFilterFormValues({
    formValues,
    excludedProperties,
  }: {
    formValues?: { [key: string]: any };
    excludedProperties?: string[];
  } = {}) {
    if (!excludedProperties) {
      excludedProperties = ['ordering'];
    }

    Object.keys(formValues).forEach(key => {
      const value = formValues[key];

      if (excludedProperties.includes(key)) {
        return;
      }

      this[key] = value;
    });
  }
}

export class ListRequestParams extends RequestParams {
  search?: string;
  page?: number;
  ordering?: string;
  page_size?: number;

  constructor(
    page?: number,
    search?: string,
    ordering?: string,
    page_size?: number
  ) {
    super();
    this.page = page;
    this.search = search;
    this.ordering = ordering;
    this.page_size = page_size;
  }

  toDatatableSorts(): any[] {
    return [
      {
        prop: this.ordering.replace('-', ''),
        dir: this.ordering.startsWith('-') ? 'desc' : 'asc',
      },
    ];
  }

  toTableSort(): Sort {
    return {
      active: this.ordering.replace('-', ''),
      direction: this.ordering.startsWith('-') ? 'desc' : 'asc',
    };
  }
}
