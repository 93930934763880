import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxMaskModule } from 'ngx-mask';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { ServerErrorDialogComponent } from '@/shared/components/server-error-dialog/server-error-dialog.component';
import { OkDialogComponent } from '@/shared/components/ok-dialog/ok-dialog.component';
import { MaterialModule } from '@/shared/material.module';
import { AddressInputComponent } from '@/shared/components/address-input/address-input.component';
import { DividePipe } from '@/shared/pipes/divide.pipe';
import { SafePipe } from '@/shared/pipes/safe.pipe';
import { YesNoPipe } from '@/shared/pipes/yes-no.pipe';
import { SumArrayOfObjectsPipe } from '@/shared/pipes/sum-array-of-objects-pipe';
import { PageNotFoundComponent } from '@/shared/pages/page-not-found/page-not-found.component';
import { InputDialogComponent } from '@/shared/components/input-dialog/input-dialog.component';
import { AccessDeniedComponent } from '@/shared/pages/access-denied/access-denied.component';
import { HomeAddressInputComponent } from '@/shared/components/home-address-input/home-address-input.component';
import { AdvancedLinkComponent } from '@/shared/components/external-link/advanced-link.component';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { HistoryTableComponent } from '@/shared/components/history-table/history-table.component';
import { GroupByPipe } from '@/shared/pipes/group-by.pipe';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { CompanyDisplayComponent } from '@/shared/components/company-display/company-display.component';
import { FloorplanDisplayComponent } from './components/floorplan-display/floorplan-display.component';
import { CommunityDisplayComponent } from '@/shared/components/community-display/community-display.component';
import { DropzoneDialogComponent } from '@/shared/components/dropzone-dialog/dropzone-dialog.component';
import { AddressDisplayComponent } from '@/shared/components/address-display/address-display.component';
import { BackButtonDirective } from '@/shared/directives/back-button.directive';
import { ContentObjectDisplayComponent } from '@/shared/components/content-object-display/content-object-display.component';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { GoogleMapsModule } from '@angular/google-maps';
import { SearchInputComponent } from '@/shared/components/search-input/search-input.component';
import { GenericModelAutocompleteComponent } from '@/shared/components/generic-model-autocomplete/generic-model-autocomplete.component';
import { GenericModelChipsComponent } from '@/shared/components/generic-model-chips/generic-model-chips.component';
import { FileInputComponent } from '@/shared/components/file-input/file-input.component';
import { PermissionMatButtonComponent } from '@/shared/components/permission-mat-button/permission-mat-button.component';
import { MtmDisplayComponent } from '@/shared/components/mtm-display/mtm-display.component';
import { appMenuTriggerFor } from '@/shared/directives/app-mat-menu-trigger';
import { HomeAddressDisplayComponent } from '@/shared/components/home-address-display/home-address-display.component';
import { HistoryDialogComponent } from '@/shared/components/history-dialog/history-dialog.component';
import { HistoryMatButtonComponent } from '@/shared/components/history-mat-button/history-mat-button.component';
import { GenericMatSelectComponent } from '@/shared/components/generic-mat-select/generic-mat-select.component';
import { MonthMatPickerComponent } from '@/shared/components/month-mat-picker/month-mat-picker.component';
import { DefaultOrderKeyValuePipe } from '@/shared/pipes/default-order-key-value.pipe';
import { RowsPerPageComponent } from '@/shared/components/rows-per-page/rows-per-page.component';
import { TypeofPipe } from '@/shared/pipes/type-of-pipe';
import { CopyToClipboardButtonComponent } from '@/shared/components/copy-to-clipboard-button/copy-to-clipboard-button.component';
import { SensitiveInfoComponent } from '@/shared/components/sensitive-info/sensitive-info.component';
import { LbsToTonnesPipe } from '@/shared/pipes/lbs-to-tonnes.pipe';
import { SubdivisionDisplayComponent } from '@/shared/components/subdivision-display/subdivision-display.component';
import { UserDisplayComponent } from '@/shared/components/user-display/user-display.component';
import { HomeStatusDisplayComponent } from '@/shared/components/home-status-display/home-status-display.component';
import { QuillModule } from 'ngx-quill';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PaymentRequestDisplayComponent } from '@/shared/components/display/customer-hirl/payment-request-display/payment-request-display.component';
import { InvoiceDisplayComponent } from '@/shared/components/display/invoicing/invoice-display/invoice-display.component';
import { HirlProductAgreementDisplayComponent } from '@/shared/components/display/customer-hirl/hirl-product-agreement-display/hirl-product-agreement-display.component';
import { CoiDocumentDisplayComponent } from '@/shared/components/display/customer-hirl/coi-document-display/coi-document-display.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { DateRangeMatSelectComponent } from '@/shared/components/date-range-mat-select/date-range-mat-select.component';
import { AnalysisTypeSelectorComponent } from './components/analysis-type-selector/analysis-type-selector.component';
@NgModule({
  providers: [DatePipe, CurrencyPipe],
  declarations: [
    ConfirmDialogComponent,
    OkDialogComponent,
    ServerErrorDialogComponent,
    AddressInputComponent,
    HomeAddressInputComponent,
    InputDialogComponent,
    AdvancedLinkComponent,
    HistoryTableComponent,
    HistoryDialogComponent,
    HistoryMatButtonComponent,
    DropzoneDialogComponent,

    SearchInputComponent,
    GenericModelAutocompleteComponent,
    GenericModelChipsComponent,
    FileInputComponent,
    PermissionMatButtonComponent,
    DateRangeMatSelectComponent,

    GenericMatSelectComponent,
    MonthMatPickerComponent,
    RowsPerPageComponent,
    CopyToClipboardButtonComponent,
    SensitiveInfoComponent,
    AnalysisTypeSelectorComponent,

    // Display
    MtmDisplayComponent,
    HomeAddressDisplayComponent,
    CompanyDisplayComponent,
    FloorplanDisplayComponent,
    CommunityDisplayComponent,
    AddressDisplayComponent,
    ContentObjectDisplayComponent,
    UserDisplayComponent,
    HomeStatusDisplayComponent,
    SubdivisionDisplayComponent,
    PaymentRequestDisplayComponent,
    InvoiceDisplayComponent,
    HirlProductAgreementDisplayComponent,
    CoiDocumentDisplayComponent,

    // pages
    PageNotFoundComponent,
    AccessDeniedComponent,

    // pipes
    DividePipe,
    SafePipe,
    YesNoPipe,
    SumArrayOfObjectsPipe,
    GroupByPipe,
    DefaultOrderKeyValuePipe,
    TypeofPipe,
    LbsToTonnesPipe,

    // directives
    BackButtonDirective,
    appMenuTriggerFor,
  ],
  imports: [
    OverlayModule,
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    DragDropModule,
    FormsModule,
    RouterModule,

    MaterialModule,
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: 'No data to display',
        totalMessage: 'total',
        selectedMessage: 'selected',
      },
    }),
    NgxMaskModule.forRoot(),
    GoogleMapsModule,
    NgxDropzoneModule,
    FontAwesomeModule,
    QuillModule.forRoot(),
    CarouselModule,
  ],
  exports: [
    OverlayModule,
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,

    MaterialModule,
    NgxDatatableModule,
    NgxMaskModule,
    NgxDropzoneModule,
    FontAwesomeModule,
    GoogleMapsModule,
    QuillModule,
    CarouselModule,

    // shared app
    ConfirmDialogComponent,
    OkDialogComponent,
    ServerErrorDialogComponent,
    AddressInputComponent,
    HomeAddressInputComponent,
    InputDialogComponent,
    AdvancedLinkComponent,
    HistoryTableComponent,
    HistoryDialogComponent,
    HistoryMatButtonComponent,
    DropzoneDialogComponent,
    CompanyDisplayComponent,
    FloorplanDisplayComponent,
    AddressDisplayComponent,
    ContentObjectDisplayComponent,
    SearchInputComponent,
    GenericModelAutocompleteComponent,
    GenericModelChipsComponent,
    DateRangeMatSelectComponent,
    FileInputComponent,
    PermissionMatButtonComponent,
    MtmDisplayComponent,
    HomeAddressDisplayComponent,
    GenericMatSelectComponent,
    MonthMatPickerComponent,
    RowsPerPageComponent,
    CopyToClipboardButtonComponent,
    SensitiveInfoComponent,
    SubdivisionDisplayComponent,
    UserDisplayComponent,
    HomeStatusDisplayComponent,
    PaymentRequestDisplayComponent,
    InvoiceDisplayComponent,
    HirlProductAgreementDisplayComponent,
    CoiDocumentDisplayComponent,
    AnalysisTypeSelectorComponent,

    // pages
    PageNotFoundComponent,
    AccessDeniedComponent,

    // pipes
    DividePipe,
    SafePipe,
    YesNoPipe,
    SumArrayOfObjectsPipe,
    GroupByPipe,
    DefaultOrderKeyValuePipe,
    TypeofPipe,
    LbsToTonnesPipe,

    // directives
    BackButtonDirective,
    appMenuTriggerFor,
    CommunityDisplayComponent,
  ],
})
export class SharedModule {
  constructor(private library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
