import { PracticeScoringPathInfo } from '@/data/customer-hirl/models/practice-scoring-path';

export class PracticeChapter {
  id?: number;
  title?: string;
  scoring_path?: number;
  scoring_path_info?: PracticeScoringPathInfo;
}

export class PracticeChapterInfo {
  id?: number;
  title?: string;
  scoring_path?: number;
  scoring_path_info?: PracticeScoringPathInfo;
}
