import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InspectionGradeListComponent } from './pages/inspection-grade-list/inspection-grade-list.component';
import { SharedModule } from '@/shared/shared.module';
import { InspectionGradeDisplayComponent } from './components/inspection-grade-display/inspection-grade-display.component';

@NgModule({
  declarations: [InspectionGradeListComponent, InspectionGradeDisplayComponent],
  exports: [InspectionGradeListComponent],
  imports: [CommonModule, SharedModule],
})
export class UserManagementModule {}
