import {
  ModelFormControl,
  ModelFormGroup,
  createConditionalValidator,
} from '../ModelForm';
import { Validators } from '@angular/forms';
import { WindowTypeBackendDict } from './WindowType';
import {
  aboveWarningRangeValidator,
  enumValidator,
} from '@/modules/simulation/validtaors/helper';
import { AreaUnit, Orientation } from '../../enumerations';
import { FieldLabels } from '../base';
import { SimulationModelsState } from '@/modules/simulation/state/reducers';

export const HES_MAX_AREA = 999;

export enum WindowPerformanceClass {
  RESIDENTIAL = 'residential',
  ARCHITECTURAL = 'architectural',
}

export const WindowPerformanceClassLabels: Record<
  WindowPerformanceClass,
  string
> = {
  [WindowPerformanceClass.RESIDENTIAL]: 'Residential',
  [WindowPerformanceClass.ARCHITECTURAL]: 'Architectural',
};

export const DEFAULT_WINDOW: WindowBackendDict = {
  id: null,
  type: null,
  name: '',
  orientation: Orientation.NORTH,
  area: 0.0,
  area_units: AreaUnit.SQ_FT,
  overhang_depth: 0.0,
  overhang_depth_to_top_of_window: 0.0,
  overhang_depth_to_bottom_of_window: 0.0,
  shading_factor_interior_summer: 0.0,
  shading_factor_interior_winter: 0.0,
  shading_factor_adjacent_summer: 0.0,
  shading_factor_adjacent_winter: 0.0,
  above_grade_wall: 0.0,
  foundation_wall: 0.0,
  is_operable: true,
  azimuth: 0.0,
  performance_class: '',
  percent_operable: 0.0,
};

// Labels
export const FIELD_LABELS: FieldLabels<WindowBackendDict> = {
  name: 'Name',
  type: 'Type',
  orientation: 'Orientation',
  area: {
    label: 'Area',
    description: `
      This is the Projected Fenestration Product Area as defined by the <a href="https://nfrc.org" target="_blank" >NFRC</a>.`,
  },

  area_units: 'Area Units',
  overhang_depth: {
    label: 'Overhang Depth',
    description: `
      The horizontal distance from the top of the exterior wall to outer most edge of the overhang.`,
  },
  overhang_depth_to_top_of_window: {
    label: 'Top',
    description: `
      The vertical distance from the top of the exterior wall to the top edge of the window.`,
  },
  overhang_depth_to_bottom_of_window: {
    label: 'Bottom',
    description: `
      The vertical distance from the top of the exterior wall to the bottom edge of the window.`,
  },
  shading_factor_interior_summer: {
    label: 'Interior',
    description: `
      A value between 0.0 and 1.0 that represents the fraction of solar heat that enters the home through the window during the
      summer months. A value of 1.0 means there are no interior window coverings (transparent). This value depends on both the window glass
      and the interior shades.`,
  },
  shading_factor_interior_winter: {
    label: 'Interior',
    description: `
      A value between 0.0 and 1.0 that represents the fraction of solar heat that enters the home through the window during the
      summer months. A value of 1.0 means there are no interior window coverings (transparent). This value depends on both the window glass
      and the interior shades.`,
  },
  shading_factor_adjacent_summer: {
    label: 'Adjacent',
    description: `
      A value between 0.0 and 1.0 to indicate how much the windows are shaded by external objects, which might be attached to the
      building or not during the summer months. Examples include wing walls (part of the building's structure), trees and shrubs
      (which may lose or gain foliage seasonally), and nearby buildings and landforms. If your building has no overhangs or
      significant obstructions, you should select 1.0 for the winter and summer shading factors.`,
  },
  shading_factor_adjacent_winter: {
    label: 'Adjacent',
    description: `
      A value between 0.0 and 1.0 to indicate how much the windows are shaded by external objects, which might be attached to the
      building or not during the winter months. Examples include wing walls (part of the building's structure), trees and shrubs
      (which may lose or gain foliage seasonally), and nearby buildings and landforms. If your building has no overhangs or
      significant obstructions, you should select 1.0 for the winter and summer shading factors.`,
  },
  above_grade_wall: 'Above Grade Wall',
  foundation_wall: 'Foundation Wall',
  is_operable: 'Is Operable',
  azimuth: 'Azimuth',
  percent_operable: {
    label: 'Percent operable',
    description: `
       A value between 0 and 100 to indicate how much the windows is operable.  A fully operable window is 100%.`,
  },
  performance_class: {
    label: 'Performance Class',
    description: `The North American Fenestration Standard/Specification for windows, doors and, skylights provides a method
      for rating the structural performance, water resistance and air leakage of fenestration products.`,
    // reference: 'https://hpxml.nrel.gov/datadictionary/default/Building/BuildingDetails/Enclosure/Windows/Window/PerformanceClass'
  },
};

export interface WindowBackendDict {
  id: number;
  type: number;
  name: string;
  orientation: Orientation;
  area: number;
  area_units: AreaUnit.SQ_FT;
  overhang_depth: number;
  overhang_depth_to_top_of_window: number;
  overhang_depth_to_bottom_of_window: number;
  shading_factor_interior_summer: number;
  shading_factor_interior_winter: number;
  shading_factor_adjacent_summer: number;
  shading_factor_adjacent_winter: number;
  above_grade_wall: number;
  foundation_wall: number;
  is_operable: boolean;
  azimuth: number;
  performance_class: string;
  percent_operable: number;
}

export interface DetailedWindowBackendDict extends WindowBackendDict {
  type_info: WindowTypeBackendDict;
}

export function createWindowForm(window: WindowBackendDict) {
  const validateOnlyIfOperable = createConditionalValidator(
    parentControls => parentControls.is_operable.value,
    ['is_operable']
  );

  return new ModelFormGroup(
    {
      id: new ModelFormControl(window.id),
      name: new ModelFormControl(window.name, [
        Validators.required,
        Validators.maxLength(128),
      ]),
      type: new ModelFormControl(window.type, Validators.required),
      area: new ModelFormControl(window.area, [
        Validators.required,
        Validators.min(0.1),
        Validators.max(HES_MAX_AREA),
      ]),
      area_units: new ModelFormControl(window.area_units, [
        enumValidator([AreaUnit.SQ_FT]),
      ]),
      orientation: new ModelFormControl(window.orientation, [
        Validators.required,
        enumValidator(Orientation, true),
      ]),
      overhang_depth: new ModelFormControl(window.overhang_depth, [
        Validators.required,
        Validators.min(0),
      ]),
      overhang_depth_to_top_of_window: new ModelFormControl(
        window.overhang_depth_to_top_of_window,
        [Validators.required, Validators.min(0), aboveWarningRangeValidator(12)]
      ),
      overhang_depth_to_bottom_of_window: new ModelFormControl(
        window.overhang_depth_to_bottom_of_window,
        [Validators.required, Validators.min(0), aboveWarningRangeValidator(12)]
      ),
      shading_factor_interior_summer: new ModelFormControl(
        window.shading_factor_interior_summer,
        [Validators.required, Validators.min(0), Validators.max(1)]
      ),
      shading_factor_interior_winter: new ModelFormControl(
        window.shading_factor_interior_winter,
        [Validators.required, Validators.min(0), Validators.max(1)]
      ),
      shading_factor_adjacent_summer: new ModelFormControl(
        window.shading_factor_adjacent_summer,
        [Validators.required, Validators.min(0), Validators.max(1)]
      ),
      shading_factor_adjacent_winter: new ModelFormControl(
        window.shading_factor_adjacent_winter,
        [Validators.required, Validators.min(0), Validators.max(1)]
      ),
      above_grade_wall: new ModelFormControl(window.above_grade_wall),
      foundation_wall: new ModelFormControl(window.foundation_wall),
      azimuth: new ModelFormControl(window.azimuth),
      performance_class: new ModelFormControl(window.performance_class, [
        enumValidator(WindowPerformanceClass, true),
      ]),
      is_operable: new ModelFormControl(window.is_operable),
      percent_operable: new ModelFormControl(
        window.percent_operable,
        ...validateOnlyIfOperable([
          Validators.required,
          Validators.min(0),
          Validators.max(100),
        ])
      ),
    },
    {
      validators: [
        validateAttachedWall,
        validateOverHangDepth,
        validateDistance,
      ],
    }
  );
}

function validateAttachedWall(controls: ModelFormGroup) {
  const aboveGradeWall = controls.get('above_grade_wall').value;
  const foundationWall = controls.get('foundation_wall').value;
  if (aboveGradeWall === null && foundationWall === null) {
    return { mustBeAtachedTowall: { name: 'Window' } };
  }

  if (aboveGradeWall !== null && foundationWall !== null) {
    return { atachedToMultiplewall: { name: 'Window' } };
  }
  return null;
}

function validateOverHangDepth(controls: ModelFormGroup) {
  const overhangDepthBottom = controls.get(
    'overhang_depth_to_bottom_of_window'
  ).value;
  const overhangDepthTop = controls.get(
    'overhang_depth_to_top_of_window'
  ).value;

  if (overhangDepthBottom < overhangDepthTop) {
    return { overhangDepthBottomGreaterThanTop: true };
  }
}

function validateDistance(controls: ModelFormGroup) {
  const overhangDepthBottom = controls.get(
    'overhang_depth_to_bottom_of_window'
  ).value;
  const overhangDepthTop = controls.get(
    'overhang_depth_to_top_of_window'
  ).value;
  const distance = overhangDepthBottom - overhangDepthTop;

  if (distance <= 0.75 && controls.get('overhang_depth').value > 0) {
    return { overhangDepthDistance: { distance: distance } };
  }
}

export function denormalizeWindows(state: SimulationModelsState) {
  const denormalizedWindows: DetailedWindowBackendDict[] = [];
  for (const window of Object.values(state.window.entities)) {
    denormalizedWindows.push({
      ...window,
      type_info: state.windowType.entities[window.type],
    });
  }
  return denormalizedWindows;
}
