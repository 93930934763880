import { createReducer, on } from '@ngrx/store';
import * as NoteActions from './actions';
import { Note } from '@/data/simulation/models/Note';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type NoteState = BaseState<Note>;

export const initialState: NoteState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(NoteActions.loadNotesSuccess, (state, { notes, errors }) => {
    const entities = notes.reduce(
      (acc, note) => ({ ...acc, [note.id]: note }),
      {}
    );
    const loading = notes.reduce(
      (acc, note) => ({ ...acc, [note.id]: false }),
      {}
    );

    return {
      entities: {
        ...state.entities,
        ...entities,
      },
      loading: {
        ...state.loading,
        ...loading,
      },
      errors: {
        ...state.errors,
        ...addStateErrorCount(errors),
      },
    };
  }),

  on(NoteActions.updateNote, (state, { note }) => ({
    ...state,
    loading: {
      ...state.loading,
      [note.id]: true,
    },
  })),

  on(NoteActions.updateNoteFailure, (state, { id }) => ({
    ...state,
    loading: {
      ...state.loading,
      [id]: false,
    },
  }))
);
