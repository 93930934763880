import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { getCompany } from '@/state/selectors/company/company.selector';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Company } from '@/data/company/models';
import { MatDialog } from '@angular/material/dialog';
import { CoiHelpDialogComponent } from '@/modules/company/components/coi-help-dialog/coi-help-dialog.component';

@Component({
  selector: 'app-company-coi-tab',
  templateUrl: './company-coi-document-tab.component.html',
  styleUrls: ['./company-coi-document-tab.component.scss'],
})
export class CompanyCoiDocumentTabComponent implements OnInit, OnDestroy {
  company: Company;
  companyDisplayName: string;

  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public injector: Injector,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.store
      .select(getCompany)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(company => {
        this.company = Object.assign(new Company(), company);
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onCOIHelpClicked($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(CoiHelpDialogComponent, {
      width: '45%',
    });

    dialogRef.afterClosed().subscribe(_ => {});
  }
}
