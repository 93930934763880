import { Component, ViewChild } from '@angular/core';
import {
  EditorNavigationService,
  SubTab,
  TABS,
  Tab,
} from '../../services/editor-navigation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  styleUrls: ['./side-nav-bar.component.scss'],
})
export class SideNavBarComponent {
  tabsInfo = TABS;
  isExpanded = true;
  activeTabLink: Tab;
  activeSubTabLink: SubTab;
  componentDestroyed$ = new Subject();
  @ViewChild('drawer') drawer: MatSidenav;

  public toggleDrawer(): void {
    this.isExpanded = !this.isExpanded;
    this.drawer.toggle();
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    public editorNavigationService: EditorNavigationService
  ) {}

  ngOnInit() {
    this.editorNavigationService
      .getActiveTabLinkObservable()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(tab => {
        this.activeTabLink = tab;
      });

    this.editorNavigationService
      .getActiveSubTabLinkObservable()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(subTab => {
        this.activeSubTabLink = subTab;
      });

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.editorNavigationService.setActiveTabLink(
          params.tab,
          params.subTab
        );
      });
  }

  setActiveTabLink(tab: Tab, subTab?: SubTab) {
    this.editorNavigationService.setActiveTabLink(tab, subTab);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
