<div class="card position-relative" appFocusTarget entityName="slab" entityId="{{entityId}}">
  <app-loading-overlay *ngIf="loading"></app-loading-overlay>
  <div class="card-body pb-0">
    <app-form formName="slabFormGroup" [formGroup]="slabFormGroup" (save)="onSave($event)">
      <div>
          <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
      </div>
      <div>
        <app-select formControlName="interior_location" [label]="fieldLabels.interior_location"
          [items]="interiorLocationLabelMapping"></app-select>
      </div>
      <div>
          <app-input formControlName="area" type="expression" [label]="fieldLabels.area" unit="sqft." type="expression"></app-input>
          <app-checkbox formControlName="is_on_grade" [label]="fieldLabels.is_on_grade"></app-checkbox>
      </div>
      <div>
          <app-input formControlName="perimeter" type="number" [label]="fieldLabels.perimeter" unit="ft."></app-input>
          <app-input formControlName="exposed_perimeter" type="number" [label]="fieldLabels.exposed_perimeter" unit="ft."></app-input>
      </div>
      <div>
          <app-input formControlName="depth_below_grade" type="number" [label]="fieldLabels.depth_below_grade" unit="ft."></app-input>
          <app-input formControlName="slab_exposed_area" type="number" [label]="fieldLabels.slab_exposed_area" unit="sqft."></app-input>
      </div>
      <div>
          <app-input formControlName="carpet_fraction" type="number" [label]="fieldLabels.carpet_fraction"></app-input>
          <app-input formControlName="carpet_r_value" type="number" [label]="fieldLabels.carpet_r_value"></app-input>
      </div>
      <div>
          <app-foreign-model-field
            [name]="slabTypeName"
            [errorCount]="slabTypeErrorCount"
            (add)="addSlabType()"
            (edit)="editSlabType()"
            (remove)="removeSlabType()"
            label="Slab type">
          </app-foreign-model-field>
      </div>
    </app-form>
  </div>
  <div class="card-footer p-0 text-right">
    <app-model-action-buttons [entityId]="entityId" modelName="slab" (clone)="clone()" (delete)="removeSlab()">
    </app-model-action-buttons>
  </div>
</div>
