<div class="row">
    <mat-card class="card-container position-relative">
        <app-loading-overlay *ngIf="loading"></app-loading-overlay>
        <mat-card-content>
            <app-form formName="aboveGradeWallTypeFormGroup" [formGroup]="aboveGradeWallTypeFormGroup" (save)="saveAboveGradeWallType($event)">
                <div>
                    <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
                </div>
                <div>
                    <app-input formControlName="assembly_u_value" [label]="fieldLabels.assembly_u_value"></app-input>
                    <app-checkbox formControlName="user_defined_assembly_u_value" label="Directly defined U-value"></app-checkbox>
                </div>
            </app-form>
            <app-assembly-editor [form]="aboveGradeWallTypeFormGroup" (save)="saveAboveGradeWallType($event)" enclosureType="above_grade_wall">
                <app-form formName="aboveGradeWallType" [formGroup]="aboveGradeWallTypeFormGroup" (save)="saveAboveGradeWallType($event)">
                    <div>
                        <app-input formControlName="continuous_insulation_r_value" type="number"
                            [label]="fieldLabels.continuous_insulation_r_value"></app-input>
                    </div>
                    <div>
                        <app-select formControlName="stud_type" [label]="fieldLabels.stud_type" [items]="studTypeLabels"></app-select>
                    </div>
                    <ng-container *ngIf="aboveGradeWallTypeFormGroup.controls.stud_type.value">
                        <div>
                            <app-input formControlName="gypsum_thickness" type="number"
                            [label]="fieldLabels.gypsum_thickness"></app-input>
                        </div>
                        <div><h5>Framing</h5></div>
                        <div>
                            <app-input formControlName="frame_width" type="number" label="Width" unit="ft."></app-input>
                            <app-input formControlName="frame_depth" type="number" label="Depth" unit="ft."></app-input>
                        </div>
                        <div>
                            <app-input formControlName="frame_spacing" type="number" label="Spacing" unit="ft."></app-input>
                        </div>
                        <div>
                            <app-input formControlName="framing_factor" type="number" [label]="fieldLabels.framing_factor"></app-input>
                            <app-checkbox formControlName="use_default_framing_factor"[label]="fieldLabels.use_default_framing_factor" ></app-checkbox>
                        </div>

                        <div><h5>Cavity Insulation</h5></div>
                        <div>
                            <app-select formControlName="cavity_insulation_material" [label]="fieldLabels.cavity_insulation_material"
                                [items]="wallCavityInsulationTypeLabels"></app-select>
                            <app-input formControlName="cavity_insulation_r_value" type="number"
                                [label]="fieldLabels.cavity_insulation_r_value"></app-input>
                        </div>
                        <div>
                            <app-input formControlName="cavity_insulation_thickness" type="number"
                                [label]="fieldLabels.cavity_insulation_thickness"></app-input>
                            <app-select formControlName="cavity_insulation_grade" type="number"
                                [label]="fieldLabels.cavity_insulation_grade"
                                [items]="insulationGrade"></app-select>
                        </div>
                    </ng-container>
                </app-form>
            </app-assembly-editor>
        </mat-card-content>
    </mat-card>
</div>
