import { CustomerDocument } from '@/data/filehandling/models';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '@/state/reducers';
import { History } from '@/data/core/models';
import { ServerResponse } from '@/core/schemes/server-response';
import { ListRequestParams } from '@/core/schemes/request-params';
import { CustomerDocumentRequestParams } from '@/data/filehandling/services/customer-document.service';
import { Annotation } from '@/data/annotation/models';
import { Relationship } from '@/data/relationship/models';
import { AnnotationRequestParams } from '@/data/annotation/services/annotation.service';
import { RelationshipRequestParams } from '@/data/relationship/services/relationship';
import { formatDate } from '@angular/common';

export interface IBaseDataService {
  baseUrl: string;
  history(objectId: number | string, params?: ListRequestParams);
}

export interface IDataServiceMethodOptions {
  ignoreStore?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class BaseDataService implements IBaseDataService {
  baseUrl = '';

  constructor(public http: HttpClient, public store: Store<AppState>) {}

  // Convert object to query params
  rollParams(query = {}): HttpParams {
    let params: HttpParams = new HttpParams();
    for (const key of Object.keys(query)) {
      if (query.hasOwnProperty(key)) {
        if (query[key] instanceof Array) {
          query[key].forEach(item => {
            params = params.append(`${key.toString()}`, item);
          });
        } else if (query[key] instanceof Date) {
          params = params.append(
            key.toString(),
            formatDate(query[key], 'MM/dd/yyyy', 'en-US')
          );
        } else if (query[key] === null || query[key] === undefined) {
          // pass
        } else {
          params = params.append(key.toString(), query[key]);
        }
      }
    }
    return params;
  }

  history(objectId: number | string, params?: ListRequestParams) {
    return this.http.get<ServerResponse<History>>(
      `${this.baseUrl}${objectId}/history/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  documents(objectId: number | string, params?: CustomerDocumentRequestParams) {
    return this.http.get<ServerResponse<CustomerDocument>>(
      `${this.baseUrl}${objectId}/documents/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  documentsCreate(
    objectId: number,
    customerDocument: CustomerDocument | FormData
  ) {
    return this.http.post<CustomerDocument>(
      `${this.baseUrl}${objectId}/documents/`,
      customerDocument
    );
  }

  relationships(objectId: number | string, params?: RelationshipRequestParams) {
    return this.http.get<ServerResponse<Relationship>>(
      `${this.baseUrl}${objectId}/relationships/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  annotations(
    objectId: number,
    params?: AnnotationRequestParams
  ): Observable<ServerResponse<Annotation>> {
    return this.http.get<ServerResponse<Annotation>>(
      `${this.baseUrl}${objectId}/annotations/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  annotationsCreate(
    objectId: number | string,
    annotation: Annotation | FormData
  ): Observable<Annotation> {
    return this.http.post<Annotation>(
      `${this.baseUrl}${objectId}/annotations/`,
      annotation
    );
  }
}
