import { Component, Input, OnInit } from '@angular/core';
import { MechanicalEquipmet } from '@/data/simulation/models/mechanicals';

@Component({
  selector: 'app-equipment-heater',
  templateUrl: './equipment-heater.component.html',
  styleUrls: ['./equipment-heater.component.scss'],
})
export class EquipmentHeaterComponent implements OnInit {
  @Input() equipment: MechanicalEquipmet;

  ngOnInit(): void {}
}
