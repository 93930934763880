import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { Note } from '../models/Note';
import {} from '@/modules/simulation/state/helper';
import { STATE_BACKEND_NAME } from '@/modules/simulation/state.registry';

@Injectable({ providedIn: 'root' })
export class NoteService extends BaseDataService {
  update(note: Note): Observable<{ note: string }> {
    return this.http.patch<{ note: string }>(
      `${environment.AXIS_API_V3_ROOT}/${
        STATE_BACKEND_NAME[note.entity_name]
      }/${note.entity_id}/`,
      {
        note: note.data,
      }
    );
  }
}
