import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { InfiltrationService } from '@/data/simulation/services/infiltration.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as InfiltrationActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { InfiltrationValidator } from '../../validtaors/infiltration.validator';

@Injectable()
export class InfiltrationEffects {
  loadInfiltration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InfiltrationActions.loadInfiltration),
      mergeMap(action => {
        const errors = InfiltrationValidator.validate(action.infiltration);
        return of(
          InfiltrationActions.loadInfiltrationSuccess({
            infiltration: action.infiltration,
            errors,
          })
        );
      })
    );
  });

  updateInfiltration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InfiltrationActions.updateInfiltration),
      mergeMap(action => {
        return this.infiltrationService.update(action.infiltrationChanges).pipe(
          mergeMap(infiltration => {
            const errors = InfiltrationValidator.validate(infiltration);
            return of(
              InfiltrationActions.loadInfiltrationSuccess({
                infiltration: infiltration,
                errors,
              })
            );
          }),
          catchError(error =>
            of(
              InfiltrationActions.updateInfiltrationFailure({
                id: action.infiltrationChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private infiltrationService: InfiltrationService
  ) {}
}
