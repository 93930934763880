import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FloorPlan } from '@/data/floorplan/models/floorplan';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { Router } from '@angular/router';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  first,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { FloorPlanService } from '@/data/floorplan/services/floorplan.service';
import { SimulationService } from '@/data/simulation/services/simulation.service';
import { SimulationList } from '@/data/simulation/models';
import { ServerResponse } from '@/core/schemes/server-response';
import { SimulationRequestParams } from '@/data/simulation/services/simulation.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

export class FloorplanChangeDialogData {
  floorplan?: FloorPlan;
  commit = true;
  retrieve = true;
}

@Component({
  selector: 'app-floorplan-change-dialog',
  templateUrl: './floorplan-change-dialog.component.html',
  styleUrls: ['./floorplan-change-dialog.component.scss'],
})
export class FloorplanChangeDialogComponent implements OnInit, OnDestroy {
  public initialized: boolean;
  public isEditForm = false;
  public entity?: FloorPlan;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;
  public currentUser: User;

  private componentDestroyed$ = new Subject();

  public existingSimulationControl = new FormControl();
  public existingSimulations$: Observable<ServerResponse<SimulationList>>;

  public simulationName = '';
  public selectedCommunity?: SimulationList;
  public checkedForExistingSimulation = false;

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<FloorplanChangeDialogComponent>,
    private fb: FormBuilder,
    private floorplanService: FloorPlanService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    private simulationService: SimulationService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: FloorplanChangeDialogData
  ) {
    if (data?.floorplan) {
      this.entity = Object.assign({}, data?.floorplan);
    }
    this.commit = data?.commit || true;

    if (this.entity?.id) {
      this.isEditForm = true;
    }
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      floorplan: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.floorplan = this.floorplanService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
      this.existingSimulations$ =
        this.existingSimulationControl.valueChanges.pipe(
          takeUntil(this.componentDestroyed$),
          startWith(''),
          debounceTime(100),
          distinctUntilChanged(),
          tap(value => (this.simulationName = value)),
          tap(_ => this.store.dispatch(toggleLoading({ payload: true }))),
          switchMap(value => {
            const simulationRequestParams = new SimulationRequestParams();
            simulationRequestParams.search = value;
            simulationRequestParams.page_size = 50;
            simulationRequestParams.floorplan__isnull = true;
            return this.simulationService
              .list(simulationRequestParams)
              .pipe(catchError(err => EMPTY));
          }),
          tap(_ => this.store.dispatch(toggleLoading({ payload: false })))
        );
    }

    forkJoin(sources).subscribe({
      next: ({ currentUser, floorplan }) => {
        this.store.dispatch(toggleLoading({ payload: false }));

        this.currentUser = currentUser;
        this.entity = floorplan;
        this.setupForm();
        this.initialized = true;
        if (this.isEditForm) {
          this.hydrateForm();
        }
      },
      error: error => this.uiHelperService.handleUserRequestError(error),
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      number: [null],
      square_footage: [null, Validators.required],
      comment: [''],
      remrate_data_file: [null],
    });
  }

  hydrateForm() {
    this.form.patchValue(Object.assign({}, this.entity), {
      emitEvent: false,
      onlySelf: true,
    });
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = new FormData();

    data.append('name', this.form.value.name);
    data.append('square_footage', this.form.value.square_footage);
    data.append('number', this.form.value.number);
    data.append('comment', this.form.value.comment);
    data.append('remrate_data_file', this.form.value.remrate_data_file);
    data.append('simulation', this.selectedCommunity.id.toString());

    this.floorplanService
      .create(data)
      .pipe(first())
      .subscribe({
        next: floorPlan => {
          this.store.dispatch(toggleLoading({ payload: false }));
          const _ = this.router.navigate(['/', 'floorplans', floorPlan.id]);
          this.dialogRef.close(floorPlan);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = new FormData();

    data.append('name', this.form.value.name);
    data.append('square_footage', this.form.value.square_footage);
    data.append('number', this.form.value.number);
    data.append('comment', this.form.value.comment);
    data.append('remrate_data_file', this.form.value.remrate_data_file);

    this.floorplanService
      .update(data, this.entity.id)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: floorplan => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(floorplan);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  onSimulationSelected($event: MatAutocompleteSelectedEvent) {
    if (typeof $event.option.value === 'string') {
      this.checkedForExistingSimulation = true;
    } else {
      this.selectedCommunity = $event.option.value;
    }
  }

  displayExistingSimulationAutocomplete(simulation: SimulationList) {
    if (simulation) {
      return `${simulation.name}`;
    }
  }
}
