import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import { BaseDataService } from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { ContactCard, ContactCardReportDownload } from '@/data/core/models';
import { CompanyType } from '@/data/company/models';
import { AsynchronousProcessedDocument } from '@/data/filehandling/models';

export enum ContactCardRequestParamsAttachedTo {
  company = 'company',
  user = 'user',
}

export const ContactCardRequestParamsAttachedToLabelMapping: Record<
  ContactCardRequestParamsAttachedTo,
  string
> = {
  [ContactCardRequestParamsAttachedTo.company]: 'Company',
  [ContactCardRequestParamsAttachedTo.user]: 'User',
};

export enum ContactCardRequestParamsHIRLProjectClientType {
  sf = 'sf',
  mf = 'mf',
  not_client = 'not_client',
}

export const ContactCardRequestParamsHIRLProjectClientTypeLabelMapping: Record<
  ContactCardRequestParamsHIRLProjectClientType,
  string
> = {
  [ContactCardRequestParamsHIRLProjectClientType.sf]: 'Single Family',
  [ContactCardRequestParamsHIRLProjectClientType.mf]: 'Multi Family',
  [ContactCardRequestParamsHIRLProjectClientType.not_client]: 'Not a Client',
};

export class ContactCardRequestParams extends ListRequestParams {
  attached_to?: ContactCardRequestParamsAttachedTo;
  company?: number;
  company_type?: CompanyType;
  company__company_type?: CompanyType;
  hirl_marketing_role?: number | number[];
  company_and_related_users?: number;
  protected?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ContactCardService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/contact_cards/`;

  list(
    params?: ContactCardRequestParams
  ): Observable<ServerResponse<ContactCard>> {
    return this.http.get<ServerResponse<ContactCard>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  create(contactCard: ContactCard): Observable<ContactCard> {
    return this.http.post<ContactCard>(`${this.baseUrl}`, contactCard);
  }

  update(
    contactCard: ContactCard,
    contactCardId: number,
    partial = true
  ): Observable<ContactCard> {
    let method = this.http.patch;
    if (!partial) {
      method = this.http.put;
    }
    return this.http.patch<ContactCard>(
      `${this.baseUrl}${contactCardId}/`,
      contactCard
    );
  }

  report_download(
    data: ContactCardReportDownload,
    params?: ContactCardRequestParams
  ): Observable<AsynchronousProcessedDocument> {
    return this.http.post<AsynchronousProcessedDocument>(
      `${this.baseUrl}report_download/`,
      data,
      {
        params: this.rollParams(params),
      }
    );
  }
}
