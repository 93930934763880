import { Location } from '@/data/simulation/enumerations';
import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { WaterHeaterBackendDict } from './WaterHeater';
import { HeaterBackendDict } from './Heater';
import { AirConditionerBackendDict } from './AirConditioner';
import { AirSourceHeatPumpBackendDict } from './AirSourceHeatPump';
import { GroundSourceHeatPumpBackendDict } from './GroundSourceHeatPump';
import { DehumidifierBackendDict } from './Dehumidifier';
import { FieldLabels } from '../base';
import { SimulationModelsState } from '@/modules/simulation/state/reducers';
import { requiredAsWarning } from '@/modules/simulation/validtaors/helper';

export enum EquipmentLocation {
  CONDITIONED_SPACE = Location.CONDITIONED_SPACE,
  UNCONDITIONED_GARAGE = Location.UNCONDITIONED_GARAGE,
  VENTED_ATTIC = Location.VENTED_ATTIC,
  SEALED_ATTIC = Location.SEALED_ATTIC,
  ATTIC = Location.ATTIC,
  AMBIENT = Location.AMBIENT,
  UNCONDITIONED_BASEMENT = Location.UNCONDITIONED_BASEMENT,
  CONDITIONED_SEALED_ATTIC = Location.CONDITIONED_SEALED_ATTIC,
  CONDITIONED_CRAWL_SPACE = Location.CONDITIONED_CRAWL_SPACE,
  CRAWL_SPACE_VENTED = Location.CRAWL_SPACE_VENTED,
  CRAWL_SPACE_UNVENTED = Location.CRAWL_SPACE_UNVENTED,
  ENCLOSED_CRAWL = Location.ENCLOSED_CRAWL,
  GARAGE = Location.GARAGE,
  UNKNOWN = Location.UNKNOWN,
  MF_UNRATED_CONDITIONED = Location.MF_UNRATED_CONDITIONED,
  MF_UNRATED_HEATED = Location.MF_UNRATED_HEATED,
  MF_BUFFER = Location.MF_BUFFER,
  MF_NONFREEZING = Location.MF_NONFREEZING,
}

export enum EquipmentType {
  AIR_CONDITIONER = 'air_conditioner',
  AIR_SOURCE_HEAT_PUMP = 'air_source_heat_pump',
  DEHUMIDIFIER = 'dehumidifier',
  GROUND_SOURCE_HEAT_PUMP = 'ground_source_heat_pump',
  HEATER = 'heater',
  WATER_HEATER = 'water_heater',
}

export const EquipmentTypeLabels: Record<EquipmentType, string> = {
  [EquipmentType.AIR_CONDITIONER]: 'Air Conditioner',
  [EquipmentType.AIR_SOURCE_HEAT_PUMP]: 'Air Source Heat Pump',
  [EquipmentType.DEHUMIDIFIER]: 'Dehumidifier',
  [EquipmentType.GROUND_SOURCE_HEAT_PUMP]: 'Ground Source Heat Pump',
  [EquipmentType.HEATER]: 'Heater',
  [EquipmentType.WATER_HEATER]: 'Water Heater',
};

export type Equipment = {
  [K in EquipmentType]: number | null;
};

export const FIELD_LABELS: FieldLabels<MechanicalEquipmentBackendDict> = {
  name: 'Name',
  location: 'Location',
  qty_installed: 'Qty Installed',
  water_heater_percent_served: 'Water Heater Percent Served',
  heating_percent_served: 'Heating Percent Served',
  cooling_percent_served: 'Cooling Percent Served',
  dehumidifier_percent_served: 'Dehumidifier Percent Served',
  ...EquipmentTypeLabels,
};

export interface MechanicalEquipmentBackendDict extends Equipment {
  id: number | null;
  name: string;
  location: EquipmentLocation;
  qty_installed: number;
  water_heater_percent_served: number;
  heating_percent_served: number;
  cooling_percent_served: number;
  dehumidifier_percent_served: number;
}

export interface DetailedMechanicalEquipmentBackendDict
  extends MechanicalEquipmentBackendDict {
  water_heater_info: WaterHeaterBackendDict | null;
  heater_info: HeaterBackendDict | null;
  air_conditioner_info: AirConditionerBackendDict | null;
  air_source_heat_pump_info: AirSourceHeatPumpBackendDict | null;
  ground_source_heat_pump_info: GroundSourceHeatPumpBackendDict | null;
  dehumidifier_info: DehumidifierBackendDict | null;
}

export const getEquipmentType = (equipment: MechanicalEquipmentBackendDict) => {
  if (equipment.air_conditioner !== null) {
    return EquipmentType.AIR_CONDITIONER;
  } else if (equipment.air_source_heat_pump !== null) {
    return EquipmentType.AIR_SOURCE_HEAT_PUMP;
  } else if (equipment.dehumidifier !== null) {
    return EquipmentType.DEHUMIDIFIER;
  } else if (equipment.ground_source_heat_pump !== null) {
    return EquipmentType.GROUND_SOURCE_HEAT_PUMP;
  } else if (equipment.heater !== null) {
    return EquipmentType.HEATER;
  } else if (equipment.water_heater !== null) {
    return EquipmentType.WATER_HEATER;
  }
};

export function createMechanicalEquipmentForm(
  mechanicalequipment: MechanicalEquipmentBackendDict
) {
  return new ModelFormGroup(
    {
      name: new ModelFormControl(mechanicalequipment.name, Validators.required),
      location: new ModelFormControl(
        mechanicalequipment.location,
        requiredAsWarning()
      ),
      qty_installed: new ModelFormControl(
        mechanicalequipment.qty_installed,
        Validators.required
      ),
      heating_percent_served: new ModelFormControl(
        mechanicalequipment.heating_percent_served,
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      cooling_percent_served: new ModelFormControl(
        mechanicalequipment.cooling_percent_served,
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      water_heater_percent_served: new ModelFormControl(
        mechanicalequipment.water_heater_percent_served,
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      dehumidifier_percent_served: new ModelFormControl(
        mechanicalequipment.dehumidifier_percent_served,
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      dehumidifier: new ModelFormControl(mechanicalequipment.dehumidifier),
      air_conditioner: new ModelFormControl(
        mechanicalequipment.air_conditioner
      ),
      air_source_heat_pump: new ModelFormControl(
        mechanicalequipment.air_source_heat_pump
      ),
      ground_source_heat_pump: new ModelFormControl(
        mechanicalequipment.ground_source_heat_pump
      ),
      heater: new ModelFormControl(mechanicalequipment.heater),
      water_heater: new ModelFormControl(mechanicalequipment.water_heater),
    },
    {
      validators: [
        (controls: ModelFormGroup) => {
          const dehumidifier = controls.get('dehumidifier').value;
          const dehumidifierPercentServed = controls.get(
            'dehumidifier_percent_served'
          ).value;

          if (!dehumidifier && dehumidifierPercentServed !== 0) {
            return {
              invalidDehumidifierPercent: {
                equipment: getEquipmentType(mechanicalequipment),
                dehumidifierPercentServed: dehumidifierPercentServed,
              },
            };
          } else if (dehumidifierPercentServed === 0 && dehumidifier) {
            return {
              dehumidifierPercent: {
                equipment: getEquipmentType(mechanicalequipment),
              },
            };
          }

          return null;
        },

        (controls: ModelFormGroup) => {
          const airConditioner = controls.get('air_conditioner').value;
          const air_source_heat_pump = controls.get(
            'air_source_heat_pump'
          ).value;
          const ground_source_heat_pump = controls.get(
            'ground_source_heat_pump'
          ).value;
          const coolingPercentServed = controls.get(
            'cooling_percent_served'
          ).value;

          if (
            !airConditioner &&
            !air_source_heat_pump &&
            !ground_source_heat_pump &&
            coolingPercentServed !== 0
          ) {
            return {
              invalidCoolingPercent: {
                equipment: getEquipmentType(mechanicalequipment),
                coolingPercentServed: coolingPercentServed,
              },
            };
          } else if (coolingPercentServed === 0 && airConditioner) {
            return {
              coolingPercent: {
                equipment: getEquipmentType(mechanicalequipment),
              },
            };
          }
        },

        (controls: ModelFormGroup) => {
          const heater = controls.get('heater').value;
          const air_source_heat_pump = controls.get(
            'air_source_heat_pump'
          ).value;
          const ground_source_heat_pump = controls.get(
            'ground_source_heat_pump'
          ).value;
          const heatingPercentServed = controls.get(
            'heating_percent_served'
          ).value;

          if (
            !heater &&
            !air_source_heat_pump &&
            !ground_source_heat_pump &&
            heatingPercentServed !== 0
          ) {
            return {
              invalidHeatingPercent: {
                equipment: getEquipmentType(mechanicalequipment),
                heatingPercentServed: heatingPercentServed,
              },
            };
          } else if (heatingPercentServed === 0 && heater) {
            return {
              heatingPercent: {
                equipment: getEquipmentType(mechanicalequipment),
              },
            };
          }
        },

        (controls: ModelFormGroup) => {
          const waterHeater = controls.get('water_heater').value;
          const waterHeaterPercentServed = controls.get(
            'water_heater_percent_served'
          ).value;

          if (!waterHeater && waterHeaterPercentServed !== 0) {
            return {
              invalidWaterHeaterPercent: {
                equipment: getEquipmentType(mechanicalequipment),
                waterHeaterPercentServed: waterHeaterPercentServed,
              },
            };
          }
        },
      ],
    }
  );
}

export function denormalizeMechanicalEquipment(state: SimulationModelsState) {
  const denormalizedMechanicalEquipment: DetailedMechanicalEquipmentBackendDict[] =
    [];
  for (const mechanicalequipment of Object.values(
    state.mechanicalEquipment.entities
  )) {
    denormalizedMechanicalEquipment.push({
      ...mechanicalequipment,
      water_heater_info:
        mechanicalequipment.water_heater !== null
          ? state.waterHeater.entities[mechanicalequipment.water_heater]
          : null,
      heater_info:
        mechanicalequipment.heater !== null
          ? state.heater.entities[mechanicalequipment.heater]
          : null,
      air_conditioner_info:
        mechanicalequipment.air_conditioner !== null
          ? state.airConditioner.entities[mechanicalequipment.air_conditioner]
          : null,
      air_source_heat_pump_info:
        mechanicalequipment.air_source_heat_pump !== null
          ? state.airSourceHeatPump.entities[
              mechanicalequipment.air_source_heat_pump
            ]
          : null,
      ground_source_heat_pump_info:
        mechanicalequipment.ground_source_heat_pump !== null
          ? state.groundSourceHeatPump.entities[
              mechanicalequipment.ground_source_heat_pump
            ]
          : null,
      dehumidifier_info:
        mechanicalequipment.dehumidifier !== null
          ? state.dehumidifier.entities[mechanicalequipment.dehumidifier]
          : null,
    });
  }
  return denormalizedMechanicalEquipment;
}
