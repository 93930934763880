import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as UtilityRateActions from './actions';
import * as SeasonalRateActions from '../seasonal-rate/actions';
import * as SimulationActions from '../simulation/actions';
import * as SharedActions from '../shared/shared.actions';
import { UtilityRateService } from '@/data/simulation/services/utility-rate.service';
import { UtilityRateBackendDict } from '@/data/simulation/models/utility/UtilityRate';
import { UtilityRateValidator } from '../../validtaors/utility-rate.validator';
import { DetailedSeasonalRateBackendDict } from '@/data/simulation/models/utility/SeasonalRate';

@Injectable()
export class UtilityRateEffects {
  loadDetailedUtilityRates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UtilityRateActions.loadDetailedUtilityRates),
      mergeMap(action => {
        const detailedSeasonRates: DetailedSeasonalRateBackendDict[] = [];
        const utilityRates: UtilityRateBackendDict[] = [];

        action.detailedUtilityRates.forEach(detailedUtilityRate => {
          const { seasonal_rates_info: seasonalRates, ...utilityRate } =
            detailedUtilityRate;

          if (seasonalRates) {
            detailedSeasonRates.push(...seasonalRates);
          }

          utilityRates.push(utilityRate);
        });

        const errors = UtilityRateValidator.validate(
          action.detailedUtilityRates
        );

        return of(
          UtilityRateActions.loadUtilityRatesSuccess({
            utilityRates: utilityRates,
            errors: errors,
          }),
          SeasonalRateActions.loadDetailedSeasonalRates({
            detailedSeasonalRates: detailedSeasonRates,
          })
        );
      })
    );
  });

  updateUtilityRate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UtilityRateActions.updateUtilityRate),
      mergeMap(action =>
        this.utilityrateService.update(action.utilityRateChanges).pipe(
          mergeMap(updatedFloor => {
            const errors = UtilityRateValidator.validate([updatedFloor]);
            return of(
              UtilityRateActions.updateUtilityRateSuccess({
                utilityRate: updatedFloor,
                errors: errors[updatedFloor.id],
              })
            );
          }),
          catchError(error =>
            of(
              UtilityRateActions.updateUtilityRateFailure({
                id: action.utilityRateChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeUtilityRate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UtilityRateActions.removeUtilityRate),
      mergeMap(action =>
        this.utilityrateService.delete(action.utilityRate.id).pipe(
          mergeMap(() =>
            of(
              UtilityRateActions.removeUtilityRateSuccess({
                id: action.utilityRate.id,
              }),
              SimulationActions.removeItemFromList({
                fieldName: 'utility_rates',
                id: action.utilityRate.id,
              })
            )
          ),
          catchError(error =>
            of(
              UtilityRateActions.removeUtilityRateFailure({
                id: action.utilityRate.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private utilityrateService: UtilityRateService
  ) {}
}
