import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { EditSimulationComponent } from '@/modules/simulation/pages/edit-simulation/edit-simulation.component';
import { AboveGradeWallComponent } from '@/modules/simulation/components/enclosure/above-grade-wall/above-grade-wall.component';
import { SectionComponent } from '@/modules/simulation/components/section/section.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AboveGradeWallTypeFormComponent } from './components/enclosure/above-grade-wall-type-form/above-grade-wall-type-form.component';
import { AboveGradeWallTypeChangeDialogComponent } from './components/enclosure/above-grade-wall-type-change-dialog/above-grade-wall-type-change-dialog.component';
import { InputComponent } from './components/fields/input/input.component';
import { SelectComponent } from './components/fields/select/select.component';
import { FormComponent } from './components/forms/form/form.component';
import { ForeignModelFieldComponent } from './components/fields/foreign-model-field/foreign-model-field.component';
import { FoundationWallsFormComponent } from './components/enclosure/foundation-walls-form/foundation-walls-form.component';
import { FoundationWallFormComponent } from './components/enclosure/foundation-wall-form/foundation-wall-form.component';
import { FoundationWallTypeChangeDialogComponent } from './components/enclosure/foundation-wall-type-change-dialog/foundation-wall-type-change-dialog.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './state/reducers';
import { effects } from './state/effects';
import { EnvelopeTabComponent } from './components/enclosure/envelope-tab/envelope-tab.component';
import { SimulationListPageComponent } from './pages/simulation-list-page/simulation-list-page.component';
import { AboveGradeWallsComponent } from './components/enclosure/above-grade-walls/above-grade-walls.component';
import { FoundationWallTypeComponent } from './components/enclosure/foundation-wall-type/foundation-wall-type.component';
import { SimulationErrorsAndWarningsComponent } from './components/simulation-errors-and-warnings/simulation-errors-and-warnings.component';
import { FieldErrorsComponent } from './components/fields/field-errors/field-errors.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { AboveGradeWallErrorsComponent } from './components/enclosure/above-grade-wall-errors/above-grade-wall-errors.component';
import { ModelErrorsTreeComponent } from './components/model-errors-tree/model-errors-tree.component';
import { FrameFloorsComponent } from './components/enclosure/frame-floors/frame-floors.component';
import { FrameFloorComponent } from './components/enclosure/frame-floor/frame-floor.component';
import { FrameFloorTypeChangeDialogComponent } from './components/enclosure/frame-floor-type-change-dialog/frame-floor-type-change-dialog.component';
import { FrameFloorTypeComponent } from './components/enclosure/frame-floor-type/frame-floor-type.component';
import { RoofsComponent } from './components/enclosure/roofs/roofs.component';
import { RoofComponent } from './components/enclosure/roof/roof.component';
import { RoofTypeComponent } from './components/enclosure/roof-type/roof-type.component';
import { RoofTypeChangeDialogComponent } from './components/enclosure/roof-type-change-dialog/roof-type-change-dialog.component';
import { FrameFloorErrorsComponent } from './components/enclosure/frame-floor-errors/frame-floor-errors.component';
import { RoofErrorsComponent } from './components/enclosure/roof-errors/roof-errors.component';
import { FoundationWallErrorsComponent } from './components/enclosure/foundation-wall-errors/foundation-wall-errors.component';
import { ErrorCountComponent } from './components/error-count/error-count.component';
import { RimJoistsComponent } from './components/enclosure/rim-joists/rim-joists.component';
import { RimJoistComponent } from './components/enclosure/rim-joist/rim-joist.component';
import { RimJoistTypeComponent } from './components/enclosure/rim-joist-type/rim-joist-type.component';
import { RimJoistTypeChangeDialogComponent } from './components/enclosure/rim-joist-type-change-dialog/rim-joist-type-change-dialog.component';
import { RimJoistErrorsComponent } from './components/enclosure/rim-joist-errors/rim-joist-errors.component';
import { SlabComponent } from './components/enclosure/slab/slab.component';
import { SlabsComponent } from './components/enclosure/slabs/slabs.component';
import { SlabTypeComponent } from './components/enclosure/slab-type/slab-type.component';
import { SlabErrorsComponent } from './components/enclosure/slab-errors/slab-errors.component';
import { SlabTypeChangeDialogComponent } from './components/enclosure/slab-type-change-dialog/slab-type-change-dialog.component';
import { SkylightComponent } from './components/enclosure/skylight/skylight.component';
import { SkylightTypeComponent } from './components/enclosure/skylight-type/skylight-type.component';
import { SkylightTypeChangeDialogComponent } from './components/enclosure/skylight-type-change-dialog/skylight-type-change-dialog.component';
import { SkylightsComponent } from './components/enclosure/skylights/skylights.component';
import { SkylightErrorsComponent } from './components/enclosure/skylight-errors/skylight-errors.component';
import { ModelSelectComponent } from './components/fields/model-select/model-select.component';
import { CheckboxComponent } from './components/fields/checkbox/checkbox.component';
import { DoorComponent } from './components/enclosure/door/door.component';
import { DoorTypeComponent } from './components/enclosure/door-type/door-type.component';
import { DoorTypeChangeDialogComponent } from './components/enclosure/door-type-change-dialog/door-type-change-dialog.component';
import { DoorsComponent } from './components/enclosure/doors/doors.component';
import { DoorErrorsComponent } from './components/enclosure/door-errors/door-errors.component';
import { WindowComponent } from './components/enclosure/window/window.component';
import { WindowsComponent } from './components/enclosure/windows/windows.component';
import { WindowTypeComponent } from './components/enclosure/window-type/window-type.component';
import { WindowTypeChangeDialogComponent } from './components/enclosure/window-type-change-dialog/window-type-change-dialog.component';
import { WindowErrorsComponent } from './components/enclosure/window-errors/window-errors.component';
import { MechanicalsTabComponent } from './components/mechanicals/mechanicals-tab/mechanicals-tab.component';
import { MechanicalEquipmentComponent } from './components/mechanicals/mechanical-equipment/mechanical-equipment.component';
import { EquipmentDetailsChangeDialogComponent } from './components/mechanicals/equipment-details-change-dialog/equipment-details-change-dialog.component';
import { HeaterComponent } from './components/mechanicals/heater/heater.component';
import { WaterHeaterComponent } from './components/mechanicals/water-heater/water-heater.component';
import { AirConditionerComponent } from './components/mechanicals/air-conditioner/air-conditioner.component';
import { AirSourceHeatPumpComponent } from './components/mechanicals/air-source-heat-pump/air-source-heat-pump.component';
import { GroundSourceHeatPumpComponent } from './components/mechanicals/ground-source-heat-pump/ground-source-heat-pump.component';
import { DehumidifierComponent } from './components/mechanicals/dehumidifier/dehumidifier.component';
import { MechanicalEquipmentErrorsComponent } from './components/mechanicals/mechanical-equipment-errors/mechanical-equipment-errors.component';
import { FenestrationTabComponent } from './components/fenestration/fenestration-tab/fenestration-tab.component';
import { DishwasherComponent } from './components/appliances/dishwasher/dishwasher.component';
import { BaseAppliancesDirective } from './components/appliances/base-appliances.directive';
import { AppliancesTabComponent } from './components/appliances/appliances-tab/appliances-tab.component';
import { RefrigeratorComponent } from './components/appliances/refrigerator/refrigerator.component';
import { KitchenAppliancesComponent } from './components/appliances/kitchen-appliances/kitchen-appliances.component';
import { CookingRangeComponent } from './components/appliances/cooking-range/cooking-range.component';
import { LaundryAppliancesComponent } from './components/appliances/laundry-appliances/laundry-appliances.component';
import { ClothesWasherComponent } from './components/appliances/clothes-washer/clothes-washer.component';
import { ClothesDryerComponent } from './components/appliances/clothes-dryer/clothes-dryer.component';
import { FansComponent } from './components/appliances/fans/fans.component';
import { LightsComponent } from './components/appliances/lights/lights.component';
import { LightsErrorsComponent } from './components/appliances/lights-errors/lights-errors.component';
import { MechanicalEquipmentsComponent } from './components/mechanicals/mechanical-equipments/mechanical-equipments.component';
import { DistributionSystemsComponent } from './components/mechanicals/distribution-systems/distribution-systems.component';
import { DistributionSystemComponent } from './components/mechanicals/distribution-system/distribution-system.component';
import { DuctComponent } from './components/mechanicals/duct/duct.component';
import { DuctsComponent } from './components/ducts/ducts.component';
import { GeneratorsTabComponent } from './components/generators/generators-tab/generators-tab.component';
import { PhotovoltaicsComponent } from './components/generators/photovoltaics/photovoltaics.component';
import { PhotovoltaicComponent } from './components/generators/photovoltaic/photovoltaic.component';
import { PhotovoltaicErrorsComponent } from './components/generators/photovoltaic-errors/photovoltaic-errors.component';
import { WaterSystemComponent } from './components/systems/water-system/water-system.component';
import { SystemsTabComponent } from './components/systems/systems-tab/systems-tab.component';
import { WaterSystemErrorsComponent } from './components/systems/water-system-errors/water-system-errors.component';
import { ModelListComponent } from './components/model-list/model-list.component';
import { SideNavBarComponent } from './components/side-nav-bar/side-nav-bar.component';
import { GeneralInfoTabComponent } from './components/general-info/general-info-tab/general-info-tab.component';
import { ProjectComponent } from './components/general-info/project/project.component';
import { LocationComponent } from './components/general-info/location/location.component';
import { SimulationComponent } from './components/general-info/simulation/simulation.component';
import { UdrhListComponent } from './pages/udrh-list/udrh-list.component';
import { ChangeUdrhDialogComponent } from './components/change-udrh-dialog/change-udrh-dialog.component';
import { TopNavBarComponent } from './components/top-nav-bar/top-nav-bar.component';
import { ReportsTabComponent } from './components/reports/reports-tab/reports-tab.component';
import { AnalysisComponent } from './components/reports/analysis/analysis.component';
import { ComponentLoadsComponent } from './components/reports/component-loads/component-loads.component';
import { FuelUsageComponent } from './components/reports/fuel-usage/fuel-usage.component';
import { EmissionsComponent } from './components/reports/emissions/emissions.component';
import { AnalysisSummaryComponent } from './components/reports/analysis-summary/analysis-summary.component';
import { RunSimulationButtonComponent } from './components/run-simulation-button/run-simulation-button.component';
import { UtilityRateDialogComponent } from './components/utility/utility-rate-dialog/utility-rate-dialog.component';
import { UtilityRateComponent } from './components/utility/utility-rate/utility-rate.component';
import { SeasonalRateComponent } from './components/utility/seasonal-rate/seasonal-rate.component';
import { BlockRateComponent } from './components/utility/block-rate/block-rate.component';
import { SeasonalRatesComponent } from './components/utility/seasonal-rates/seasonal-rates.component';
import { UtilitiesComponent } from './components/utility/utilities/utilities.component';
import { RequiresEditPermissionDirective } from './directives/requires-edit-permission.directive';
import { LabelComponent } from './components/fields/label/label.component';
import { AssemblyLayerComponent } from './components/assembly-layer/assembly-layer.component';
import { AssemblyLayersComponent } from './components/assembly-layers/assembly-layers.component';
import { AssemblyLayerEditorComponent } from './components/assembly-layer-editor/assembly-layer-editor.component';
import { AssemblyLayerMaterialComponent } from './components/assembly-layer-material/assembly-layer-material.component';
import { AssemblyLayerMaterialsComponent } from './components/assembly-layer-materials/assembly-layer-materials.component';
import { AssemblyLayerStudComponent } from './components/assembly-layer-stud/assembly-layer-stud.component';
import { AmbienceComponent } from './components/appliances/ambience/ambience.component';
import { CompanySelectDialogComponent } from './components/company-select-dialog/company-select-dialog.component';
import { UserSelectDialogComponent } from './components/user-select-dialog/user-select-dialog.component';
import { ThermostatComponent } from './components/mechanicals/thermostat/thermostat.component';
import { ThermostatsComponent } from './components/mechanicals/thermostats/thermostats.component';
import { InfiltrationComponent } from './components/enclosure/infiltration/infiltration.component';
import { DateComponent } from './components/fields/date/date.component';
import { WeatherSelectDialogComponent } from './components/weather-select-dialog/weather-select-dialog.component';
import { ShelterClassInfoDialogComponent } from './components/enclosure/shelter-class-info-dialog/shelter-class-info-dialog.component';
import { NoteComponent } from './components/note/note.component';
import { NotesComponent } from './components/notes/notes.component';
import { FocusTargetDirective } from './directives/focus-target.directive';
import { ModelLinkComponent } from './components/model-link/model-link.component';
import { AssemblyEditorComponent } from './components/enclosure/assembly-editor/assembly-editor.component';
import { SimulationSummaryComponent } from './components/simulation-summary/simulation-summary.component';
import { InfiltrationErrorsComponent } from './components/enclosure/infiltration-errors/infiltration-errors.component';
import { ThermostatErrorsComponent } from './components/mechanicals/thermostat-errors/thermostat-errors.component';
import { BackendValidationResponseDialogComponent } from './components/reports/backend-validation-response-dialog/backend-validation-response-dialog.component';
import { RunSimulationStatusComponent } from './components/run-simulation-status/run-simulation-status.component';
import { AppliancesErrorsComponent } from './components/appliances/appliances-errors/appliances-errors.component';
import { LocationErrorsComponent } from './components/general-info/location-errors/location-errors.component';
import { UtilityRateErrorsComponent } from './components/utility/utility-rate-errors/utility-rate-errors.component';
import { DistributionSystemErrorsComponent } from './components/mechanicals/distribution-system-errors/distribution-system-errors.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ProjectErrorsComponent } from './components/general-info/project-errors/project-errors.component';
import { MechanicalVentilationComponent } from './components/systems/mechanical-ventilation/mechanical-ventilation.component';
import { MechanicalVentilationsComponent } from './components/systems/mechanical-ventilations/mechanical-ventilations.component';
import { NaturalVentilationComponent } from './components/systems/natural-ventilation/natural-ventilation.component';
import { NaturalVentilationErrorsComponent } from './components/systems/natural-ventilation-errors/natural-ventilation-errors.component';
import { MechanicalVentilationErrorsComponent } from './components/systems/mechanical-ventilation-errors/mechanical-ventilation-errors.component';
import { ModelActionButtonsComponent } from './components/model-action-buttons/model-action-buttons.component';
import { AnalysisTypeConfigComponent } from './components/reports/analysis-type-config/analysis-type-config.component';
import { CompanySimulationConfigComponent } from './components/company-simulation-config/company-simulation-config.component';
import { QaFlagsComponent } from './components/qa-flags/qa-flags.component';
import { errorCountMetaReducer } from './state/helper';
import { CompanyLibraryElementsComponent } from './components/company-library-elements/company-library-elements.component';

@NgModule({
  declarations: [
    AboveGradeWallComponent,
    SectionComponent,
    EditSimulationComponent,
    AboveGradeWallTypeChangeDialogComponent,
    AboveGradeWallTypeFormComponent,
    InputComponent,
    SelectComponent,
    FormComponent,
    ForeignModelFieldComponent,
    FoundationWallsFormComponent,
    FoundationWallFormComponent,
    FoundationWallTypeChangeDialogComponent,
    EnvelopeTabComponent,
    AboveGradeWallsComponent,
    FoundationWallTypeComponent,
    SimulationErrorsAndWarningsComponent,
    FieldErrorsComponent,
    LoadingOverlayComponent,
    AboveGradeWallErrorsComponent,
    ModelErrorsTreeComponent,
    FrameFloorsComponent,
    FrameFloorComponent,
    FrameFloorTypeChangeDialogComponent,
    FrameFloorTypeComponent,
    RoofsComponent,
    RoofComponent,
    RoofTypeComponent,
    RoofTypeChangeDialogComponent,
    FrameFloorErrorsComponent,
    RoofErrorsComponent,
    FoundationWallErrorsComponent,
    ErrorCountComponent,
    RimJoistsComponent,
    RimJoistComponent,
    RimJoistTypeComponent,
    RimJoistTypeChangeDialogComponent,
    RimJoistErrorsComponent,
    SlabComponent,
    SlabsComponent,
    SlabTypeComponent,
    SlabErrorsComponent,
    SlabTypeChangeDialogComponent,
    SkylightComponent,
    SkylightTypeComponent,
    SkylightTypeChangeDialogComponent,
    SkylightsComponent,
    SkylightErrorsComponent,
    DoorComponent,
    DoorTypeComponent,
    DoorTypeChangeDialogComponent,
    DoorsComponent,
    ModelSelectComponent,
    CheckboxComponent,
    DoorErrorsComponent,
    ModelSelectComponent,
    CheckboxComponent,
    WindowComponent,
    WindowsComponent,
    WindowTypeComponent,
    WindowTypeChangeDialogComponent,
    WindowErrorsComponent,
    MechanicalsTabComponent,
    MechanicalEquipmentComponent,
    EquipmentDetailsChangeDialogComponent,
    HeaterComponent,
    WaterHeaterComponent,
    AirConditionerComponent,
    AirSourceHeatPumpComponent,
    GroundSourceHeatPumpComponent,
    DehumidifierComponent,
    MechanicalEquipmentErrorsComponent,
    FenestrationTabComponent,
    DishwasherComponent,
    BaseAppliancesDirective,
    AppliancesTabComponent,
    RefrigeratorComponent,
    KitchenAppliancesComponent,
    CookingRangeComponent,
    LaundryAppliancesComponent,
    ClothesWasherComponent,
    ClothesDryerComponent,
    FansComponent,
    LightsComponent,
    LightsErrorsComponent,
    MechanicalEquipmentsComponent,
    DistributionSystemsComponent,
    DistributionSystemComponent,
    DuctComponent,
    DuctsComponent,
    GeneratorsTabComponent,
    PhotovoltaicsComponent,
    PhotovoltaicComponent,
    PhotovoltaicErrorsComponent,
    WaterSystemComponent,
    SystemsTabComponent,
    WaterSystemErrorsComponent,
    UdrhListComponent,
    ChangeUdrhDialogComponent,
    ModelListComponent,
    SideNavBarComponent,
    GeneralInfoTabComponent,
    ProjectComponent,
    LocationComponent,
    SimulationComponent,
    TopNavBarComponent,
    ReportsTabComponent,
    AnalysisComponent,
    ComponentLoadsComponent,
    FuelUsageComponent,
    EmissionsComponent,
    AnalysisSummaryComponent,
    RunSimulationButtonComponent,
    UtilityRateDialogComponent,
    UtilityRateComponent,
    SeasonalRateComponent,
    BlockRateComponent,
    SeasonalRatesComponent,
    SimulationListPageComponent,
    UtilitiesComponent,
    RequiresEditPermissionDirective,
    SimulationListPageComponent,
    LabelComponent,
    AssemblyLayerComponent,
    AssemblyLayersComponent,
    AssemblyLayerEditorComponent,
    AssemblyLayerMaterialComponent,
    AssemblyLayerMaterialsComponent,
    AssemblyLayerStudComponent,
    AmbienceComponent,
    CompanySelectDialogComponent,
    UserSelectDialogComponent,
    ThermostatComponent,
    ThermostatComponent,
    ThermostatsComponent,
    InfiltrationComponent,
    DateComponent,
    WeatherSelectDialogComponent,
    ShelterClassInfoDialogComponent,
    NoteComponent,
    NotesComponent,
    FocusTargetDirective,
    ModelLinkComponent,
    AssemblyEditorComponent,
    SimulationSummaryComponent,
    InfiltrationErrorsComponent,
    ThermostatErrorsComponent,
    BackendValidationResponseDialogComponent,
    RunSimulationStatusComponent,
    AppliancesErrorsComponent,
    LocationErrorsComponent,
    UtilityRateErrorsComponent,
    DistributionSystemErrorsComponent,
    TruncatePipe,
    ProjectErrorsComponent,
    MechanicalVentilationComponent,
    MechanicalVentilationsComponent,
    NaturalVentilationComponent,
    NaturalVentilationErrorsComponent,
    MechanicalVentilationErrorsComponent,
    ModelActionButtonsComponent,
    AnalysisTypeConfigComponent,
    CompanySimulationConfigComponent,
    QaFlagsComponent,
    CompanyLibraryElementsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    DragDropModule,
    StoreModule.forFeature('simulation', reducers, {
      metaReducers: [debug, errorCountMetaReducer],
    }),
    EffectsModule.forFeature(effects),
  ],
  exports: [],
})
export class SimulationModule {}

export function debug(reducer) {
  return function (state, action) {
    return reducer(action.type === 'FLUSH' ? undefined : state, action);
  };
}
