import { HIRLProjectListComponent } from '@/modules/customer-hirl/pages/hirl-projects-list/hirl-project-list.component';
import { AuthGuard } from '@/core/guards/auth.guard';
import { HIRLInvoicesListComponent } from '@/modules/customer-hirl/pages/hirl-invoices-list/hirl-invoices-list.component';
import { HIRLInvoiceItemGroupsListComponent } from '@/modules/customer-hirl/pages/hirl-invoice-item-groups-list/hirl-invoice-item-groups-list.component';
import { HIRLInvoiceDetailComponent } from '@/modules/customer-hirl/pages/hirl-invoice-detail/hirl-invoice-detail.component';
import { HIRLJamisDashboardComponent } from '@/modules/customer-hirl/pages/hirl-jamis-dashboard/hirl-jamis-dashboard.component';
import { CustomerHIRLCompanyMemberGuard } from '@/modules/customer-hirl/guards/customer-hirl-company-member.guard';
import { HIRLProjectRegistrationListComponent } from '@/modules/customer-hirl/pages/hirl-project-registration-list/hirl-project-registration-list.component';
import { HIRLProjectRegistrationSFFormComponent } from '@/modules/customer-hirl/pages/hirl-project-registration-sf-form/hirl-project-registration-sf-form.component';
import { HIRLProjectRegistrationDetailComponent } from '@/modules/customer-hirl/pages/hirl-project-registration-detail/hirl-project-registration-detail.component';
import { HIRLProjectSFFormComponent } from '@/modules/customer-hirl/pages/hirl-project-sf-form/hirl-project-sf-form.component';
import { HIRLProjectRegistrationMFFormComponent } from '@/modules/customer-hirl/pages/hirl-project-registration-mf-form/hirl-project-registration-mf-form.component';
import { HIRLProjectMFFormComponent } from '@/modules/customer-hirl/pages/hirl-project-mf-form/hirl-project-mf-form.component';
import { HIRLProjectMFAddFormComponent } from '@/modules/customer-hirl/pages/hirl-project-mf-add-form/hirl-project-mf-add-form.component';
import { CustomerHIRLInvoiceItemListGuard } from '@/modules/customer-hirl/guards/hirl-invoice-item-list-guard';
import { SponsoredByCompanyGuard } from '@/modules/auth/guards/sponsored-by-company.guard';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { HirlCreateClientAgreementWithoutDocusignComponent } from '@/modules/customer-hirl/pages/hirl-create-client-agreement-without-docusign/hirl-create-client-agreement-without-docusign.component';
import { HirlCreateClientAgreementWithoutUserComponent } from '@/modules/customer-hirl/pages/hirl-create-client-agreement-without-user/hirl-create-client-agreement-without-user.component';
import { CustomerHIRLCompanyMemberOrRaterGuard } from '@/modules/customer-hirl/guards/customer-hirl-company-member-or-rater.guard';
import { HirlVerifierResourcesPageComponent } from '@/modules/customer-hirl/pages/hirl-verifier-resources-page/hirl-verifier-resources-page.component';
import { CustomerHIRLEditHIRLProjectRegistrationGuard } from '@/modules/customer-hirl/guards/edit-hirl-project-registration-guard';
import { CustomerHIRLEditHIRLProjectGuard } from '@/modules/customer-hirl/guards/edit-hirl-project-guard';
import { HirlCopyCaComponent } from '@/modules/customer-hirl/pages/hirl-copy-ca/hirl-copy-ca.component';
import { NgbsBuilderCentralComponent } from '@/modules/customer-hirl/pages/ngbs-builder-central/ngbs-builder-central.component';
import { HirlQaDashboardComponent } from '@/modules/customer-hirl/pages/hirl-qa-dashboard/hirl-qa-dashboard.component';
import { HirlProjectRegistrationLandDevelopmentFormComponent } from '@/modules/customer-hirl/pages/hirl-project-registration-land-development-form/hirl-project-registration-land-development-form.component';
import { HirlProjectLdFormComponent } from '@/modules/customer-hirl/pages/hirl-project-ld-form/hirl-project-ld-form.component';
import { HirlVerifierReportUploadComponent } from '@/modules/customer-hirl/pages/hirl-verifier-report-upload/hirl-verifier-report-upload.component';
import { HirlBulkCertificateDownloadComponent } from '@/modules/customer-hirl/pages/hirl-bulk-certificate-download/hirl-bulk-certificate-download.component';
import { HirlRpcUpdaterRequestListComponent } from '@/modules/customer-hirl/pages/hirl-rpc-updater-request-list/hirl-rpc-updater-request-list.component';
import { HirlRpcUpdaterRequestCreateComponent } from '@/modules/customer-hirl/pages/hirl-rpc-updater-request-create/hirl-rpc-updater-request-create.component';
import { HirlRpcUpdaterRequestDetailComponent } from '@/modules/customer-hirl/pages/hirl-rpc-updater-request-detail/hirl-rpc-updater-request-detail.component';
import { HirlProjectAddDwellingUnitComponent } from '@/modules/customer-hirl/pages/hirl-project-add-dwelling-unit/hirl-project-add-dwelling-unit.component';
import { HirlProjectSfAddFormComponent } from '@/modules/customer-hirl/pages/hirl-project-sf-add-form/hirl-project-sf-add-form.component';
import { HirlClientAgreementDetailComponent } from '@/modules/customer-hirl/pages/hirl-client-agreement-detail/hirl-client-agreement-detail.component';
import { HirlClientAgreementCreateComponent } from '@/modules/customer-hirl/pages/hirl-client-agreement-create/hirl-client-agreement-create.component';
import { BatchSubmissionDashboardComponent } from './pages/ngbs-batch-submission-dashboard/ngbs-batch-submission-dashboard.component';
import { HirlCoiDashboardComponent } from '@/modules/customer-hirl/pages/hirl-coi-dashboard/hirl-coi-dashboard.component';
import { HirlProjectAddStandAloneCommercialSpaceComponent } from '@/modules/customer-hirl/pages/hirl-project-add-stand-alone-commercial-space/hirl-project-add-stand-alone-commercial-space.component';
import { PaymentRequestListComponent } from '@/modules/customer-hirl/pages/payment-request-list/payment-request-list.component';
import { PaymentRequestDetailComponent } from '@/modules/customer-hirl/pages/payment-request-detail/payment-request-detail.component';
import { HirlProductAgreementListComponent } from '@/modules/customer-hirl/pages/hirl-product-agreement-list/hirl-product-agreement-list.component';
import { HirlProductAgreementDetailComponent } from '@/modules/customer-hirl/pages/hirl-product-agreement-detail/hirl-product-agreement-detail.component';
import { HirlGcpInvoicesListComponent } from '@/modules/customer-hirl/pages/hirl-gcp-invoices-list/hirl-gcp-invoices-list.component';
import { HIRLProductListComponent } from '@/modules/customer-hirl/pages/hirl-product-list/hirl-product-list.component';
import { HIRLProductDetailPageComponent } from '@/modules/customer-hirl/pages/hirl-product-detail-page/hirl-product-detail-page.component';

export const CustomerHIRLRouting = [
  {
    path: 'hi',
    canActivate: [AuthGuard, SponsoredByCompanyGuard],
    data: {
      sponsorSlugs: [CustomerHIRLSettings.companySlug],
    },
    children: [
      {
        path: 'product',
        children: [
          {
            path: 'list',
            component: HIRLProductListComponent,
            resolve: {},
          },
          {
            path: 'detail/:productId',
            component: HIRLProductDetailPageComponent,
            resolve: {},
          },
        ],
      },
      {
        path: 'payment/request',
        children: [
          {
            path: 'list',
            component: PaymentRequestListComponent,
            resolve: {},
          },
          {
            path: 'detail/:paymentRequestId',
            component: PaymentRequestDetailComponent,
            resolve: {},
          },
        ],
      },
      {
        path: 'project_registrations',
        children: [
          {
            path: '',
            component: HIRLProjectRegistrationListComponent,
            resolve: {},
          },
          {
            path: ':projectRegistrationId',
            component: HIRLProjectRegistrationDetailComponent,
            resolve: {},
          },
          {
            path: 'add/single-family',
            component: HIRLProjectRegistrationSFFormComponent,
            resolve: {},
          },
          {
            path: ':projectRegistrationId/edit-single-family',
            component: HIRLProjectRegistrationSFFormComponent,
            canActivate: [CustomerHIRLEditHIRLProjectRegistrationGuard],
            resolve: {},
          },
          {
            path: 'add/multi-family',
            component: HIRLProjectRegistrationMFFormComponent,
            resolve: {},
          },
          {
            path: ':projectRegistrationId/edit-multi-family',
            component: HIRLProjectRegistrationMFFormComponent,
            canActivate: [CustomerHIRLEditHIRLProjectRegistrationGuard],
            resolve: {},
          },
          {
            path: ':projectRegistrationId/edit-land-development',
            component: HirlProjectRegistrationLandDevelopmentFormComponent,
            canActivate: [CustomerHIRLEditHIRLProjectRegistrationGuard],
            resolve: {},
          },
          {
            path: ':projectRegistrationId/add/home',
            component: HirlProjectSfAddFormComponent,
            resolve: {},
          },
          {
            path: ':projectRegistrationId/add/building',
            component: HIRLProjectMFAddFormComponent,
            resolve: {},
          },
          {
            path: ':projectRegistrationId/add/accessory-dwelling-unit',
            component: HirlProjectAddDwellingUnitComponent,
            resolve: {},
          },
          {
            path: ':projectRegistrationId/add/stand-alone-commercial-space',
            component: HirlProjectAddStandAloneCommercialSpaceComponent,
            resolve: {},
          },
          {
            path: 'add/land-development',
            component: HirlProjectRegistrationLandDevelopmentFormComponent,
            resolve: {},
          },
        ],
      },
      {
        path: 'projects',
        children: [
          {
            path: '',
            component: HIRLProjectListComponent,
            resolve: {},
          },
          {
            path: ':projectId/edit-single-family',
            canActivate: [CustomerHIRLEditHIRLProjectGuard],
            component: HIRLProjectSFFormComponent,
            resolve: {},
          },
          {
            path: ':projectId/edit-multi-family',
            canActivate: [CustomerHIRLEditHIRLProjectGuard],
            component: HIRLProjectMFFormComponent,
            resolve: {},
          },
          {
            path: ':projectId/edit-land-development',
            canActivate: [CustomerHIRLEditHIRLProjectGuard],
            component: HirlProjectLdFormComponent,
            resolve: {},
          },
          {
            path: 'certificate/download',
            component: HirlBulkCertificateDownloadComponent,
          },
        ],
      },
      {
        path: 'invoices',
        children: [
          {
            path: '',
            component: HIRLInvoicesListComponent,
          },
          {
            path: 'gcp/list',
            component: HirlGcpInvoicesListComponent,
          },
          {
            path: ':invoiceId',
            component: HIRLInvoiceDetailComponent,
            resolve: {},
          },
        ],
      },
      {
        path: 'ngbs_batch_submission_dashboard',
        children: [
          {
            path: '',
            component: BatchSubmissionDashboardComponent,
          },
        ],
      },
      {
        path: 'invoice_item_groups',
        canActivate: [CustomerHIRLInvoiceItemListGuard],
        children: [
          {
            path: '',
            component: HIRLInvoiceItemGroupsListComponent,
          },
        ],
      },
      {
        path: 'jamis/dashboard',
        component: HIRLJamisDashboardComponent,
        canActivate: [CustomerHIRLCompanyMemberGuard],
        resolve: {},
      },
      {
        path: 'client_agreements',
        canActivate: [],
        children: [
          {
            path: 'create',
            component: HirlClientAgreementCreateComponent,
          },
          {
            path: 'detail/:clientAgreementId',
            component: HirlClientAgreementDetailComponent,
            resolve: {},
          },
          {
            path: 'create_without_docusign',
            component: HirlCreateClientAgreementWithoutDocusignComponent,
            canActivate: [CustomerHIRLCompanyMemberGuard],
          },
          {
            path: ':componentCompanyType/create_without_user',
            component: HirlCreateClientAgreementWithoutUserComponent,
            canActivate: [CustomerHIRLCompanyMemberOrRaterGuard],
            resolve: {},
          },
        ],
      },
      {
        path: 'product/agreements',
        canActivate: [],
        children: [
          {
            path: 'list',
            component: HirlProductAgreementListComponent,
            resolve: {},
          },
          {
            path: 'detail/:productAgreementId',
            component: HirlProductAgreementDetailComponent,
            resolve: {},
          },
        ],
      },
      {
        path: 'verifier/resources',
        component: HirlVerifierResourcesPageComponent,
        canActivate: [CustomerHIRLCompanyMemberOrRaterGuard],
        resolve: {},
      },
      {
        path: 'verifier/:userId/resources',
        component: HirlVerifierResourcesPageComponent,
        canActivate: [CustomerHIRLCompanyMemberOrRaterGuard],
        resolve: {},
      },
      {
        path: 'verifier/resources/:year',
        component: HirlVerifierResourcesPageComponent,
        canActivate: [CustomerHIRLCompanyMemberOrRaterGuard],
        resolve: {},
      },
      {
        path: 'verifier/:userId/resources/:year',
        component: HirlVerifierResourcesPageComponent,
        canActivate: [CustomerHIRLCompanyMemberOrRaterGuard],
        resolve: {},
      },
      {
        path: 'copy/ca',
        component: HirlCopyCaComponent,
        canActivate: [CustomerHIRLCompanyMemberGuard],
      },
      {
        path: 'qa/dashboard',
        component: HirlQaDashboardComponent,
        canActivate: [CustomerHIRLCompanyMemberGuard],
      },
      {
        path: 'scoring_upload',
        component: HirlVerifierReportUploadComponent,
        canActivate: [CustomerHIRLCompanyMemberOrRaterGuard],
      },
      {
        path: 'hirl_rpc_updater_request_list',
        canActivate: [CustomerHIRLCompanyMemberOrRaterGuard],
        children: [
          {
            path: '',
            component: HirlRpcUpdaterRequestListComponent,
          },
          {
            path: 'create',
            component: HirlRpcUpdaterRequestCreateComponent,
          },
          {
            path: 'detail/:hirlRPCUpdaterId',
            component: HirlRpcUpdaterRequestDetailComponent,
          },
        ],
      },
      {
        path: 'coi/dashboard',
        component: HirlCoiDashboardComponent,
      },
    ],
  },
  {
    path: 'ngbs/builder/central',
    component: NgbsBuilderCentralComponent,
  },
];
