<div class="card position-relative" appFocusTarget entityName="aboveGradeWall" [entityId]="entityId">
  <app-loading-overlay *ngIf="loading"></app-loading-overlay>
  <div class="card-body pb-0">
    <app-form formName="aboveGradeWallFormGroup" [formGroup]="aboveGradeWallFormGroup" (save)="onSave($event)">
      <div>
        <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
      </div>
      <div>
        <app-select formControlName="interior_location" [label]="fieldLabels.interior_location"
          [items]="interiorLocationLabelMapping"></app-select>
          <app-select formControlName="exterior_location" [label]="fieldLabels.exterior_location"
            [items]="exteriorLocationLabelMapping"></app-select>
      </div>
      <div>
        <app-select
                formControlName="exterior_color"
                [items]="colorLabels"
                [label]="fieldLabels.exterior_color">
              </app-select>
        <app-input formControlName="area" [label]="fieldLabels.area" unit="sqft." type="expression"></app-input>
      </div>
      <div>
        <app-foreign-model-field
          [name]="aboveGradeWallTypeName"
          [errorCount]="aboveGradeWallTypeErrorCount"
          [errors]="aboveGradeWallFormGroup?.controls.type.errors"
          subLabel="R-value"
          [subValue]="aboveGradeWallTypeRValue | number : '1.2-2'"
          (add)="addWallType()"
          (edit)="editWallType()"
          (remove)="removeWallType()"
          label="Wall type">
        </app-foreign-model-field>
      </div>
    </app-form>
  </div>
  <div class="card-footer p-0">
    <app-model-action-buttons
      [entityId]="entityId"
      modelName="above_grade_wall"
      (clone)="cloneWall()"
      (delete)="removeAboveGradeWall()">
    </app-model-action-buttons>
  </div>
</div>
