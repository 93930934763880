<ng-container>
    <li class="list-group-item header" style="background-color:rgba(0, 0, 0, 0.03); align-items: center;">
        <b>
            Air Source Heat Pump
        </b>
    </li>
    <li class="list-group-item">
        <b>Name:</b>
        {{ equipment.air_source_heat_pump_info.name }}
    </li>
    <li class="list-group-item">
        <b>Fuel Type:</b>
        {{ equipment.air_source_heat_pump_info.fuel_display }}
    </li>

    <li class="list-group-item">
        <b>Heating Capacity At (17&deg;) / (47&deg;):</b>
        <span *ngIf="equipment.air_source_heat_pump_info.heating_capacity_17f">
            ( {{ equipment.air_source_heat_pump_info.heating_capacity_17f }} /
            {{ equipment.air_source_heat_pump_info.capacity_unit_display }} ) /
        </span>

        <span *ngIf="equipment.air_source_heat_pump_info.heating_capacity_47f">
            ( {{ equipment.air_source_heat_pump_info.heating_capacity_47f }} /
            {{ equipment.air_source_heat_pump_info.capacity_unit_display }} )
        </span>
    </li>

    <li class="list-group-item"
        *ngIf="equipment.air_source_heat_pump_info.backup_heating_capacity || equipment.air_source_heat_pump_info.backup_heating_efficiency">
        <b>Backup Heating Capacity/Efficiency:</b>
        <span *ngIf="equipment.air_source_heat_pump_info.backup_heating_capacity">
            ( {{ equipment.air_source_heat_pump_info.backup_heating_capacity }} /
            {{ equipment.air_source_heat_pump_info.backup_heating_capacity_unit }} ) /
        </span>

        <span *ngIf="equipment.air_source_heat_pump_info.backup_heating_efficiency">
            ( {{ equipment.air_source_heat_pump_info.backup_heating_efficiency }} /
            {{ equipment.air_source_heat_pump_info.backup_heating_efficiency_unit }} )
        </span>
    </li>

    <li class="list-group-item" *ngIf="equipment.air_source_heat_pump_info.heating_efficiency">
        <b>Heating Efficiency:</b>
        {{ equipment.air_source_heat_pump_info.heating_efficiency }}
        {{ equipment.air_source_heat_pump_info.heating_efficiency_unit_display }}
    </li>

    <li class="list-group-item" *ngIf="equipment.air_source_heat_pump_info.cooling_capacity">
        <b>Cooling Capacity:</b>
        {{ equipment.air_source_heat_pump_info.cooling_capacity }}
        {{ equipment.air_source_heat_pump_info.capacity_unit_display }}
    </li>
    <li class="list-group-item" *ngIf="equipment.air_source_heat_pump_info.cooling_efficiency">
        <b>Cooling Efficiency:</b>
        {{ equipment.air_source_heat_pump_info.cooling_efficiency }}
        {{ equipment.air_source_heat_pump_info.cooling_efficiency_unit_display }}
    </li>

    <li class="list-group-item" *ngIf="equipment.air_source_heat_pump_info.motor_type">
        <b>Motor Type:</b>
        {{ equipment.air_source_heat_pump_info.motor_type_display }}
    </li>

    <li class="list-group-item">
        <b>Has De-Superheater:</b>
        {{ equipment.air_source_heat_pump_info.has_desuperheater && 'Yes' || 'No' }}
    </li>
</ng-container>