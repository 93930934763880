import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { FieldLabels } from '../base';
import { enumValidator } from '@/modules/simulation/validtaors/helper';

export enum PvCapacity {
  KWDC = 'kwdc',
  WATT = 'watt',
}

export const PvCapacityLabels = {
  [PvCapacity.KWDC]: 'KWdc',
  [PvCapacity.WATT]: 'Watts',
};

export enum pvLocation {
  ROOF = 'roof',
  GROUND = 'ground',
  OTHER = 'other',
}

export const pvLocationLabels = {
  [pvLocation.ROOF]: 'Roof',
  [pvLocation.GROUND]: 'Ground',
  [pvLocation.OTHER]: 'Other',
};

export enum PvTracking {
  FIXED = 'fixed',
  ONE_AXIS = 'single',
  TWO_AXIS = 'dual',
}

export const PvTrackingLabels = {
  [PvTracking.FIXED]: 'Fixed',
  [PvTracking.ONE_AXIS]: 'Single axis',
  [PvTracking.TWO_AXIS]: 'Twin axis',
};

// Labels
export const FIELD_LABELS: FieldLabels<PhotovoltaicBackendDict> = {
  name: 'Name',
  area: 'Area',
  area_units: 'Area Units',
  capacity: 'Capacity',
  capacity_units: 'Capacity Units',
  derate_factor: 'Derate Factor',
  tilt: 'Tilt',
  track_mode: 'Track Mode',
  azimuth: 'Azimuth',
  note: 'Note',
  percent_inverter_efficiency: 'Inverter Efficiency',
  location: 'Location',
};

export interface PhotovoltaicBackendDict {
  id: number;
  name: string;
  area: number;
  area_units: string;
  capacity: number;
  capacity_units: PvCapacity;
  derate_factor: number;
  tilt: number;
  track_mode: PvTracking;
  azimuth: number;
  note: string;
  percent_inverter_efficiency: number;
  location: pvLocation;
}

export function createPhotovoltaicForm(
  data: PhotovoltaicBackendDict
): ModelFormGroup {
  return new ModelFormGroup({
    id: new ModelFormControl(data.id),
    name: new ModelFormControl(data.name, Validators.required),
    area: new ModelFormControl(data.area, [
      Validators.required,
      Validators.min(0),
    ]),
    area_units: new ModelFormControl(data.area_units, Validators.required),
    capacity: new ModelFormControl(data.capacity, [
      Validators.required,
      Validators.min(0),
    ]),
    capacity_units: new ModelFormControl(
      data.capacity_units,
      Validators.required
    ),
    derate_factor: new ModelFormControl(data.derate_factor),
    tilt: new ModelFormControl(data.tilt),
    track_mode: new ModelFormControl(data.track_mode, [
      enumValidator(PvTracking),
    ]),
    location: new ModelFormControl(data.location, [enumValidator(pvLocation)]),
    azimuth: new ModelFormControl(data.azimuth, [
      Validators.required,
      Validators.min(0),
      Validators.max(360),
    ]),
    note: new ModelFormControl(data.note),
    percent_inverter_efficiency: new ModelFormControl(
      data.percent_inverter_efficiency,
      [Validators.required, Validators.min(0), Validators.max(100)]
    ),
  });
}
