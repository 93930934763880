import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulingListComponent } from './pages/scheduling-list/scheduling-list.component';
import { SharedModule } from '@/shared/shared.module';
import { ChangeTaskDialogComponent } from './components/change-task-dialog/change-task-dialog.component';
import { ChangeStatusDialogComponent } from './components/change-status-dialog/change-status-dialog.component';

@NgModule({
  declarations: [
    SchedulingListComponent,
    ChangeTaskDialogComponent,
    ChangeStatusDialogComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class SchedulingModule {}
