import { PracticeStandardInfo } from '@/data/customer-hirl/models/practice-standard';

export class PracticeScoringPath {
  id?: number;
  title?: string;
  standard?: number;
  standard_info?: PracticeStandardInfo;
}

export class PracticeScoringPathInfo {
  id?: number;
  title?: string;
  standard?: number;
  standard_info?: PracticeStandardInfo;
}
