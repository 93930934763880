import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SimulationModelsState } from '../state/reducers';

export enum Tab {
  GENERAL_INFO = 'general_info',
  ENVELOPE = 'envelope',
  FENESTRATION = 'fenestration',
  MECHANICALS = 'mechanicals',
  APPLIANCES = 'appliances',
  SYSTEMS = 'systems',
  GENERATORS = 'generators',
  REPORTS = 'reports',
}

export enum SubTab {
  PROJECT = 'project',
  LOCATION = 'location',
  FUELS = 'fuels',
  ABOVE_GRADE_WALLS = 'above_grade_walls',
  FOUNDATION_WALLS = 'foundation_walls',
  ROOFS = 'roofs',
  FRAME_FLOORS = 'frame_floors',
  SLABS = 'slabs',
  RIM_JOISTS = 'rim_joists',
  INFILTRATION = 'infiltration',
  DOORS = 'doors',
  WINDOWS = 'windows',
  SKYLIGHTS = 'skylights',
  EQUIPMENT = 'equipment',
  DISTRIBUTION_SYSTEMS = 'distribution_systems',
  KITCHEN_APPLIANCES = 'kitchen_appliances',
  LAUNDRY_APPLIANCES = 'laundry_appliances',
  AMBIENCE = 'ambience',
  WATER_SYSTEM = 'water_system',
  PHOTOVOLTAICS = 'photovoltaics',
  THERMOSTATS = 'thermostats',
  MECHANICAL_VENTILATIONS = 'mechanical_ventilations',
  NATURAL_VENTILATION = 'natural_ventilation',
}

export interface TabInfo {
  name: string;
  link: Tab;
  icon: string;
  iconType: string;
  subTabs: Array<{
    name: string;
    link: SubTab;
    component: string;
    entityName: keyof SimulationModelsState;
  }>;
}

export const TABS: TabInfo[] = [
  {
    name: 'General info',
    link: Tab.GENERAL_INFO,
    icon: 'home',
    iconType: 'fa',
    subTabs: [
      {
        name: 'Project',
        link: SubTab.PROJECT,
        component: 'app-project',
        entityName: 'project',
      },
      {
        name: 'Location',
        link: SubTab.LOCATION,
        component: 'app-location',
        entityName: 'location',
      },
      {
        name: 'Fuels',
        link: SubTab.FUELS,
        component: 'app-fuels',
        entityName: 'utilityRate',
      },
    ],
  },
  {
    name: 'Envelope',
    link: Tab.ENVELOPE,
    icon: 'building',
    iconType: 'fa',
    subTabs: [
      {
        name: 'Above Grade Walls',
        link: SubTab.ABOVE_GRADE_WALLS,
        component: 'app-above-grade-walls',
        entityName: 'aboveGradeWall',
      },
      {
        name: 'Foundation Walls',
        link: SubTab.FOUNDATION_WALLS,
        component: 'app-foundation-walls',
        entityName: 'foundationWall',
      },
      {
        name: 'Roofs',
        link: SubTab.ROOFS,
        component: 'app-roofs',
        entityName: 'roof',
      },
      {
        name: 'Frame Floors',
        link: SubTab.FRAME_FLOORS,
        component: 'app-frame-floors',
        entityName: 'frameFloor',
      },
      {
        name: 'Slabs',
        link: SubTab.SLABS,
        component: 'app-slabs',
        entityName: 'slab',
      },
      {
        name: 'Rim Joists',
        link: SubTab.RIM_JOISTS,
        component: 'app-rim-joists',
        entityName: 'rimJoist',
      },
      {
        name: 'Infiltration',
        link: SubTab.INFILTRATION,
        component: 'app-infiltration',
        entityName: 'infiltration',
      },
    ],
  },
  {
    link: Tab.FENESTRATION,
    name: 'Fenestration',
    icon: 'door-open',
    iconType: 'fa',
    subTabs: [
      {
        name: 'Doors',
        link: SubTab.DOORS,
        component: 'app-doors',
        entityName: 'door',
      },
      {
        name: 'Windows',
        link: SubTab.WINDOWS,
        component: 'app-windows',
        entityName: 'window',
      },
      {
        name: 'Skylights',
        link: SubTab.SKYLIGHTS,
        component: 'app-skylights',
        entityName: 'skylight',
      },
    ],
  },
  {
    name: 'Mechanicals',
    link: Tab.MECHANICALS,
    icon: 'temperature-low',
    iconType: 'fa',
    subTabs: [
      {
        name: 'Equipment',
        link: SubTab.EQUIPMENT,
        component: 'app-mechanical-equipment',
        entityName: 'mechanicalEquipment',
      },
      {
        name: 'Distribution Systems',
        link: SubTab.DISTRIBUTION_SYSTEMS,
        component: 'app-distribution-systems',
        entityName: 'distributionSystem',
      },
      {
        name: 'Thermostats',
        link: SubTab.THERMOSTATS,
        component: 'app-thermostats',
        entityName: 'thermostat',
      },
    ],
  },
  {
    name: 'Appliances',
    link: Tab.APPLIANCES,
    icon: 'kitchen',
    iconType: 'mat',
    subTabs: [
      {
        name: 'Kitchen Appliances',
        link: SubTab.KITCHEN_APPLIANCES,
        component: 'app-kitchen-appliances',
        entityName: 'appliances',
      },
      {
        name: 'Laundry Appliances',
        link: SubTab.LAUNDRY_APPLIANCES,
        component: 'app-laundry-appliances',
        entityName: 'appliances',
      },
      {
        name: 'Ambience',
        link: SubTab.AMBIENCE,
        component: 'app-ambeince',
        entityName: 'appliances',
      },
    ],
  },
  {
    name: 'Systems',
    link: Tab.SYSTEMS,
    icon: 'tune',
    iconType: 'mat',
    subTabs: [
      {
        name: 'Mechanical Ventilations',
        link: SubTab.MECHANICAL_VENTILATIONS,
        component: 'app-mechanical-ventilations',
        entityName: 'mechanicalVentilation',
      },
      {
        name: 'Natural Ventilation',
        link: SubTab.NATURAL_VENTILATION,
        component: 'app-natural-ventilation',
        entityName: 'naturalVentilation',
      },
      {
        name: 'Water System',
        link: SubTab.WATER_SYSTEM,
        component: 'app-water-system',
        entityName: 'waterSystem',
      },
    ],
  },
  {
    name: 'Generators',
    link: Tab.GENERATORS,
    icon: 'bolt',
    iconType: 'mat',
    subTabs: [
      {
        name: 'Photovoltaics',
        link: SubTab.PHOTOVOLTAICS,
        component: 'app-photovoltaics',
        entityName: 'photovoltaic',
      },
    ],
  },
  {
    name: 'Reports',
    link: Tab.REPORTS,
    icon: 'file',
    iconType: 'fa',
    subTabs: [],
  },
];

@Injectable({
  providedIn: 'root',
})
export class EditorNavigationService {
  private activeTabLink: Tab;
  private activeSubTabLink: SubTab;
  private activeTabLinkSubject = new BehaviorSubject<Tab | null>(null);
  private activeSubTabLinkSubject = new BehaviorSubject<SubTab | null>(null);

  init() {
    this.setActiveTabLink(Tab.GENERAL_INFO);
  }

  getTabInfo(tab: Tab): TabInfo {
    return TABS.find(tabInfo => tabInfo.link === tab);
  }

  getSubTabInfo(tabInfo: TabInfo, subTab: SubTab) {
    return tabInfo.subTabs.find(subTabInfo => subTabInfo.link === subTab);
  }

  setActiveTabLink(tab: Tab, subTab?: SubTab) {
    const tabInfo = this.getTabInfo(tab);
    if (!tabInfo) return;
    this.activeTabLink = tab;
    this.activeTabLinkSubject.next(tab); // Emitting new value

    const subTabInfo = this.getSubTabInfo(tabInfo, subTab);
    if (!subTabInfo) return;
    this.activeSubTabLink = subTab;
    this.activeSubTabLinkSubject.next(subTab); // Emitting new value
  }

  getActiveTabLink(): Tab {
    return this.activeTabLink;
  }

  getActiveSubTabLink(): SubTab {
    return this.activeSubTabLink;
  }

  // Methods to expose the observables
  getActiveTabLinkObservable() {
    return this.activeTabLinkSubject.asObservable();
  }

  getActiveSubTabLinkObservable() {
    return this.activeSubTabLinkSubject.asObservable();
  }

  _findTabAndSubTab(entityName: string): { tabLink: Tab; subTabLink: SubTab } {
    let tabLink: Tab = null;
    let subTabLink: SubTab = null;

    for (const tabInfo of TABS) {
      for (const subTabInfo of tabInfo.subTabs) {
        if (subTabInfo.entityName === entityName) {
          tabLink = tabInfo.link;
          subTabLink = subTabInfo.link;
          break;
        }
      }
      if (tabLink && subTabLink) {
        break;
      }
    }

    return {
      tabLink,
      subTabLink,
    };
  }

  focusElementOfTab(entityName, entityId: number | string) {
    const { tabLink, subTabLink } = this._findTabAndSubTab(entityName);
    this.setActiveTabLink(tabLink, subTabLink);

    setTimeout(() => {
      if (!entityId) return;
      const element = document.getElementById(entityName + entityId);
      if (element) {
        element.focus();
      }
    }, 100);
  }
}
