import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as FoundationWallTypeActions from './actions';
import * as NoteActions from '../note/actions';
import * as SharedActions from '../shared/shared.actions';
import { FoundationWallTypeService } from '@/data/simulation/services/foundation-wall-type.service';
import { ModelGraphService } from '../../services/model-graph.service';
import { FoundationWallTypeValidator } from '../../validtaors/foundation-wall-type.validator';
import { createNote } from '@/data/simulation/models/Note';
import { StateModelName } from '../../state.registry';

@Injectable()
export class FoundationWallTypeEffects {
  loadDetailedFoundationWallTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FoundationWallTypeActions.loadFoundationWallTypes),
      mergeMap(action => {
        const errors = FoundationWallTypeValidator.validate(action.wallTypes);
        const notes = [];
        action.wallTypes.forEach(wallType => {
          notes.push(
            createNote(
              StateModelName.foundationWallType,
              wallType.id,
              wallType.note
            )
          );
        });
        return of(
          FoundationWallTypeActions.loadFoundationWallTypesSuccess({
            wallTypes: action.wallTypes,
            errors,
          }),
          NoteActions.loadNotes({ notes })
        );
      })
    );
  });

  updateFoundationWallType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FoundationWallTypeActions.updateFoundationWallType),
      mergeMap(action =>
        this.wallTypeService.update(action.wallTypeChanges).pipe(
          mergeMap(updatedWallType => {
            const errors = FoundationWallTypeValidator.validate([
              updatedWallType,
            ]);
            return of(
              FoundationWallTypeActions.loadFoundationWallTypesSuccess({
                wallTypes: [updatedWallType],
                errors,
              })
            );
          }),
          catchError(error =>
            of(
              FoundationWallTypeActions.updateFoundationWallTypeFailure({
                id: action.wallTypeChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private wallTypeService: FoundationWallTypeService
  ) {}
}
