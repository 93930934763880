import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { PracticeChapter } from '@/data/customer-hirl/models';

export class PracticeChapterRequestParams extends ListRequestParams {
  scoring_path?: number;
}

@Injectable({
  providedIn: 'root',
})
export class PracticeChapterService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/practice_chapters/`;

  list(params?: PracticeChapterRequestParams) {
    return this.http.get<ServerResponse<PracticeChapter>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    practiceChapterId: number,
    options?: IDataServiceMethodOptions
  ): Observable<PracticeChapter> {
    return this.http.get<PracticeChapter>(
      `${this.baseUrl}${practiceChapterId}`,
      {}
    );
  }

  update(practiceChapter: PracticeChapter, practiceChapterId: number) {
    return this.http.patch<PracticeChapter>(
      `${this.baseUrl}${practiceChapterId}/`,
      practiceChapter
    );
  }

  delete(practiceChapterId: number) {
    return this.http.delete(`${this.baseUrl}${practiceChapterId}`);
  }
}
