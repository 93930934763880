import { MechanicalEquipmet } from '@/data/simulation/models/mechanicals';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment-air-source-heat-pump',
  templateUrl: './equipment-air-source-heat-pump.component.html',
  styleUrls: ['./equipment-air-source-heat-pump.component.scss'],
})
export class EquipmentAirSourceHeatPumpComponent implements OnInit {
  @Input() equipment: MechanicalEquipmet;

  ngOnInit(): void {}
}
