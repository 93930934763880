<div class="container">
    <ng-container *appRequiresEditPermission>
        <button mat-icon-button (click)="delete.emit()" *ngIf="requiredButtons.delete">
            <mat-icon inline="true">delete</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Make a copy" (click)="clone.emit()" *ngIf="requiredButtons.clone">
            <mat-icon inline="true">content_copy</mat-icon>
        </button>
        <app-history-mat-button displayType="iconButton" [objectId]="entityId" [modelClassName]="modelName">
            <mat-icon inline="true">history</mat-icon>
        </app-history-mat-button>
    </ng-container>
</div>