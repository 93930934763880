import {
  StudTypeLabels,
  WallCavityInsulationTypeLabels,
} from '@/data/simulation/enumerations';
import {
  RoofTypeBackendDict,
  FIELD_LABELS,
  RoofTypeFormGroup,
} from '@/data/simulation/models/enclosure/RoofType';
import { updateRoofType } from '@/modules/simulation/state/roof-type/actions';
import { selectRoofTypeViewSet } from '@/modules/simulation/state/roof-type/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-roof-type',
  templateUrl: './roof-type.component.html',
  styleUrls: ['./roof-type.component.scss'],
})
export class RoofTypeComponent implements OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  private componentDestroyed$ = new Subject();

  loading = false;
  entity: RoofTypeBackendDict = null;
  roofTypeFormGroup: FormGroup;
  studTypeLabels: { [key: string]: string } = StudTypeLabels;
  wallCavityInsulationTypeLabels: { [key: string]: string } =
    WallCavityInsulationTypeLabels;

  constructor(public store: Store) {}

  ngOnInit() {
    this.store
      .select(selectRoofTypeViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;

        if (loading) return;

        if (this.roofTypeFormGroup) {
          this.roofTypeFormGroup.patchValue(data);
        } else {
          this.roofTypeFormGroup = new RoofTypeFormGroup(data);
          const control =
            this.roofTypeFormGroup.controls['user_defined_assembly_u_value'];
          control.valueChanges.subscribe(value => {
            if (value) {
              this.roofTypeFormGroup.controls.assembly_u_value.enable();
            } else {
              this.roofTypeFormGroup.controls.assembly_u_value.disable();
            }
          });
        }
        this.entity = data;
      });
  }

  saveRoofType(changedValues: Partial<RoofTypeBackendDict>) {
    const newValues = this.roofTypeFormGroup.value;
    const changes = {};
    Object.entries(this.entity).forEach(([key, val]) => {
      if (!this.entity.hasOwnProperty(key)) return;
      if (val !== newValues[key]) changes[key] = newValues[key];
    });
    this.store.dispatch(
      updateRoofType({
        id: this.entityId,
        roofTypeChanges: changes,
      })
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
