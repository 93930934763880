import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ColorLabels, LocationLabels } from '@/data/simulation/enumerations';
import { FormGroup } from '@angular/forms';
import { DIALOG_WIDTH } from '@/modules/simulation/constants';
import { selectRoofViewSet } from '@/modules/simulation/state/roof/selectors';
import { selectRoofTypeNameAndError } from '@/modules/simulation/state/roof-type/selectors';
import {
  removeRoof,
  updateRoof,
} from '@/modules/simulation/state/roof/actions';
import {
  RoofBackendDict,
  ExteriorLocation,
  InteriorLocation,
  createRoofForm,
  ExteriorColor,
  FIELD_LABELS,
} from '@/data/simulation/models/enclosure/Roof';
import { removeRoofType } from '@/modules/simulation/state/roof-type/actions';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { CREATE_MODE, EDIT_MODE } from '@/core/constants';
import {
  RoofTypeChangeDialogComponent,
  RoofTypeChangeDialogData,
} from '../roof-type-change-dialog/roof-type-change-dialog.component';
import { cloneRoof } from '@/modules/simulation/state/simulation/actions';

@Component({
  selector: 'app-roof',
  templateUrl: './roof.component.html',
  styleUrls: ['./roof.component.scss'],
})
export class RoofComponent implements OnDestroy, OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  entity: RoofBackendDict = null;
  loading = false;

  private componentDestroyed$ = new Subject();
  typeViewSubscription$ = null;
  public exteriorLocationLabelMapping = {};
  public interiorLocationLabelMapping = {};
  exteriorColorLabelMapping = {};

  public roofTypeName: string;
  public roofTypeErrorCount = 0;

  public roofFormGroup: FormGroup;
  constructor(public dialog: MatDialog, public store: Store) {
    Object.values(InteriorLocation).forEach(value => {
      this.interiorLocationLabelMapping[value] = LocationLabels[value];
    });
    Object.values(ExteriorLocation).forEach(value => {
      this.exteriorLocationLabelMapping[value] = LocationLabels[value];
    });
    Object.values(ExteriorColor).forEach(value => {
      this.exteriorColorLabelMapping[value] = ColorLabels[value];
    });
  }

  updateTypeDetails(typeId: number) {
    if (this.typeViewSubscription$) {
      this.typeViewSubscription$.unsubscribe();
    }
    if (!typeId) {
      this.roofTypeName = null;
      this.roofTypeErrorCount = 1;
      return;
    }

    this.store
      .select(selectRoofTypeNameAndError(typeId))
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(({ name, errorCount }) => {
        this.roofTypeName = name;
        this.roofTypeErrorCount = errorCount;
      });
  }

  ngOnInit(): void {
    this.store
      .select(selectRoofViewSet(this.entityId))
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val !== null)
      )
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (!this.roofFormGroup) {
          this.roofFormGroup = createRoofForm(data);
          this.roofFormGroup.controls.interior_location.valueChanges.subscribe(
            value => {
              if (value === InteriorLocation.SEALED_ATTIC) {
                this.roofFormGroup.controls.attic_within_infiltration_volume.setValue(
                  true
                );
              }
            }
          );
        }

        this.updateTypeDetails(data.type);
      });
  }

  openEditRoofTypeChangeDialog(data: RoofTypeChangeDialogData) {
    return this.dialog.open(RoofTypeChangeDialogComponent, {
      width: DIALOG_WIDTH,
      disableClose: true,
      data,
    });
  }

  removeRoof() {
    let roofName = this.entity.name;
    if (roofName === null || roofName === '') {
      roofName = 'Untitled';
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${roofName}" roof ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(removeRoof({ roof: this.entity }));
        }
      });
  }

  clone() {
    this.store.dispatch(cloneRoof({ id: this.entityId }));
  }

  editRoofType() {
    const updatedRoofType = this.openEditRoofTypeChangeDialog({
      commit: true,
      mode: EDIT_MODE,
      entityId: this.entity.type,
    });

    updatedRoofType.afterClosed().subscribe(() => {
      this.updateTypeDetails(this.entity.type);
    });
  }

  addRoofType() {
    const newRoofDialog = this.openEditRoofTypeChangeDialog({
      commit: true,
      mode: CREATE_MODE,
      parentId: this.entityId,
    });

    newRoofDialog.afterClosed().subscribe(roofType => {
      if (!roofType) {
        return;
      }

      this.roofFormGroup.patchValue({ type: roofType.id });
      this.updateTypeDetails(roofType.id);
    });
  }

  removeRoofType() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${this.roofTypeName}" roof type ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            removeRoofType({
              roofId: this.entityId,
              roofTypeId: this.entity.type,
            })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onSave(roofChanges: Partial<RoofBackendDict>): void {
    this.store.dispatch(
      updateRoof({
        roofChanges: roofChanges,
      })
    );
  }
}
