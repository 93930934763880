import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { NavComponent } from './layout/nav/nav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { reducers } from '@/state/reducers';
import { EffectsModule } from '@ngrx/effects';
import { InfoEffects } from '@/state/effects/info.effects';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@/shared/shared.module';
import { AuthModule } from '@/modules/auth/auth.module';
import { CustomerHIRLModule } from '@/modules/customer-hirl/customer-hirl.module';
import { GeocoderModule } from '@/modules/geocoder/geocoder.module';
import { MessagingModule } from '@/modules/messaging/messaging.module';
import { CommunityModule } from '@/modules/community/community.module';
import { InternalModule } from '@/modules/internal/internal.module';
import { ZendeskModule } from '@/modules/zendesk/zendesk.module';
import { CompanyModule } from '@/modules/company/company.module';
import { FilehandlingModule } from '@/modules/filehandling/filehandling.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { UserModule } from '@/modules/user/user.module';
import { FlatpageModule } from '@/modules/flatpage/flatpage.module';
import { MiscModule } from '@/modules/misc/misc.module';
import { SubdivisionModule } from '@/modules/subdivision/subdivision.module';
import { UserManagementModule } from '@/modules/user-management/user-management.module';
import { FloorPlanModule } from '@/modules/floorplan/floorplan.module';
import { SchedulingModule } from '@/modules/scheduling/scheduling.module';
import { CustomerETOModule } from './modules/customer-eto/customer-eto.module';
import { SimulationModule } from '@/modules/simulation/simulation.module';
import { RPCModule } from '@/modules/rpc/rpc.module';
import { EquipmentModule } from '@/modules/equipment/equipment.module';
import { RemrateModule } from '@/modules/remrate/remrate.module';
import { AnnotationModule } from '@/modules/annotation/annotation.module';
import { ProductModule } from '@/modules/product/product.module';

@NgModule({
  declarations: [
    AppComponent,
    ContentLayoutComponent,
    NavComponent,
    // FooterComponent,
  ],
  imports: [
    // angular
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    InternalModule,

    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([InfoEffects]),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.STATE === 'production', // Restrict extension to log-only mode
    }),

    // core & shared
    CoreModule,
    SharedModule,

    // 3rd party
    AuthModule,
    FlatpageModule,
    GeocoderModule,
    CustomerETOModule,
    CustomerHIRLModule,
    MessagingModule,
    CommunityModule,
    CompanyModule,
    FilehandlingModule,
    SubdivisionModule,
    UserModule,
    UserManagementModule,
    FloorPlanModule,
    SchedulingModule,
    RPCModule,
    EquipmentModule,
    RemrateModule,
    ZendeskModule,
    AnnotationModule,
    MiscModule,
    SimulationModule,
    ProductModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
