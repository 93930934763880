import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import {
  TaskRequestParams,
  TaskService,
} from '@/data/scheduling/services/task.service';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { first, takeUntil, tap } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import {
  FlatListTask,
  TaskApprovalState,
  TaskApprovalStateLabelMapping,
  TaskChangeState,
  TaskChangeStatus,
  TaskStatusLabelMapping,
} from '@/data/scheduling/models';
import {
  ChangeTaskDialogComponent,
  ITaskChangeDialogData,
} from '@/modules/scheduling/components/change-task-dialog/change-task-dialog.component';
import { toggleLoading } from '@/state/actions/app.actions';
import { ServerResponse } from '@/core/schemes/server-response';
import {
  InputDialogComponent,
  InputDialogData,
  InputDialogInputTypeEnum,
} from '@/shared/components/input-dialog/input-dialog.component';
import { ChangeStatusDialogComponent } from '@/modules/scheduling/components/change-status-dialog/change-status-dialog.component';
import { TaskPermissionService } from '@/modules/scheduling/services/task-permission.service';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';

@Component({
  selector: 'app-scheduling-list',
  templateUrl: './scheduling-list.component.html',
  styleUrls: ['./scheduling-list.component.scss'],
})
export class SchedulingListComponent implements OnInit, OnDestroy {
  public initialized = false;
  public currentUser: User;
  public filterFromGroup: UntypedFormGroup;

  public defaultParams: TaskRequestParams = new TaskRequestParams(
    1,
    '',
    '-id',
    25
  );
  public storedParams: TaskRequestParams;

  public isLoading = true;
  public rows: FlatListTask[] = [];
  public rowsCount = 0;
  public rowPermissions: {
    [task: number]: {
      [actionName: string]: Observable<ObjectPermissionResponse>;
    };
  } = {};
  public selectedRows: FlatListTask[] = [];

  public approvalState = TaskApprovalState;
  public approvalStateLabelMapping = TaskApprovalStateLabelMapping;
  public statusLabelMapping = TaskStatusLabelMapping;

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public taskService: TaskService,
    public taskPermissionService: TaskPermissionService,
    public router: Router,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private navigation: NavigationService,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit() {
    this.storedParams = Object.assign(
      new TaskRequestParams(),
      this.defaultParams
    );

    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(({ queryParams, currentUser }) => {
      this.initialized = true;
      this.currentUser = currentUser;
      this.storedParams.assignQueryParams(queryParams);
      this.setupFilterForm();
      this.hydrateForm();
      this.list();
    });
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      approval_state: [],
      status: [],
      date__lte: [],
      date__gte: [],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = Object.assign(this.defaultParams, values);
        delete params.ordering;

        this.storedParams = Object.assign(this.storedParams, params);
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  listSubscription(
    params?: TaskRequestParams
  ): Observable<ServerResponse<FlatListTask>> {
    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    return this.taskService.flat_list(this.storedParams).pipe(
      takeUntil(this.componentDestroyed$),
      first(),
      tap(response => {
        this.rows = response.results;
        this.rowsCount = response.count;
        this.rowPermissions = Object.assign(
          {},
          ...response.results.map(task => ({
            [task.id]: {
              canChangeState: this.taskPermissionService.canChangeState(task),
              canEdit: this.taskPermissionService.canEdit(task),
            },
          }))
        );
      })
    );
  }

  list() {
    this.isLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    this.listSubscription$ = this.listSubscription().subscribe(_ => {
      this.isLoading = false;
    });
  }

  getId(row) {
    return row.id;
  }

  onSort({ sorts }) {
    this.storedParams.ordering = `${sorts[0].dir === 'desc' ? '-' : ''}${
      sorts[0].prop
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }

  onSelect({ selected }) {
    this.selectedRows.splice(0, this.selectedRows.length);
    this.selectedRows = [].concat(selected);
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  returnZero() {
    // prevent angular keyvalue sorting
    // https://stackoverflow.com/questions/54091011/disable-the-default-keyvalue-pipe-sort-in-angular
    return 0;
  }

  removeAllSelectedRows($event: MouseEvent) {
    $event.preventDefault();
    this.selectedRows = [];
  }

  onEditTask($event: MouseEvent, taskId: number) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ChangeTaskDialogComponent, {
      width: '45%',
      data: {
        taskId,
      } as ITaskChangeDialogData,
    });

    dialogRef.afterClosed().subscribe(formData => {
      if (!formData) {
        return;
      }
      this.store.dispatch(toggleLoading({ payload: true }));

      this.taskService
        .update(formData, taskId)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe(changedTask => {
          this.listSubscription$ = this.listSubscription().subscribe(_ => {});
          this.store.dispatch(toggleLoading({ payload: false }));
          this.snackBar.open(
            `Task #${changedTask.id} successfully changed`,
            'Close',
            {
              duration: 5000,
            }
          );
        });
    });
  }

  onChangeStateForSelectedRows(
    $event: MouseEvent,
    newState: TaskApprovalState
  ) {
    this.changeState(
      this.selectedRows.map(task => task.id),
      newState
    );
  }

  onChangeState(
    $event: MouseEvent,
    taskId: number,
    newState: TaskApprovalState
  ) {
    $event.preventDefault();
    this.changeState([taskId], newState);
  }

  changeState(taskIds: number[], newState: TaskApprovalState) {
    const inputDialogData = new InputDialogData({
      title: 'Note',
      inputType: InputDialogInputTypeEnum.textArea,
      inputIsRequired: true,
      confirmBtnText: 'Save',
    });

    const dialogRef = this.dialog.open(InputDialogComponent, {
      width: '45%',
      data: inputDialogData,
    });

    dialogRef.afterClosed().subscribe(formData => {
      if (!formData) {
        return;
      }
      this.store.dispatch(toggleLoading({ payload: true }));

      const changeStateData = new TaskChangeState();
      changeStateData.task_ids = taskIds;
      changeStateData.approval_annotation = formData;
      changeStateData.new_state = newState;

      this.taskService
        .change_state(changeStateData)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe(
          taskChangeState => {
            this.listSubscription$ = this.listSubscription().subscribe(_ => {});
            this.selectedRows = [];
            this.store.dispatch(toggleLoading({ payload: false }));
            this.snackBar.open('State successfully changed', 'Close', {
              duration: 5000,
            });
          },
          error => this.uiHelperService.handleUserRequestError(error)
        );
    });
  }

  onChangeStatusForSelectedRows($event: MouseEvent) {
    $event.preventDefault();
    this.changeStatus(this.selectedRows.map(task => task.id));
  }

  onChangeStatus($event: MouseEvent, taskId: number) {
    $event.preventDefault();
    this.changeStatus([taskId]);
  }

  changeStatus(taskIds: number[]) {
    const dialogRef = this.dialog.open(ChangeStatusDialogComponent, {
      width: '45%',
    });

    dialogRef.afterClosed().subscribe(formData => {
      if (!formData) {
        return;
      }
      this.store.dispatch(toggleLoading({ payload: true }));

      const taskChangeStatusData = new TaskChangeStatus();
      taskChangeStatusData.task_ids = taskIds;
      taskChangeStatusData.new_status = formData.new_status;
      taskChangeStatusData.status_annotation = formData.status_annotation;

      this.taskService
        .change_status(taskChangeStatusData)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe(
          taskChangeStatus => {
            this.listSubscription$ = this.listSubscription().subscribe(
              data => {}
            );
            this.selectedRows = [];
            this.store.dispatch(toggleLoading({ payload: false }));
            this.snackBar.open('Status successfully changed', 'Close', {
              duration: 5000,
            });
          },
          error => this.uiHelperService.handleUserRequestError(error)
        );
    });
  }

  onExportToCalendarSelectedRows($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleLoading({ payload: true }));
    this.taskService
      .export_to_cal({
        task_ids: this.selectedRows.map(task => task.id),
      })
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(
        response => {
          this.selectedRows = [];
          this.store.dispatch(toggleLoading({ payload: false }));
          this.uiHelperService.downloadServerResponse(
            response,
            'text/calendar',
            'tasks.ics'
          );
          this.snackBar.open('Export completed', 'Close', {
            duration: 5000,
          });
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }
}
