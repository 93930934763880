import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as RimJoistActions from './actions';
import * as RimJoistTypeActions from '../rim-joist-type/actions';
import * as SimulationActions from '../simulation/actions';
import * as SharedActions from '../shared/shared.actions';
import { RimJoistService } from '@/data/simulation/services/rim-joist.service';
import { RimJoistTypeService } from '@/data/simulation/services/rim-joist-type.service';
import { RimJoistTypeBackendDict } from '@/data/simulation/models/enclosure/RimJoistType';
import { RimJoistBackendDict } from '@/data/simulation/models/enclosure/RimJoist';
import { ModelGraphService } from '../../services/model-graph.service';
import { RimJoistValidator } from '../../validtaors/rim-joist.validator';
import { createNote } from '@/data/simulation/models/Note';
import { StateModelName } from '../../state.registry';

@Injectable()
export class RimJoistEffects {
  loadDetailedRimJoists$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RimJoistActions.loadDetailedRimJoists),
      mergeMap(action => {
        const detailedRimJoistTypes: RimJoistTypeBackendDict[] = [];
        const rimJoists: RimJoistBackendDict[] = [];
        const notes = [];
        action.detailedRimJoists.forEach(detailedRimJoist => {
          const { type_info: detailedRimJoistType, ...rimJoist } =
            detailedRimJoist;
          if (detailedRimJoistType) {
            detailedRimJoistTypes.push(detailedRimJoistType);
          }
          this.modelGraphService.attachModel(
            StateModelName.rimJoist,
            detailedRimJoist.id,
            'rimJoistType',
            [detailedRimJoist.type]
          );
          rimJoists.push(rimJoist);
          notes.push(
            createNote(
              StateModelName.rimJoist,
              detailedRimJoist.id,
              detailedRimJoist.note
            )
          );
        });

        const errors = RimJoistValidator.validate(action.detailedRimJoists);

        return of(
          RimJoistActions.loadRimJoistsSuccess({
            rimJoists: rimJoists,
            errors: errors,
          }),
          RimJoistTypeActions.loadRimJoistTypes({
            rimJoistTypes: detailedRimJoistTypes,
          })
        );
      })
    );
  });

  updateRimJoist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RimJoistActions.updateRimJoist),
      mergeMap(action =>
        this.rimJoistService.update(action.id, action.rimJoistChanges).pipe(
          mergeMap(updatedRimJoist => {
            const errors = RimJoistValidator.validate([updatedRimJoist]);
            return of(
              RimJoistActions.updateRimJoistSuccess({
                rimJoist: updatedRimJoist,
                errors: errors[updatedRimJoist.id],
              })
            );
          }),
          catchError(error =>
            of(
              RimJoistActions.updateRimJoistFailure({
                id: action.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeRimJoist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RimJoistActions.removeRimJoist),
      mergeMap(action =>
        this.rimJoistService.delete(action.rimJoist.id).pipe(
          mergeMap(() => {
            return of(
              RimJoistActions.removeRimJoistSuccess({ id: action.rimJoist.id }),
              SimulationActions.removeItemFromList({
                fieldName: 'rim_joists',
                id: action.rimJoist.id,
              })
            );
          }),
          catchError(error =>
            of(
              RimJoistActions.removeRimJoistFailure({ id: action.rimJoist.id }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeRimJoistType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RimJoistTypeActions.removeRimJoistType),
      mergeMap(action =>
        this.rimJoistTypeService.delete(action.rimJoistTypeId).pipe(
          mergeMap(() => [
            RimJoistActions.setRimJoistType({
              rimJoistId: action.rimJoistId,
              rimJoistTypeId: null,
            }),
            RimJoistTypeActions.removeRimJoistTypeSuccess({
              id: action.rimJoistTypeId,
            }),
          ]),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addRimJoistType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RimJoistTypeActions.addRimJoistType),
      mergeMap(action =>
        this.rimJoistTypeService
          .create(action.rimJoistId, action.rimJoistType)
          .pipe(
            mergeMap(rimJoistType =>
              of(
                RimJoistTypeActions.loadRimJoistTypes({
                  rimJoistTypes: [rimJoistType],
                }),
                RimJoistActions.setRimJoistType({
                  rimJoistId: action.rimJoistId,
                  rimJoistTypeId: rimJoistType.id,
                })
              )
            ),
            catchError(error => of(SharedActions.reportAPIFailure({ error })))
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private rimJoistService: RimJoistService,
    private rimJoistTypeService: RimJoistTypeService
  ) {}
}
