import { Injectable } from '@angular/core';
import { ListRequestParams } from '@/core/schemes/request-params';
import { environment } from '../../../../environments/environment';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { ServerResponse } from '@/core/schemes/server-response';
import {
  FloorplanSimulationSummary,
  SimulationList,
  SimulationVersions,
} from '@/data/simulation/models';
import {
  DetailedSimulation,
  Simulation,
  SimulationBackendDict,
  SimulationSourceType,
  SimulationStatusType,
} from '@/data/simulation/models/simulation';
import { Observable } from 'rxjs';
import {
  AboveGradeWallBackendDict,
  DetailedAboveGradeWallBackendDict,
} from '../models/enclosure/AboveGradeWall';
import {
  DetailedFrameFloorBackendDict,
  FrameFloorBackendDict,
} from '../models/enclosure/FrameFloor';
import {
  DetailedRoofBackendDict,
  RoofBackendDict,
} from '../models/enclosure/Roof';
import {
  DetailedFoundationWallBackendDict,
  FoundationWallBackendDict,
} from '../models/enclosure/FoundationWall';
import {
  DetailedRimJoistBackendDict,
  RimJoistBackendDict,
} from '../models/enclosure/RimJoist';
import {
  DetailedSlabBackendDict,
  SlabBackendDict,
} from '../models/enclosure/Slab';
import {
  DetailedSkylightBackendDict,
  SkylightBackendDict,
} from '../models/enclosure/Skylight';
import {
  DetailedDoorBackendDict,
  DoorBackendDict,
} from '../models/enclosure/Door';
import {
  DetailedWindowBackendDict,
  WindowBackendDict,
} from '../models/enclosure/Window';
import { DetailedMechanicalEquipmentBackendDict } from '../models/mechanicals/MechanicalEquipment';
import { DetailedDistributionSystemBackendDict } from '../models/mechanicals/DistributionSystem';
import { PhotovoltaicBackendDict } from '../models/generators/Photovoltaic';
import { tap } from 'rxjs/operators';
import { loadSimulation } from '@/state/actions/floorplan/simulation.actions';
import { DetailedUtilityRateBackendDict } from '../models/utility/UtilityRate';
import { ThermostatBackendDict } from '../models/mechanicals/Thermostat';
import { MechanicalVentilationBackendDict } from '../models/systems/MechanicalVentilation';

export class SimulationRequestParams extends ListRequestParams {
  source_type?: SimulationSourceType;
  status?: SimulationStatusType;
  company?: number | number[];
  floorplan__isnull?: boolean;
}

@Injectable({ providedIn: 'root' })
export class SimulationService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/simulations/`;

  list(
    params?: SimulationRequestParams
  ): Observable<ServerResponse<SimulationList>> {
    return this.http.get<ServerResponse<SimulationList>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  oldRetrieve(
    simulationId: number,
    options?: IDataServiceMethodOptions
  ): Observable<Simulation> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.get<Simulation>(`${this.baseUrl}${simulationId}/`);
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(simulation =>
          this.store.dispatch(loadSimulation({ payload: simulation }))
        )
      );
    }
    return chain;
  }

  update(
    simulationId: number,
    changes: Partial<SimulationBackendDict>
  ): Observable<SimulationBackendDict> {
    return this.http.patch<SimulationBackendDict>(
      `${this.baseUrl}${simulationId}/`,
      changes
    );
  }

  retrieve(simulationId: number): Observable<DetailedSimulation> {
    return this.http.get<DetailedSimulation>(`${this.baseUrl}${simulationId}/`);
  }

  validate(simulationId: number) {
    return this.http.get<any>(`${this.baseUrl}${simulationId}/validate/`, {
      params: { flags: 'os_eri' },
    });
  }

  create(
    simulation: Partial<SimulationBackendDict>
  ): Observable<SimulationBackendDict> {
    return this.http.post<SimulationBackendDict>(`${this.baseUrl}`, simulation);
  }

  delete(simulationId: number) {
    return this.http.delete(`${this.baseUrl}${simulationId}/`);
  }

  clone(simulationId: number, newName: string): Observable<SimulationList> {
    return this.http.post(`${this.baseUrl}${simulationId}/clone/`, {
      name: newName,
    });
  }

  compare(simulationId: number) {
    return this.http.get(`${this.baseUrl}${simulationId}/blg_compare/`);
  }

  summary(simulationId: number): Observable<FloorplanSimulationSummary> {
    return this.http.get<FloorplanSimulationSummary>(
      `${this.baseUrl}${simulationId}/summary/`
    );
  }

  getObject(
    simulationId: number,
    object_type: string,
    params?: ListRequestParams
  ) {
    return this.http.get(`${this.baseUrl}${simulationId}/${object_type}/`, {
      params: this.rollParams(params),
    });
  }

  versions(): Observable<SimulationVersions> {
    return this.http.get<SimulationVersions>(`${this.baseUrl}versions/`);
  }

  hpxml(simulationId: number, hpxmlType: string) {
    const url = `${this.baseUrl}/${simulationId}/hpxml?hpxml_type=${hpxmlType}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  remxml(simulationId: number) {
    const url = `${this.baseUrl}/${simulationId}/remxml`;
    return this.http.get(url, { responseType: 'blob' });
  }

  addAboveGradeWall(
    simulationId: number,
    wall: AboveGradeWallBackendDict
  ): Observable<AboveGradeWallBackendDict> {
    return this.http.post<AboveGradeWallBackendDict>(
      `${this.baseUrl}${simulationId}/above_grade_walls/`,
      wall
    );
  }

  cloneAboveGradeWall(
    simulationId: number,
    aboveGradeWallId: number
  ): Observable<DetailedAboveGradeWallBackendDict> {
    return this.http.post<DetailedAboveGradeWallBackendDict>(
      `${this.baseUrl}${simulationId}/above_grade_walls/${aboveGradeWallId}/clone/`,
      {}
    );
  }

  addFrameFloor(simulationId: number): Observable<FrameFloorBackendDict> {
    return this.http.post<FrameFloorBackendDict>(
      `${this.baseUrl}${simulationId}/frame_floors/`,
      {}
    );
  }

  cloneFrameFloor(
    simulationId: number,
    frameFloorId: number
  ): Observable<DetailedFrameFloorBackendDict> {
    return this.http.post<DetailedFrameFloorBackendDict>(
      `${this.baseUrl}${simulationId}/frame_floors/${frameFloorId}/clone/`,
      {}
    );
  }

  addFoundationWall(
    simulationId: number,
    foundationWall: FoundationWallBackendDict
  ): Observable<FoundationWallBackendDict> {
    return this.http.post<FoundationWallBackendDict>(
      `${this.baseUrl}${simulationId}/foundation_walls/`,
      foundationWall
    );
  }

  cloneFoundationWall(
    simulationId: number,
    foundationWallId: number
  ): Observable<DetailedFoundationWallBackendDict> {
    return this.http.post<DetailedFoundationWallBackendDict>(
      `${this.baseUrl}${simulationId}/foundation_walls/${foundationWallId}/clone/`,
      {}
    );
  }

  addRoof(simulationId: number): Observable<RoofBackendDict> {
    return this.http.post<RoofBackendDict>(
      `${this.baseUrl}${simulationId}/roofs/`,
      {}
    );
  }

  cloneRoof(
    simulationId: number,
    roofId: number
  ): Observable<DetailedRoofBackendDict> {
    return this.http.post<DetailedRoofBackendDict>(
      `${this.baseUrl}${simulationId}/roofs/${roofId}/clone/`,
      {}
    );
  }

  addRimJoist(simulationId: number): Observable<RimJoistBackendDict> {
    return this.http.post<RimJoistBackendDict>(
      `${this.baseUrl}${simulationId}/rim_joists/`,
      {}
    );
  }

  cloneRimJoist(
    simulationId: number,
    rimJoistId: number
  ): Observable<DetailedRimJoistBackendDict> {
    return this.http.post<DetailedRimJoistBackendDict>(
      `${this.baseUrl}${simulationId}/rim_joists/${rimJoistId}/clone/`,
      {}
    );
  }

  addSlab(
    simulationId: number,
    slab: SlabBackendDict
  ): Observable<SlabBackendDict> {
    return this.http.post<SlabBackendDict>(
      `${this.baseUrl}${simulationId}/slabs/`,
      {}
    );
  }

  cloneSlab(
    simulationId: number,
    slabId: number
  ): Observable<DetailedSlabBackendDict> {
    return this.http.post<DetailedSlabBackendDict>(
      `${this.baseUrl}${simulationId}/slabs/${slabId}/clone/`,
      {}
    );
  }

  addSkylight(
    simulationId: number,
    skylight: SkylightBackendDict
  ): Observable<SkylightBackendDict> {
    return this.http.post<SkylightBackendDict>(
      `${this.baseUrl}${simulationId}/skylights/`,
      skylight
    );
  }

  cloneSkylight(
    simulationId: number,
    skylightId: number
  ): Observable<DetailedSkylightBackendDict> {
    return this.http.post<DetailedSkylightBackendDict>(
      `${this.baseUrl}${simulationId}/skylights/${skylightId}/clone/`,
      {}
    );
  }

  addDoor(simulationId: number): Observable<DoorBackendDict> {
    return this.http.post<DoorBackendDict>(
      `${this.baseUrl}${simulationId}/doors/`,
      {}
    );
  }

  cloneDoor(
    simulationId: number,
    doorId: number
  ): Observable<DetailedDoorBackendDict> {
    return this.http.post<DetailedDoorBackendDict>(
      `${this.baseUrl}${simulationId}/doors/${doorId}/clone/`,
      {}
    );
  }

  addWindow(simulationId: number): Observable<WindowBackendDict> {
    return this.http.post<WindowBackendDict>(
      `${this.baseUrl}${simulationId}/windows/`,
      {}
    );
  }

  cloneWindow(
    simulationId: number,
    windowId: number
  ): Observable<DetailedWindowBackendDict> {
    return this.http.post<DetailedWindowBackendDict>(
      `${this.baseUrl}${simulationId}/windows/${windowId}/clone/`,
      {}
    );
  }

  addEquipment(
    simulationId: number,
    equipment: DetailedMechanicalEquipmentBackendDict
  ): Observable<DetailedMechanicalEquipmentBackendDict> {
    return this.http.post<DetailedMechanicalEquipmentBackendDict>(
      `${this.baseUrl}${simulationId}/mechanicals/`,
      equipment
    );
  }

  addDistributionSystem(
    simulationId: number
  ): Observable<DetailedDistributionSystemBackendDict> {
    return this.http.post<DetailedDistributionSystemBackendDict>(
      `${this.baseUrl}${simulationId}/distribution_systems/`,
      {}
    );
  }

  cloneDistributionSystem(
    simulationId: number,
    distributionSystemId: number
  ): Observable<DetailedDistributionSystemBackendDict> {
    return this.http.post<DetailedDistributionSystemBackendDict>(
      `${this.baseUrl}${simulationId}/distribution_system/${distributionSystemId}/clone/`,
      {}
    );
  }

  addPhotovoltaic(simulationId: number): Observable<PhotovoltaicBackendDict> {
    return this.http.post<PhotovoltaicBackendDict>(
      `${this.baseUrl}${simulationId}/photovoltaics/`,
      {}
    );
  }

  clonePhotovoltaic(
    simulationId: number,
    PhotovoltaicId: number
  ): Observable<PhotovoltaicBackendDict> {
    return this.http.post<PhotovoltaicBackendDict>(
      `${this.baseUrl}${simulationId}/photovoltaics/${PhotovoltaicId}/clone/`,
      {}
    );
  }

  addUtilityRate(simulationId: number, entity: DetailedUtilityRateBackendDict) {
    const { id, seasonal_rates_info, seasonal_rates, ...utilityRate } = entity;

    const seasonalRates = [];
    seasonal_rates_info.forEach(seasonalRate => {
      const { id, block_rates, block_rates_info, ...seasonalRateInfo } =
        seasonalRate;

      seasonalRates.push({
        ...seasonalRateInfo,
        block_rates: block_rates_info,
      });
    });

    return this.http.post<DetailedUtilityRateBackendDict>(
      `${this.baseUrl}${simulationId}/utility_rates/`,
      {
        ...utilityRate,
        seasonal_rates: seasonalRates,
      }
    );
  }

  addThermostat(simulationId: number): Observable<ThermostatBackendDict> {
    return this.http.post<ThermostatBackendDict>(
      `${this.baseUrl}${simulationId}/thermostats/`,
      {}
    );
  }

  addMechanicalVentilation(
    simulationId: number
  ): Observable<MechanicalVentilationBackendDict> {
    return this.http.post<MechanicalVentilationBackendDict>(
      `${this.baseUrl}${simulationId}/mechanical_ventilations/`,
      {}
    );
  }

  cloneMechanicalVentilation(
    simulationId: number,
    mechanicalVentilationId: number
  ): Observable<MechanicalVentilationBackendDict> {
    return this.http.post<MechanicalVentilationBackendDict>(
      `${this.baseUrl}${simulationId}/mechanical_ventilations/${mechanicalVentilationId}/clone/`,
      {}
    );
  }
}
