import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProjectService } from '@/data/simulation/services/project.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ProjectActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { ProjectValidator } from '../../validtaors/project.validator';

@Injectable()
export class ProjectEffects {
  loadProjects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.loadProjects),
      mergeMap(action => {
        const errors = ProjectValidator.validate(action.projects);
        return of(
          ProjectActions.loadProjectsSuccess({
            projects: action.projects,
            errors,
          })
        );
      })
    );
  });

  updateProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.updateProject),
      mergeMap(action => {
        return this.projectService.update(action.projectChanges).pipe(
          mergeMap(project => {
            const errors = ProjectValidator.validate([project]);
            return of(
              ProjectActions.loadProjectsSuccess({
                projects: [project],
                errors,
              })
            );
          }),
          catchError(error =>
            of(
              ProjectActions.updateProjectFailure({
                id: action.projectChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private projectService: ProjectService
  ) {}
}
