import { Note } from '@/data/simulation/models/Note';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectNoteViewSet } from '../../state/note/selectors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { NoteService } from '@/data/simulation/services/note.service';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements OnInit {
  @Input() entityId: string;

  quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block', { align: [] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ], // outdent/indent

      [
        { header: [1, 2, 3, 4, 5, 6, false] },
        { color: [] },
        { background: [] },
      ], // dropdown with defaults from theme]
    ],
  };
  componentDestroyed$ = new Subject();
  entity: Note;
  loading: boolean;
  control: FormControl = new FormControl();
  edit = false;

  constructor(public store: Store, public noteService: NoteService) {}

  ngOnInit(): void {
    this.store
      .select(selectNoteViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.control.setValue(data.data);
        this.loading = loading;
      });
  }

  cancel(e) {
    this.edit = false;
    e.preventDefault();
    e.stopPropagation();
  }

  save(e) {
    e.preventDefault();
    e.stopPropagation();
    this.noteService
      .update({ ...this.entity, data: this.control.value })
      .subscribe(() => {
        this.edit = false;
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
