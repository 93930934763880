<div class="row h-100" *ngIf="initialized">
  <div class="col-12">
    <mat-sidenav-container class="h-100" [hasBackdrop]="false">
      <mat-sidenav
        #selectedSideNav
        mode="side"
        [opened]="!!selectedRows.length"
        position="end"
        fixedInViewport="true"
        [fixedTopGap]="50"
      >
        <div class="container">
          <div class="row mt-2">
            <div class="col-12">
              <h3>
                Selected QA
                <a
                  href="#"
                  class="small fa-pull-right"
                  (click)="removeAllSelectedRows($event)"
                >
                  <fa-icon
                    [icon]="['fas', 'times']"
                    matTooltip="Clear Selection"
                  ></fa-icon>
                </a>
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <ul class="list-group list-group-flush">
                <li class="list-group-item" *ngFor="let row of selectedRows">
                  <a
                    [routerLink]="['/', 'home', row.home_status_info?.home]"
                    matTooltip="View Home"
                    target="_blank"
                  >
                    <app-home-address-display
                      [home]="row.home_status_info?.home_info"
                    ></app-home-address-display>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <hr />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button
                mat-raised-button
                color="accent"
                class="btn-block"
                (click)="onCreateQANoteForSelectedRows($event)"
                [disabled]="!selectedRows.length"
              >
                Create Note
              </button>
            </div>
          </div>
        </div>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="container">
          <div class="row">
            <div class="col-6">
              <h3>
                <span *ngIf="!entitiesIsLoading">{{ entitiesCount | number }}</span>
                <fa-icon
                  [icon]="['fas', 'spinner']"
                  [spin]="true"
                  *ngIf="entitiesIsLoading"
                ></fa-icon>
                QA Dashboard
              </h3>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-2">
              <button
                mat-stroked-button
                [matTooltip]="'QA Statuses: ' + userFilterBadges.all_projects"
                [matBadge]="userFilterBadges.all_projects"
                matBadgeColor="accent"
                class="quick-filter-btn mr-3 mb-2"
                (click)="onQuickFilterAllProjects($event)"
              >
                All Projects
              </button>
              <button
                mat-stroked-button
                [matTooltip]="'QA Statuses: ' + userFilterBadges.my_projects"
                [matBadge]="userFilterBadges.my_projects"
                matBadgeColor="accent"
                class="quick-filter-btn mr-3 mb-2"
                (click)="onQuickFilterMyProjects($event)"
              >
                My Projects
              </button>
              <button
                mat-stroked-button
                [matTooltip]="
                  'QA Statuses: ' + userFilterBadges.rough_qa_projects
                "
                [matBadge]="userFilterBadges.rough_qa_projects"
                matBadgeColor="accent"
                class="quick-filter-btn mr-3 mb-2"
                (click)="onQuickFilterMyRoughQAProjects($event)"
              >
                Rough QA
              </button>
              <button
                mat-stroked-button
                [matTooltip]="
                  'QA Statuses: ' + userFilterBadges.final_qa_projects
                "
                [matBadge]="userFilterBadges.final_qa_projects"
                matBadgeColor="accent"
                class="quick-filter-btn mr-3 mb-2"
                (click)="onQuickFilterMyFinalQAProjects($event)"
              >
                Final QA
              </button>
              <button
                mat-stroked-button
                [matTooltip]="
                  'QA Statuses: ' + userFilterBadges.desktop_audit_projects
                "
                [matBadge]="userFilterBadges.desktop_audit_projects"
                matBadgeColor="accent"
                class="quick-filter-btn mr-3 mb-2"
                (click)="onQuickFilterMyDesktopAuditQAProjects($event)"
              >
                Desktop Audit
              </button>
              <button
                mat-stroked-button
                [matTooltip]="
                  'QA Statuses: ' +
                  userFilterBadges.qa_correction_received_projects
                "
                [matBadge]="userFilterBadges.qa_correction_received_projects"
                matBadgeColor="accent"
                class="quick-filter-btn mr-3 mb-2"
                (click)="onQuickFilterMyCorrectionReceivedQAProjects($event)"
              >
                QA Correction Received
              </button>
              <button
                mat-stroked-button
                [matTooltip]="
                  'QA Statuses: ' +
                  userFilterBadges.qa_correction_required_projects
                "
                [matBadge]="userFilterBadges.qa_correction_required_projects"
                matBadgeColor="accent"
                class="quick-filter-btn mb-2"
                (click)="onQuickFilterMyCorrectionRequiredQAProjects($event)"
              >
                QA Correction Required
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <hr />
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <form [formGroup]="filterFromGroup">
                      <h5 class="card-header">
                        Filters
                        <a href="#" class="small" (click)="resetFilters($event)"
                          >Reset filters</a
                        >
                      </h5>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field class="w-100">
                              <mat-label>Search</mat-label>
                              <app-search-input
                                formControlName="search"
                                placeholder="Type to search something"
                              ></app-search-input>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field class="w-100">
                              <mat-label>QA Designee</mat-label>
                              <app-generic-model-chips
                                placeholder="Select Users"
                                modelClassName="user"
                                formControlName="qa_designee_info"
                                [panelWidth]="640"
                                [initialValueIds]="storedParams.qa_designee"
                                [filterParams]="{
                                  hirluserprofile__is_qa_designee: true,
                                  is_active: true
                                }"
                              ></app-generic-model-chips>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field class="w-100">
                              <mat-label>QA Status</mat-label>
                              <mat-select formControlName="state">
                                <mat-option [value]="''"> Any </mat-option>
                                <mat-option
                                  *ngFor="
                                    let item of qaStatusStateLabelMapping
                                      | defaultOrderKeyValue
                                  "
                                  [value]="item.key"
                                >
                                  {{ item.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field class="w-100">
                              <mat-label>QA Type</mat-label>
                              <mat-select formControlName="requirement__type">
                                <mat-option [value]="''"> Any </mat-option>
                                <mat-option
                                  *ngFor="
                                    let item of requirementTypeLabelMapping
                                      | defaultOrderKeyValue
                                  "
                                  [value]="item.key"
                                >
                                  {{ item.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field class="w-100">
                              <mat-label>Program Status</mat-label>
                              <mat-select formControlName="home_status__state">
                                <mat-option [value]="''"> Any </mat-option>
                                <mat-option
                                  *ngFor="
                                    let item of homeStatusStateLabelMapping
                                      | defaultOrderKeyValue
                                  "
                                  [value]="item.key"
                                >
                                  {{ item.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field class="w-100">
                              <mat-label>Priority</mat-label>
                              <mat-select formControlName="hirl_priority" [multiple]="true">
                                <mat-option
                                  *ngFor="
                                    let item of HIRLPriorityLabelMapping
                                      | defaultOrderKeyValue
                                  "
                                  [value]="item.key"
                                >
                                  {{ item.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field class="w-100">
                              <mat-label>Verifier Organization</mat-label>
                              <app-generic-model-autocomplete
                                placeholder="Verifier Organization"
                                modelClassName="company"
                                formControlName="home_status__customer_hirl_project__registration__registration_user__company_info"
                                [panelWidth]="640"
                                [initialValueId]="
                                  storedParams.home_status__customer_hirl_project__registration__registration_user__company
                                "
                                [filterParams]="{
                                  company_type: companyType.rater
                                }"
                              ></app-generic-model-autocomplete>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field class="w-100">
                              <mat-label>Client Company</mat-label>
                              <app-generic-model-autocomplete
                                placeholder="Client Company"
                                modelClassName="company"
                                formControlName="home_status__customer_hirl_project__registration__builder_organization_info"
                                [panelWidth]="640"
                                [initialValueId]="
                                  storedParams.home_status__customer_hirl_project__registration__builder_organization
                                "
                                [filterParams]="{
                                  company_type: companyType.builder
                                }"
                              ></app-generic-model-autocomplete>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12">
                            <mat-form-field class="w-100">
                              <mat-label>City</mat-label>
                              <app-generic-model-chips formControlName="home_status__home__city_info" modelClassName="city" [filterParams]="{ordering: 'name', home_status_related: true}" [panelWidth]="640" [initialValueIds]="storedParams.home_status__home__city"></app-generic-model-chips>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field class="w-100">
                              <mat-label>State</mat-label>
                              <app-generic-model-chips formControlName="home_status__home__state_info" modelClassName="us_state" [filterParams]="{ordering: 'name', home_status_related: true}" [panelWidth]="640" [initialValueIds]="storedParams.home_status__home__state"></app-generic-model-chips>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field class="w-100">
                              <mat-label>Country</mat-label>
                              <app-generic-model-chips formControlName="home_status__home__city__country_info" modelClassName="country" [filterParams]="{ordering: 'name', home_status_related: true}" [panelWidth]="640" [initialValueIds]="storedParams.home_status__home__city__country"></app-generic-model-chips>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-9">
              <ngx-datatable
                class="material striped"
                [rows]="entities"
                columnMode="force"
                headerHeight="64"
                rowHeight="auto"
                footerHeight="50"
                [rowIdentity]="getId"
                [limit]="storedParams.page_size"
                [externalSorting]="true"
                [selectAllRowsOnPage]="true"
                [selected]="selectedRows"
                [selectionType]="'checkbox'"
                (sort)="onSort($event)"
                (select)="onSelect($event)"
                [loadingIndicator]="entitiesIsLoading"
              >
                <ngx-datatable-column
                  [width]="40"
                  [sortable]="false"
                  [canAutoResize]="false"
                  [draggable]="false"
                  [resizeable]="false"
                >
                  <ng-template
                    ngx-datatable-cell-template
                    let-value="value"
                    let-isSelected="isSelected"
                    let-onCheckboxChangeFn="onCheckboxChangeFn"
                  >
                    <input
                      type="checkbox"
                      class="customCheckbox"
                      [checked]="isSelected"
                      (change)="onCheckboxChangeFn($event)"
                    />
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column
                  name="Address"
                  prop="home_status__home__street_line1"
                >
                  <ng-template
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    <a
                      [routerLink]="['/', 'home', row.home_status_info?.home]"
                      matTooltip="View Home"
                      target="_blank"
                    >
                      <app-home-address-display
                        [home]="row.home_status_info?.home_info"
                      ></app-home-address-display>
                    </a>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column
                  name="Program"
                  prop="home_status__eep_program__name"
                >
                  <ng-template
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    <a
                      [routerLink]="[
                        '/',
                        'eep_program',
                        row.home_status_info?.eep_program_info?.id
                      ]"
                      matTooltip="View QA"
                    >
                      {{ row.home_status_info?.eep_program_info?.name }}
                    </a>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column prop="home_status__state">
                  <ng-template
                    let-column="column"
                    ngx-datatable-header-template
                  >
                    Project<br />
                    Status
                  </ng-template>
                  <ng-template
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    <a
                      [routerLink]="['/', 'home', row.home_status_info?.home]"
                      matTooltip="View QA"
                    >
                      {{
                        homeStatusStateLabelMapping[row.home_status_info?.state]
                      }}
                    </a>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Verifier" prop="verifier_name">
                  <ng-template
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    <a
                      [routerLink]="['/', 'profile', row.verifier_id]"
                      matTooltip="View Verifier"
                      target="_blank"
                    >
                      {{ row.verifier_name }}
                    </a>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="QA Type" prop="requirement__type">
                  <ng-template
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    <a
                      [routerLink]="['/', 'home', row.home_status_info?.home]"
                      [fragment]="'/tabs/qa'"
                      matTooltip="View Home"
                      target="_blank"
                    >
                      {{
                        requirementTypeLabelMapping[row.requirement_info.type]
                      }}
                    </a>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="QA Status" prop="state">
                  <ng-template
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    {{ qaStatusStateLabelMapping[row.state] }}
                    <div
                      *ngIf="
                        row.state !== qaState.complete &&
                        row.state_cycle_time_total_duration / (3600 * 24) > 0
                      "
                    >
                      {{
                        row.state_cycle_time_total_duration / (3600 * 24)
                          | number : "1.2-2"
                      }}
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Priority" prop="hirl_priority">
                  <ng-template
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    {{ HIRLPriorityLabelMapping[row.hirl_priority] }}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Notes" [sortable]="false">
                  <ng-template
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    <p *ngFor="let annotation of row.home_status_notes">
                      <i
                        >{{ annotation.user_info?.first_name }}
                        {{ annotation.user_info?.last_name }}:</i
                      >
                      {{ annotation.content }}
                    </p>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Actions" [sortable]="false">
                  <ng-template
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    <a
                      mat-icon-button
                      color="accent"
                      href="#"
                      matTooltip="View QA notes"
                      (click)="onShowQANotesList($event, row.id)"
                    >
                      <fa-icon [icon]="['fas', 'list']"></fa-icon>
                    </a>
                    <a
                      mat-icon-button
                      color="accent"
                      href="#"
                      (click)="onCreateQANote($event, [row.id])"
                      matTooltip="Add Note"
                    >
                      <fa-icon [icon]="['fas', 'sticky-note']"></fa-icon>
                    </a>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-footer>
                  <ng-template ngx-datatable-footer-template>
                    <div class="page-count col-3 text-center" *ngIf="entitiesCount">
                      Showing {{ storedParams.page }} to
                      {{ entitiesCount | divide : storedParams.page_size }} of
                      {{ entitiesCount }}
                    </div>
                    <div class="col-9 text-right">
                      <datatable-pager
                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                        [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'"
                        [pagerNextIcon]="'datatable-icon-skip'"
                        [page]="storedParams.page"
                        [size]="storedParams.page_size"
                        [count]="entitiesCount"
                        (change)="onFooterPage($event)"
                      >
                      </datatable-pager>
                    </div>
                  </ng-template>
                </ngx-datatable-footer>
              </ngx-datatable>
            </div>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
