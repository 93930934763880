import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { User } from '@/data/core/models/user';
import { AppState } from '@/state/reducers';
import { HIRLProjectService } from '@/data/customer-hirl/services/hirl-projects.service';
import { Router } from '@angular/router';
import { UserRequestParams } from '@/data/core/services/user.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { toggleLoading } from '@/state/actions/app.actions';
import { COIDocumentParams } from '@/data/customer-hirl/services/coi-documents.service';
import { CompanyService } from '@/data/company/services/company-base.service';
import {
  COIDocumentInsuranceType,
  COIDocumentInsuranceTypeMapping,
} from '@/data/customer-hirl/models';

export enum ScoringExtractionSamplingDocumentationType {
  NOT_PROVIDED = 'not_provided',
  SEPARATELY = 'separately',
}

export const ScoringExtractionSamplingDocumentationTypeMapping: Record<
  ScoringExtractionSamplingDocumentationType,
  string
> = {
  [ScoringExtractionSamplingDocumentationType.NOT_PROVIDED]:
    'Not Applicable/Provided in the Verification Report',
  [ScoringExtractionSamplingDocumentationType.SEPARATELY]:
    'To be uploaded separately',
};

@Component({
  selector: 'app-hirl-verifier-report-upload',
  templateUrl: './hirl-verifier-report-upload.component.html',
  styleUrls: ['./hirl-verifier-report-upload.component.scss'],
})
export class HirlVerifierReportUploadComponent implements OnInit, OnDestroy {
  protected readonly ScoringExtractionSamplingDocumentationTypeMapping =
    ScoringExtractionSamplingDocumentationTypeMapping;

  initialized = false;

  public currentUser: User;
  public formGroup: UntypedFormGroup;
  public missingCOITypes: string[];

  public userRequestParams: UserRequestParams;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private hirlProjectService: HIRLProjectService,
    private companyService: CompanyService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private uiHelperService: UIHelperService
  ) {
    this.userRequestParams = new UserRequestParams();
  }

  ngOnInit(): void {
    forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    })
      .pipe(
        takeUntil(this.componentDestroyed$),
        switchMap(({ currentUser }) => {
          const params = new COIDocumentParams();
          params.is_active = true;
          return this.companyService
            .coi_documents(currentUser.company, params)
            .pipe(
              first(),
              map(coiDocumentsResponse => {
                const coiDocuments = coiDocumentsResponse.results;
                return {
                  currentUser: currentUser,
                  coiDocuments: coiDocuments,
                };
              })
            );
        })
      )
      .subscribe(({ currentUser, coiDocuments }) => {
        this.currentUser = currentUser;
        const requiredCOITypes = [
          COIDocumentInsuranceType.generalLiability,
          COIDocumentInsuranceType.professionalLiability,
          COIDocumentInsuranceType.automobileInsurance,
        ];

        this.missingCOITypes = [];

        for (const requiredType of requiredCOITypes) {
          const typeExists = coiDocuments.some(
            doc => doc.insurance_type === requiredType
          );
          if (!typeExists) {
            this.missingCOITypes.push(
              COIDocumentInsuranceTypeMapping[requiredType]
            );
          }
        }

        this.setupForm();

        this.userRequestParams.company = this.currentUser.company;
        this.initialized = true;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public setupForm() {
    this.formGroup = this.fb.group({
      report_type: [null, Validators.required],
      verifier_info: [null, Validators.required],
      report_file: [null, Validators.required],
      sampling_documentation: [
        ScoringExtractionSamplingDocumentationType.NOT_PROVIDED,
        Validators.required,
      ],
    });
  }

  onSubmit($event: MouseEvent) {
    $event.preventDefault();
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const formData = new FormData();
    formData.append('report_type', this.formGroup.value.report_type);
    formData.append('verifier', this.formGroup.value.verifier_info.id);
    formData.append('report_file', this.formGroup.value.report_file);
    formData.append(
      'sampling_documentation',
      this.formGroup.value.sampling_documentation
    );

    this.hirlProjectService
      .verification_report_upload(formData)
      .pipe(first(), takeUntil(this.componentDestroyed$))
      .subscribe(
        asynchronousProcessedDocument => {
          this.store.dispatch(toggleLoading({ payload: false }));
          const _ = this.router.navigate([
            'file-operation',
            'document',
            asynchronousProcessedDocument.id,
          ]);
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }

  verifierOptionDisplay(value: User): string {
    let display = `${value.first_name} ${value.last_name}`;
    if (
      !value.active_customer_hirl_verifier_agreements_count &&
      !value.customer_hirl_project_accreditations_count
    ) {
      display +=
        ' (Does not have an active Verifier Agreement or Accreditations)';
    } else if (!value.active_customer_hirl_verifier_agreements_count) {
      display += ' (Does not have an active Verifier Agreement)';
    } else if (!value.customer_hirl_project_accreditations_count) {
      display += ' (Does not have any active Accreditations)';
    }
    return display;
  }

  verifierOptionDisabled(value: User): boolean {
    let disabled = false;
    if (!value.active_customer_hirl_verifier_agreements_count) {
      disabled = true;
    }
    if (!value.customer_hirl_project_accreditations_count) {
      disabled = true;
    }
    return disabled;
  }
}
