<div class="card position-relative" appFocusTarget entityName="roof" entityId="{{entityId}}">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <div class="card-body pb-0">
        <app-form formName="roofFormGroup" [formGroup]="roofFormGroup" (save)="onSave($event)">
            <div>
                <app-input formControlName="name" [label]="fieldLabels.name" cdkFocusInitial></app-input>
            </div>
            <div>
                <app-select formControlName="interior_location" [label]="fieldLabels.interior_location"
                    [items]="interiorLocationLabelMapping"></app-select>
                <app-select formControlName="exterior_location" [label]="fieldLabels.exterior_location"
                    [items]="exteriorLocationLabelMapping"></app-select>
            </div>
            <div>
                <app-select formControlName="exterior_color" [label]="fieldLabels.exterior_color"
                    [items]="exteriorColorLabelMapping"></app-select>
                <app-input formControlName="ceiling_area" [label]="fieldLabels.ceiling_area" type="expression" unit="sqft."></app-input>
            </div>
            <div>
                <app-input formControlName="roof_area" [label]="fieldLabels.roof_area" type="expression" unit="sqft."></app-input>
            </div>
            <div>
                <app-foreign-model-field
                    [name]="roofTypeName"
                    [errorCount]="roofTypeErrorCount"
                    (add)="addRoofType()"
                    (edit)="editRoofType()"
                    (remove)="removeRoofType()"
                    label="Roof type">
                </app-foreign-model-field>
            </div>

            <mat-expansion-panel class="advance-option-header shadow-none">
                <mat-expansion-panel-header class="p-0">
                    <mat-panel-title> Advance options </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="advance-option-inputs">
                    <app-input formControlName="pitch" [label]="'Pitch'"></app-input>
                    <app-input formControlName="solar_absorptance" [label]="'Solar Absorptance'" type="number"></app-input>
                </div>
                <div class="advance-option-inputs">
                    <app-input formControlName="emittance" [label]="'Emittance'" type="number"></app-input>
                    <app-input formControlName="attic_ventilation_rate" [label]="'Attic Ventilation Rate'"></app-input>
                </div>
            </mat-expansion-panel>
        </app-form>
    </div>
    <div class="card-footer p-0 text-right">
        <app-model-action-buttons [entityId]="entityId" modelName="roof" (clone)="clone()" (delete)="removeRoof()">
        </app-model-action-buttons>
    </div>
</div>
