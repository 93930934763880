<ng-container>
    <li class="list-group-item header" style="background-color:rgba(0, 0, 0, 0.03); align-items: center;">
        <b>
            Ground Source Heat Pump
        </b>
    </li>
    <li class="list-group-item">
        <b>Name:</b>
        {{ equipment.ground_source_heat_pump_info.name }}
    </li>
    <li class="list-group-item">
        <b>Fuel Type:</b>
        {{ equipment.ground_source_heat_pump_info.fuel_display }}
    </li>

    <li class="list-group-item" *ngIf="equipment.ground_source_heat_pump_info.heating_capacity">
        <b>Heating Capacity:</b>
        {{ equipment.ground_source_heat_pump_info.heating_capacity }}
        {{ equipment.ground_source_heat_pump_info.capacity_unit_display }}
    </li>
    <li class="list-group-item" *ngIf="equipment.ground_source_heat_pump_info.heating_efficiency">
        <b>Heating Efficiency:</b>
        {{ equipment.ground_source_heat_pump_info.heating_efficiency }}
        {{ equipment.ground_source_heat_pump_info.heating_efficiency_unit_display }}
    </li>

    <li class="list-group-item" *ngIf="equipment.ground_source_heat_pump_info.cooling_capacity">
        <b>Cooling Capacity:</b>
        {{ equipment.ground_source_heat_pump_info.cooling_capacity }}
        {{ equipment.ground_source_heat_pump_info.capacity_unit_display }}
    </li>
    <li class="list-group-item" *ngIf="equipment.ground_source_heat_pump_info.cooling_efficiency">
        <b>Cooling Efficiency:</b>
        {{ equipment.ground_source_heat_pump_info.cooling_efficiency }}
        {{ equipment.ground_source_heat_pump_info.cooling_efficiency_unit_display }}
    </li>

    <li class="list-group-item" *ngIf="equipment.ground_source_heat_pump_info.motor_type">
        <b>Motor Type:</b>
        {{ equipment.ground_source_heat_pump_info.motor_type_display }}
    </li>
    <li class="list-group-item">
        <b>Has De-Superheater:</b>
        {{ equipment.ground_source_heat_pump_info.has_desuperheater && 'Yes' || 'No'}}
    </li>
</ng-container>