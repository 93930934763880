<div class="row mb-2">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                Certificate of Insurance
            </div>
            <div class="card-body">
                <p>
                    A Certification of Insurance is required before enrollment can be completed.
                    <a href="#" (click)="onCOIHelpClicked($event);">
                        Why is this necessary?
                    </a>
                </p>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <app-company-coi-list></app-company-coi-list>
    </div>
</div>
