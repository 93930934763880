import { createReducer, on } from '@ngrx/store';
import * as SimulationActions from './actions';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { SimulationBackendDict } from '@/data/simulation/models/simulation';
import { addStateErrorCount } from '../helper';

export interface SimulationState extends BaseState<SimulationBackendDict> {
  simulationId: number;
}

export const initialState: SimulationState = {
  ...sharedInitialState,
  simulationId: null,
};

export const reducer = createReducer(
  initialState,
  on(
    SimulationActions.loadSimulationSuccess,
    (state, { simulation, errors }) => {
      return {
        entities: {
          [simulation.id]: simulation,
        },
        loading: {
          [simulation.id]: false,
        },
        errors: {
          ...errors,
          ...addStateErrorCount(errors),
        },
        simulationId: simulation.id,
      };
    }
  ),

  on(SimulationActions.updateSimulation, (state, { id }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [id]: true,
      },
    };
  }),

  on(SimulationActions.updateSimulationFailure, (state, { id }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [id]: false,
      },
    };
  })
);
