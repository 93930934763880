<div class="card w-100">
    <div class="card-header">
        <div class="d-flex justify-content-between">
            <div>
                {{ entity.entity_name }} (#{{ entity.entity_id }})
            </div>
            <app-model-link [entityName]="entity.entity_name" [entityId]="entity.entity_id"></app-model-link>
        </div>
    </div>
    <div class="card-body" (click)="edit = true">
        <span [innerHTML]="control.value" *ngIf="!edit"></span>
        <quill-editor [formControl]="control" [modules]="quillModules" *ngIf="edit"></quill-editor>
        <div class="form-buttons-container">
            <ng-container *ngIf="edit">
                <button type="button" class="cancel" (click)="cancel($event)">
                    Cancel
                </button>
                <button type="button" (click)="save($event)">Save</button>
            </ng-container>
        </div>
    </div>
</div>
