<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-6">
                    <h4>
                        <span *ngIf="!isLoading">{{ count | number }}</span>
                        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="isLoading"></fa-icon>
                        Inspection Grades
                    </h4>
                </div>
                <div class="col-6 text-right">
                </div>
            </div>

            <div class="row" *ngIf="!aggregateByLetterGradeIsLoading else letterGradeLoading">
                <div class="col-6">
                    <span>Totals: </span>
                    <span class="badge badge-secondary mr-2">A: {{ inspectionGradeAggregateByLetterGrade.a_grade }}</span>
                    <span class="badge badge-secondary mr-2">B: {{ inspectionGradeAggregateByLetterGrade.b_grade }}</span>
                    <span class="badge badge-secondary mr-2">C: {{ inspectionGradeAggregateByLetterGrade.c_grade }}</span>
                    <span class="badge badge-secondary mr-2">D: {{ inspectionGradeAggregateByLetterGrade.d_grade }}</span>
                    <span class="badge badge-secondary mr-2">F: {{ inspectionGradeAggregateByLetterGrade.f_grade }}</span>
                    <span>Average: </span>
                    <span class="badge badge-secondary mr-2"> {{ averageGrade || '-' }}</span>
                </div>
            </div>
            <ng-template #letterGradeLoading>
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-center">
                            <mat-spinner [diameter]="20"></mat-spinner>
                        </div>
                    </div>
                </div>
            </ng-template>

            <div class="row">
                <div class="col-12">
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-3">
                    <div class="row mb-2">
                        <div class="col-12">
                            <form [formGroup]="filterFromGroup" class="card">
                                    <h5 class="card-header">Filters</h5>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <mat-form-field class="w-100">
                                                    <mat-label>Search</mat-label>
                                                    <app-search-input formControlName="search" placeholder="Type to search something"></app-search-input>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <mat-form-field class="w-100 mb-2">
                                                    <mat-label>Program</mat-label>
                                                    <app-generic-model-chips
                                                            placeholder="Filter by Program..."
                                                            formControlName="qa_status__home_status__eep_program_info"
                                                            modelClassName="eep_program" [panelWidth]="640" [initialValueIds]="storedParams.qa_status__home_status__eep_program">
                                                    </app-generic-model-chips>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <mat-form-field class="w-100">
                                                    <mat-label>Grade</mat-label>
                                                    <mat-select formControlName="letter_grade">
                                                        <mat-option [value]="''">
                                                            Any
                                                        </mat-option>
                                                        <mat-option *ngFor="let item of letterGradeLabelMapping | keyvalue " [value]="item.key">
                                                            {{ item.value }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                      <div class="row">
                                        <div class="col-12">
                                          <mat-form-field class="w-100">
                                            <mat-label>User</mat-label>
                                            <app-generic-model-autocomplete modelClassName="user" formControlName="user_info" [panelWidth]="480" [initialValueId]="storedParams.user"></app-generic-model-autocomplete>
                                          </mat-form-field>
                                        </div>
                                      </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <mat-form-field class="w-100">
                                                    <mat-label>NGBS Reviewer</mat-label>
                                                    <app-generic-model-autocomplete modelClassName="user" formControlName="approver_info" [filterParams]="userRequestParams" [panelWidth]="480" [initialValueId]="storedParams.approver"></app-generic-model-autocomplete>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <mat-form-field class="w-100">
                                                    <mat-label>QA Type</mat-label>
                                                    <mat-select formControlName="qa_status__requirement__type">
                                                        <mat-option [value]="''">
                                                            Any
                                                        </mat-option>
                                                        <mat-option *ngFor="let item of qaRequirementTypeChoices | keyvalue : returnZero" [value]="item.key">
                                                            {{ item.value }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <mat-form-field class="w-100">
                                                    <mat-label>Date Graded Start</mat-label>
                                                    <input matInput [matDatepicker]="datepicker_date_graded__gte" formControlName="graded_date__gte">
                                                    <mat-datepicker-toggle matSuffix (click)="filterFromGroup.get('graded_date__gte').setValue(null)">
                                                        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker-toggle matSuffix [for]="datepicker_date_graded__gte"></mat-datepicker-toggle>
                                                    <mat-datepicker #datepicker_date_graded__gte></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12">
                                                <mat-form-field class="w-100">
                                                    <mat-label>Date Graded End</mat-label>
                                                    <input matInput [matDatepicker]="datepicker_date_graded__lte" formControlName="graded_date__lte">
                                                    <mat-datepicker-toggle matSuffix (click)="filterFromGroup.get('graded_date__lte').setValue(null)">
                                                        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker-toggle matSuffix [for]="datepicker_date_graded__lte"></mat-datepicker-toggle>
                                                    <mat-datepicker #datepicker_date_graded__lte></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                            </form>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button mat-raised-button color="accent" (click)="onCreateReport($event)" class="w-100">
                                Create report
                            </button>
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="canPerformCustomerHIRLInspectionGradeQuarterReport.hasPermission">
                        <div class="col-12">
                            <button mat-raised-button color="accent" (click)="onCreateVerifierQuarterReport($event)" class="w-100">
                                Email Verifier Report Cards
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-9">
                    <ngx-datatable
                            class="material"
                            [rows]="rows"
                            columnMode="force"
                            headerHeight="40"
                            rowHeight="auto"
                            footerHeight="50"
                            limit="25"
                            externalSorting="true"
                            (sort)="onSort($event)"
                            [sorts]="[{prop: storedParams.ordering.replace('-', ''), dir: (storedParams.ordering.startsWith('-')) ? 'desc' : 'asc'}]"
                            [loadingIndicator]="isLoading">
                        <ngx-datatable-column name="Name" prop="user__full_name" [width]="140" [sortable]="false">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <a [routerLink]="['/', 'profile', row.user]">
                                    {{ row.user_fullname }}
                                </a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Company" prop="company__name" [width]="140" [sortable]="false">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <a [routerLink]="['/', 'company', row.user_company_type, row.user_company]">
                                    {{ row.user_company_name }}
                                </a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Date grading" prop="graded_date" [width]="140">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                {{ row.graded_date | date: 'MM/dd/yyyy' }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Grade" [width]="80" [sortable]="false">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <app-inspection-grade-display [inspectionGrade]="row"></app-inspection-grade-display>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Address" [width]="140" [sortable]="false">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <a [routerLink]="['/', 'home', row.home_id]" *ngIf="row.home_id">
                                    {{ row.home_address }}
                                </a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="QA Type" [width]="100" [sortable]="false">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                {{ qaRequirementTypeLabelMapping[row.qa_type] }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Program" [width]="140" [sortable]="false">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                {{ row.eep_program_name }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Reviewer Notes" [width]="150" [sortable]="false">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                {{ row.notes }}
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-footer>
                            <ng-template ngx-datatable-footer-template>
                                <div class="col-12 text-center">
                                    <datatable-pager
                                            [pagerLeftArrowIcon]="'datatable-icon-left'"
                                            [pagerRightArrowIcon]="'datatable-icon-right'"
                                            [pagerPreviousIcon]="'datatable-icon-prev'"
                                            [pagerNextIcon]="'datatable-icon-skip'"
                                            [page]="storedParams.page"
                                            [size]="25"
                                            [count]="count"
                                            (change)="onFooterPage($event)"
                                    >
                                    </datatable-pager>
                                </div>
                            </ng-template>
                        </ngx-datatable-footer>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
</div>
