import { Location, Color, AreaUnit } from '@/data/simulation/enumerations';
import { AboveGradeWallTypeBackendDict } from './AboveGradeWallType';
import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import {
  belowWarningRangeValidator,
  enumValidator,
  requiredAsWarning,
} from '@/modules/simulation/validtaors/helper';
import { FieldLabels } from '../base';
import { SimulationModelsState } from '@/modules/simulation/state/reducers';

export enum WallAreaUnit {
  SQ_FT = AreaUnit.SQ_FT,
}

export const FIELD_LABELS: FieldLabels<AboveGradeWallBackendDict> = {
  name: 'Name',
  type: 'Type',
  interior_location: 'Interior Location',
  exterior_location: 'Exterior Location',
  exterior_color: 'Exterior Color',
  area: 'Area',
  area_units: 'Area Units',
};

export enum InteriorLocation {
  CONDITIONED_SPACE = Location.CONDITIONED_SPACE,
  VENTED_ATTIC = Location.VENTED_ATTIC,
  SEALED_ATTIC = Location.SEALED_ATTIC,
  UNCONDITIONED_BASEMENT = Location.UNCONDITIONED_BASEMENT,
  CONDITIONED_BASEMENT = Location.CONDITIONED_BASEMENT,
  CRAWL_SPACE_VENTED = Location.CRAWL_SPACE_VENTED,
  CRAWL_SPACE_UNVENTED = Location.CRAWL_SPACE_UNVENTED,
  GARAGE = Location.GARAGE,
  CONDITIONED_CRAWL_SPACE = Location.CONDITIONED_CRAWL_SPACE,
}

export enum ExteriorLocation {
  AMBIENT = Location.AMBIENT,
  SEALED_ATTIC = Location.SEALED_ATTIC,
  VENTED_ATTIC = Location.VENTED_ATTIC,
  CONDITIONED_BASEMENT = Location.CONDITIONED_BASEMENT,
  UNCONDITIONED_BASEMENT = Location.UNCONDITIONED_BASEMENT,
  CRAWL_SPACE_VENTED = Location.CRAWL_SPACE_VENTED,
  CRAWL_SPACE_UNVENTED = Location.CRAWL_SPACE_UNVENTED,
  GARAGE = Location.GARAGE,
  MF_BUFFER = Location.MF_BUFFER,
  MF_NONFREEZING = Location.MF_NONFREEZING,
  MF_UNRATED_CONDITIONED = Location.MF_UNRATED_CONDITIONED,
  MF_UNRATED_HEATED = Location.MF_UNRATED_HEATED,
}

export enum ExteriorColor {
  LIGHT = Color.LIGHT,
  MEDIUM = Color.MEDIUM,
  DARK = Color.DARK,
  STD140 = Color.STD140,
  STD140_LOWABS = Color.STD140_LOWABS,
}

export interface AboveGradeWallBackendDict {
  id: number;
  type: number;
  name: string;
  interior_location: InteriorLocation;
  exterior_location: ExteriorLocation;
  exterior_color: ExteriorColor;
  area: number;
  area_units: WallAreaUnit;
}

export interface DetailedAboveGradeWallBackendDict
  extends AboveGradeWallBackendDict {
  type_info: AboveGradeWallTypeBackendDict;
}

export function createAboveGradeWallForm(wall: AboveGradeWallBackendDict) {
  return new ModelFormGroup({
    id: new ModelFormControl(wall.id, Validators.required),
    name: new ModelFormControl(wall.name, Validators.required),
    type: new ModelFormControl(wall.type, Validators.required),
    interior_location: new ModelFormControl(wall.interior_location, [
      enumValidator(InteriorLocation),
    ]),
    exterior_location: new ModelFormControl(wall.exterior_location, [
      enumValidator(ExteriorLocation),
    ]),
    area: new ModelFormControl(wall.area, [
      Validators.required,
      Validators.min(1),
      belowWarningRangeValidator(2),
    ]),
    area_units: new ModelFormControl(wall.area_units, [
      enumValidator(WallAreaUnit),
    ]),
    exterior_color: new ModelFormControl(wall.exterior_color, [
      enumValidator(ExteriorColor, true),
      requiredAsWarning(),
    ]),
  });
}

export const DEFAULT_ABOVE_GRADE_WALL: AboveGradeWallBackendDict = {
  id: null,
  name: 'Untitled Wall',
  type: null,
  interior_location: InteriorLocation.CONDITIONED_SPACE,
  exterior_location: ExteriorLocation.AMBIENT,
  exterior_color: ExteriorColor.LIGHT,
  area: 120,
  area_units: WallAreaUnit.SQ_FT,
};

export function denormalizeAboveGradeWalls(
  state: SimulationModelsState
): DetailedAboveGradeWallBackendDict[] {
  const denormalizedWalls: DetailedAboveGradeWallBackendDict[] = [];
  for (const wall of Object.values(state.aboveGradeWall.entities)) {
    denormalizedWalls.push({
      ...wall,
      type_info: state.aboveGradeWallType.entities[wall.type],
    });
  }

  return denormalizedWalls;
}
