import { createAction, props } from '@ngrx/store';

export const toggleLoading = createAction(
  '[Application] Toggle Loading',
  props<{ payload: boolean }>()
);
export const toggleBooting = createAction(
  '[Application] Toggle Booting',
  props<{ payload: boolean }>()
);
