import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as SharedActions from './shared.actions';
import { UIHelperService } from '@/shared/services/ui-helper.service';

@Injectable()
export class SharedEffects {
  reportAPIFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SharedActions.reportAPIFailure),
        tap(console.error),
        map(action => this.uiHelperService.handleUserRequestError(action.error))
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private uiHelperService: UIHelperService
  ) {}
}
