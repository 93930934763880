import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-model-action-buttons',
  templateUrl: './model-action-buttons.component.html',
  styleUrls: ['./model-action-buttons.component.scss'],
})
export class ModelActionButtonsComponent implements OnInit {
  @Input() entityId;
  @Input() modelName;
  @Input() options = ['delete', 'clone', 'history'];
  @Output() delete = new EventEmitter();
  @Output() clone = new EventEmitter();
  @Output() history = new EventEmitter();

  requiredButtons = {
    delete: false,
    clone: false,
    history: false,
  };

  ngOnInit() {
    this.requiredButtons = this.options.reduce((acc, option) => {
      acc[option] = true;
      return acc;
    }, this.requiredButtons);
  }
}
