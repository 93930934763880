
<mat-sidenav-container class="side-nav-container">
    <mat-sidenav #drawer mode="side" opened class="drawr-test" fixedTopGap="10">
        <div>
            <div class="spacer"></div>
            <div class="tabs-container">
                <div class="tab" *ngFor="let tabInfo of tabsInfo" [ngClass]="{'active-tab': tabInfo.link === activeTabLink}">
                    <a class="tab-item" [routerLink]="'.'" [queryParams]="{ tab: tabInfo.link}"
                        (click)="setActiveTabLink(tabInfo.link)">
                        <fa-icon *ngIf="tabInfo?.iconType === 'fa'" [icon]="tabInfo.icon" size="2x"></fa-icon>
                        <mat-icon *ngIf="tabInfo?.iconType === 'mat'" size="2x">{{tabInfo.icon}} [inline]="true"</mat-icon>
                        <div class="mat-line font-weight-bold fs-lg display-6" *ngIf="isExpanded">{{ tabInfo.name }}</div>
                    </a>
                    <div class="sub-tabs-container">
                        <div *ngFor="let subTab of tabInfo.subTabs">
                            <a class="sub-tab-item" [routerLink]="'.'" [queryParams]="{ tab: tabInfo.link, subTab: subTab.link}"
                                [ngClass]="{'active-color': tabInfo.link === activeTabLink}"
                                (click)="setActiveTabLink(tabInfo.link, subTab.link)">
                                <div matLine *ngIf="isExpanded">{{ subTab.name }}</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="isExpanded ? 'nav-drawer-unexpanded' : 'nav-drawer-expanded'">
        <ng-content></ng-content>
    </mat-sidenav-content>
    <mat-sidenav mode="side" opened position="end" fixedTopGap="10">
        <div class="spacer"></div>
        <mat-tab-group color="accent" class="side-tab-group" disableRipple="true" animationDuration="0ms">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span id="errors-tab">Errors</span>
                </ng-template>
                <app-simulation-errors-and-warnings></app-simulation-errors-and-warnings>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span>Summary</span>
                </ng-template>
                <app-simulation-summary></app-simulation-summary>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span>Notes</span>
                </ng-template>
                <app-notes></app-notes>
            </mat-tab>
        </mat-tab-group>
    </mat-sidenav>

</mat-sidenav-container>