<div class="row">
    <mat-card class="card-container position-relative">
        <app-loading-overlay *ngIf="loading"></app-loading-overlay>
        <mat-card-content>
            <app-form formName="doorTypeFormGroup" [formGroup]="doorTypeFormGroup" (save)="saveDoorType($event)">
                <div>
                    <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
                </div>
                <div>
                    <app-input formControlName="u_value" type="number" [label]="fieldLabels.u_value"></app-input>
                    <app-checkbox formControlName="includes_storm_door" [label]="fieldLabels.includes_storm_door"></app-checkbox>
                </div>
            </app-form>
        </mat-card-content>
    </mat-card>
</div>

