<div class="app-input-container">
    <app-label [data]="label" [presentAstrix]="required && isInEditMode" [info]="info"></app-label>
    <mat-form-field  *ngIf="isInEditMode" class="custom-input" appearance="fill">
        <mat-select [formControl]="control">
            <mat-option *ngFor="let item of items | defaultOrderKeyValue: ordering[order]" [value]="item.key">
                {{ item.value }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class="view-mode-continer input-view input-view-border" *ngIf="!isInEditMode">
        <span>{{items[control.value] | truncate: 30}}</span>
        <div class="position-relative">
            <div class="mat-select-arrow-wrapper">
                <div class="mat-select-arrow"></div>
            </div>
        </div>
    </div>
    <app-field-errors [errors]="control.errors"></app-field-errors>
</div>
