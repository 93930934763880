<ng-container [ngSwitch]="display">
    <ng-container *ngSwitchCase="displayType.link">
        <a [routerLink]="['/', 'home', home?.id]">
            {{ home?.street_line1 }}
            <span *ngIf="home?.street_line2">
            {{ home?.street_line2 }}
        </span>
            {{ home?.city_info?.name }}
            {{ home?.zipcode }}
        </a>
    </ng-container>
    <ng-container *ngSwitchCase="displayType.default">
        {{ home?.street_line1 }}
        <span *ngIf="home?.street_line2">
            {{ home?.street_line2 }}
        </span>
        {{ home?.city_info?.name }}
        {{ home?.zipcode }}
    </ng-container>
</ng-container>