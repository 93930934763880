import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { User } from '@/data/core/models/user';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { first, takeUntil } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import {
  CustomerHIRLInspectionGradeList,
  InspectionGradeAggregateByLetterGrade,
} from '@/data/user-management/models';
import {
  InspectionGradeRequestParams,
  InspectionGradeService,
} from '@/data/user-management/services/inspection-grade.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  UserRequestParams,
  UserService,
} from '@/data/core/services/user.service';
import {
  LetterGrade,
  LetterGradeLabelMapping,
} from '@/data/user-management/models/inspection-grade';
import {
  QARequirementType,
  QARequirementTypeLabelMapping,
} from '@/data/qa/models';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { InspectionGradePermissionService } from '@/modules/user-management/services/inspection-grade-permission-service';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { HIRLProjectRequestParams } from '@/data/customer-hirl/services/hirl-projects.service';

@Component({
  selector: 'app-inspection-grade-list',
  templateUrl: './inspection-grade-list.component.html',
  styleUrls: ['./inspection-grade-list.component.scss'],
})
export class InspectionGradeListComponent implements OnInit, OnDestroy {
  public currentUser: User;
  public rows: CustomerHIRLInspectionGradeList[];
  public count: number;
  public searchTerm$: Subject<string> = new Subject<string>();
  public isLoading = true;
  public customerHIRLSettings = CustomerHIRLSettings;

  public userRequestParams: UserRequestParams;

  public defaultParams: HIRLProjectRequestParams =
    new InspectionGradeRequestParams(1, '', '-id', 25);
  public storedParams: InspectionGradeRequestParams = Object.assign(
    new InspectionGradeRequestParams(),
    this.defaultParams
  );

  public letterGradeLabelMapping = LetterGradeLabelMapping;

  public filterFromGroup: UntypedFormGroup;

  public inspectionGradeAggregateByLetterGrade: InspectionGradeAggregateByLetterGrade;
  public qaRequirementTypeLabelMapping = QARequirementTypeLabelMapping;
  public qaRequirementTypeChoices = {
    [QARequirementType.roughInspection]: 'Rough Inspection',
    [QARequirementType.finalInspection]: 'Final Inspection',
    [QARequirementType.desktopAudit]: 'Desktop Audit',
    [QARequirementType.roughInspectionVirtualAudit]: 'Rough Virtual Audit',
    [QARequirementType.finalInspectionVirtualAudit]: 'Final Virtual Audit',
  };

  private componentDestroyed$ = new Subject();

  public aggregateByLetterGradeIsLoading = true;
  public averageGrade: string;
  public canPerformCustomerHIRLInspectionGradeQuarterReport =
    new ObjectPermissionResponse(false);

  constructor(
    public store: Store<AppState>,
    public inspectionGradeService: InspectionGradeService,
    public router: Router,
    public dialog: MatDialog,
    public inspectionGradePermissionService: InspectionGradePermissionService,
    public userService: UserService,
    public activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public uiHelperService: UIHelperService
  ) {
    this.userRequestParams = new UserRequestParams();
    this.userRequestParams.company__slug =
      this.customerHIRLSettings.companySlug;
    this.userRequestParams.hirluserprofile__is_qa_designee = true;
  }

  ngOnInit() {
    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
      canPerformCustomerHIRLInspectionGradeQuarterReport:
        this.inspectionGradePermissionService.canPerformCustomerHIRLInspectionGradeQuarterReport(),
    }).subscribe(
      ({
        queryParams,
        currentUser,
        canPerformCustomerHIRLInspectionGradeQuarterReport,
      }) => {
        this.storedParams.assignQueryParams(queryParams);
        this.currentUser = currentUser;

        this.canPerformCustomerHIRLInspectionGradeQuarterReport =
          canPerformCustomerHIRLInspectionGradeQuarterReport;

        this.setupFilterForm();
        this.hydrateForm();
        this.list();
      }
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      qa_status__home_status__eep_program_info: [null],
      letter_grade: [null],
      qa_status__requirement__type: [null],
      graded_date__gte: [null],
      graded_date__lte: [null],
      approver_info: [null],
      user_info: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = Object.assign(this.defaultParams, values);
        delete params.ordering;

        if (params.approver_info) {
          params.approver = params.approver_info.id;
        } else {
          params.approver = null;
        }
        delete params.approver_info;

        if (params.user_info) {
          params.user = params.user_info.id;
        } else {
          params.user = null;
        }
        delete params.user_info;

        if (params.qa_status__home_status__eep_program_info) {
          params.qa_status__home_status__eep_program =
            params.qa_status__home_status__eep_program_info.map(
              eepProgramInfo => eepProgramInfo.id
            );
        } else {
          params.qa_status__home_status__eep_program = null;
        }
        delete params.qa_status__home_status__eep_program_info;

        this.storedParams = Object.assign(this.storedParams, params);
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  list() {
    this.isLoading = true;
    this.aggregateByLetterGradeIsLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });
    this.inspectionGradeService
      .customer_hirl_list(this.storedParams)
      .pipe(first())
      .subscribe(
        data => {
          this.rows = data.results;
          this.count = data.count;
          this.isLoading = false;
        },
        error => this.uiHelperService.handleMainRequestError(error)
      );

    this.inspectionGradeService
      .aggregate_by_letter_grade(this.storedParams)
      .pipe(first())
      .subscribe(inspectionGradeAggregateByLetterGrade => {
        this.inspectionGradeAggregateByLetterGrade =
          inspectionGradeAggregateByLetterGrade;
        const total =
          this.inspectionGradeAggregateByLetterGrade.a_grade +
          this.inspectionGradeAggregateByLetterGrade.b_grade +
          this.inspectionGradeAggregateByLetterGrade.c_grade +
          this.inspectionGradeAggregateByLetterGrade.d_grade +
          this.inspectionGradeAggregateByLetterGrade.f_grade;
        const grades =
          LetterGrade.A * this.inspectionGradeAggregateByLetterGrade.a_grade +
          LetterGrade.B * this.inspectionGradeAggregateByLetterGrade.b_grade +
          LetterGrade.C * this.inspectionGradeAggregateByLetterGrade.c_grade +
          LetterGrade.D * this.inspectionGradeAggregateByLetterGrade.d_grade +
          LetterGrade.F * this.inspectionGradeAggregateByLetterGrade.f_grade;
        const average = Math.round(grades / total);
        this.averageGrade = LetterGradeLabelMapping[average];
        this.aggregateByLetterGradeIsLoading = false;
      });
  }

  applySearch(searchTerm: string) {
    this.searchTerm$.next(searchTerm);
  }

  clearSearch() {
    this.storedParams.search = '';
    this.storedParams.page = 1;
    this.list();
  }

  onSort({ sorts }) {
    this.storedParams.ordering = `${sorts[0].dir === 'desc' ? '-' : ''}${
      sorts[0].prop
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }

  // onDateGradedStartChange($event: MatDatepickerInputEvent<unknown, unknown | null>) {
  //     if ($event.value) {
  //         this.storedParams.graded_date__gte = `${formatDate($event.value as Date, 'MM/dd/yyyy', 'en-US')}`;
  //     } else {
  //         this.storedParams.graded_date__gte = '';
  //     }
  //     this.list();
  // }
  //
  // onClearDateGradedStart($event: MouseEvent) {
  //     this.storedParams.graded_date__gte = '';
  //     this.dateGradedStartControl.patchValue('');
  //     this.list();
  // }
  //
  // onClearDateGradedEnd($event: MouseEvent) {
  //     this.storedParams.graded_date__lte = '';
  //     this.dateGradedEndControl.patchValue('');
  //     this.list();
  // }
  //
  // onDateGradedEndChange($event: MatDatepickerInputEvent<unknown, unknown | null>) {
  //     if ($event.value) {
  //         this.storedParams.graded_date__lte = `${formatDate($event.value as Date, 'MM/dd/yyyy', 'en-US')}`;
  //     } else {
  //         this.storedParams.graded_date__lte = '';
  //     }
  //     this.list();
  // }

  onCreateReport($event: MouseEvent) {
    $event.preventDefault();

    this.store.dispatch(toggleLoading({ payload: true }));
    this.inspectionGradeService
      .create_approver_report(this.storedParams)
      .subscribe(
        asynchronousProcessedDocument => {
          const _ = this.router.navigate([
            'file-operation',
            'document',
            asynchronousProcessedDocument.id,
          ]);
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }

  returnZero() {
    // prevent angular keyvalue sorting
    // https://stackoverflow.com/questions/54091011/disable-the-default-keyvalue-pipe-sort-in-angular
    return 0;
  }

  onCreateVerifierQuarterReport($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: 'Are you sure you want to continue ?',
        content: `By confirming this action you will send email report for ALL verifiers`,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.store.dispatch(toggleLoading({ payload: true }));
      this.inspectionGradeService
        .customer_hirl_inspection_grade_quarter_report()
        .pipe(first())
        .subscribe(
          _ => {
            this.store.dispatch(toggleLoading({ payload: false }));
          },
          error => this.uiHelperService.handleUserRequestError(error)
        );
    });
  }
}
