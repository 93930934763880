import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MechanicalVentilationService } from '@/data/simulation/services/mechanical-ventilation.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as MechanicalVentilationActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import * as SimulationActions from '../simulation/actions';
import { MechanicalVentilationValidator } from '../../validtaors/mechanical-ventilation.validator';

@Injectable()
export class MechanicalVentilationEffects {
  loadMechanicalVentilations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MechanicalVentilationActions.loadMechanicalVentilations),
      mergeMap(action => {
        const errors = MechanicalVentilationValidator.validate(
          action.mechanicalVentilations
        );
        return of(
          MechanicalVentilationActions.loadMechanicalVentilationsSuccess({
            mechanicalVentilations: action.mechanicalVentilations,
            errors,
          })
        );
      })
    );
  });

  updateMechanicalVentilation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MechanicalVentilationActions.updateMechanicalVentilation),
      mergeMap(action => {
        return this.mechanicalVentilationService
          .update(action.mechanicalVentilationChanges)
          .pipe(
            mergeMap(mechanicalVentilation => {
              const errors = MechanicalVentilationValidator.validate([
                mechanicalVentilation,
              ]);
              return of(
                MechanicalVentilationActions.loadMechanicalVentilationsSuccess({
                  mechanicalVentilations: [mechanicalVentilation],
                  errors,
                })
              );
            }),
            catchError(error =>
              of(
                MechanicalVentilationActions.updateMechanicalVentilationFailure(
                  {
                    id: action.mechanicalVentilationChanges.id,
                  }
                ),
                SharedActions.reportAPIFailure({ error })
              )
            )
          );
      })
    );
  });

  removeMechanicalVentilation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MechanicalVentilationActions.removeMechanicalVentilation),
      mergeMap(action =>
        this.mechanicalVentilationService
          .delete(action.mechanicalVentilation.id)
          .pipe(
            mergeMap(() =>
              of(
                MechanicalVentilationActions.removeMechanicalVentilationSuccess(
                  {
                    id: action.mechanicalVentilation.id,
                  }
                ),
                SimulationActions.removeItemFromList({
                  fieldName: 'mechanical_ventilation_systems',
                  id: action.mechanicalVentilation.id,
                })
              )
            ),
            catchError(error =>
              of(
                MechanicalVentilationActions.removeMechanicalVentilationFailure(
                  {
                    id: action.mechanicalVentilation.id,
                  }
                ),
                SharedActions.reportAPIFailure({ error })
              )
            )
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private mechanicalVentilationService: MechanicalVentilationService
  ) {}
}
