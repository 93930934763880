import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { SlabTypeBackendDict } from './SlabType';
import { AreaUnit, LengthUnit, Location } from '../../enumerations';
import { enumValidator } from '@/modules/simulation/validtaors/helper';
import { FieldLabels } from '../base';
import { SimulationModelsState } from '@/modules/simulation/state/reducers';

export enum InteriorLocation {
  CONDITIONED_SPACE = Location.CONDITIONED_SPACE,
  CONDITIONED_BASEMENT = Location.CONDITIONED_BASEMENT,
  CONDITIONED_CRAWL_SPACE = Location.CONDITIONED_CRAWL_SPACE,
  GARAGE = Location.GARAGE,
  UNCONDITIONED_BASEMENT = Location.UNCONDITIONED_BASEMENT,
  CRAWL_SPACE_VENTED = Location.CRAWL_SPACE_VENTED,
  CRAWL_SPACE_UNVENTED = Location.CRAWL_SPACE_UNVENTED,
}

export function isCrawlSpaceLocation(location: InteriorLocation): boolean {
  const CRAWLSPACE_LOCATIONS = new Set<InteriorLocation>([
    InteriorLocation.CRAWL_SPACE_VENTED,
    InteriorLocation.CRAWL_SPACE_UNVENTED,
    InteriorLocation.CONDITIONED_CRAWL_SPACE,
  ]);
  return CRAWLSPACE_LOCATIONS.has(location);
}

export const DEFAULT_SLAB: SlabBackendDict = {
  id: null,
  type: null,
  name: '',
  area: 0.0,
  area_units: AreaUnit.SQ_FT,
  perimeter: 0.0,
  exposed_perimeter: 0.0,
  is_on_grade: false,
  depth_below_grade: 0.0,
  units: LengthUnit.FT,
  slab_exposed_area: 0.0,
  interior_location: InteriorLocation.CONDITIONED_SPACE,
  carpet_fraction: 0.0,
  carpet_r_value: 0.0,
  note: '',
};

// Labels
export const FIELD_LABELS: FieldLabels<SlabBackendDict> = {
  name: 'Name',
  type: 'Type',
  area: 'Area',
  area_units: 'Area Units',
  perimeter: 'Perimeter',
  exposed_perimeter: 'Exposed Perimeter',
  is_on_grade: 'Is On Grade',
  depth_below_grade: 'Depth Below Grade',
  units: 'Units',
  slab_exposed_area: 'Slab Exposed Area',
  interior_location: 'Interior Location',
  carpet_fraction: 'Carpet Fraction',
  carpet_r_value: 'Carpet R-Value',
  note: 'Note',
};

export interface SlabBackendDict {
  id: number;
  type: number;
  name: string;
  area: number;
  area_units: AreaUnit.SQ_FT;
  perimeter: number;
  exposed_perimeter: number;
  is_on_grade: boolean;
  depth_below_grade: number;
  units: LengthUnit.FT;
  slab_exposed_area: number;
  interior_location: InteriorLocation;
  carpet_fraction: number;
  carpet_r_value: number;
  note: string;
}

export interface DetailedSlabBackendDict extends SlabBackendDict {
  type_info: SlabTypeBackendDict;
}

export function createSlabForm(slab: SlabBackendDict) {
  return new ModelFormGroup(
    {
      name: new ModelFormControl(slab.name, [
        Validators.required,
        Validators.maxLength(128),
      ]),
      type: new ModelFormControl(slab.type, Validators.required),
      area: new ModelFormControl(slab.area, [
        Validators.required,
        Validators.min(0),
      ]),
      area_units: new ModelFormControl(slab.area_units, [
        enumValidator([AreaUnit.SQ_FT]),
      ]),
      perimeter: new ModelFormControl(slab.perimeter, [
        Validators.required,
        Validators.min(0),
      ]),
      exposed_perimeter: new ModelFormControl(slab.exposed_perimeter, [
        Validators.required,
        Validators.min(0),
      ]),
      is_on_grade: new ModelFormControl(slab.is_on_grade, Validators.required),
      depth_below_grade: new ModelFormControl(
        slab.depth_below_grade,
        Validators.min(0)
      ),
      carpet_fraction: new ModelFormControl(slab.carpet_fraction, [
        Validators.min(0),
        Validators.max(1),
      ]),
      carpet_r_value: new ModelFormControl(slab.carpet_r_value),
      slab_exposed_area: new ModelFormControl(slab.slab_exposed_area, [
        Validators.required,
        Validators.min(0),
      ]),
      interior_location: new ModelFormControl(slab.interior_location, [
        Validators.required,
        enumValidator(InteriorLocation),
      ]),
      note: new ModelFormControl(slab.note),
    },
    {
      validators: [validatePerimeter],
    }
  );
}

function validatePerimeter(controls: ModelFormControl) {
  // Permieter should be less than area.
  const area = controls.get('area').value;
  const perimeter = controls.get('perimeter').value;
  if (perimeter > area) {
    return { perimeterGreaterThanArea: true };
  }
}

export function denormalizeSlabs(state: SimulationModelsState) {
  const denormalizedSlab: DetailedSlabBackendDict[] = [];
  for (const slab of Object.values(state.slab.entities)) {
    denormalizedSlab.push({
      ...slab,
      type_info: state.slabType[slab.type],
    });
  }
  return denormalizedSlab;
}
